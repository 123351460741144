import axios from 'axios';
import {
  ICrudSearchAction,
  parseHeaderForLinks,
  loadMoreDataWhenScrolled,
  ICrudGetAction,
  ICrudGetAllAction,
  ICrudPutAction,
  ICrudDeleteAction
} from 'react-jhipster';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

import { IResponseGamma, defaultValue } from 'app/shared/model/response-gamma.model';

export const ACTION_TYPES = {
  SEARCH_RESPONSEGAMMAS: 'responseGamma/SEARCH_RESPONSEGAMMAS',
  FETCH_RESPONSEGAMMA_LIST: 'responseGamma/FETCH_RESPONSEGAMMA_LIST',
  FETCH_RESPONSEGAMMA: 'responseGamma/FETCH_RESPONSEGAMMA',
  CREATE_RESPONSEGAMMA: 'responseGamma/CREATE_RESPONSEGAMMA',
  UPDATE_RESPONSEGAMMA: 'responseGamma/UPDATE_RESPONSEGAMMA',
  DELETE_RESPONSEGAMMA: 'responseGamma/DELETE_RESPONSEGAMMA',
  RESET: 'responseGamma/RESET'
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<IResponseGamma>,
  entity: defaultValue,
  links: { next: 0 },
  updating: false,
  totalItems: 0,
  updateSuccess: false
};

export type ResponseGammaState = Readonly<typeof initialState>;

// Reducer

export default (state: ResponseGammaState = initialState, action): ResponseGammaState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.SEARCH_RESPONSEGAMMAS):
    case REQUEST(ACTION_TYPES.FETCH_RESPONSEGAMMA_LIST):
    case REQUEST(ACTION_TYPES.FETCH_RESPONSEGAMMA):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true
      };
    case REQUEST(ACTION_TYPES.CREATE_RESPONSEGAMMA):
    case REQUEST(ACTION_TYPES.UPDATE_RESPONSEGAMMA):
    case REQUEST(ACTION_TYPES.DELETE_RESPONSEGAMMA):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true
      };
    case FAILURE(ACTION_TYPES.SEARCH_RESPONSEGAMMAS):
    case FAILURE(ACTION_TYPES.FETCH_RESPONSEGAMMA_LIST):
    case FAILURE(ACTION_TYPES.FETCH_RESPONSEGAMMA):
    case FAILURE(ACTION_TYPES.CREATE_RESPONSEGAMMA):
    case FAILURE(ACTION_TYPES.UPDATE_RESPONSEGAMMA):
    case FAILURE(ACTION_TYPES.DELETE_RESPONSEGAMMA):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload
      };
    case SUCCESS(ACTION_TYPES.SEARCH_RESPONSEGAMMAS):
    case SUCCESS(ACTION_TYPES.FETCH_RESPONSEGAMMA_LIST):
      const links = parseHeaderForLinks(action.payload.headers.link);
      return {
        ...state,
        links,
        loading: false,
        totalItems: action.payload.headers['x-total-count'],
        entities: loadMoreDataWhenScrolled(state.entities, action.payload.data, links)
      };
    case SUCCESS(ACTION_TYPES.FETCH_RESPONSEGAMMA):
      return {
        ...state,
        loading: false,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.CREATE_RESPONSEGAMMA):
    case SUCCESS(ACTION_TYPES.UPDATE_RESPONSEGAMMA):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.DELETE_RESPONSEGAMMA):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {}
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

const apiUrl = 'api/response-gammas';
const apiSearchUrl = 'api/_search/response-gammas';

// Actions

export const getSearchEntities: ICrudSearchAction<IResponseGamma> = (query, page, size, sort) => ({
  type: ACTION_TYPES.SEARCH_RESPONSEGAMMAS,
  payload: axios.get<IResponseGamma>(`${apiSearchUrl}?query=${query}${sort ? `&page=${page}&size=${size}&sort=${sort}` : ''}`)
});

export const getEntities: ICrudGetAllAction<IResponseGamma> = (page, size, sort) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.FETCH_RESPONSEGAMMA_LIST,
    payload: axios.get<IResponseGamma>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`)
  };
};

export const getEntity: ICrudGetAction<IResponseGamma> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_RESPONSEGAMMA,
    payload: axios.get<IResponseGamma>(requestUrl)
  };
};

export const createEntity: ICrudPutAction<IResponseGamma> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_RESPONSEGAMMA,
    payload: axios.post(apiUrl, cleanEntity(entity))
  });
  return result;
};

export const updateEntity: ICrudPutAction<IResponseGamma> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_RESPONSEGAMMA,
    payload: axios.put(apiUrl, cleanEntity(entity))
  });
  return result;
};

export const deleteEntity: ICrudDeleteAction<IResponseGamma> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_RESPONSEGAMMA,
    payload: axios.delete(requestUrl)
  });
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET
});
