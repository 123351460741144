import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import ClientQualificationResponse from './client-qualification-response';
import ClientQualificationResponseDetail from './client-qualification-response-detail';
import ClientQualificationResponseUpdate from './client-qualification-response-update';
import ClientQualificationResponseDeleteDialog from './client-qualification-response-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={ClientQualificationResponseUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={ClientQualificationResponseUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={ClientQualificationResponseDetail} />
      <ErrorBoundaryRoute path={match.url} component={ClientQualificationResponse} />
    </Switch>
    <ErrorBoundaryRoute path={`${match.url}/:id/delete`} component={ClientQualificationResponseDeleteDialog} />
  </>
);

export default Routes;
