import { Moment } from 'moment';
import { IRefusedProposalResponse } from 'app/shared/model/refused-proposal-response.model';

export interface IRefusedProposalSync {
  id?: number;
  receivedIn?: Moment;
  synchronizedIn?: Moment;
  responses?: IRefusedProposalResponse[];
  syonetRefusedProposalId?: number;
}

export const defaultValue: Readonly<IRefusedProposalSync> = {};
