import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, UncontrolledTooltip, Row, Col } from 'reactstrap';
// tslint:disable-next-line:no-unused-variable
import { Translate, ICrudGetAction, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntity } from './syonet-visit-showroom.reducer';
import { ISyonetVisitShowroom } from 'app/shared/model/syonet-visit-showroom.model';
// tslint:disable-next-line:no-unused-variable
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';

export interface ISyonetVisitShowroomDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export class SyonetVisitShowroomDetail extends React.Component<ISyonetVisitShowroomDetailProps> {
  componentDidMount() {
    this.props.getEntity(this.props.match.params.id);
  }

  render() {
    const { syonetVisitShowroomEntity } = this.props;
    return (
      <Row>
        <Col md="8">
          <h2>
            <Translate contentKey="nissanApp.syonetVisitShowroom.detail.title">SyonetVisitShowroom</Translate> [
            <b>{syonetVisitShowroomEntity.id}</b>]
          </h2>
          <dl className="jh-entity-details">
            <dt>
              <span id="lastSync">
                <Translate contentKey="nissanApp.syonetVisitShowroom.lastSync">Last Sync</Translate>
              </span>
              <UncontrolledTooltip target="lastSync">
                <Translate contentKey="nissanApp.syonetVisitShowroom.help.lastSync" />
              </UncontrolledTooltip>
            </dt>
            <dd>
              <TextFormat value={syonetVisitShowroomEntity.lastSync} type="date" format={APP_DATE_FORMAT} />
            </dd>
            <dt>
              <span id="crmID">
                <Translate contentKey="nissanApp.syonetVisitShowroom.crmID">Crm ID</Translate>
              </span>
              <UncontrolledTooltip target="crmID">
                <Translate contentKey="nissanApp.syonetVisitShowroom.help.crmID" />
              </UncontrolledTooltip>
            </dt>
            <dd>{syonetVisitShowroomEntity.crmID}</dd>
            <dt>
              <span id="extracionDate">
                <Translate contentKey="nissanApp.syonetVisitShowroom.extracionDate">Extracion Date</Translate>
              </span>
              <UncontrolledTooltip target="extracionDate">
                <Translate contentKey="nissanApp.syonetVisitShowroom.help.extracionDate" />
              </UncontrolledTooltip>
            </dt>
            <dd>
              <TextFormat value={syonetVisitShowroomEntity.extracionDate} type="date" format={APP_DATE_FORMAT} />
            </dd>
            <dt>
              <span id="attendanceID">
                <Translate contentKey="nissanApp.syonetVisitShowroom.attendanceID">Attendance ID</Translate>
              </span>
              <UncontrolledTooltip target="attendanceID">
                <Translate contentKey="nissanApp.syonetVisitShowroom.help.attendanceID" />
              </UncontrolledTooltip>
            </dt>
            <dd>{syonetVisitShowroomEntity.attendanceID}</dd>
            <dt>
              <span id="nissanLeadID">
                <Translate contentKey="nissanApp.syonetVisitShowroom.nissanLeadID">Nissan Lead ID</Translate>
              </span>
              <UncontrolledTooltip target="nissanLeadID">
                <Translate contentKey="nissanApp.syonetVisitShowroom.help.nissanLeadID" />
              </UncontrolledTooltip>
            </dt>
            <dd>{syonetVisitShowroomEntity.nissanLeadID}</dd>
            <dt>
              <span id="visitDate">
                <Translate contentKey="nissanApp.syonetVisitShowroom.visitDate">Visit Date</Translate>
              </span>
              <UncontrolledTooltip target="visitDate">
                <Translate contentKey="nissanApp.syonetVisitShowroom.help.visitDate" />
              </UncontrolledTooltip>
            </dt>
            <dd>
              <TextFormat value={syonetVisitShowroomEntity.visitDate} type="date" format={APP_DATE_FORMAT} />
            </dd>
            <dt>
              <span id="flagReturn">
                <Translate contentKey="nissanApp.syonetVisitShowroom.flagReturn">Flag Return</Translate>
              </span>
              <UncontrolledTooltip target="flagReturn">
                <Translate contentKey="nissanApp.syonetVisitShowroom.help.flagReturn" />
              </UncontrolledTooltip>
            </dt>
            <dd>{syonetVisitShowroomEntity.flagReturn ? 'true' : 'false'}</dd>
            <dt>
              <Translate contentKey="nissanApp.syonetVisitShowroom.syonetLead">Syonet Lead</Translate>
            </dt>
            <dd>{syonetVisitShowroomEntity.syonetLeadId ? syonetVisitShowroomEntity.syonetLeadId : ''}</dd>
          </dl>
          <Button tag={Link} to="/entity/syonet-visit-showroom" replace color="info">
            <FontAwesomeIcon icon="arrow-left" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.back">Back</Translate>
            </span>
          </Button>
          &nbsp;
          <Button tag={Link} to={`/entity/syonet-visit-showroom/${syonetVisitShowroomEntity.id}/edit`} replace color="primary">
            <FontAwesomeIcon icon="pencil-alt" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.edit">Edit</Translate>
            </span>
          </Button>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = ({ syonetVisitShowroom }: IRootState) => ({
  syonetVisitShowroomEntity: syonetVisitShowroom.entity
});

const mapDispatchToProps = { getEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SyonetVisitShowroomDetail);
