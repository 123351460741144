import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import ResponseGammaStatusCode from './response-gamma-status-code';
import ResponseGammaStatusCodeDetail from './response-gamma-status-code-detail';
import ResponseGammaStatusCodeUpdate from './response-gamma-status-code-update';
import ResponseGammaStatusCodeDeleteDialog from './response-gamma-status-code-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={ResponseGammaStatusCodeUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={ResponseGammaStatusCodeUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={ResponseGammaStatusCodeDetail} />
      <ErrorBoundaryRoute path={match.url} component={ResponseGammaStatusCode} />
    </Switch>
    <ErrorBoundaryRoute path={`${match.url}/:id/delete`} component={ResponseGammaStatusCodeDeleteDialog} />
  </>
);

export default Routes;
