import { Moment } from 'moment';
import { ISchedulingLeadSync } from 'app/shared/model/scheduling-lead-sync.model';

export interface ISyonetSchedulingLead {
  id?: number;
  lastSync?: Moment;
  crmID?: string;
  extracionDate?: Moment;
  attendanceID?: number;
  nissanLeadID?: number;
  contactDate?: Moment;
  comment?: string;
  scheduledDate?: Moment;
  flagTestDrive?: boolean;
  syonetLeadId?: number;
  syncs?: ISchedulingLeadSync[];
}

export const defaultValue: Readonly<ISyonetSchedulingLead> = {
  flagTestDrive: false
};
