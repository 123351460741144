import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import SchedulingLeadSync from './scheduling-lead-sync';
import SchedulingLeadSyncDetail from './scheduling-lead-sync-detail';
import SchedulingLeadSyncUpdate from './scheduling-lead-sync-update';
import SchedulingLeadSyncDeleteDialog from './scheduling-lead-sync-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={SchedulingLeadSyncUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={SchedulingLeadSyncUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={SchedulingLeadSyncDetail} />
      <ErrorBoundaryRoute path={match.url} component={SchedulingLeadSync} />
    </Switch>
    <ErrorBoundaryRoute path={`${match.url}/:id/delete`} component={SchedulingLeadSyncDeleteDialog} />
  </>
);

export default Routes;
