import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, UncontrolledTooltip, Row, Col } from 'reactstrap';
// tslint:disable-next-line:no-unused-variable
import { Translate, ICrudGetAction, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntity, reprocess } from './account-info.reducer';
import { IAccountInfo } from 'app/shared/model/account-info.model';
// tslint:disable-next-line:no-unused-variable
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';

export interface IAccountInfoDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export class AccountInfoDetail extends React.Component<IAccountInfoDetailProps> {
  componentDidMount() {
    this.props.getEntity(this.props.match.params.id);
  }

  reprocessLeads = () => {
    this.props.reprocess(this.props.accountInfoEntity.id);
  };

  render() {
    const { accountInfoEntity } = this.props;
    return (
      <Row>
        <Col md="8">
          <h2>
            <Translate contentKey="nissanApp.accountInfo.detail.title">AccountInfo</Translate> [<b>{accountInfoEntity.id}</b>]
          </h2>
          <dl className="jh-entity-details">
            <dt>
              <span id="url">
                <Translate contentKey="nissanApp.accountInfo.url">Url</Translate>
              </span>
              <UncontrolledTooltip target="url">
                <Translate contentKey="nissanApp.accountInfo.help.url" />
              </UncontrolledTooltip>
            </dt>
            <dd>{accountInfoEntity.url}</dd>
            <dt>
              <span id="userSyonet">
                <Translate contentKey="nissanApp.accountInfo.userSyonet">User Syonet</Translate>
              </span>
              <UncontrolledTooltip target="userSyonet">
                <Translate contentKey="nissanApp.accountInfo.help.userSyonet" />
              </UncontrolledTooltip>
            </dt>
            <dd>{accountInfoEntity.userSyonet}</dd>
            <dt>
              <span id="passSyonet">
                <Translate contentKey="nissanApp.accountInfo.passSyonet">Pass Syonet</Translate>
              </span>
              <UncontrolledTooltip target="passSyonet">
                <Translate contentKey="nissanApp.accountInfo.help.passSyonet" />
              </UncontrolledTooltip>
            </dt>
            <dd>{accountInfoEntity.passSyonet}</dd>
            <dt>
              <span id="enterpriseCRMID">
                <Translate contentKey="nissanApp.accountInfo.enterpriseCRMID">Enterprise CRMID</Translate>
              </span>
              <UncontrolledTooltip target="enterpriseCRMID">
                <Translate contentKey="nissanApp.accountInfo.help.enterpriseCRMID" />
              </UncontrolledTooltip>
            </dt>
            <dd>{accountInfoEntity.enterpriseCRMID}</dd>
            <dt>
              <span id="birCode">
                <Translate contentKey="nissanApp.accountInfo.birCode">Bir Code</Translate>
              </span>
              <UncontrolledTooltip target="birCode">
                <Translate contentKey="nissanApp.accountInfo.help.birCode" />
              </UncontrolledTooltip>
            </dt>
            <dd>{accountInfoEntity.birCode}</dd>
            <dt>
              <span id="enterpriseID">
                <Translate contentKey="nissanApp.accountInfo.enterpriseID">Enterprise ID</Translate>
              </span>
              <UncontrolledTooltip target="enterpriseID">
                <Translate contentKey="nissanApp.accountInfo.help.enterpriseID" />
              </UncontrolledTooltip>
            </dt>
            <dd>{accountInfoEntity.enterpriseID}</dd>
            <dt>
              <span id="userNissan">
                <Translate contentKey="nissanApp.accountInfo.userNissan">User Nissan</Translate>
              </span>
              <UncontrolledTooltip target="userNissan">
                <Translate contentKey="nissanApp.accountInfo.help.userNissan" />
              </UncontrolledTooltip>
            </dt>
            <dd>{accountInfoEntity.userNissan}</dd>
            <dt>
              <span id="passNissan">
                <Translate contentKey="nissanApp.accountInfo.passNissan">Pass Nissan</Translate>
              </span>
              <UncontrolledTooltip target="passNissan">
                <Translate contentKey="nissanApp.accountInfo.help.passNissan" />
              </UncontrolledTooltip>
            </dt>
            <dd>{accountInfoEntity.passNissan}</dd>
            <dt>
              <span id="sourceSystem">
                <Translate contentKey="nissanApp.accountInfo.sourceSystem">Source System</Translate>
              </span>
              <UncontrolledTooltip target="sourceSystem">
                <Translate contentKey="nissanApp.accountInfo.help.sourceSystem" />
              </UncontrolledTooltip>
            </dt>
            <dd>{accountInfoEntity.sourceSystem}</dd>
            <dt>
              <span id="group">
                <Translate contentKey="nissanApp.accountInfo.group">Group</Translate>
              </span>
              <UncontrolledTooltip target="group">
                <Translate contentKey="nissanApp.accountInfo.help.group" />
              </UncontrolledTooltip>
            </dt>
            <dd>{accountInfoEntity.group}</dd>
            <dt>
              <span id="name">
                <Translate contentKey="nissanApp.accountInfo.name">Name</Translate>
              </span>
              <UncontrolledTooltip target="name">
                <Translate contentKey="nissanApp.accountInfo.help.name" />
              </UncontrolledTooltip>
            </dt>
            <dd>{accountInfoEntity.name}</dd>
            <dt>
              <span id="webServiceVersion">
                <Translate contentKey="nissanApp.accountInfo.webServiceVersion">Web Service Version</Translate>
              </span>
              <UncontrolledTooltip target="webServiceVersion">
                <Translate contentKey="nissanApp.accountInfo.help.webServiceVersion" />
              </UncontrolledTooltip>
            </dt>
            <dd>{accountInfoEntity.webServiceVersion}</dd>
            <dt>
              <span id="lastSync">
                <Translate contentKey="nissanApp.accountInfo.lastSync">Last Sync</Translate>
              </span>
              <UncontrolledTooltip target="lastSync">
                <Translate contentKey="nissanApp.accountInfo.help.lastSync" />
              </UncontrolledTooltip>
            </dt>
            <dd>
              <TextFormat value={accountInfoEntity.lastSync} type="date" format={APP_DATE_FORMAT} />
            </dd>
          </dl>
          <Button tag={Link} to="/entity/account-info" replace color="info">
            <FontAwesomeIcon icon="arrow-left" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.back">Back</Translate>
            </span>
          </Button>
          &nbsp;
          <Button tag={Link} to={`/entity/account-info/${accountInfoEntity.id}/edit`} replace color="primary">
            <FontAwesomeIcon icon="pencil-alt" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.edit">Edit</Translate>
            </span>
          </Button>
          &nbsp;
          <Button color="primary" onClick={this.reprocessLeads} replace>
            <FontAwesomeIcon icon="cog" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.reprocess">Reprocess</Translate>
            </span>
          </Button>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = ({ accountInfo }: IRootState) => ({
  accountInfoEntity: accountInfo.entity
});

const mapDispatchToProps = { getEntity, reprocess };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountInfoDetail);
