import React from 'react';
import { Switch } from 'react-router-dom';

// tslint:disable-next-line:no-unused-variable
import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import AttendantLeadMessage from './attendant-lead-message';
import AttendantLeadStatusCode from './attendant-lead-status-code';
import ApprovedProposalMessage from './approved-proposal-message';
import ApprovedProposalStatusCode from './approved-proposal-status-code';
import ApprovedProposalSync from './approved-proposal-sync';
import ApprovedProposalResponse from './approved-proposal-response';
import ClientQualificationMessage from './client-qualification-message';
import ClientQualificationStatusCode from './client-qualification-status-code';
import ClientQualificationSync from './client-qualification-sync';
import ClientQualificationResponse from './client-qualification-response';
import TestDriveMessage from './test-drive-message';
import TestDriveStatusCode from './test-drive-status-code';
import TestDriveSync from './test-drive-sync';
import TestDriveResponse from './test-drive-response';
import VisitShowroomLeadMessage from './visit-showroom-lead-message';
import VisitShowroomStatusCode from './visit-showroom-status-code';
import VisitShowroomSync from './visit-showroom-sync';
import VisitShowroomResponse from './visit-showroom-response';
import NegotiationLeadMessage from './negotiation-lead-message';
import NegotiationStatusCode from './negotiation-status-code';
import NegotiationSync from './negotiation-sync';
import NegotiationResponse from './negotiation-response';
import FollowUpLeadMessage from './follow-up-lead-message';
import FollowUpStatusCode from './follow-up-status-code';
import FollowUpSync from './follow-up-sync';
import FollowUpResponse from './follow-up-response';
import RefusedProposalLeadMessage from './refused-proposal-lead-message';
import RefusedProposalStatusCode from './refused-proposal-status-code';
import RefusedProposalSync from './refused-proposal-sync';
import RefusedProposalResponse from './refused-proposal-response';
import SchedulingLeadResponse from './scheduling-lead-response';
import SchedulingLeadMessage from './scheduling-lead-message';
import SchedulingLeadStatusCode from './scheduling-lead-status-code';
import SchedulingLeadSync from './scheduling-lead-sync';
import SyonetLead from './syonet-lead';
import AccountInfo from './account-info';
import ExecutionHotLead from './execution-hot-lead';
import ExecutionGamma from './execution-gamma';
import SyonetAttendantLead from './syonet-attendant-lead';
import SyonetSchedulingLead from './syonet-scheduling-lead';
import SyonetVisitShowroom from './syonet-visit-showroom';
import SyonetClientQualification from './syonet-client-qualification';
import ClientQualificationVehicleChange from './client-qualification-vehicle-change';
import ClientQualificationVehicleInterested from './client-qualification-vehicle-interested';
import SyonetTestDrive from './syonet-test-drive';
import SyonetNegotiation from './syonet-negotiation';
import NegotiationVehicleInterested from './negotiation-vehicle-interested';
import NegotiationVehicleOnTrade from './negotiation-vehicle-on-trade';
import SyonetApprovedProposal from './syonet-approved-proposal';
import ClientApprovedProposal from './client-approved-proposal';
import VehicleSaledApprovedProposal from './vehicle-saled-approved-proposal';
import SyonetRefusedProposal from './syonet-refused-proposal';
import SyonetFollowUp from './syonet-follow-up';
import AttendantLeadResponse from './attendant-lead-response';
import ResponseHotLead from './response-hot-lead';
import VehicleNissanInterest from './vehicle-nissan-interest';
import VehicleExchangeNissan from './vehicle-exchange-nissan';
import Lead from './lead';
import NissanModel from './nissan-model';
import Color from './color';
import ResponseGammaMessage from './response-gamma-message';
import ResponseGammaStatusCode from './response-gamma-status-code';
import ResponseHotLeadMessage from './response-hot-lead-message';
import ResponseHotLeadStatusCode from './response-hot-lead-status-code';
import ResponseGamma from './response-gamma';
import SyncGamma from './sync-gamma';
import SyncHotLead from './sync-hot-lead';
import AttendantLeadSync from './attendant-lead-sync';
/* jhipster-needle-add-route-import - JHipster will add routes here */

const Routes = ({ match }) => (
  <div>
    <Switch>
      {/* prettier-ignore */}
      <ErrorBoundaryRoute path={`${match.url}/attendant-lead-message`} component={AttendantLeadMessage} />
      <ErrorBoundaryRoute path={`${match.url}/attendant-lead-status-code`} component={AttendantLeadStatusCode} />
      <ErrorBoundaryRoute path={`${match.url}/approved-proposal-message`} component={ApprovedProposalMessage} />
      <ErrorBoundaryRoute path={`${match.url}/approved-proposal-status-code`} component={ApprovedProposalStatusCode} />
      <ErrorBoundaryRoute path={`${match.url}/approved-proposal-sync`} component={ApprovedProposalSync} />
      <ErrorBoundaryRoute path={`${match.url}/approved-proposal-response`} component={ApprovedProposalResponse} />
      <ErrorBoundaryRoute path={`${match.url}/client-qualification-message`} component={ClientQualificationMessage} />
      <ErrorBoundaryRoute path={`${match.url}/client-qualification-status-code`} component={ClientQualificationStatusCode} />
      <ErrorBoundaryRoute path={`${match.url}/client-qualification-sync`} component={ClientQualificationSync} />
      <ErrorBoundaryRoute path={`${match.url}/client-qualification-response`} component={ClientQualificationResponse} />
      <ErrorBoundaryRoute path={`${match.url}/test-drive-message`} component={TestDriveMessage} />
      <ErrorBoundaryRoute path={`${match.url}/test-drive-status-code`} component={TestDriveStatusCode} />
      <ErrorBoundaryRoute path={`${match.url}/test-drive-sync`} component={TestDriveSync} />
      <ErrorBoundaryRoute path={`${match.url}/test-drive-response`} component={TestDriveResponse} />
      <ErrorBoundaryRoute path={`${match.url}/visit-showroom-lead-message`} component={VisitShowroomLeadMessage} />
      <ErrorBoundaryRoute path={`${match.url}/visit-showroom-status-code`} component={VisitShowroomStatusCode} />
      <ErrorBoundaryRoute path={`${match.url}/visit-showroom-sync`} component={VisitShowroomSync} />
      <ErrorBoundaryRoute path={`${match.url}/visit-showroom-response`} component={VisitShowroomResponse} />
      <ErrorBoundaryRoute path={`${match.url}/negotiation-lead-message`} component={NegotiationLeadMessage} />
      <ErrorBoundaryRoute path={`${match.url}/negotiation-status-code`} component={NegotiationStatusCode} />
      <ErrorBoundaryRoute path={`${match.url}/negotiation-sync`} component={NegotiationSync} />
      <ErrorBoundaryRoute path={`${match.url}/negotiation-response`} component={NegotiationResponse} />
      <ErrorBoundaryRoute path={`${match.url}/follow-up-lead-message`} component={FollowUpLeadMessage} />
      <ErrorBoundaryRoute path={`${match.url}/follow-up-status-code`} component={FollowUpStatusCode} />
      <ErrorBoundaryRoute path={`${match.url}/follow-up-sync`} component={FollowUpSync} />
      <ErrorBoundaryRoute path={`${match.url}/follow-up-response`} component={FollowUpResponse} />
      <ErrorBoundaryRoute path={`${match.url}/refused-proposal-lead-message`} component={RefusedProposalLeadMessage} />
      <ErrorBoundaryRoute path={`${match.url}/refused-proposal-status-code`} component={RefusedProposalStatusCode} />
      <ErrorBoundaryRoute path={`${match.url}/refused-proposal-sync`} component={RefusedProposalSync} />
      <ErrorBoundaryRoute path={`${match.url}/refused-proposal-response`} component={RefusedProposalResponse} />
      <ErrorBoundaryRoute path={`${match.url}/scheduling-lead-response`} component={SchedulingLeadResponse} />
      <ErrorBoundaryRoute path={`${match.url}/scheduling-lead-message`} component={SchedulingLeadMessage} />
      <ErrorBoundaryRoute path={`${match.url}/scheduling-lead-status-code`} component={SchedulingLeadStatusCode} />
      <ErrorBoundaryRoute path={`${match.url}/scheduling-lead-sync`} component={SchedulingLeadSync} />
      <ErrorBoundaryRoute path={`${match.url}/syonet-lead`} component={SyonetLead} />
      <ErrorBoundaryRoute path={`${match.url}/account-info`} component={AccountInfo} />
      <ErrorBoundaryRoute path={`${match.url}/execution-hot-lead`} component={ExecutionHotLead} />
      <ErrorBoundaryRoute path={`${match.url}/execution-gamma`} component={ExecutionGamma} />
      <ErrorBoundaryRoute path={`${match.url}/syonet-attendant-lead`} component={SyonetAttendantLead} />
      <ErrorBoundaryRoute path={`${match.url}/syonet-scheduling-lead`} component={SyonetSchedulingLead} />
      <ErrorBoundaryRoute path={`${match.url}/syonet-visit-showroom`} component={SyonetVisitShowroom} />
      <ErrorBoundaryRoute path={`${match.url}/syonet-client-qualification`} component={SyonetClientQualification} />
      <ErrorBoundaryRoute path={`${match.url}/client-qualification-vehicle-change`} component={ClientQualificationVehicleChange} />
      <ErrorBoundaryRoute path={`${match.url}/client-qualification-vehicle-interested`} component={ClientQualificationVehicleInterested} />
      <ErrorBoundaryRoute path={`${match.url}/syonet-test-drive`} component={SyonetTestDrive} />
      <ErrorBoundaryRoute path={`${match.url}/syonet-negotiation`} component={SyonetNegotiation} />
      <ErrorBoundaryRoute path={`${match.url}/negotiation-vehicle-interested`} component={NegotiationVehicleInterested} />
      <ErrorBoundaryRoute path={`${match.url}/negotiation-vehicle-on-trade`} component={NegotiationVehicleOnTrade} />
      <ErrorBoundaryRoute path={`${match.url}/syonet-approved-proposal`} component={SyonetApprovedProposal} />
      <ErrorBoundaryRoute path={`${match.url}/client-approved-proposal`} component={ClientApprovedProposal} />
      <ErrorBoundaryRoute path={`${match.url}/vehicle-saled-approved-proposal`} component={VehicleSaledApprovedProposal} />
      <ErrorBoundaryRoute path={`${match.url}/syonet-refused-proposal`} component={SyonetRefusedProposal} />
      <ErrorBoundaryRoute path={`${match.url}/syonet-follow-up`} component={SyonetFollowUp} />
      <ErrorBoundaryRoute path={`${match.url}/attendant-lead-response`} component={AttendantLeadResponse} />
      <ErrorBoundaryRoute path={`${match.url}/response-hot-lead`} component={ResponseHotLead} />
      <ErrorBoundaryRoute path={`${match.url}/vehicle-nissan-interest`} component={VehicleNissanInterest} />
      <ErrorBoundaryRoute path={`${match.url}/vehicle-exchange-nissan`} component={VehicleExchangeNissan} />
      <ErrorBoundaryRoute path={`${match.url}/lead`} component={Lead} />
      <ErrorBoundaryRoute path={`${match.url}/nissan-model`} component={NissanModel} />
      <ErrorBoundaryRoute path={`${match.url}/color`} component={Color} />
      <ErrorBoundaryRoute path={`${match.url}/response-gamma-message`} component={ResponseGammaMessage} />
      <ErrorBoundaryRoute path={`${match.url}/response-gamma-status-code`} component={ResponseGammaStatusCode} />
      <ErrorBoundaryRoute path={`${match.url}/response-hot-lead-message`} component={ResponseHotLeadMessage} />
      <ErrorBoundaryRoute path={`${match.url}/response-hot-lead-status-code`} component={ResponseHotLeadStatusCode} />
      <ErrorBoundaryRoute path={`${match.url}/response-gamma`} component={ResponseGamma} />
      <ErrorBoundaryRoute path={`${match.url}/sync-gamma`} component={SyncGamma} />
      <ErrorBoundaryRoute path={`${match.url}/sync-hot-lead`} component={SyncHotLead} />
      <ErrorBoundaryRoute path={`${match.url}/attendant-lead-sync`} component={AttendantLeadSync} />
      {/* jhipster-needle-add-route-path - JHipster will add routes here */}
    </Switch>
  </div>
);

export default Routes;
