import { Moment } from 'moment';
import { IClientQualificationMessage } from 'app/shared/model/client-qualification-message.model';

export interface IClientQualificationResponse {
  id?: number;
  receivedIn?: Moment;
  synchronizedIn?: Moment;
  clientQualificationSyncId?: number;
  statusCodeId?: number;
  messages?: IClientQualificationMessage[];
}

export const defaultValue: Readonly<IClientQualificationResponse> = {};
