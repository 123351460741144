import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, UncontrolledTooltip, Row, Col } from 'reactstrap';
// tslint:disable-next-line:no-unused-variable
import { Translate, ICrudGetAction, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntity } from './syonet-refused-proposal.reducer';
import { ISyonetRefusedProposal } from 'app/shared/model/syonet-refused-proposal.model';
// tslint:disable-next-line:no-unused-variable
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';

export interface ISyonetRefusedProposalDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export class SyonetRefusedProposalDetail extends React.Component<ISyonetRefusedProposalDetailProps> {
  componentDidMount() {
    this.props.getEntity(this.props.match.params.id);
  }

  render() {
    const { syonetRefusedProposalEntity } = this.props;
    return (
      <Row>
        <Col md="8">
          <h2>
            <Translate contentKey="nissanApp.syonetRefusedProposal.detail.title">SyonetRefusedProposal</Translate> [
            <b>{syonetRefusedProposalEntity.id}</b>]
          </h2>
          <dl className="jh-entity-details">
            <dt>
              <span id="lastSync">
                <Translate contentKey="nissanApp.syonetRefusedProposal.lastSync">Last Sync</Translate>
              </span>
              <UncontrolledTooltip target="lastSync">
                <Translate contentKey="nissanApp.syonetRefusedProposal.help.lastSync" />
              </UncontrolledTooltip>
            </dt>
            <dd>
              <TextFormat value={syonetRefusedProposalEntity.lastSync} type="date" format={APP_DATE_FORMAT} />
            </dd>
            <dt>
              <span id="crmID">
                <Translate contentKey="nissanApp.syonetRefusedProposal.crmID">Crm ID</Translate>
              </span>
              <UncontrolledTooltip target="crmID">
                <Translate contentKey="nissanApp.syonetRefusedProposal.help.crmID" />
              </UncontrolledTooltip>
            </dt>
            <dd>{syonetRefusedProposalEntity.crmID}</dd>
            <dt>
              <span id="extracionDate">
                <Translate contentKey="nissanApp.syonetRefusedProposal.extracionDate">Extracion Date</Translate>
              </span>
              <UncontrolledTooltip target="extracionDate">
                <Translate contentKey="nissanApp.syonetRefusedProposal.help.extracionDate" />
              </UncontrolledTooltip>
            </dt>
            <dd>
              <TextFormat value={syonetRefusedProposalEntity.extracionDate} type="date" format={APP_DATE_FORMAT} />
            </dd>
            <dt>
              <span id="attendanceID">
                <Translate contentKey="nissanApp.syonetRefusedProposal.attendanceID">Attendance ID</Translate>
              </span>
              <UncontrolledTooltip target="attendanceID">
                <Translate contentKey="nissanApp.syonetRefusedProposal.help.attendanceID" />
              </UncontrolledTooltip>
            </dt>
            <dd>{syonetRefusedProposalEntity.attendanceID}</dd>
            <dt>
              <span id="nissanLeadID">
                <Translate contentKey="nissanApp.syonetRefusedProposal.nissanLeadID">Nissan Lead ID</Translate>
              </span>
              <UncontrolledTooltip target="nissanLeadID">
                <Translate contentKey="nissanApp.syonetRefusedProposal.help.nissanLeadID" />
              </UncontrolledTooltip>
            </dt>
            <dd>{syonetRefusedProposalEntity.nissanLeadID}</dd>
            <dt>
              <span id="date">
                <Translate contentKey="nissanApp.syonetRefusedProposal.date">Date</Translate>
              </span>
              <UncontrolledTooltip target="date">
                <Translate contentKey="nissanApp.syonetRefusedProposal.help.date" />
              </UncontrolledTooltip>
            </dt>
            <dd>
              <TextFormat value={syonetRefusedProposalEntity.date} type="date" format={APP_DATE_FORMAT} />
            </dd>
            <dt>
              <span id="reasonID">
                <Translate contentKey="nissanApp.syonetRefusedProposal.reasonID">Reason ID</Translate>
              </span>
              <UncontrolledTooltip target="reasonID">
                <Translate contentKey="nissanApp.syonetRefusedProposal.help.reasonID" />
              </UncontrolledTooltip>
            </dt>
            <dd>{syonetRefusedProposalEntity.reasonID}</dd>
            <dt>
              <Translate contentKey="nissanApp.syonetRefusedProposal.syonetLead">Syonet Lead</Translate>
            </dt>
            <dd>{syonetRefusedProposalEntity.syonetLeadId ? syonetRefusedProposalEntity.syonetLeadId : ''}</dd>
          </dl>
          <Button tag={Link} to="/entity/syonet-refused-proposal" replace color="info">
            <FontAwesomeIcon icon="arrow-left" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.back">Back</Translate>
            </span>
          </Button>
          &nbsp;
          <Button tag={Link} to={`/entity/syonet-refused-proposal/${syonetRefusedProposalEntity.id}/edit`} replace color="primary">
            <FontAwesomeIcon icon="pencil-alt" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.edit">Edit</Translate>
            </span>
          </Button>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = ({ syonetRefusedProposal }: IRootState) => ({
  syonetRefusedProposalEntity: syonetRefusedProposal.entity
});

const mapDispatchToProps = { getEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SyonetRefusedProposalDetail);
