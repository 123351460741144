export interface ISchedulingLeadMessage {
  id?: number;
  message?: string;
  messageCode?: number;
  registryNumber?: number;
  returnType?: string;
  schedulingLeadResponseId?: number;
}

export const defaultValue: Readonly<ISchedulingLeadMessage> = {};
