import { Moment } from 'moment';
import { ITestDriveMessage } from 'app/shared/model/test-drive-message.model';

export interface ITestDriveResponse {
  id?: number;
  receivedIn?: Moment;
  synchronizedIn?: Moment;
  testDriveSyncId?: number;
  statusCodeId?: number;
  messages?: ITestDriveMessage[];
}

export const defaultValue: Readonly<ITestDriveResponse> = {};
