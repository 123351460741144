import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, UncontrolledTooltip, Row, Col } from 'reactstrap';
// tslint:disable-next-line:no-unused-variable
import { Translate, ICrudGetAction } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntity } from './vehicle-nissan-interest.reducer';
import { IVehicleNissanInterest } from 'app/shared/model/vehicle-nissan-interest.model';
// tslint:disable-next-line:no-unused-variable
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';

export interface IVehicleNissanInterestDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export class VehicleNissanInterestDetail extends React.Component<IVehicleNissanInterestDetailProps> {
  componentDidMount() {
    this.props.getEntity(this.props.match.params.id);
  }

  render() {
    const { vehicleNissanInterestEntity } = this.props;
    return (
      <Row>
        <Col md="8">
          <h2>
            <Translate contentKey="nissanApp.vehicleNissanInterest.detail.title">VehicleNissanInterest</Translate> [
            <b>{vehicleNissanInterestEntity.id}</b>]
          </h2>
          <dl className="jh-entity-details">
            <dt>
              <span id="vehicleCode">
                <Translate contentKey="nissanApp.vehicleNissanInterest.vehicleCode">Vehicle Code</Translate>
              </span>
              <UncontrolledTooltip target="vehicleCode">
                <Translate contentKey="nissanApp.vehicleNissanInterest.help.vehicleCode" />
              </UncontrolledTooltip>
            </dt>
            <dd>{vehicleNissanInterestEntity.vehicleCode}</dd>
            <dt>
              <span id="modelCode">
                <Translate contentKey="nissanApp.vehicleNissanInterest.modelCode">Model Code</Translate>
              </span>
              <UncontrolledTooltip target="modelCode">
                <Translate contentKey="nissanApp.vehicleNissanInterest.help.modelCode" />
              </UncontrolledTooltip>
            </dt>
            <dd>{vehicleNissanInterestEntity.modelCode}</dd>
            <dt>
              <span id="model">
                <Translate contentKey="nissanApp.vehicleNissanInterest.model">Model</Translate>
              </span>
              <UncontrolledTooltip target="model">
                <Translate contentKey="nissanApp.vehicleNissanInterest.help.model" />
              </UncontrolledTooltip>
            </dt>
            <dd>{vehicleNissanInterestEntity.model}</dd>
            <dt>
              <span id="eimVehicleInterest">
                <Translate contentKey="nissanApp.vehicleNissanInterest.eimVehicleInterest">Eim Vehicle Interest</Translate>
              </span>
              <UncontrolledTooltip target="eimVehicleInterest">
                <Translate contentKey="nissanApp.vehicleNissanInterest.help.eimVehicleInterest" />
              </UncontrolledTooltip>
            </dt>
            <dd>{vehicleNissanInterestEntity.eimVehicleInterest}</dd>
            <dt>
              <span id="version">
                <Translate contentKey="nissanApp.vehicleNissanInterest.version">Version</Translate>
              </span>
              <UncontrolledTooltip target="version">
                <Translate contentKey="nissanApp.vehicleNissanInterest.help.version" />
              </UncontrolledTooltip>
            </dt>
            <dd>{vehicleNissanInterestEntity.version}</dd>
            <dt>
              <span id="modelYear">
                <Translate contentKey="nissanApp.vehicleNissanInterest.modelYear">Model Year</Translate>
              </span>
              <UncontrolledTooltip target="modelYear">
                <Translate contentKey="nissanApp.vehicleNissanInterest.help.modelYear" />
              </UncontrolledTooltip>
            </dt>
            <dd>{vehicleNissanInterestEntity.modelYear}</dd>
            <dt>
              <span id="colorCode">
                <Translate contentKey="nissanApp.vehicleNissanInterest.colorCode">Color Code</Translate>
              </span>
              <UncontrolledTooltip target="colorCode">
                <Translate contentKey="nissanApp.vehicleNissanInterest.help.colorCode" />
              </UncontrolledTooltip>
            </dt>
            <dd>{vehicleNissanInterestEntity.colorCode}</dd>
            <dt>
              <span id="color">
                <Translate contentKey="nissanApp.vehicleNissanInterest.color">Color</Translate>
              </span>
              <UncontrolledTooltip target="color">
                <Translate contentKey="nissanApp.vehicleNissanInterest.help.color" />
              </UncontrolledTooltip>
            </dt>
            <dd>{vehicleNissanInterestEntity.color}</dd>
            <dt>
              <span id="modelYearVehicleNegotiated">
                <Translate contentKey="nissanApp.vehicleNissanInterest.modelYearVehicleNegotiated">Model Year Vehicle Negotiated</Translate>
              </span>
              <UncontrolledTooltip target="modelYearVehicleNegotiated">
                <Translate contentKey="nissanApp.vehicleNissanInterest.help.modelYearVehicleNegotiated" />
              </UncontrolledTooltip>
            </dt>
            <dd>{vehicleNissanInterestEntity.modelYearVehicleNegotiated}</dd>
            <dt>
              <Translate contentKey="nissanApp.vehicleNissanInterest.lead">Lead</Translate>
            </dt>
            <dd>{vehicleNissanInterestEntity.leadId ? vehicleNissanInterestEntity.leadId : ''}</dd>
          </dl>
          <Button tag={Link} to="/entity/vehicle-nissan-interest" replace color="info">
            <FontAwesomeIcon icon="arrow-left" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.back">Back</Translate>
            </span>
          </Button>
          &nbsp;
          <Button tag={Link} to={`/entity/vehicle-nissan-interest/${vehicleNissanInterestEntity.id}/edit`} replace color="primary">
            <FontAwesomeIcon icon="pencil-alt" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.edit">Edit</Translate>
            </span>
          </Button>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = ({ vehicleNissanInterest }: IRootState) => ({
  vehicleNissanInterestEntity: vehicleNissanInterest.entity
});

const mapDispatchToProps = { getEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VehicleNissanInterestDetail);
