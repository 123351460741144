import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import SyonetVisitShowroom from './syonet-visit-showroom';
import SyonetVisitShowroomDetail from './syonet-visit-showroom-detail';
import SyonetVisitShowroomUpdate from './syonet-visit-showroom-update';
import SyonetVisitShowroomDeleteDialog from './syonet-visit-showroom-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={SyonetVisitShowroomUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={SyonetVisitShowroomUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={SyonetVisitShowroomDetail} />
      <ErrorBoundaryRoute path={match.url} component={SyonetVisitShowroom} />
    </Switch>
    <ErrorBoundaryRoute path={`${match.url}/:id/delete`} component={SyonetVisitShowroomDeleteDialog} />
  </>
);

export default Routes;
