import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import ClientQualificationVehicleChange from './client-qualification-vehicle-change';
import ClientQualificationVehicleChangeDetail from './client-qualification-vehicle-change-detail';
import ClientQualificationVehicleChangeUpdate from './client-qualification-vehicle-change-update';
import ClientQualificationVehicleChangeDeleteDialog from './client-qualification-vehicle-change-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={ClientQualificationVehicleChangeUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={ClientQualificationVehicleChangeUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={ClientQualificationVehicleChangeDetail} />
      <ErrorBoundaryRoute path={match.url} component={ClientQualificationVehicleChange} />
    </Switch>
    <ErrorBoundaryRoute path={`${match.url}/:id/delete`} component={ClientQualificationVehicleChangeDeleteDialog} />
  </>
);

export default Routes;
