import { Moment } from 'moment';

export interface ISyncHotLead {
  id?: number;
  receivedIn?: Moment;
  synchronizedIn?: Moment;
  executionHotLeadId?: number;
}

export const defaultValue: Readonly<ISyncHotLead> = {};
