import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col, Label, UncontrolledTooltip } from 'reactstrap';
import { AvFeedback, AvForm, AvGroup, AvInput, AvField } from 'availity-reactstrap-validation';
// tslint:disable-next-line:no-unused-variable
import { Translate, translate, ICrudGetAction, ICrudGetAllAction, ICrudPutAction } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';

import { ISyonetApprovedProposal } from 'app/shared/model/syonet-approved-proposal.model';
import { getEntities as getSyonetApprovedProposals } from 'app/entities/syonet-approved-proposal/syonet-approved-proposal.reducer';
import { getEntity, updateEntity, createEntity, reset } from './vehicle-saled-approved-proposal.reducer';
import { IVehicleSaledApprovedProposal } from 'app/shared/model/vehicle-saled-approved-proposal.model';
// tslint:disable-next-line:no-unused-variable
import { convertDateTimeFromServer, convertDateTimeToServer } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';

export interface IVehicleSaledApprovedProposalUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export interface IVehicleSaledApprovedProposalUpdateState {
  isNew: boolean;
  syonetApprovedProposalId: string;
}

export class VehicleSaledApprovedProposalUpdate extends React.Component<
  IVehicleSaledApprovedProposalUpdateProps,
  IVehicleSaledApprovedProposalUpdateState
> {
  constructor(props) {
    super(props);
    this.state = {
      syonetApprovedProposalId: '0',
      isNew: !this.props.match.params || !this.props.match.params.id
    };
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextProps.updateSuccess !== this.props.updateSuccess && nextProps.updateSuccess) {
      this.handleClose();
    }
  }

  componentDidMount() {
    if (!this.state.isNew) {
      this.props.getEntity(this.props.match.params.id);
    }

    this.props.getSyonetApprovedProposals();
  }

  saveEntity = (event, errors, values) => {
    if (errors.length === 0) {
      const { vehicleSaledApprovedProposalEntity } = this.props;
      const entity = {
        ...vehicleSaledApprovedProposalEntity,
        ...values
      };

      if (this.state.isNew) {
        this.props.createEntity(entity);
      } else {
        this.props.updateEntity(entity);
      }
    }
  };

  handleClose = () => {
    this.props.history.push('/entity/vehicle-saled-approved-proposal');
  };

  render() {
    const { vehicleSaledApprovedProposalEntity, syonetApprovedProposals, loading, updating } = this.props;
    const { isNew } = this.state;

    return (
      <div>
        <Row className="justify-content-center">
          <Col md="8">
            <h2 id="nissanApp.vehicleSaledApprovedProposal.home.createOrEditLabel">
              <Translate contentKey="nissanApp.vehicleSaledApprovedProposal.home.createOrEditLabel">
                Create or edit a VehicleSaledApprovedProposal
              </Translate>
            </h2>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md="8">
            {loading ? (
              <p>Loading...</p>
            ) : (
              <AvForm model={isNew ? {} : vehicleSaledApprovedProposalEntity} onSubmit={this.saveEntity}>
                {!isNew ? (
                  <AvGroup>
                    <Label for="vehicle-saled-approved-proposal-id">
                      <Translate contentKey="global.field.id">ID</Translate>
                    </Label>
                    <AvInput id="vehicle-saled-approved-proposal-id" type="text" className="form-control" name="id" required readOnly />
                  </AvGroup>
                ) : null}
                <AvGroup>
                  <Label id="vehicleCodeLabel" for="vehicle-saled-approved-proposal-vehicleCode">
                    <Translate contentKey="nissanApp.vehicleSaledApprovedProposal.vehicleCode">Vehicle Code</Translate>
                  </Label>
                  <AvField
                    id="vehicle-saled-approved-proposal-vehicleCode"
                    type="text"
                    name="vehicleCode"
                    validate={{
                      required: { value: true, errorMessage: translate('entity.validation.required') }
                    }}
                  />
                  <UncontrolledTooltip target="vehicleCodeLabel">
                    <Translate contentKey="nissanApp.vehicleSaledApprovedProposal.help.vehicleCode" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="vimLabel" for="vehicle-saled-approved-proposal-vim">
                    <Translate contentKey="nissanApp.vehicleSaledApprovedProposal.vim">Vim</Translate>
                  </Label>
                  <AvField id="vehicle-saled-approved-proposal-vim" type="text" name="vim" />
                  <UncontrolledTooltip target="vimLabel">
                    <Translate contentKey="nissanApp.vehicleSaledApprovedProposal.help.vim" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="modelCodeLabel" for="vehicle-saled-approved-proposal-modelCode">
                    <Translate contentKey="nissanApp.vehicleSaledApprovedProposal.modelCode">Model Code</Translate>
                  </Label>
                  <AvField
                    id="vehicle-saled-approved-proposal-modelCode"
                    type="text"
                    name="modelCode"
                    validate={{
                      required: { value: true, errorMessage: translate('entity.validation.required') }
                    }}
                  />
                  <UncontrolledTooltip target="modelCodeLabel">
                    <Translate contentKey="nissanApp.vehicleSaledApprovedProposal.help.modelCode" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="eimCodeLabel" for="vehicle-saled-approved-proposal-eimCode">
                    <Translate contentKey="nissanApp.vehicleSaledApprovedProposal.eimCode">Eim Code</Translate>
                  </Label>
                  <AvField id="vehicle-saled-approved-proposal-eimCode" type="text" name="eimCode" />
                  <UncontrolledTooltip target="eimCodeLabel">
                    <Translate contentKey="nissanApp.vehicleSaledApprovedProposal.help.eimCode" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="colorCodeLabel" for="vehicle-saled-approved-proposal-colorCode">
                    <Translate contentKey="nissanApp.vehicleSaledApprovedProposal.colorCode">Color Code</Translate>
                  </Label>
                  <AvField id="vehicle-saled-approved-proposal-colorCode" type="text" name="colorCode" />
                  <UncontrolledTooltip target="colorCodeLabel">
                    <Translate contentKey="nissanApp.vehicleSaledApprovedProposal.help.colorCode" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="modelYearLabel" for="vehicle-saled-approved-proposal-modelYear">
                    <Translate contentKey="nissanApp.vehicleSaledApprovedProposal.modelYear">Model Year</Translate>
                  </Label>
                  <AvField id="vehicle-saled-approved-proposal-modelYear" type="string" className="form-control" name="modelYear" />
                  <UncontrolledTooltip target="modelYearLabel">
                    <Translate contentKey="nissanApp.vehicleSaledApprovedProposal.help.modelYear" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="taxInvoiceLabel" for="vehicle-saled-approved-proposal-taxInvoice">
                    <Translate contentKey="nissanApp.vehicleSaledApprovedProposal.taxInvoice">Tax Invoice</Translate>
                  </Label>
                  <AvField id="vehicle-saled-approved-proposal-taxInvoice" type="text" name="taxInvoice" />
                  <UncontrolledTooltip target="taxInvoiceLabel">
                    <Translate contentKey="nissanApp.vehicleSaledApprovedProposal.help.taxInvoice" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label for="vehicle-saled-approved-proposal-syonetApprovedProposal">
                    <Translate contentKey="nissanApp.vehicleSaledApprovedProposal.syonetApprovedProposal">
                      Syonet Approved Proposal
                    </Translate>
                  </Label>
                  <AvInput
                    id="vehicle-saled-approved-proposal-syonetApprovedProposal"
                    type="select"
                    className="form-control"
                    name="syonetApprovedProposalId"
                  >
                    <option value="" key="0" />
                    {syonetApprovedProposals
                      ? syonetApprovedProposals.map(otherEntity => (
                          <option value={otherEntity.id} key={otherEntity.id}>
                            {otherEntity.id}
                          </option>
                        ))
                      : null}
                  </AvInput>
                </AvGroup>
                <Button tag={Link} id="cancel-save" to="/entity/vehicle-saled-approved-proposal" replace color="info">
                  <FontAwesomeIcon icon="arrow-left" />
                  &nbsp;
                  <span className="d-none d-md-inline">
                    <Translate contentKey="entity.action.back">Back</Translate>
                  </span>
                </Button>
                &nbsp;
                <Button color="primary" id="save-entity" type="submit" disabled={updating}>
                  <FontAwesomeIcon icon="save" />
                  &nbsp;
                  <Translate contentKey="entity.action.save">Save</Translate>
                </Button>
              </AvForm>
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (storeState: IRootState) => ({
  syonetApprovedProposals: storeState.syonetApprovedProposal.entities,
  vehicleSaledApprovedProposalEntity: storeState.vehicleSaledApprovedProposal.entity,
  loading: storeState.vehicleSaledApprovedProposal.loading,
  updating: storeState.vehicleSaledApprovedProposal.updating,
  updateSuccess: storeState.vehicleSaledApprovedProposal.updateSuccess
});

const mapDispatchToProps = {
  getSyonetApprovedProposals,
  getEntity,
  updateEntity,
  createEntity,
  reset
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VehicleSaledApprovedProposalUpdate);
