import { Moment } from 'moment';
import { IExecutionGamma } from 'app/shared/model/execution-gamma.model';
import { IExecutionHotLead } from 'app/shared/model/execution-hot-lead.model';
import { ISyonetLead } from 'app/shared/model/syonet-lead.model';

export interface IAccountInfo {
  id?: number;
  url?: string;
  userSyonet?: string;
  passSyonet?: string;
  enterpriseCRMID?: number;
  birCode?: string;
  enterpriseID?: string;
  userNissan?: string;
  passNissan?: string;
  sourceSystem?: number;
  group?: string;
  name?: string;
  webServiceVersion?: string;
  lastSync?: Moment;
  gammas?: IExecutionGamma[];
  hotleads?: IExecutionHotLead[];
  crmLeads?: ISyonetLead[];
}

export const defaultValue: Readonly<IAccountInfo> = {};
