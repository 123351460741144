import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import RefusedProposalLeadMessage from './refused-proposal-lead-message';
import RefusedProposalLeadMessageDetail from './refused-proposal-lead-message-detail';
import RefusedProposalLeadMessageUpdate from './refused-proposal-lead-message-update';
import RefusedProposalLeadMessageDeleteDialog from './refused-proposal-lead-message-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={RefusedProposalLeadMessageUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={RefusedProposalLeadMessageUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={RefusedProposalLeadMessageDetail} />
      <ErrorBoundaryRoute path={match.url} component={RefusedProposalLeadMessage} />
    </Switch>
    <ErrorBoundaryRoute path={`${match.url}/:id/delete`} component={RefusedProposalLeadMessageDeleteDialog} />
  </>
);

export default Routes;
