export interface IAttendantLeadMessage {
  id?: number;
  message?: string;
  messageCode?: number;
  registryNumber?: number;
  returnType?: string;
  attendantLeadResponseId?: number;
}

export const defaultValue: Readonly<IAttendantLeadMessage> = {};
