import axios from 'axios';
import {
  ICrudSearchAction,
  parseHeaderForLinks,
  loadMoreDataWhenScrolled,
  ICrudGetAction,
  ICrudGetAllAction,
  ICrudPutAction,
  ICrudDeleteAction
} from 'react-jhipster';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

import { ISyonetClientQualification, defaultValue } from 'app/shared/model/syonet-client-qualification.model';

export const ACTION_TYPES = {
  SEARCH_SYONETCLIENTQUALIFICATIONS: 'syonetClientQualification/SEARCH_SYONETCLIENTQUALIFICATIONS',
  FETCH_SYONETCLIENTQUALIFICATION_LIST: 'syonetClientQualification/FETCH_SYONETCLIENTQUALIFICATION_LIST',
  FETCH_SYONETCLIENTQUALIFICATION: 'syonetClientQualification/FETCH_SYONETCLIENTQUALIFICATION',
  CREATE_SYONETCLIENTQUALIFICATION: 'syonetClientQualification/CREATE_SYONETCLIENTQUALIFICATION',
  UPDATE_SYONETCLIENTQUALIFICATION: 'syonetClientQualification/UPDATE_SYONETCLIENTQUALIFICATION',
  DELETE_SYONETCLIENTQUALIFICATION: 'syonetClientQualification/DELETE_SYONETCLIENTQUALIFICATION',
  RESET: 'syonetClientQualification/RESET'
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<ISyonetClientQualification>,
  entity: defaultValue,
  links: { next: 0 },
  updating: false,
  totalItems: 0,
  updateSuccess: false
};

export type SyonetClientQualificationState = Readonly<typeof initialState>;

// Reducer

export default (state: SyonetClientQualificationState = initialState, action): SyonetClientQualificationState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.SEARCH_SYONETCLIENTQUALIFICATIONS):
    case REQUEST(ACTION_TYPES.FETCH_SYONETCLIENTQUALIFICATION_LIST):
    case REQUEST(ACTION_TYPES.FETCH_SYONETCLIENTQUALIFICATION):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true
      };
    case REQUEST(ACTION_TYPES.CREATE_SYONETCLIENTQUALIFICATION):
    case REQUEST(ACTION_TYPES.UPDATE_SYONETCLIENTQUALIFICATION):
    case REQUEST(ACTION_TYPES.DELETE_SYONETCLIENTQUALIFICATION):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true
      };
    case FAILURE(ACTION_TYPES.SEARCH_SYONETCLIENTQUALIFICATIONS):
    case FAILURE(ACTION_TYPES.FETCH_SYONETCLIENTQUALIFICATION_LIST):
    case FAILURE(ACTION_TYPES.FETCH_SYONETCLIENTQUALIFICATION):
    case FAILURE(ACTION_TYPES.CREATE_SYONETCLIENTQUALIFICATION):
    case FAILURE(ACTION_TYPES.UPDATE_SYONETCLIENTQUALIFICATION):
    case FAILURE(ACTION_TYPES.DELETE_SYONETCLIENTQUALIFICATION):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload
      };
    case SUCCESS(ACTION_TYPES.SEARCH_SYONETCLIENTQUALIFICATIONS):
    case SUCCESS(ACTION_TYPES.FETCH_SYONETCLIENTQUALIFICATION_LIST):
      const links = parseHeaderForLinks(action.payload.headers.link);
      return {
        ...state,
        links,
        loading: false,
        totalItems: action.payload.headers['x-total-count'],
        entities: loadMoreDataWhenScrolled(state.entities, action.payload.data, links)
      };
    case SUCCESS(ACTION_TYPES.FETCH_SYONETCLIENTQUALIFICATION):
      return {
        ...state,
        loading: false,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.CREATE_SYONETCLIENTQUALIFICATION):
    case SUCCESS(ACTION_TYPES.UPDATE_SYONETCLIENTQUALIFICATION):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.DELETE_SYONETCLIENTQUALIFICATION):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {}
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

const apiUrl = 'api/syonet-client-qualifications';
const apiSearchUrl = 'api/_search/syonet-client-qualifications';

// Actions

export const getSearchEntities: ICrudSearchAction<ISyonetClientQualification> = (query, page, size, sort) => ({
  type: ACTION_TYPES.SEARCH_SYONETCLIENTQUALIFICATIONS,
  payload: axios.get<ISyonetClientQualification>(`${apiSearchUrl}?query=${query}${sort ? `&page=${page}&size=${size}&sort=${sort}` : ''}`)
});

export const getEntities: ICrudGetAllAction<ISyonetClientQualification> = (page, size, sort) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.FETCH_SYONETCLIENTQUALIFICATION_LIST,
    payload: axios.get<ISyonetClientQualification>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`)
  };
};

export const getEntity: ICrudGetAction<ISyonetClientQualification> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_SYONETCLIENTQUALIFICATION,
    payload: axios.get<ISyonetClientQualification>(requestUrl)
  };
};

export const createEntity: ICrudPutAction<ISyonetClientQualification> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_SYONETCLIENTQUALIFICATION,
    payload: axios.post(apiUrl, cleanEntity(entity))
  });
  return result;
};

export const updateEntity: ICrudPutAction<ISyonetClientQualification> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_SYONETCLIENTQUALIFICATION,
    payload: axios.put(apiUrl, cleanEntity(entity))
  });
  return result;
};

export const deleteEntity: ICrudDeleteAction<ISyonetClientQualification> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_SYONETCLIENTQUALIFICATION,
    payload: axios.delete(requestUrl)
  });
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET
});
