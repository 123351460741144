import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col, Label, UncontrolledTooltip } from 'reactstrap';
import { AvFeedback, AvForm, AvGroup, AvInput, AvField } from 'availity-reactstrap-validation';
// tslint:disable-next-line:no-unused-variable
import { Translate, translate, ICrudGetAction, ICrudGetAllAction, ICrudPutAction } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';

import { ISyonetLead } from 'app/shared/model/syonet-lead.model';
import { getEntities as getSyonetLeads } from 'app/entities/syonet-lead/syonet-lead.reducer';
import { getEntity, updateEntity, createEntity, reset } from './syonet-negotiation.reducer';
import { ISyonetNegotiation } from 'app/shared/model/syonet-negotiation.model';
// tslint:disable-next-line:no-unused-variable
import { convertDateTimeFromServer, convertDateTimeToServer } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';

export interface ISyonetNegotiationUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export interface ISyonetNegotiationUpdateState {
  isNew: boolean;
  syonetLeadId: string;
}

export class SyonetNegotiationUpdate extends React.Component<ISyonetNegotiationUpdateProps, ISyonetNegotiationUpdateState> {
  constructor(props) {
    super(props);
    this.state = {
      syonetLeadId: '0',
      isNew: !this.props.match.params || !this.props.match.params.id
    };
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextProps.updateSuccess !== this.props.updateSuccess && nextProps.updateSuccess) {
      this.handleClose();
    }
  }

  componentDidMount() {
    if (!this.state.isNew) {
      this.props.getEntity(this.props.match.params.id);
    }

    this.props.getSyonetLeads();
  }

  saveEntity = (event, errors, values) => {
    values.lastSync = convertDateTimeToServer(values.lastSync);
    values.extracionDate = convertDateTimeToServer(values.extracionDate);
    values.contactDate = convertDateTimeToServer(values.contactDate);

    if (errors.length === 0) {
      const { syonetNegotiationEntity } = this.props;
      const entity = {
        ...syonetNegotiationEntity,
        ...values
      };

      if (this.state.isNew) {
        this.props.createEntity(entity);
      } else {
        this.props.updateEntity(entity);
      }
    }
  };

  handleClose = () => {
    this.props.history.push('/entity/syonet-negotiation');
  };

  render() {
    const { syonetNegotiationEntity, syonetLeads, loading, updating } = this.props;
    const { isNew } = this.state;

    return (
      <div>
        <Row className="justify-content-center">
          <Col md="8">
            <h2 id="nissanApp.syonetNegotiation.home.createOrEditLabel">
              <Translate contentKey="nissanApp.syonetNegotiation.home.createOrEditLabel">Create or edit a SyonetNegotiation</Translate>
            </h2>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md="8">
            {loading ? (
              <p>Loading...</p>
            ) : (
              <AvForm model={isNew ? {} : syonetNegotiationEntity} onSubmit={this.saveEntity}>
                {!isNew ? (
                  <AvGroup>
                    <Label for="syonet-negotiation-id">
                      <Translate contentKey="global.field.id">ID</Translate>
                    </Label>
                    <AvInput id="syonet-negotiation-id" type="text" className="form-control" name="id" required readOnly />
                  </AvGroup>
                ) : null}
                <AvGroup>
                  <Label id="lastSyncLabel" for="syonet-negotiation-lastSync">
                    <Translate contentKey="nissanApp.syonetNegotiation.lastSync">Last Sync</Translate>
                  </Label>
                  <AvInput
                    id="syonet-negotiation-lastSync"
                    type="datetime-local"
                    className="form-control"
                    name="lastSync"
                    placeholder={'YYYY-MM-DD HH:mm'}
                    value={isNew ? null : convertDateTimeFromServer(this.props.syonetNegotiationEntity.lastSync)}
                  />
                  <UncontrolledTooltip target="lastSyncLabel">
                    <Translate contentKey="nissanApp.syonetNegotiation.help.lastSync" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="crmIDLabel" for="syonet-negotiation-crmID">
                    <Translate contentKey="nissanApp.syonetNegotiation.crmID">Crm ID</Translate>
                  </Label>
                  <AvField id="syonet-negotiation-crmID" type="text" name="crmID" />
                  <UncontrolledTooltip target="crmIDLabel">
                    <Translate contentKey="nissanApp.syonetNegotiation.help.crmID" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="extracionDateLabel" for="syonet-negotiation-extracionDate">
                    <Translate contentKey="nissanApp.syonetNegotiation.extracionDate">Extracion Date</Translate>
                  </Label>
                  <AvInput
                    id="syonet-negotiation-extracionDate"
                    type="datetime-local"
                    className="form-control"
                    name="extracionDate"
                    placeholder={'YYYY-MM-DD HH:mm'}
                    value={isNew ? null : convertDateTimeFromServer(this.props.syonetNegotiationEntity.extracionDate)}
                    validate={{
                      required: { value: true, errorMessage: translate('entity.validation.required') }
                    }}
                  />
                  <UncontrolledTooltip target="extracionDateLabel">
                    <Translate contentKey="nissanApp.syonetNegotiation.help.extracionDate" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="attendanceIDLabel" for="syonet-negotiation-attendanceID">
                    <Translate contentKey="nissanApp.syonetNegotiation.attendanceID">Attendance ID</Translate>
                  </Label>
                  <AvField
                    id="syonet-negotiation-attendanceID"
                    type="string"
                    className="form-control"
                    name="attendanceID"
                    validate={{
                      required: { value: true, errorMessage: translate('entity.validation.required') },
                      number: { value: true, errorMessage: translate('entity.validation.number') }
                    }}
                  />
                  <UncontrolledTooltip target="attendanceIDLabel">
                    <Translate contentKey="nissanApp.syonetNegotiation.help.attendanceID" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="nissanLeadIDLabel" for="syonet-negotiation-nissanLeadID">
                    <Translate contentKey="nissanApp.syonetNegotiation.nissanLeadID">Nissan Lead ID</Translate>
                  </Label>
                  <AvField id="syonet-negotiation-nissanLeadID" type="string" className="form-control" name="nissanLeadID" />
                  <UncontrolledTooltip target="nissanLeadIDLabel">
                    <Translate contentKey="nissanApp.syonetNegotiation.help.nissanLeadID" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="negotiationIDLabel" for="syonet-negotiation-negotiationID">
                    <Translate contentKey="nissanApp.syonetNegotiation.negotiationID">Negotiation ID</Translate>
                  </Label>
                  <AvField
                    id="syonet-negotiation-negotiationID"
                    type="string"
                    className="form-control"
                    name="negotiationID"
                    validate={{
                      required: { value: true, errorMessage: translate('entity.validation.required') },
                      number: { value: true, errorMessage: translate('entity.validation.number') }
                    }}
                  />
                  <UncontrolledTooltip target="negotiationIDLabel">
                    <Translate contentKey="nissanApp.syonetNegotiation.help.negotiationID" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="contactDateLabel" for="syonet-negotiation-contactDate">
                    <Translate contentKey="nissanApp.syonetNegotiation.contactDate">Contact Date</Translate>
                  </Label>
                  <AvInput
                    id="syonet-negotiation-contactDate"
                    type="datetime-local"
                    className="form-control"
                    name="contactDate"
                    placeholder={'YYYY-MM-DD HH:mm'}
                    value={isNew ? null : convertDateTimeFromServer(this.props.syonetNegotiationEntity.contactDate)}
                    validate={{
                      required: { value: true, errorMessage: translate('entity.validation.required') }
                    }}
                  />
                  <UncontrolledTooltip target="contactDateLabel">
                    <Translate contentKey="nissanApp.syonetNegotiation.help.contactDate" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="salesChannelLabel" for="syonet-negotiation-salesChannel">
                    <Translate contentKey="nissanApp.syonetNegotiation.salesChannel">Sales Channel</Translate>
                  </Label>
                  <AvField
                    id="syonet-negotiation-salesChannel"
                    type="string"
                    className="form-control"
                    name="salesChannel"
                    validate={{
                      required: { value: true, errorMessage: translate('entity.validation.required') },
                      number: { value: true, errorMessage: translate('entity.validation.number') }
                    }}
                  />
                  <UncontrolledTooltip target="salesChannelLabel">
                    <Translate contentKey="nissanApp.syonetNegotiation.help.salesChannel" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="paymentConditionLabel" for="syonet-negotiation-paymentCondition">
                    <Translate contentKey="nissanApp.syonetNegotiation.paymentCondition">Payment Condition</Translate>
                  </Label>
                  <AvField
                    id="syonet-negotiation-paymentCondition"
                    type="text"
                    name="paymentCondition"
                    validate={{
                      required: { value: true, errorMessage: translate('entity.validation.required') }
                    }}
                  />
                  <UncontrolledTooltip target="paymentConditionLabel">
                    <Translate contentKey="nissanApp.syonetNegotiation.help.paymentCondition" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="negotiationValueLabel" for="syonet-negotiation-negotiationValue">
                    <Translate contentKey="nissanApp.syonetNegotiation.negotiationValue">Negotiation Value</Translate>
                  </Label>
                  <AvField
                    id="syonet-negotiation-negotiationValue"
                    type="string"
                    className="form-control"
                    name="negotiationValue"
                    validate={{
                      required: { value: true, errorMessage: translate('entity.validation.required') },
                      number: { value: true, errorMessage: translate('entity.validation.number') }
                    }}
                  />
                  <UncontrolledTooltip target="negotiationValueLabel">
                    <Translate contentKey="nissanApp.syonetNegotiation.help.negotiationValue" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="negotiationDeadlineLabel" for="syonet-negotiation-negotiationDeadline">
                    <Translate contentKey="nissanApp.syonetNegotiation.negotiationDeadline">Negotiation Deadline</Translate>
                  </Label>
                  <AvField
                    id="syonet-negotiation-negotiationDeadline"
                    type="string"
                    className="form-control"
                    name="negotiationDeadline"
                    validate={{
                      required: { value: true, errorMessage: translate('entity.validation.required') },
                      number: { value: true, errorMessage: translate('entity.validation.number') }
                    }}
                  />
                  <UncontrolledTooltip target="negotiationDeadlineLabel">
                    <Translate contentKey="nissanApp.syonetNegotiation.help.negotiationDeadline" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="inputValueLabel" for="syonet-negotiation-inputValue">
                    <Translate contentKey="nissanApp.syonetNegotiation.inputValue">Input Value</Translate>
                  </Label>
                  <AvField id="syonet-negotiation-inputValue" type="string" className="form-control" name="inputValue" />
                  <UncontrolledTooltip target="inputValueLabel">
                    <Translate contentKey="nissanApp.syonetNegotiation.help.inputValue" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="plotValueLabel" for="syonet-negotiation-plotValue">
                    <Translate contentKey="nissanApp.syonetNegotiation.plotValue">Plot Value</Translate>
                  </Label>
                  <AvField id="syonet-negotiation-plotValue" type="string" className="form-control" name="plotValue" />
                  <UncontrolledTooltip target="plotValueLabel">
                    <Translate contentKey="nissanApp.syonetNegotiation.help.plotValue" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="changeAmountLabel" for="syonet-negotiation-changeAmount">
                    <Translate contentKey="nissanApp.syonetNegotiation.changeAmount">Change Amount</Translate>
                  </Label>
                  <AvField id="syonet-negotiation-changeAmount" type="string" className="form-control" name="changeAmount" />
                  <UncontrolledTooltip target="changeAmountLabel">
                    <Translate contentKey="nissanApp.syonetNegotiation.help.changeAmount" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="totalValueVehiclesUsedLabel" for="syonet-negotiation-totalValueVehiclesUsed">
                    <Translate contentKey="nissanApp.syonetNegotiation.totalValueVehiclesUsed">Total Value Vehicles Used</Translate>
                  </Label>
                  <AvField
                    id="syonet-negotiation-totalValueVehiclesUsed"
                    type="string"
                    className="form-control"
                    name="totalValueVehiclesUsed"
                  />
                </AvGroup>
                <AvGroup>
                  <Label for="syonet-negotiation-syonetLead">
                    <Translate contentKey="nissanApp.syonetNegotiation.syonetLead">Syonet Lead</Translate>
                  </Label>
                  <AvInput id="syonet-negotiation-syonetLead" type="select" className="form-control" name="syonetLeadId">
                    <option value="" key="0" />
                    {syonetLeads
                      ? syonetLeads.map(otherEntity => (
                          <option value={otherEntity.id} key={otherEntity.id}>
                            {otherEntity.id}
                          </option>
                        ))
                      : null}
                  </AvInput>
                </AvGroup>
                <Button tag={Link} id="cancel-save" to="/entity/syonet-negotiation" replace color="info">
                  <FontAwesomeIcon icon="arrow-left" />
                  &nbsp;
                  <span className="d-none d-md-inline">
                    <Translate contentKey="entity.action.back">Back</Translate>
                  </span>
                </Button>
                &nbsp;
                <Button color="primary" id="save-entity" type="submit" disabled={updating}>
                  <FontAwesomeIcon icon="save" />
                  &nbsp;
                  <Translate contentKey="entity.action.save">Save</Translate>
                </Button>
              </AvForm>
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (storeState: IRootState) => ({
  syonetLeads: storeState.syonetLead.entities,
  syonetNegotiationEntity: storeState.syonetNegotiation.entity,
  loading: storeState.syonetNegotiation.loading,
  updating: storeState.syonetNegotiation.updating,
  updateSuccess: storeState.syonetNegotiation.updateSuccess
});

const mapDispatchToProps = {
  getSyonetLeads,
  getEntity,
  updateEntity,
  createEntity,
  reset
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SyonetNegotiationUpdate);
