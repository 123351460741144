import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col, Label, UncontrolledTooltip } from 'reactstrap';
import { AvFeedback, AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
// tslint:disable-next-line:no-unused-variable
import { Translate, translate, ICrudGetAction, ICrudGetAllAction, ICrudPutAction } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';

import { ISyonetAttendantLead } from 'app/shared/model/syonet-attendant-lead.model';
import { getEntities as getSyonetAttendantLeads } from 'app/entities/syonet-attendant-lead/syonet-attendant-lead.reducer';
import { getEntity, updateEntity, createEntity, reset } from './attendant-lead-sync.reducer';
import { IAttendantLeadSync } from 'app/shared/model/attendant-lead-sync.model';
// tslint:disable-next-line:no-unused-variable
import { convertDateTimeFromServer, convertDateTimeToServer } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';

export interface IAttendantLeadSyncUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export interface IAttendantLeadSyncUpdateState {
  isNew: boolean;
  syonetAttendantLeadId: string;
}

export class AttendantLeadSyncUpdate extends React.Component<IAttendantLeadSyncUpdateProps, IAttendantLeadSyncUpdateState> {
  constructor(props) {
    super(props);
    this.state = {
      syonetAttendantLeadId: '0',
      isNew: !this.props.match.params || !this.props.match.params.id
    };
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextProps.updateSuccess !== this.props.updateSuccess && nextProps.updateSuccess) {
      this.handleClose();
    }
  }

  componentDidMount() {
    if (!this.state.isNew) {
      this.props.getEntity(this.props.match.params.id);
    }

    this.props.getSyonetAttendantLeads();
  }

  saveEntity = (event, errors, values) => {
    values.receivedIn = convertDateTimeToServer(values.receivedIn);
    values.synchronizedIn = convertDateTimeToServer(values.synchronizedIn);

    if (errors.length === 0) {
      const { attendantLeadSyncEntity } = this.props;
      const entity = {
        ...attendantLeadSyncEntity,
        ...values
      };

      if (this.state.isNew) {
        this.props.createEntity(entity);
      } else {
        this.props.updateEntity(entity);
      }
    }
  };

  handleClose = () => {
    this.props.history.push('/entity/attendant-lead-sync');
  };

  render() {
    const { attendantLeadSyncEntity, syonetAttendantLeads, loading, updating } = this.props;
    const { isNew } = this.state;

    return (
      <div>
        <Row className="justify-content-center">
          <Col md="8">
            <h2 id="nissanApp.attendantLeadSync.home.createOrEditLabel">
              <Translate contentKey="nissanApp.attendantLeadSync.home.createOrEditLabel">Create or edit a AttendantLeadSync</Translate>
            </h2>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md="8">
            {loading ? (
              <p>Loading...</p>
            ) : (
              <AvForm model={isNew ? {} : attendantLeadSyncEntity} onSubmit={this.saveEntity}>
                {!isNew ? (
                  <AvGroup>
                    <Label for="attendant-lead-sync-id">
                      <Translate contentKey="global.field.id">ID</Translate>
                    </Label>
                    <AvInput id="attendant-lead-sync-id" type="text" className="form-control" name="id" required readOnly />
                  </AvGroup>
                ) : null}
                <AvGroup>
                  <Label id="receivedInLabel" for="attendant-lead-sync-receivedIn">
                    <Translate contentKey="nissanApp.attendantLeadSync.receivedIn">Received In</Translate>
                  </Label>
                  <AvInput
                    id="attendant-lead-sync-receivedIn"
                    type="datetime-local"
                    className="form-control"
                    name="receivedIn"
                    placeholder={'YYYY-MM-DD HH:mm'}
                    value={isNew ? null : convertDateTimeFromServer(this.props.attendantLeadSyncEntity.receivedIn)}
                  />
                  <UncontrolledTooltip target="receivedInLabel">
                    <Translate contentKey="nissanApp.attendantLeadSync.help.receivedIn" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="synchronizedInLabel" for="attendant-lead-sync-synchronizedIn">
                    <Translate contentKey="nissanApp.attendantLeadSync.synchronizedIn">Synchronized In</Translate>
                  </Label>
                  <AvInput
                    id="attendant-lead-sync-synchronizedIn"
                    type="datetime-local"
                    className="form-control"
                    name="synchronizedIn"
                    placeholder={'YYYY-MM-DD HH:mm'}
                    value={isNew ? null : convertDateTimeFromServer(this.props.attendantLeadSyncEntity.synchronizedIn)}
                  />
                  <UncontrolledTooltip target="synchronizedInLabel">
                    <Translate contentKey="nissanApp.attendantLeadSync.help.synchronizedIn" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label for="attendant-lead-sync-syonetAttendantLead">
                    <Translate contentKey="nissanApp.attendantLeadSync.syonetAttendantLead">Syonet Attendant Lead</Translate>
                  </Label>
                  <AvInput id="attendant-lead-sync-syonetAttendantLead" type="select" className="form-control" name="syonetAttendantLeadId">
                    <option value="" key="0" />
                    {syonetAttendantLeads
                      ? syonetAttendantLeads.map(otherEntity => (
                          <option value={otherEntity.id} key={otherEntity.id}>
                            {otherEntity.id}
                          </option>
                        ))
                      : null}
                  </AvInput>
                </AvGroup>
                <Button tag={Link} id="cancel-save" to="/entity/attendant-lead-sync" replace color="info">
                  <FontAwesomeIcon icon="arrow-left" />
                  &nbsp;
                  <span className="d-none d-md-inline">
                    <Translate contentKey="entity.action.back">Back</Translate>
                  </span>
                </Button>
                &nbsp;
                <Button color="primary" id="save-entity" type="submit" disabled={updating}>
                  <FontAwesomeIcon icon="save" />
                  &nbsp;
                  <Translate contentKey="entity.action.save">Save</Translate>
                </Button>
              </AvForm>
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (storeState: IRootState) => ({
  syonetAttendantLeads: storeState.syonetAttendantLead.entities,
  attendantLeadSyncEntity: storeState.attendantLeadSync.entity,
  loading: storeState.attendantLeadSync.loading,
  updating: storeState.attendantLeadSync.updating,
  updateSuccess: storeState.attendantLeadSync.updateSuccess
});

const mapDispatchToProps = {
  getSyonetAttendantLeads,
  getEntity,
  updateEntity,
  createEntity,
  reset
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AttendantLeadSyncUpdate);
