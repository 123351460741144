import { Moment } from 'moment';
import { IRefusedProposalLeadMessage } from 'app/shared/model/refused-proposal-lead-message.model';

export interface IRefusedProposalResponse {
  id?: number;
  receivedIn?: Moment;
  synchronizedIn?: Moment;
  refusedProposalSyncId?: number;
  statusCodeId?: number;
  messages?: IRefusedProposalLeadMessage[];
}

export const defaultValue: Readonly<IRefusedProposalResponse> = {};
