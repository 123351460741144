import axios from 'axios';
import {
  ICrudSearchAction,
  parseHeaderForLinks,
  loadMoreDataWhenScrolled,
  ICrudGetAction,
  ICrudGetAllAction,
  ICrudPutAction,
  ICrudDeleteAction
} from 'react-jhipster';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

import { IClientQualificationSync, defaultValue } from 'app/shared/model/client-qualification-sync.model';

export const ACTION_TYPES = {
  SEARCH_CLIENTQUALIFICATIONSYNCS: 'clientQualificationSync/SEARCH_CLIENTQUALIFICATIONSYNCS',
  FETCH_CLIENTQUALIFICATIONSYNC_LIST: 'clientQualificationSync/FETCH_CLIENTQUALIFICATIONSYNC_LIST',
  FETCH_CLIENTQUALIFICATIONSYNC: 'clientQualificationSync/FETCH_CLIENTQUALIFICATIONSYNC',
  CREATE_CLIENTQUALIFICATIONSYNC: 'clientQualificationSync/CREATE_CLIENTQUALIFICATIONSYNC',
  UPDATE_CLIENTQUALIFICATIONSYNC: 'clientQualificationSync/UPDATE_CLIENTQUALIFICATIONSYNC',
  DELETE_CLIENTQUALIFICATIONSYNC: 'clientQualificationSync/DELETE_CLIENTQUALIFICATIONSYNC',
  RESET: 'clientQualificationSync/RESET'
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<IClientQualificationSync>,
  entity: defaultValue,
  links: { next: 0 },
  updating: false,
  totalItems: 0,
  updateSuccess: false
};

export type ClientQualificationSyncState = Readonly<typeof initialState>;

// Reducer

export default (state: ClientQualificationSyncState = initialState, action): ClientQualificationSyncState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.SEARCH_CLIENTQUALIFICATIONSYNCS):
    case REQUEST(ACTION_TYPES.FETCH_CLIENTQUALIFICATIONSYNC_LIST):
    case REQUEST(ACTION_TYPES.FETCH_CLIENTQUALIFICATIONSYNC):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true
      };
    case REQUEST(ACTION_TYPES.CREATE_CLIENTQUALIFICATIONSYNC):
    case REQUEST(ACTION_TYPES.UPDATE_CLIENTQUALIFICATIONSYNC):
    case REQUEST(ACTION_TYPES.DELETE_CLIENTQUALIFICATIONSYNC):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true
      };
    case FAILURE(ACTION_TYPES.SEARCH_CLIENTQUALIFICATIONSYNCS):
    case FAILURE(ACTION_TYPES.FETCH_CLIENTQUALIFICATIONSYNC_LIST):
    case FAILURE(ACTION_TYPES.FETCH_CLIENTQUALIFICATIONSYNC):
    case FAILURE(ACTION_TYPES.CREATE_CLIENTQUALIFICATIONSYNC):
    case FAILURE(ACTION_TYPES.UPDATE_CLIENTQUALIFICATIONSYNC):
    case FAILURE(ACTION_TYPES.DELETE_CLIENTQUALIFICATIONSYNC):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload
      };
    case SUCCESS(ACTION_TYPES.SEARCH_CLIENTQUALIFICATIONSYNCS):
    case SUCCESS(ACTION_TYPES.FETCH_CLIENTQUALIFICATIONSYNC_LIST):
      const links = parseHeaderForLinks(action.payload.headers.link);
      return {
        ...state,
        links,
        loading: false,
        totalItems: action.payload.headers['x-total-count'],
        entities: loadMoreDataWhenScrolled(state.entities, action.payload.data, links)
      };
    case SUCCESS(ACTION_TYPES.FETCH_CLIENTQUALIFICATIONSYNC):
      return {
        ...state,
        loading: false,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.CREATE_CLIENTQUALIFICATIONSYNC):
    case SUCCESS(ACTION_TYPES.UPDATE_CLIENTQUALIFICATIONSYNC):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.DELETE_CLIENTQUALIFICATIONSYNC):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {}
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

const apiUrl = 'api/client-qualification-syncs';
const apiSearchUrl = 'api/_search/client-qualification-syncs';

// Actions

export const getSearchEntities: ICrudSearchAction<IClientQualificationSync> = (query, page, size, sort) => ({
  type: ACTION_TYPES.SEARCH_CLIENTQUALIFICATIONSYNCS,
  payload: axios.get<IClientQualificationSync>(`${apiSearchUrl}?query=${query}${sort ? `&page=${page}&size=${size}&sort=${sort}` : ''}`)
});

export const getEntities: ICrudGetAllAction<IClientQualificationSync> = (page, size, sort) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.FETCH_CLIENTQUALIFICATIONSYNC_LIST,
    payload: axios.get<IClientQualificationSync>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`)
  };
};

export const getEntity: ICrudGetAction<IClientQualificationSync> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_CLIENTQUALIFICATIONSYNC,
    payload: axios.get<IClientQualificationSync>(requestUrl)
  };
};

export const createEntity: ICrudPutAction<IClientQualificationSync> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_CLIENTQUALIFICATIONSYNC,
    payload: axios.post(apiUrl, cleanEntity(entity))
  });
  return result;
};

export const updateEntity: ICrudPutAction<IClientQualificationSync> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_CLIENTQUALIFICATIONSYNC,
    payload: axios.put(apiUrl, cleanEntity(entity))
  });
  return result;
};

export const deleteEntity: ICrudDeleteAction<IClientQualificationSync> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_CLIENTQUALIFICATIONSYNC,
    payload: axios.delete(requestUrl)
  });
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET
});
