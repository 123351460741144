import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col, Label, UncontrolledTooltip } from 'reactstrap';
import { AvFeedback, AvForm, AvGroup, AvInput, AvField } from 'availity-reactstrap-validation';
// tslint:disable-next-line:no-unused-variable
import { Translate, translate, ICrudGetAction, ICrudGetAllAction, ICrudPutAction } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';

import { IResponseGamma } from 'app/shared/model/response-gamma.model';
import { getEntities as getResponseGammas } from 'app/entities/response-gamma/response-gamma.reducer';
import { getEntity, updateEntity, createEntity, reset } from './response-gamma-message.reducer';
import { IResponseGammaMessage } from 'app/shared/model/response-gamma-message.model';
// tslint:disable-next-line:no-unused-variable
import { convertDateTimeFromServer, convertDateTimeToServer } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';

export interface IResponseGammaMessageUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export interface IResponseGammaMessageUpdateState {
  isNew: boolean;
  responseGammaId: string;
}

export class ResponseGammaMessageUpdate extends React.Component<IResponseGammaMessageUpdateProps, IResponseGammaMessageUpdateState> {
  constructor(props) {
    super(props);
    this.state = {
      responseGammaId: '0',
      isNew: !this.props.match.params || !this.props.match.params.id
    };
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextProps.updateSuccess !== this.props.updateSuccess && nextProps.updateSuccess) {
      this.handleClose();
    }
  }

  componentDidMount() {
    if (!this.state.isNew) {
      this.props.getEntity(this.props.match.params.id);
    }

    this.props.getResponseGammas();
  }

  saveEntity = (event, errors, values) => {
    if (errors.length === 0) {
      const { responseGammaMessageEntity } = this.props;
      const entity = {
        ...responseGammaMessageEntity,
        ...values
      };

      if (this.state.isNew) {
        this.props.createEntity(entity);
      } else {
        this.props.updateEntity(entity);
      }
    }
  };

  handleClose = () => {
    this.props.history.push('/entity/response-gamma-message');
  };

  render() {
    const { responseGammaMessageEntity, responseGammas, loading, updating } = this.props;
    const { isNew } = this.state;

    return (
      <div>
        <Row className="justify-content-center">
          <Col md="8">
            <h2 id="nissanApp.responseGammaMessage.home.createOrEditLabel">
              <Translate contentKey="nissanApp.responseGammaMessage.home.createOrEditLabel">
                Create or edit a ResponseGammaMessage
              </Translate>
            </h2>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md="8">
            {loading ? (
              <p>Loading...</p>
            ) : (
              <AvForm model={isNew ? {} : responseGammaMessageEntity} onSubmit={this.saveEntity}>
                {!isNew ? (
                  <AvGroup>
                    <Label for="response-gamma-message-id">
                      <Translate contentKey="global.field.id">ID</Translate>
                    </Label>
                    <AvInput id="response-gamma-message-id" type="text" className="form-control" name="id" required readOnly />
                  </AvGroup>
                ) : null}
                <AvGroup>
                  <Label id="messageLabel" for="response-gamma-message-message">
                    <Translate contentKey="nissanApp.responseGammaMessage.message">Message</Translate>
                  </Label>
                  <AvField id="response-gamma-message-message" type="text" name="message" />
                  <UncontrolledTooltip target="messageLabel">
                    <Translate contentKey="nissanApp.responseGammaMessage.help.message" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="messageCodeLabel" for="response-gamma-message-messageCode">
                    <Translate contentKey="nissanApp.responseGammaMessage.messageCode">Message Code</Translate>
                  </Label>
                  <AvField id="response-gamma-message-messageCode" type="string" className="form-control" name="messageCode" />
                  <UncontrolledTooltip target="messageCodeLabel">
                    <Translate contentKey="nissanApp.responseGammaMessage.help.messageCode" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="registryNumberLabel" for="response-gamma-message-registryNumber">
                    <Translate contentKey="nissanApp.responseGammaMessage.registryNumber">Registry Number</Translate>
                  </Label>
                  <AvField id="response-gamma-message-registryNumber" type="string" className="form-control" name="registryNumber" />
                  <UncontrolledTooltip target="registryNumberLabel">
                    <Translate contentKey="nissanApp.responseGammaMessage.help.registryNumber" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="returnTypeLabel" for="response-gamma-message-returnType">
                    <Translate contentKey="nissanApp.responseGammaMessage.returnType">Return Type</Translate>
                  </Label>
                  <AvField id="response-gamma-message-returnType" type="text" name="returnType" />
                  <UncontrolledTooltip target="returnTypeLabel">
                    <Translate contentKey="nissanApp.responseGammaMessage.help.returnType" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label for="response-gamma-message-responseGamma">
                    <Translate contentKey="nissanApp.responseGammaMessage.responseGamma">Response Gamma</Translate>
                  </Label>
                  <AvInput id="response-gamma-message-responseGamma" type="select" className="form-control" name="responseGammaId">
                    <option value="" key="0" />
                    {responseGammas
                      ? responseGammas.map(otherEntity => (
                          <option value={otherEntity.id} key={otherEntity.id}>
                            {otherEntity.id}
                          </option>
                        ))
                      : null}
                  </AvInput>
                </AvGroup>
                <Button tag={Link} id="cancel-save" to="/entity/response-gamma-message" replace color="info">
                  <FontAwesomeIcon icon="arrow-left" />
                  &nbsp;
                  <span className="d-none d-md-inline">
                    <Translate contentKey="entity.action.back">Back</Translate>
                  </span>
                </Button>
                &nbsp;
                <Button color="primary" id="save-entity" type="submit" disabled={updating}>
                  <FontAwesomeIcon icon="save" />
                  &nbsp;
                  <Translate contentKey="entity.action.save">Save</Translate>
                </Button>
              </AvForm>
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (storeState: IRootState) => ({
  responseGammas: storeState.responseGamma.entities,
  responseGammaMessageEntity: storeState.responseGammaMessage.entity,
  loading: storeState.responseGammaMessage.loading,
  updating: storeState.responseGammaMessage.updating,
  updateSuccess: storeState.responseGammaMessage.updateSuccess
});

const mapDispatchToProps = {
  getResponseGammas,
  getEntity,
  updateEntity,
  createEntity,
  reset
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResponseGammaMessageUpdate);
