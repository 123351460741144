import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, UncontrolledTooltip, Row, Col } from 'reactstrap';
// tslint:disable-next-line:no-unused-variable
import { Translate, ICrudGetAction, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntity } from './syonet-follow-up.reducer';
import { ISyonetFollowUp } from 'app/shared/model/syonet-follow-up.model';
// tslint:disable-next-line:no-unused-variable
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';

export interface ISyonetFollowUpDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export class SyonetFollowUpDetail extends React.Component<ISyonetFollowUpDetailProps> {
  componentDidMount() {
    this.props.getEntity(this.props.match.params.id);
  }

  render() {
    const { syonetFollowUpEntity } = this.props;
    return (
      <Row>
        <Col md="8">
          <h2>
            <Translate contentKey="nissanApp.syonetFollowUp.detail.title">SyonetFollowUp</Translate> [<b>{syonetFollowUpEntity.id}</b>]
          </h2>
          <dl className="jh-entity-details">
            <dt>
              <span id="lastSync">
                <Translate contentKey="nissanApp.syonetFollowUp.lastSync">Last Sync</Translate>
              </span>
              <UncontrolledTooltip target="lastSync">
                <Translate contentKey="nissanApp.syonetFollowUp.help.lastSync" />
              </UncontrolledTooltip>
            </dt>
            <dd>
              <TextFormat value={syonetFollowUpEntity.lastSync} type="date" format={APP_DATE_FORMAT} />
            </dd>
            <dt>
              <span id="crmID">
                <Translate contentKey="nissanApp.syonetFollowUp.crmID">Crm ID</Translate>
              </span>
              <UncontrolledTooltip target="crmID">
                <Translate contentKey="nissanApp.syonetFollowUp.help.crmID" />
              </UncontrolledTooltip>
            </dt>
            <dd>{syonetFollowUpEntity.crmID}</dd>
            <dt>
              <span id="extracionDate">
                <Translate contentKey="nissanApp.syonetFollowUp.extracionDate">Extracion Date</Translate>
              </span>
              <UncontrolledTooltip target="extracionDate">
                <Translate contentKey="nissanApp.syonetFollowUp.help.extracionDate" />
              </UncontrolledTooltip>
            </dt>
            <dd>
              <TextFormat value={syonetFollowUpEntity.extracionDate} type="date" format={APP_DATE_FORMAT} />
            </dd>
            <dt>
              <span id="attendanceID">
                <Translate contentKey="nissanApp.syonetFollowUp.attendanceID">Attendance ID</Translate>
              </span>
              <UncontrolledTooltip target="attendanceID">
                <Translate contentKey="nissanApp.syonetFollowUp.help.attendanceID" />
              </UncontrolledTooltip>
            </dt>
            <dd>{syonetFollowUpEntity.attendanceID}</dd>
            <dt>
              <span id="nissanLeadID">
                <Translate contentKey="nissanApp.syonetFollowUp.nissanLeadID">Nissan Lead ID</Translate>
              </span>
              <UncontrolledTooltip target="nissanLeadID">
                <Translate contentKey="nissanApp.syonetFollowUp.help.nissanLeadID" />
              </UncontrolledTooltip>
            </dt>
            <dd>{syonetFollowUpEntity.nissanLeadID}</dd>
            <dt>
              <span id="initDate">
                <Translate contentKey="nissanApp.syonetFollowUp.initDate">Init Date</Translate>
              </span>
              <UncontrolledTooltip target="initDate">
                <Translate contentKey="nissanApp.syonetFollowUp.help.initDate" />
              </UncontrolledTooltip>
            </dt>
            <dd>
              <TextFormat value={syonetFollowUpEntity.initDate} type="date" format={APP_DATE_FORMAT} />
            </dd>
            <dt>
              <span id="endDate">
                <Translate contentKey="nissanApp.syonetFollowUp.endDate">End Date</Translate>
              </span>
              <UncontrolledTooltip target="endDate">
                <Translate contentKey="nissanApp.syonetFollowUp.help.endDate" />
              </UncontrolledTooltip>
            </dt>
            <dd>
              <TextFormat value={syonetFollowUpEntity.endDate} type="date" format={APP_DATE_FORMAT} />
            </dd>
            <dt>
              <span id="redemptionStatus">
                <Translate contentKey="nissanApp.syonetFollowUp.redemptionStatus">Redemption Status</Translate>
              </span>
              <UncontrolledTooltip target="redemptionStatus">
                <Translate contentKey="nissanApp.syonetFollowUp.help.redemptionStatus" />
              </UncontrolledTooltip>
            </dt>
            <dd>{syonetFollowUpEntity.redemptionStatus}</dd>
            <dt>
              <span id="reasonID">
                <Translate contentKey="nissanApp.syonetFollowUp.reasonID">Reason ID</Translate>
              </span>
              <UncontrolledTooltip target="reasonID">
                <Translate contentKey="nissanApp.syonetFollowUp.help.reasonID" />
              </UncontrolledTooltip>
            </dt>
            <dd>{syonetFollowUpEntity.reasonID}</dd>
            <dt>
              <span id="rescuerID">
                <Translate contentKey="nissanApp.syonetFollowUp.rescuerID">Rescuer ID</Translate>
              </span>
              <UncontrolledTooltip target="rescuerID">
                <Translate contentKey="nissanApp.syonetFollowUp.help.rescuerID" />
              </UncontrolledTooltip>
            </dt>
            <dd>{syonetFollowUpEntity.rescuerID}</dd>
            <dt>
              <span id="rescuerName">
                <Translate contentKey="nissanApp.syonetFollowUp.rescuerName">Rescuer Name</Translate>
              </span>
              <UncontrolledTooltip target="rescuerName">
                <Translate contentKey="nissanApp.syonetFollowUp.help.rescuerName" />
              </UncontrolledTooltip>
            </dt>
            <dd>{syonetFollowUpEntity.rescuerName}</dd>
            <dt>
              <span id="rescuerEmail">
                <Translate contentKey="nissanApp.syonetFollowUp.rescuerEmail">Rescuer Email</Translate>
              </span>
              <UncontrolledTooltip target="rescuerEmail">
                <Translate contentKey="nissanApp.syonetFollowUp.help.rescuerEmail" />
              </UncontrolledTooltip>
            </dt>
            <dd>{syonetFollowUpEntity.rescuerEmail}</dd>
            <dt>
              <Translate contentKey="nissanApp.syonetFollowUp.syonetLead">Syonet Lead</Translate>
            </dt>
            <dd>{syonetFollowUpEntity.syonetLeadId ? syonetFollowUpEntity.syonetLeadId : ''}</dd>
          </dl>
          <Button tag={Link} to="/entity/syonet-follow-up" replace color="info">
            <FontAwesomeIcon icon="arrow-left" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.back">Back</Translate>
            </span>
          </Button>
          &nbsp;
          <Button tag={Link} to={`/entity/syonet-follow-up/${syonetFollowUpEntity.id}/edit`} replace color="primary">
            <FontAwesomeIcon icon="pencil-alt" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.edit">Edit</Translate>
            </span>
          </Button>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = ({ syonetFollowUp }: IRootState) => ({
  syonetFollowUpEntity: syonetFollowUp.entity
});

const mapDispatchToProps = { getEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SyonetFollowUpDetail);
