export interface IVehicleNissanInterest {
  id?: number;
  vehicleCode?: string;
  modelCode?: string;
  model?: string;
  eimVehicleInterest?: string;
  version?: string;
  modelYear?: number;
  colorCode?: string;
  color?: string;
  modelYearVehicleNegotiated?: number;
  leadId?: number;
}

export const defaultValue: Readonly<IVehicleNissanInterest> = {};
