import { Moment } from 'moment';
import { IFollowUpResponse } from 'app/shared/model/follow-up-response.model';

export interface IFollowUpSync {
  id?: number;
  receivedIn?: Moment;
  synchronizedIn?: Moment;
  responses?: IFollowUpResponse[];
  syonetFollowUpId?: number;
}

export const defaultValue: Readonly<IFollowUpSync> = {};
