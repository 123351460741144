export interface INegotiationLeadMessage {
  id?: number;
  message?: string;
  messageCode?: number;
  registryNumber?: number;
  returnType?: string;
  negotiationResponseId?: number;
}

export const defaultValue: Readonly<INegotiationLeadMessage> = {};
