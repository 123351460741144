import { Moment } from 'moment';
import { INegotiationSync } from 'app/shared/model/negotiation-sync.model';
import { INegotiationVehicleInterested } from 'app/shared/model/negotiation-vehicle-interested.model';
import { INegotiationVehicleOnTrade } from 'app/shared/model/negotiation-vehicle-on-trade.model';

export interface ISyonetNegotiation {
  id?: number;
  lastSync?: Moment;
  crmID?: string;
  extracionDate?: Moment;
  attendanceID?: number;
  nissanLeadID?: number;
  negotiationID?: number;
  contactDate?: Moment;
  salesChannel?: number;
  paymentCondition?: string;
  negotiationValue?: number;
  negotiationDeadline?: number;
  inputValue?: number;
  plotValue?: number;
  changeAmount?: number;
  totalValueVehiclesUsed?: number;
  syonetLeadId?: number;
  syncs?: INegotiationSync[];
  vehiclesInteresteds?: INegotiationVehicleInterested[];
  vehiclesOnTrades?: INegotiationVehicleOnTrade[];
}

export const defaultValue: Readonly<ISyonetNegotiation> = {};
