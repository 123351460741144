import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import VisitShowroomSync from './visit-showroom-sync';
import VisitShowroomSyncDetail from './visit-showroom-sync-detail';
import VisitShowroomSyncUpdate from './visit-showroom-sync-update';
import VisitShowroomSyncDeleteDialog from './visit-showroom-sync-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={VisitShowroomSyncUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={VisitShowroomSyncUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={VisitShowroomSyncDetail} />
      <ErrorBoundaryRoute path={match.url} component={VisitShowroomSync} />
    </Switch>
    <ErrorBoundaryRoute path={`${match.url}/:id/delete`} component={VisitShowroomSyncDeleteDialog} />
  </>
);

export default Routes;
