import { Moment } from 'moment';
import { ILead } from 'app/shared/model/lead.model';
import { IResponseHotLeadMessage } from 'app/shared/model/response-hot-lead-message.model';

export interface IResponseHotLead {
  id?: number;
  receivedIn?: Moment;
  synchronizedIn?: Moment;
  executionHotLeadId?: number;
  statusCodeId?: number;
  leads?: ILead[];
  messages?: IResponseHotLeadMessage[];
}

export const defaultValue: Readonly<IResponseHotLead> = {};
