import { Moment } from 'moment';
import { INegotiationLeadMessage } from 'app/shared/model/negotiation-lead-message.model';

export interface INegotiationResponse {
  id?: number;
  receivedIn?: Moment;
  synchronizedIn?: Moment;
  negotiationSyncId?: number;
  statusCodeId?: number;
  messages?: INegotiationLeadMessage[];
}

export const defaultValue: Readonly<INegotiationResponse> = {};
