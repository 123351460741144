import { Moment } from 'moment';

export interface ISyncGamma {
  id?: number;
  receivedIn?: Moment;
  synchronizedIn?: Moment;
  executionGammaId?: number;
}

export const defaultValue: Readonly<ISyncGamma> = {};
