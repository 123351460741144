import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import RefusedProposalResponse from './refused-proposal-response';
import RefusedProposalResponseDetail from './refused-proposal-response-detail';
import RefusedProposalResponseUpdate from './refused-proposal-response-update';
import RefusedProposalResponseDeleteDialog from './refused-proposal-response-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={RefusedProposalResponseUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={RefusedProposalResponseUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={RefusedProposalResponseDetail} />
      <ErrorBoundaryRoute path={match.url} component={RefusedProposalResponse} />
    </Switch>
    <ErrorBoundaryRoute path={`${match.url}/:id/delete`} component={RefusedProposalResponseDeleteDialog} />
  </>
);

export default Routes;
