import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, UncontrolledTooltip, Row, Col } from 'reactstrap';
// tslint:disable-next-line:no-unused-variable
import { Translate, ICrudGetAction, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntity } from './syonet-attendant-lead.reducer';
import { ISyonetAttendantLead } from 'app/shared/model/syonet-attendant-lead.model';
// tslint:disable-next-line:no-unused-variable
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';

export interface ISyonetAttendantLeadDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export class SyonetAttendantLeadDetail extends React.Component<ISyonetAttendantLeadDetailProps> {
  componentDidMount() {
    this.props.getEntity(this.props.match.params.id);
  }

  render() {
    const { syonetAttendantLeadEntity } = this.props;
    return (
      <Row>
        <Col md="8">
          <h2>
            <Translate contentKey="nissanApp.syonetAttendantLead.detail.title">SyonetAttendantLead</Translate> [
            <b>{syonetAttendantLeadEntity.id}</b>]
          </h2>
          <dl className="jh-entity-details">
            <dt>
              <span id="lastSync">
                <Translate contentKey="nissanApp.syonetAttendantLead.lastSync">Last Sync</Translate>
              </span>
              <UncontrolledTooltip target="lastSync">
                <Translate contentKey="nissanApp.syonetAttendantLead.help.lastSync" />
              </UncontrolledTooltip>
            </dt>
            <dd>
              <TextFormat value={syonetAttendantLeadEntity.lastSync} type="date" format={APP_DATE_FORMAT} />
            </dd>
            <dt>
              <span id="crmID">
                <Translate contentKey="nissanApp.syonetAttendantLead.crmID">Crm ID</Translate>
              </span>
              <UncontrolledTooltip target="crmID">
                <Translate contentKey="nissanApp.syonetAttendantLead.help.crmID" />
              </UncontrolledTooltip>
            </dt>
            <dd>{syonetAttendantLeadEntity.crmID}</dd>
            <dt>
              <span id="extracionDate">
                <Translate contentKey="nissanApp.syonetAttendantLead.extracionDate">Extracion Date</Translate>
              </span>
              <UncontrolledTooltip target="extracionDate">
                <Translate contentKey="nissanApp.syonetAttendantLead.help.extracionDate" />
              </UncontrolledTooltip>
            </dt>
            <dd>
              <TextFormat value={syonetAttendantLeadEntity.extracionDate} type="date" format={APP_DATE_FORMAT} />
            </dd>
            <dt>
              <span id="attendanceID">
                <Translate contentKey="nissanApp.syonetAttendantLead.attendanceID">Attendance ID</Translate>
              </span>
              <UncontrolledTooltip target="attendanceID">
                <Translate contentKey="nissanApp.syonetAttendantLead.help.attendanceID" />
              </UncontrolledTooltip>
            </dt>
            <dd>{syonetAttendantLeadEntity.attendanceID}</dd>
            <dt>
              <span id="attendanceDate">
                <Translate contentKey="nissanApp.syonetAttendantLead.attendanceDate">Attendance Date</Translate>
              </span>
              <UncontrolledTooltip target="attendanceDate">
                <Translate contentKey="nissanApp.syonetAttendantLead.help.attendanceDate" />
              </UncontrolledTooltip>
            </dt>
            <dd>
              <TextFormat value={syonetAttendantLeadEntity.attendanceDate} type="date" format={APP_DATE_FORMAT} />
            </dd>
            <dt>
              <span id="sourceAttendance">
                <Translate contentKey="nissanApp.syonetAttendantLead.sourceAttendance">Source Attendance</Translate>
              </span>
              <UncontrolledTooltip target="sourceAttendance">
                <Translate contentKey="nissanApp.syonetAttendantLead.help.sourceAttendance" />
              </UncontrolledTooltip>
            </dt>
            <dd>{syonetAttendantLeadEntity.sourceAttendance}</dd>
            <dt>
              <span id="nissanLeadID">
                <Translate contentKey="nissanApp.syonetAttendantLead.nissanLeadID">Nissan Lead ID</Translate>
              </span>
              <UncontrolledTooltip target="nissanLeadID">
                <Translate contentKey="nissanApp.syonetAttendantLead.help.nissanLeadID" />
              </UncontrolledTooltip>
            </dt>
            <dd>{syonetAttendantLeadEntity.nissanLeadID}</dd>
            <dt>
              <span id="salesChannel">
                <Translate contentKey="nissanApp.syonetAttendantLead.salesChannel">Sales Channel</Translate>
              </span>
              <UncontrolledTooltip target="salesChannel">
                <Translate contentKey="nissanApp.syonetAttendantLead.help.salesChannel" />
              </UncontrolledTooltip>
            </dt>
            <dd>{syonetAttendantLeadEntity.salesChannel}</dd>
            <dt>
              <span id="saleCategory">
                <Translate contentKey="nissanApp.syonetAttendantLead.saleCategory">Sale Category</Translate>
              </span>
              <UncontrolledTooltip target="saleCategory">
                <Translate contentKey="nissanApp.syonetAttendantLead.help.saleCategory" />
              </UncontrolledTooltip>
            </dt>
            <dd>{syonetAttendantLeadEntity.saleCategory}</dd>
            <dt>
              <span id="mediaAttraction">
                <Translate contentKey="nissanApp.syonetAttendantLead.mediaAttraction">Media Attraction</Translate>
              </span>
              <UncontrolledTooltip target="mediaAttraction">
                <Translate contentKey="nissanApp.syonetAttendantLead.help.mediaAttraction" />
              </UncontrolledTooltip>
            </dt>
            <dd>{syonetAttendantLeadEntity.mediaAttraction}</dd>
            <dt>
              <span id="campaignID">
                <Translate contentKey="nissanApp.syonetAttendantLead.campaignID">Campaign ID</Translate>
              </span>
              <UncontrolledTooltip target="campaignID">
                <Translate contentKey="nissanApp.syonetAttendantLead.help.campaignID" />
              </UncontrolledTooltip>
            </dt>
            <dd>{syonetAttendantLeadEntity.campaignID}</dd>
            <dt>
              <span id="campaign">
                <Translate contentKey="nissanApp.syonetAttendantLead.campaign">Campaign</Translate>
              </span>
              <UncontrolledTooltip target="campaign">
                <Translate contentKey="nissanApp.syonetAttendantLead.help.campaign" />
              </UncontrolledTooltip>
            </dt>
            <dd>{syonetAttendantLeadEntity.campaign}</dd>
            <dt>
              <span id="temperature">
                <Translate contentKey="nissanApp.syonetAttendantLead.temperature">Temperature</Translate>
              </span>
              <UncontrolledTooltip target="temperature">
                <Translate contentKey="nissanApp.syonetAttendantLead.help.temperature" />
              </UncontrolledTooltip>
            </dt>
            <dd>{syonetAttendantLeadEntity.temperature}</dd>
            <dt>
              <span id="salesmanID">
                <Translate contentKey="nissanApp.syonetAttendantLead.salesmanID">Salesman ID</Translate>
              </span>
              <UncontrolledTooltip target="salesmanID">
                <Translate contentKey="nissanApp.syonetAttendantLead.help.salesmanID" />
              </UncontrolledTooltip>
            </dt>
            <dd>{syonetAttendantLeadEntity.salesmanID}</dd>
            <dt>
              <span id="salesmanName">
                <Translate contentKey="nissanApp.syonetAttendantLead.salesmanName">Salesman Name</Translate>
              </span>
              <UncontrolledTooltip target="salesmanName">
                <Translate contentKey="nissanApp.syonetAttendantLead.help.salesmanName" />
              </UncontrolledTooltip>
            </dt>
            <dd>{syonetAttendantLeadEntity.salesmanName}</dd>
            <dt>
              <span id="salesmanEmail">
                <Translate contentKey="nissanApp.syonetAttendantLead.salesmanEmail">Salesman Email</Translate>
              </span>
              <UncontrolledTooltip target="salesmanEmail">
                <Translate contentKey="nissanApp.syonetAttendantLead.help.salesmanEmail" />
              </UncontrolledTooltip>
            </dt>
            <dd>{syonetAttendantLeadEntity.salesmanEmail}</dd>
            <dt>
              <span id="salesManagerID">
                <Translate contentKey="nissanApp.syonetAttendantLead.salesManagerID">Sales Manager ID</Translate>
              </span>
              <UncontrolledTooltip target="salesManagerID">
                <Translate contentKey="nissanApp.syonetAttendantLead.help.salesManagerID" />
              </UncontrolledTooltip>
            </dt>
            <dd>{syonetAttendantLeadEntity.salesManagerID}</dd>
            <dt>
              <span id="salesManagerName">
                <Translate contentKey="nissanApp.syonetAttendantLead.salesManagerName">Sales Manager Name</Translate>
              </span>
              <UncontrolledTooltip target="salesManagerName">
                <Translate contentKey="nissanApp.syonetAttendantLead.help.salesManagerName" />
              </UncontrolledTooltip>
            </dt>
            <dd>{syonetAttendantLeadEntity.salesManagerName}</dd>
            <dt>
              <span id="salesManagerEmail">
                <Translate contentKey="nissanApp.syonetAttendantLead.salesManagerEmail">Sales Manager Email</Translate>
              </span>
              <UncontrolledTooltip target="salesManagerEmail">
                <Translate contentKey="nissanApp.syonetAttendantLead.help.salesManagerEmail" />
              </UncontrolledTooltip>
            </dt>
            <dd>{syonetAttendantLeadEntity.salesManagerEmail}</dd>
            <dt>
              <span id="clienteType">
                <Translate contentKey="nissanApp.syonetAttendantLead.clienteType">Cliente Type</Translate>
              </span>
              <UncontrolledTooltip target="clienteType">
                <Translate contentKey="nissanApp.syonetAttendantLead.help.clienteType" />
              </UncontrolledTooltip>
            </dt>
            <dd>{syonetAttendantLeadEntity.clienteType}</dd>
            <dt>
              <span id="clientNationality">
                <Translate contentKey="nissanApp.syonetAttendantLead.clientNationality">Client Nationality</Translate>
              </span>
              <UncontrolledTooltip target="clientNationality">
                <Translate contentKey="nissanApp.syonetAttendantLead.help.clientNationality" />
              </UncontrolledTooltip>
            </dt>
            <dd>{syonetAttendantLeadEntity.clientNationality}</dd>
            <dt>
              <span id="clienteID">
                <Translate contentKey="nissanApp.syonetAttendantLead.clienteID">Cliente ID</Translate>
              </span>
              <UncontrolledTooltip target="clienteID">
                <Translate contentKey="nissanApp.syonetAttendantLead.help.clienteID" />
              </UncontrolledTooltip>
            </dt>
            <dd>{syonetAttendantLeadEntity.clienteID}</dd>
            <dt>
              <span id="clientInternacionalID">
                <Translate contentKey="nissanApp.syonetAttendantLead.clientInternacionalID">Client Internacional ID</Translate>
              </span>
              <UncontrolledTooltip target="clientInternacionalID">
                <Translate contentKey="nissanApp.syonetAttendantLead.help.clientInternacionalID" />
              </UncontrolledTooltip>
            </dt>
            <dd>{syonetAttendantLeadEntity.clientInternacionalID}</dd>
            <dt>
              <span id="clienteFullName">
                <Translate contentKey="nissanApp.syonetAttendantLead.clienteFullName">Cliente Full Name</Translate>
              </span>
              <UncontrolledTooltip target="clienteFullName">
                <Translate contentKey="nissanApp.syonetAttendantLead.help.clienteFullName" />
              </UncontrolledTooltip>
            </dt>
            <dd>{syonetAttendantLeadEntity.clienteFullName}</dd>
            <dt>
              <span id="clientSocialName">
                <Translate contentKey="nissanApp.syonetAttendantLead.clientSocialName">Client Social Name</Translate>
              </span>
              <UncontrolledTooltip target="clientSocialName">
                <Translate contentKey="nissanApp.syonetAttendantLead.help.clientSocialName" />
              </UncontrolledTooltip>
            </dt>
            <dd>{syonetAttendantLeadEntity.clientSocialName}</dd>
            <dt>
              <span id="clientFantasyName">
                <Translate contentKey="nissanApp.syonetAttendantLead.clientFantasyName">Client Fantasy Name</Translate>
              </span>
              <UncontrolledTooltip target="clientFantasyName">
                <Translate contentKey="nissanApp.syonetAttendantLead.help.clientFantasyName" />
              </UncontrolledTooltip>
            </dt>
            <dd>{syonetAttendantLeadEntity.clientFantasyName}</dd>
            <dt>
              <span id="contactName">
                <Translate contentKey="nissanApp.syonetAttendantLead.contactName">Contact Name</Translate>
              </span>
              <UncontrolledTooltip target="contactName">
                <Translate contentKey="nissanApp.syonetAttendantLead.help.contactName" />
              </UncontrolledTooltip>
            </dt>
            <dd>{syonetAttendantLeadEntity.contactName}</dd>
            <dt>
              <span id="addressType">
                <Translate contentKey="nissanApp.syonetAttendantLead.addressType">Address Type</Translate>
              </span>
              <UncontrolledTooltip target="addressType">
                <Translate contentKey="nissanApp.syonetAttendantLead.help.addressType" />
              </UncontrolledTooltip>
            </dt>
            <dd>{syonetAttendantLeadEntity.addressType}</dd>
            <dt>
              <span id="addressName">
                <Translate contentKey="nissanApp.syonetAttendantLead.addressName">Address Name</Translate>
              </span>
              <UncontrolledTooltip target="addressName">
                <Translate contentKey="nissanApp.syonetAttendantLead.help.addressName" />
              </UncontrolledTooltip>
            </dt>
            <dd>{syonetAttendantLeadEntity.addressName}</dd>
            <dt>
              <span id="addressNumber">
                <Translate contentKey="nissanApp.syonetAttendantLead.addressNumber">Address Number</Translate>
              </span>
              <UncontrolledTooltip target="addressNumber">
                <Translate contentKey="nissanApp.syonetAttendantLead.help.addressNumber" />
              </UncontrolledTooltip>
            </dt>
            <dd>{syonetAttendantLeadEntity.addressNumber}</dd>
            <dt>
              <span id="addressComplement">
                <Translate contentKey="nissanApp.syonetAttendantLead.addressComplement">Address Complement</Translate>
              </span>
              <UncontrolledTooltip target="addressComplement">
                <Translate contentKey="nissanApp.syonetAttendantLead.help.addressComplement" />
              </UncontrolledTooltip>
            </dt>
            <dd>{syonetAttendantLeadEntity.addressComplement}</dd>
            <dt>
              <span id="addressNeighborhood">
                <Translate contentKey="nissanApp.syonetAttendantLead.addressNeighborhood">Address Neighborhood</Translate>
              </span>
              <UncontrolledTooltip target="addressNeighborhood">
                <Translate contentKey="nissanApp.syonetAttendantLead.help.addressNeighborhood" />
              </UncontrolledTooltip>
            </dt>
            <dd>{syonetAttendantLeadEntity.addressNeighborhood}</dd>
            <dt>
              <span id="addressCity">
                <Translate contentKey="nissanApp.syonetAttendantLead.addressCity">Address City</Translate>
              </span>
              <UncontrolledTooltip target="addressCity">
                <Translate contentKey="nissanApp.syonetAttendantLead.help.addressCity" />
              </UncontrolledTooltip>
            </dt>
            <dd>{syonetAttendantLeadEntity.addressCity}</dd>
            <dt>
              <span id="addressStateID">
                <Translate contentKey="nissanApp.syonetAttendantLead.addressStateID">Address State ID</Translate>
              </span>
              <UncontrolledTooltip target="addressStateID">
                <Translate contentKey="nissanApp.syonetAttendantLead.help.addressStateID" />
              </UncontrolledTooltip>
            </dt>
            <dd>{syonetAttendantLeadEntity.addressStateID}</dd>
            <dt>
              <span id="addressCityCode">
                <Translate contentKey="nissanApp.syonetAttendantLead.addressCityCode">Address City Code</Translate>
              </span>
              <UncontrolledTooltip target="addressCityCode">
                <Translate contentKey="nissanApp.syonetAttendantLead.help.addressCityCode" />
              </UncontrolledTooltip>
            </dt>
            <dd>{syonetAttendantLeadEntity.addressCityCode}</dd>
            <dt>
              <span id="clientGender">
                <Translate contentKey="nissanApp.syonetAttendantLead.clientGender">Client Gender</Translate>
              </span>
              <UncontrolledTooltip target="clientGender">
                <Translate contentKey="nissanApp.syonetAttendantLead.help.clientGender" />
              </UncontrolledTooltip>
            </dt>
            <dd>{syonetAttendantLeadEntity.clientGender}</dd>
            <dt>
              <span id="clientBirthday">
                <Translate contentKey="nissanApp.syonetAttendantLead.clientBirthday">Client Birthday</Translate>
              </span>
              <UncontrolledTooltip target="clientBirthday">
                <Translate contentKey="nissanApp.syonetAttendantLead.help.clientBirthday" />
              </UncontrolledTooltip>
            </dt>
            <dd>
              <TextFormat value={syonetAttendantLeadEntity.clientBirthday} type="date" format={APP_DATE_FORMAT} />
            </dd>
            <dt>
              <span id="clienteEmail">
                <Translate contentKey="nissanApp.syonetAttendantLead.clienteEmail">Cliente Email</Translate>
              </span>
              <UncontrolledTooltip target="clienteEmail">
                <Translate contentKey="nissanApp.syonetAttendantLead.help.clienteEmail" />
              </UncontrolledTooltip>
            </dt>
            <dd>{syonetAttendantLeadEntity.clienteEmail}</dd>
            <dt>
              <span id="clienteEmail2">
                <Translate contentKey="nissanApp.syonetAttendantLead.clienteEmail2">Cliente Email 2</Translate>
              </span>
              <UncontrolledTooltip target="clienteEmail2">
                <Translate contentKey="nissanApp.syonetAttendantLead.help.clienteEmail2" />
              </UncontrolledTooltip>
            </dt>
            <dd>{syonetAttendantLeadEntity.clienteEmail2}</dd>
            <dt>
              <span id="residentialPhoneAreaCode">
                <Translate contentKey="nissanApp.syonetAttendantLead.residentialPhoneAreaCode">Residential Phone Area Code</Translate>
              </span>
              <UncontrolledTooltip target="residentialPhoneAreaCode">
                <Translate contentKey="nissanApp.syonetAttendantLead.help.residentialPhoneAreaCode" />
              </UncontrolledTooltip>
            </dt>
            <dd>{syonetAttendantLeadEntity.residentialPhoneAreaCode}</dd>
            <dt>
              <span id="residentialPhoneNumber">
                <Translate contentKey="nissanApp.syonetAttendantLead.residentialPhoneNumber">Residential Phone Number</Translate>
              </span>
              <UncontrolledTooltip target="residentialPhoneNumber">
                <Translate contentKey="nissanApp.syonetAttendantLead.help.residentialPhoneNumber" />
              </UncontrolledTooltip>
            </dt>
            <dd>{syonetAttendantLeadEntity.residentialPhoneNumber}</dd>
            <dt>
              <span id="cellPhoneAreaCode">
                <Translate contentKey="nissanApp.syonetAttendantLead.cellPhoneAreaCode">Cell Phone Area Code</Translate>
              </span>
              <UncontrolledTooltip target="cellPhoneAreaCode">
                <Translate contentKey="nissanApp.syonetAttendantLead.help.cellPhoneAreaCode" />
              </UncontrolledTooltip>
            </dt>
            <dd>{syonetAttendantLeadEntity.cellPhoneAreaCode}</dd>
            <dt>
              <span id="cellPhoneNumber">
                <Translate contentKey="nissanApp.syonetAttendantLead.cellPhoneNumber">Cell Phone Number</Translate>
              </span>
              <UncontrolledTooltip target="cellPhoneNumber">
                <Translate contentKey="nissanApp.syonetAttendantLead.help.cellPhoneNumber" />
              </UncontrolledTooltip>
            </dt>
            <dd>{syonetAttendantLeadEntity.cellPhoneNumber}</dd>
            <dt>
              <span id="commercialPhoneAreaCode">
                <Translate contentKey="nissanApp.syonetAttendantLead.commercialPhoneAreaCode">Commercial Phone Area Code</Translate>
              </span>
              <UncontrolledTooltip target="commercialPhoneAreaCode">
                <Translate contentKey="nissanApp.syonetAttendantLead.help.commercialPhoneAreaCode" />
              </UncontrolledTooltip>
            </dt>
            <dd>{syonetAttendantLeadEntity.commercialPhoneAreaCode}</dd>
            <dt>
              <span id="commercialPhoneNumber">
                <Translate contentKey="nissanApp.syonetAttendantLead.commercialPhoneNumber">Commercial Phone Number</Translate>
              </span>
              <UncontrolledTooltip target="commercialPhoneNumber">
                <Translate contentKey="nissanApp.syonetAttendantLead.help.commercialPhoneNumber" />
              </UncontrolledTooltip>
            </dt>
            <dd>{syonetAttendantLeadEntity.commercialPhoneNumber}</dd>
            <dt>
              <Translate contentKey="nissanApp.syonetAttendantLead.syonetLead">Syonet Lead</Translate>
            </dt>
            <dd>{syonetAttendantLeadEntity.syonetLeadId ? syonetAttendantLeadEntity.syonetLeadId : ''}</dd>
          </dl>
          <Button tag={Link} to="/entity/syonet-attendant-lead" replace color="info">
            <FontAwesomeIcon icon="arrow-left" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.back">Back</Translate>
            </span>
          </Button>
          &nbsp;
          <Button tag={Link} to={`/entity/syonet-attendant-lead/${syonetAttendantLeadEntity.id}/edit`} replace color="primary">
            <FontAwesomeIcon icon="pencil-alt" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.edit">Edit</Translate>
            </span>
          </Button>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = ({ syonetAttendantLead }: IRootState) => ({
  syonetAttendantLeadEntity: syonetAttendantLead.entity
});

const mapDispatchToProps = { getEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SyonetAttendantLeadDetail);
