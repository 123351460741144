import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, UncontrolledTooltip, Row, Col } from 'reactstrap';
// tslint:disable-next-line:no-unused-variable
import { Translate, ICrudGetAction } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntity } from './vehicle-saled-approved-proposal.reducer';
import { IVehicleSaledApprovedProposal } from 'app/shared/model/vehicle-saled-approved-proposal.model';
// tslint:disable-next-line:no-unused-variable
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';

export interface IVehicleSaledApprovedProposalDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export class VehicleSaledApprovedProposalDetail extends React.Component<IVehicleSaledApprovedProposalDetailProps> {
  componentDidMount() {
    this.props.getEntity(this.props.match.params.id);
  }

  render() {
    const { vehicleSaledApprovedProposalEntity } = this.props;
    return (
      <Row>
        <Col md="8">
          <h2>
            <Translate contentKey="nissanApp.vehicleSaledApprovedProposal.detail.title">VehicleSaledApprovedProposal</Translate> [
            <b>{vehicleSaledApprovedProposalEntity.id}</b>]
          </h2>
          <dl className="jh-entity-details">
            <dt>
              <span id="vehicleCode">
                <Translate contentKey="nissanApp.vehicleSaledApprovedProposal.vehicleCode">Vehicle Code</Translate>
              </span>
              <UncontrolledTooltip target="vehicleCode">
                <Translate contentKey="nissanApp.vehicleSaledApprovedProposal.help.vehicleCode" />
              </UncontrolledTooltip>
            </dt>
            <dd>{vehicleSaledApprovedProposalEntity.vehicleCode}</dd>
            <dt>
              <span id="vim">
                <Translate contentKey="nissanApp.vehicleSaledApprovedProposal.vim">Vim</Translate>
              </span>
              <UncontrolledTooltip target="vim">
                <Translate contentKey="nissanApp.vehicleSaledApprovedProposal.help.vim" />
              </UncontrolledTooltip>
            </dt>
            <dd>{vehicleSaledApprovedProposalEntity.vim}</dd>
            <dt>
              <span id="modelCode">
                <Translate contentKey="nissanApp.vehicleSaledApprovedProposal.modelCode">Model Code</Translate>
              </span>
              <UncontrolledTooltip target="modelCode">
                <Translate contentKey="nissanApp.vehicleSaledApprovedProposal.help.modelCode" />
              </UncontrolledTooltip>
            </dt>
            <dd>{vehicleSaledApprovedProposalEntity.modelCode}</dd>
            <dt>
              <span id="eimCode">
                <Translate contentKey="nissanApp.vehicleSaledApprovedProposal.eimCode">Eim Code</Translate>
              </span>
              <UncontrolledTooltip target="eimCode">
                <Translate contentKey="nissanApp.vehicleSaledApprovedProposal.help.eimCode" />
              </UncontrolledTooltip>
            </dt>
            <dd>{vehicleSaledApprovedProposalEntity.eimCode}</dd>
            <dt>
              <span id="colorCode">
                <Translate contentKey="nissanApp.vehicleSaledApprovedProposal.colorCode">Color Code</Translate>
              </span>
              <UncontrolledTooltip target="colorCode">
                <Translate contentKey="nissanApp.vehicleSaledApprovedProposal.help.colorCode" />
              </UncontrolledTooltip>
            </dt>
            <dd>{vehicleSaledApprovedProposalEntity.colorCode}</dd>
            <dt>
              <span id="modelYear">
                <Translate contentKey="nissanApp.vehicleSaledApprovedProposal.modelYear">Model Year</Translate>
              </span>
              <UncontrolledTooltip target="modelYear">
                <Translate contentKey="nissanApp.vehicleSaledApprovedProposal.help.modelYear" />
              </UncontrolledTooltip>
            </dt>
            <dd>{vehicleSaledApprovedProposalEntity.modelYear}</dd>
            <dt>
              <span id="taxInvoice">
                <Translate contentKey="nissanApp.vehicleSaledApprovedProposal.taxInvoice">Tax Invoice</Translate>
              </span>
              <UncontrolledTooltip target="taxInvoice">
                <Translate contentKey="nissanApp.vehicleSaledApprovedProposal.help.taxInvoice" />
              </UncontrolledTooltip>
            </dt>
            <dd>{vehicleSaledApprovedProposalEntity.taxInvoice}</dd>
            <dt>
              <Translate contentKey="nissanApp.vehicleSaledApprovedProposal.syonetApprovedProposal">Syonet Approved Proposal</Translate>
            </dt>
            <dd>
              {vehicleSaledApprovedProposalEntity.syonetApprovedProposalId
                ? vehicleSaledApprovedProposalEntity.syonetApprovedProposalId
                : ''}
            </dd>
          </dl>
          <Button tag={Link} to="/entity/vehicle-saled-approved-proposal" replace color="info">
            <FontAwesomeIcon icon="arrow-left" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.back">Back</Translate>
            </span>
          </Button>
          &nbsp;
          <Button
            tag={Link}
            to={`/entity/vehicle-saled-approved-proposal/${vehicleSaledApprovedProposalEntity.id}/edit`}
            replace
            color="primary"
          >
            <FontAwesomeIcon icon="pencil-alt" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.edit">Edit</Translate>
            </span>
          </Button>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = ({ vehicleSaledApprovedProposal }: IRootState) => ({
  vehicleSaledApprovedProposalEntity: vehicleSaledApprovedProposal.entity
});

const mapDispatchToProps = { getEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VehicleSaledApprovedProposalDetail);
