import { Moment } from 'moment';
import { ISyncGamma } from 'app/shared/model/sync-gamma.model';
import { IResponseGamma } from 'app/shared/model/response-gamma.model';

export interface IExecutionGamma {
  id?: number;
  endTime?: Moment;
  startTime?: Moment;
  syncTime?: Moment;
  accountInfoId?: number;
  syncs?: ISyncGamma[];
  responses?: IResponseGamma[];
}

export const defaultValue: Readonly<IExecutionGamma> = {};
