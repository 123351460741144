import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import SchedulingLeadMessage from './scheduling-lead-message';
import SchedulingLeadMessageDetail from './scheduling-lead-message-detail';
import SchedulingLeadMessageUpdate from './scheduling-lead-message-update';
import SchedulingLeadMessageDeleteDialog from './scheduling-lead-message-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={SchedulingLeadMessageUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={SchedulingLeadMessageUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={SchedulingLeadMessageDetail} />
      <ErrorBoundaryRoute path={match.url} component={SchedulingLeadMessage} />
    </Switch>
    <ErrorBoundaryRoute path={`${match.url}/:id/delete`} component={SchedulingLeadMessageDeleteDialog} />
  </>
);

export default Routes;
