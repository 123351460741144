import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col, Label, UncontrolledTooltip } from 'reactstrap';
import { AvFeedback, AvForm, AvGroup, AvInput, AvField } from 'availity-reactstrap-validation';
// tslint:disable-next-line:no-unused-variable
import { Translate, translate, ICrudGetAction, ICrudGetAllAction, ICrudPutAction } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';

import { ISyonetLead } from 'app/shared/model/syonet-lead.model';
import { getEntities as getSyonetLeads } from 'app/entities/syonet-lead/syonet-lead.reducer';
import { getEntity, updateEntity, createEntity, reset } from './syonet-follow-up.reducer';
import { ISyonetFollowUp } from 'app/shared/model/syonet-follow-up.model';
// tslint:disable-next-line:no-unused-variable
import { convertDateTimeFromServer, convertDateTimeToServer } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';

export interface ISyonetFollowUpUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export interface ISyonetFollowUpUpdateState {
  isNew: boolean;
  syonetLeadId: string;
}

export class SyonetFollowUpUpdate extends React.Component<ISyonetFollowUpUpdateProps, ISyonetFollowUpUpdateState> {
  constructor(props) {
    super(props);
    this.state = {
      syonetLeadId: '0',
      isNew: !this.props.match.params || !this.props.match.params.id
    };
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextProps.updateSuccess !== this.props.updateSuccess && nextProps.updateSuccess) {
      this.handleClose();
    }
  }

  componentDidMount() {
    if (!this.state.isNew) {
      this.props.getEntity(this.props.match.params.id);
    }

    this.props.getSyonetLeads();
  }

  saveEntity = (event, errors, values) => {
    values.lastSync = convertDateTimeToServer(values.lastSync);
    values.extracionDate = convertDateTimeToServer(values.extracionDate);
    values.initDate = convertDateTimeToServer(values.initDate);
    values.endDate = convertDateTimeToServer(values.endDate);

    if (errors.length === 0) {
      const { syonetFollowUpEntity } = this.props;
      const entity = {
        ...syonetFollowUpEntity,
        ...values
      };

      if (this.state.isNew) {
        this.props.createEntity(entity);
      } else {
        this.props.updateEntity(entity);
      }
    }
  };

  handleClose = () => {
    this.props.history.push('/entity/syonet-follow-up');
  };

  render() {
    const { syonetFollowUpEntity, syonetLeads, loading, updating } = this.props;
    const { isNew } = this.state;

    return (
      <div>
        <Row className="justify-content-center">
          <Col md="8">
            <h2 id="nissanApp.syonetFollowUp.home.createOrEditLabel">
              <Translate contentKey="nissanApp.syonetFollowUp.home.createOrEditLabel">Create or edit a SyonetFollowUp</Translate>
            </h2>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md="8">
            {loading ? (
              <p>Loading...</p>
            ) : (
              <AvForm model={isNew ? {} : syonetFollowUpEntity} onSubmit={this.saveEntity}>
                {!isNew ? (
                  <AvGroup>
                    <Label for="syonet-follow-up-id">
                      <Translate contentKey="global.field.id">ID</Translate>
                    </Label>
                    <AvInput id="syonet-follow-up-id" type="text" className="form-control" name="id" required readOnly />
                  </AvGroup>
                ) : null}
                <AvGroup>
                  <Label id="lastSyncLabel" for="syonet-follow-up-lastSync">
                    <Translate contentKey="nissanApp.syonetFollowUp.lastSync">Last Sync</Translate>
                  </Label>
                  <AvInput
                    id="syonet-follow-up-lastSync"
                    type="datetime-local"
                    className="form-control"
                    name="lastSync"
                    placeholder={'YYYY-MM-DD HH:mm'}
                    value={isNew ? null : convertDateTimeFromServer(this.props.syonetFollowUpEntity.lastSync)}
                  />
                  <UncontrolledTooltip target="lastSyncLabel">
                    <Translate contentKey="nissanApp.syonetFollowUp.help.lastSync" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="crmIDLabel" for="syonet-follow-up-crmID">
                    <Translate contentKey="nissanApp.syonetFollowUp.crmID">Crm ID</Translate>
                  </Label>
                  <AvField id="syonet-follow-up-crmID" type="text" name="crmID" />
                  <UncontrolledTooltip target="crmIDLabel">
                    <Translate contentKey="nissanApp.syonetFollowUp.help.crmID" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="extracionDateLabel" for="syonet-follow-up-extracionDate">
                    <Translate contentKey="nissanApp.syonetFollowUp.extracionDate">Extracion Date</Translate>
                  </Label>
                  <AvInput
                    id="syonet-follow-up-extracionDate"
                    type="datetime-local"
                    className="form-control"
                    name="extracionDate"
                    placeholder={'YYYY-MM-DD HH:mm'}
                    value={isNew ? null : convertDateTimeFromServer(this.props.syonetFollowUpEntity.extracionDate)}
                    validate={{
                      required: { value: true, errorMessage: translate('entity.validation.required') }
                    }}
                  />
                  <UncontrolledTooltip target="extracionDateLabel">
                    <Translate contentKey="nissanApp.syonetFollowUp.help.extracionDate" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="attendanceIDLabel" for="syonet-follow-up-attendanceID">
                    <Translate contentKey="nissanApp.syonetFollowUp.attendanceID">Attendance ID</Translate>
                  </Label>
                  <AvField
                    id="syonet-follow-up-attendanceID"
                    type="string"
                    className="form-control"
                    name="attendanceID"
                    validate={{
                      required: { value: true, errorMessage: translate('entity.validation.required') },
                      number: { value: true, errorMessage: translate('entity.validation.number') }
                    }}
                  />
                  <UncontrolledTooltip target="attendanceIDLabel">
                    <Translate contentKey="nissanApp.syonetFollowUp.help.attendanceID" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="nissanLeadIDLabel" for="syonet-follow-up-nissanLeadID">
                    <Translate contentKey="nissanApp.syonetFollowUp.nissanLeadID">Nissan Lead ID</Translate>
                  </Label>
                  <AvField id="syonet-follow-up-nissanLeadID" type="string" className="form-control" name="nissanLeadID" />
                  <UncontrolledTooltip target="nissanLeadIDLabel">
                    <Translate contentKey="nissanApp.syonetFollowUp.help.nissanLeadID" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="initDateLabel" for="syonet-follow-up-initDate">
                    <Translate contentKey="nissanApp.syonetFollowUp.initDate">Init Date</Translate>
                  </Label>
                  <AvInput
                    id="syonet-follow-up-initDate"
                    type="datetime-local"
                    className="form-control"
                    name="initDate"
                    placeholder={'YYYY-MM-DD HH:mm'}
                    value={isNew ? null : convertDateTimeFromServer(this.props.syonetFollowUpEntity.initDate)}
                    validate={{
                      required: { value: true, errorMessage: translate('entity.validation.required') }
                    }}
                  />
                  <UncontrolledTooltip target="initDateLabel">
                    <Translate contentKey="nissanApp.syonetFollowUp.help.initDate" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="endDateLabel" for="syonet-follow-up-endDate">
                    <Translate contentKey="nissanApp.syonetFollowUp.endDate">End Date</Translate>
                  </Label>
                  <AvInput
                    id="syonet-follow-up-endDate"
                    type="datetime-local"
                    className="form-control"
                    name="endDate"
                    placeholder={'YYYY-MM-DD HH:mm'}
                    value={isNew ? null : convertDateTimeFromServer(this.props.syonetFollowUpEntity.endDate)}
                  />
                  <UncontrolledTooltip target="endDateLabel">
                    <Translate contentKey="nissanApp.syonetFollowUp.help.endDate" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="redemptionStatusLabel" for="syonet-follow-up-redemptionStatus">
                    <Translate contentKey="nissanApp.syonetFollowUp.redemptionStatus">Redemption Status</Translate>
                  </Label>
                  <AvField
                    id="syonet-follow-up-redemptionStatus"
                    type="string"
                    className="form-control"
                    name="redemptionStatus"
                    validate={{
                      required: { value: true, errorMessage: translate('entity.validation.required') },
                      number: { value: true, errorMessage: translate('entity.validation.number') }
                    }}
                  />
                  <UncontrolledTooltip target="redemptionStatusLabel">
                    <Translate contentKey="nissanApp.syonetFollowUp.help.redemptionStatus" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="reasonIDLabel" for="syonet-follow-up-reasonID">
                    <Translate contentKey="nissanApp.syonetFollowUp.reasonID">Reason ID</Translate>
                  </Label>
                  <AvField
                    id="syonet-follow-up-reasonID"
                    type="string"
                    className="form-control"
                    name="reasonID"
                    validate={{
                      required: { value: true, errorMessage: translate('entity.validation.required') },
                      number: { value: true, errorMessage: translate('entity.validation.number') }
                    }}
                  />
                  <UncontrolledTooltip target="reasonIDLabel">
                    <Translate contentKey="nissanApp.syonetFollowUp.help.reasonID" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="rescuerIDLabel" for="syonet-follow-up-rescuerID">
                    <Translate contentKey="nissanApp.syonetFollowUp.rescuerID">Rescuer ID</Translate>
                  </Label>
                  <AvField id="syonet-follow-up-rescuerID" type="text" name="rescuerID" />
                  <UncontrolledTooltip target="rescuerIDLabel">
                    <Translate contentKey="nissanApp.syonetFollowUp.help.rescuerID" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="rescuerNameLabel" for="syonet-follow-up-rescuerName">
                    <Translate contentKey="nissanApp.syonetFollowUp.rescuerName">Rescuer Name</Translate>
                  </Label>
                  <AvField id="syonet-follow-up-rescuerName" type="text" name="rescuerName" />
                  <UncontrolledTooltip target="rescuerNameLabel">
                    <Translate contentKey="nissanApp.syonetFollowUp.help.rescuerName" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="rescuerEmailLabel" for="syonet-follow-up-rescuerEmail">
                    <Translate contentKey="nissanApp.syonetFollowUp.rescuerEmail">Rescuer Email</Translate>
                  </Label>
                  <AvField id="syonet-follow-up-rescuerEmail" type="text" name="rescuerEmail" />
                  <UncontrolledTooltip target="rescuerEmailLabel">
                    <Translate contentKey="nissanApp.syonetFollowUp.help.rescuerEmail" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label for="syonet-follow-up-syonetLead">
                    <Translate contentKey="nissanApp.syonetFollowUp.syonetLead">Syonet Lead</Translate>
                  </Label>
                  <AvInput id="syonet-follow-up-syonetLead" type="select" className="form-control" name="syonetLeadId">
                    <option value="" key="0" />
                    {syonetLeads
                      ? syonetLeads.map(otherEntity => (
                          <option value={otherEntity.id} key={otherEntity.id}>
                            {otherEntity.id}
                          </option>
                        ))
                      : null}
                  </AvInput>
                </AvGroup>
                <Button tag={Link} id="cancel-save" to="/entity/syonet-follow-up" replace color="info">
                  <FontAwesomeIcon icon="arrow-left" />
                  &nbsp;
                  <span className="d-none d-md-inline">
                    <Translate contentKey="entity.action.back">Back</Translate>
                  </span>
                </Button>
                &nbsp;
                <Button color="primary" id="save-entity" type="submit" disabled={updating}>
                  <FontAwesomeIcon icon="save" />
                  &nbsp;
                  <Translate contentKey="entity.action.save">Save</Translate>
                </Button>
              </AvForm>
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (storeState: IRootState) => ({
  syonetLeads: storeState.syonetLead.entities,
  syonetFollowUpEntity: storeState.syonetFollowUp.entity,
  loading: storeState.syonetFollowUp.loading,
  updating: storeState.syonetFollowUp.updating,
  updateSuccess: storeState.syonetFollowUp.updateSuccess
});

const mapDispatchToProps = {
  getSyonetLeads,
  getEntity,
  updateEntity,
  createEntity,
  reset
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SyonetFollowUpUpdate);
