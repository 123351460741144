import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col, Label, UncontrolledTooltip } from 'reactstrap';
import { AvFeedback, AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
// tslint:disable-next-line:no-unused-variable
import { Translate, translate, ICrudGetAction, ICrudGetAllAction, ICrudPutAction } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';

import { IAccountInfo } from 'app/shared/model/account-info.model';
import { getEntities as getAccountInfos } from 'app/entities/account-info/account-info.reducer';
import { getEntity, updateEntity, createEntity, reset } from './execution-hot-lead.reducer';
import { IExecutionHotLead } from 'app/shared/model/execution-hot-lead.model';
// tslint:disable-next-line:no-unused-variable
import { convertDateTimeFromServer, convertDateTimeToServer } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';

export interface IExecutionHotLeadUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export interface IExecutionHotLeadUpdateState {
  isNew: boolean;
  accountInfoId: string;
}

export class ExecutionHotLeadUpdate extends React.Component<IExecutionHotLeadUpdateProps, IExecutionHotLeadUpdateState> {
  constructor(props) {
    super(props);
    this.state = {
      accountInfoId: '0',
      isNew: !this.props.match.params || !this.props.match.params.id
    };
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextProps.updateSuccess !== this.props.updateSuccess && nextProps.updateSuccess) {
      this.handleClose();
    }
  }

  componentDidMount() {
    if (!this.state.isNew) {
      this.props.getEntity(this.props.match.params.id);
    }

    this.props.getAccountInfos();
  }

  saveEntity = (event, errors, values) => {
    values.initDate = convertDateTimeToServer(values.initDate);
    values.endDate = convertDateTimeToServer(values.endDate);
    values.endTime = convertDateTimeToServer(values.endTime);
    values.startTime = convertDateTimeToServer(values.startTime);
    values.syncTime = convertDateTimeToServer(values.syncTime);

    if (errors.length === 0) {
      const { executionHotLeadEntity } = this.props;
      const entity = {
        ...executionHotLeadEntity,
        ...values
      };

      if (this.state.isNew) {
        this.props.createEntity(entity);
      } else {
        this.props.updateEntity(entity);
      }
    }
  };

  handleClose = () => {
    this.props.history.push('/entity/execution-hot-lead');
  };

  render() {
    const { executionHotLeadEntity, accountInfos, loading, updating } = this.props;
    const { isNew } = this.state;

    return (
      <div>
        <Row className="justify-content-center">
          <Col md="8">
            <h2 id="nissanApp.executionHotLead.home.createOrEditLabel">
              <Translate contentKey="nissanApp.executionHotLead.home.createOrEditLabel">Create or edit a ExecutionHotLead</Translate>
            </h2>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md="8">
            {loading ? (
              <p>Loading...</p>
            ) : (
              <AvForm model={isNew ? {} : executionHotLeadEntity} onSubmit={this.saveEntity}>
                {!isNew ? (
                  <AvGroup>
                    <Label for="execution-hot-lead-id">
                      <Translate contentKey="global.field.id">ID</Translate>
                    </Label>
                    <AvInput id="execution-hot-lead-id" type="text" className="form-control" name="id" required readOnly />
                  </AvGroup>
                ) : null}
                <AvGroup>
                  <Label id="initDateLabel" for="execution-hot-lead-initDate">
                    <Translate contentKey="nissanApp.executionHotLead.initDate">Init Date</Translate>
                  </Label>
                  <AvInput
                    id="execution-hot-lead-initDate"
                    type="datetime-local"
                    className="form-control"
                    name="initDate"
                    placeholder={'YYYY-MM-DD HH:mm'}
                    value={isNew ? null : convertDateTimeFromServer(this.props.executionHotLeadEntity.initDate)}
                  />
                  <UncontrolledTooltip target="initDateLabel">
                    <Translate contentKey="nissanApp.executionHotLead.help.initDate" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="endDateLabel" for="execution-hot-lead-endDate">
                    <Translate contentKey="nissanApp.executionHotLead.endDate">End Date</Translate>
                  </Label>
                  <AvInput
                    id="execution-hot-lead-endDate"
                    type="datetime-local"
                    className="form-control"
                    name="endDate"
                    placeholder={'YYYY-MM-DD HH:mm'}
                    value={isNew ? null : convertDateTimeFromServer(this.props.executionHotLeadEntity.endDate)}
                  />
                  <UncontrolledTooltip target="endDateLabel">
                    <Translate contentKey="nissanApp.executionHotLead.help.endDate" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="endTimeLabel" for="execution-hot-lead-endTime">
                    <Translate contentKey="nissanApp.executionHotLead.endTime">End Time</Translate>
                  </Label>
                  <AvInput
                    id="execution-hot-lead-endTime"
                    type="datetime-local"
                    className="form-control"
                    name="endTime"
                    placeholder={'YYYY-MM-DD HH:mm'}
                    value={isNew ? null : convertDateTimeFromServer(this.props.executionHotLeadEntity.endTime)}
                  />
                  <UncontrolledTooltip target="endTimeLabel">
                    <Translate contentKey="nissanApp.executionHotLead.help.endTime" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="startTimeLabel" for="execution-hot-lead-startTime">
                    <Translate contentKey="nissanApp.executionHotLead.startTime">Start Time</Translate>
                  </Label>
                  <AvInput
                    id="execution-hot-lead-startTime"
                    type="datetime-local"
                    className="form-control"
                    name="startTime"
                    placeholder={'YYYY-MM-DD HH:mm'}
                    value={isNew ? null : convertDateTimeFromServer(this.props.executionHotLeadEntity.startTime)}
                    validate={{
                      required: { value: true, errorMessage: translate('entity.validation.required') }
                    }}
                  />
                  <UncontrolledTooltip target="startTimeLabel">
                    <Translate contentKey="nissanApp.executionHotLead.help.startTime" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="syncTimeLabel" for="execution-hot-lead-syncTime">
                    <Translate contentKey="nissanApp.executionHotLead.syncTime">Sync Time</Translate>
                  </Label>
                  <AvInput
                    id="execution-hot-lead-syncTime"
                    type="datetime-local"
                    className="form-control"
                    name="syncTime"
                    placeholder={'YYYY-MM-DD HH:mm'}
                    value={isNew ? null : convertDateTimeFromServer(this.props.executionHotLeadEntity.syncTime)}
                  />
                  <UncontrolledTooltip target="syncTimeLabel">
                    <Translate contentKey="nissanApp.executionHotLead.help.syncTime" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label for="execution-hot-lead-accountInfo">
                    <Translate contentKey="nissanApp.executionHotLead.accountInfo">Account Info</Translate>
                  </Label>
                  <AvInput id="execution-hot-lead-accountInfo" type="select" className="form-control" name="accountInfoId">
                    <option value="" key="0" />
                    {accountInfos
                      ? accountInfos.map(otherEntity => (
                          <option value={otherEntity.id} key={otherEntity.id}>
                            {otherEntity.id}
                          </option>
                        ))
                      : null}
                  </AvInput>
                </AvGroup>
                <Button tag={Link} id="cancel-save" to="/entity/execution-hot-lead" replace color="info">
                  <FontAwesomeIcon icon="arrow-left" />
                  &nbsp;
                  <span className="d-none d-md-inline">
                    <Translate contentKey="entity.action.back">Back</Translate>
                  </span>
                </Button>
                &nbsp;
                <Button color="primary" id="save-entity" type="submit" disabled={updating}>
                  <FontAwesomeIcon icon="save" />
                  &nbsp;
                  <Translate contentKey="entity.action.save">Save</Translate>
                </Button>
              </AvForm>
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (storeState: IRootState) => ({
  accountInfos: storeState.accountInfo.entities,
  executionHotLeadEntity: storeState.executionHotLead.entity,
  loading: storeState.executionHotLead.loading,
  updating: storeState.executionHotLead.updating,
  updateSuccess: storeState.executionHotLead.updateSuccess
});

const mapDispatchToProps = {
  getAccountInfos,
  getEntity,
  updateEntity,
  createEntity,
  reset
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ExecutionHotLeadUpdate);
