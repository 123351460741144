import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col, Label, UncontrolledTooltip } from 'reactstrap';
import { AvFeedback, AvForm, AvGroup, AvInput, AvField } from 'availity-reactstrap-validation';
// tslint:disable-next-line:no-unused-variable
import { Translate, translate, ICrudGetAction, ICrudGetAllAction, ICrudPutAction } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';

import { getEntity, updateEntity, createEntity, reset } from './account-info.reducer';
import { IAccountInfo } from 'app/shared/model/account-info.model';
// tslint:disable-next-line:no-unused-variable
import { convertDateTimeFromServer, convertDateTimeToServer } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';

export interface IAccountInfoUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export interface IAccountInfoUpdateState {
  isNew: boolean;
}

export class AccountInfoUpdate extends React.Component<IAccountInfoUpdateProps, IAccountInfoUpdateState> {
  constructor(props) {
    super(props);
    this.state = {
      isNew: !this.props.match.params || !this.props.match.params.id
    };
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextProps.updateSuccess !== this.props.updateSuccess && nextProps.updateSuccess) {
      this.handleClose();
    }
  }

  componentDidMount() {
    if (!this.state.isNew) {
      this.props.getEntity(this.props.match.params.id);
    }
  }

  saveEntity = (event, errors, values) => {
    values.lastSync = convertDateTimeToServer(values.lastSync);

    if (errors.length === 0) {
      const { accountInfoEntity } = this.props;
      const entity = {
        ...accountInfoEntity,
        ...values
      };

      if (this.state.isNew) {
        this.props.createEntity(entity);
      } else {
        this.props.updateEntity(entity);
      }
    }
  };

  handleClose = () => {
    this.props.history.push('/entity/account-info');
  };

  render() {
    const { accountInfoEntity, loading, updating } = this.props;
    const { isNew } = this.state;

    return (
      <div>
        <Row className="justify-content-center">
          <Col md="8">
            <h2 id="nissanApp.accountInfo.home.createOrEditLabel">
              <Translate contentKey="nissanApp.accountInfo.home.createOrEditLabel">Create or edit a AccountInfo</Translate>
            </h2>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md="8">
            {loading ? (
              <p>Loading...</p>
            ) : (
              <AvForm model={isNew ? {} : accountInfoEntity} onSubmit={this.saveEntity}>
                {!isNew ? (
                  <AvGroup>
                    <Label for="account-info-id">
                      <Translate contentKey="global.field.id">ID</Translate>
                    </Label>
                    <AvInput id="account-info-id" type="text" className="form-control" name="id" required readOnly />
                  </AvGroup>
                ) : null}
                <AvGroup>
                  <Label id="urlLabel" for="account-info-url">
                    <Translate contentKey="nissanApp.accountInfo.url">Url</Translate>
                  </Label>
                  <AvField
                    id="account-info-url"
                    type="text"
                    name="url"
                    validate={{
                      required: { value: true, errorMessage: translate('entity.validation.required') }
                    }}
                  />
                  <UncontrolledTooltip target="urlLabel">
                    <Translate contentKey="nissanApp.accountInfo.help.url" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="userSyonetLabel" for="account-info-userSyonet">
                    <Translate contentKey="nissanApp.accountInfo.userSyonet">User Syonet</Translate>
                  </Label>
                  <AvField
                    id="account-info-userSyonet"
                    type="text"
                    name="userSyonet"
                    validate={{
                      required: { value: true, errorMessage: translate('entity.validation.required') }
                    }}
                  />
                  <UncontrolledTooltip target="userSyonetLabel">
                    <Translate contentKey="nissanApp.accountInfo.help.userSyonet" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="passSyonetLabel" for="account-info-passSyonet">
                    <Translate contentKey="nissanApp.accountInfo.passSyonet">Pass Syonet</Translate>
                  </Label>
                  <AvField
                    id="account-info-passSyonet"
                    type="text"
                    name="passSyonet"
                    validate={{
                      required: { value: true, errorMessage: translate('entity.validation.required') }
                    }}
                  />
                  <UncontrolledTooltip target="passSyonetLabel">
                    <Translate contentKey="nissanApp.accountInfo.help.passSyonet" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="enterpriseCRMIDLabel" for="account-info-enterpriseCRMID">
                    <Translate contentKey="nissanApp.accountInfo.enterpriseCRMID">Enterprise CRMID</Translate>
                  </Label>
                  <AvField
                    id="account-info-enterpriseCRMID"
                    type="string"
                    className="form-control"
                    name="enterpriseCRMID"
                    validate={{
                      required: { value: true, errorMessage: translate('entity.validation.required') },
                      number: { value: true, errorMessage: translate('entity.validation.number') }
                    }}
                  />
                  <UncontrolledTooltip target="enterpriseCRMIDLabel">
                    <Translate contentKey="nissanApp.accountInfo.help.enterpriseCRMID" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="birCodeLabel" for="account-info-birCode">
                    <Translate contentKey="nissanApp.accountInfo.birCode">Bir Code</Translate>
                  </Label>
                  <AvField
                    id="account-info-birCode"
                    type="text"
                    name="birCode"
                    validate={{
                      required: { value: true, errorMessage: translate('entity.validation.required') }
                    }}
                  />
                  <UncontrolledTooltip target="birCodeLabel">
                    <Translate contentKey="nissanApp.accountInfo.help.birCode" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="enterpriseIDLabel" for="account-info-enterpriseID">
                    <Translate contentKey="nissanApp.accountInfo.enterpriseID">Enterprise ID</Translate>
                  </Label>
                  <AvField
                    id="account-info-enterpriseID"
                    type="text"
                    name="enterpriseID"
                    validate={{
                      required: { value: true, errorMessage: translate('entity.validation.required') }
                    }}
                  />
                  <UncontrolledTooltip target="enterpriseIDLabel">
                    <Translate contentKey="nissanApp.accountInfo.help.enterpriseID" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="userNissanLabel" for="account-info-userNissan">
                    <Translate contentKey="nissanApp.accountInfo.userNissan">User Nissan</Translate>
                  </Label>
                  <AvField
                    id="account-info-userNissan"
                    type="text"
                    name="userNissan"
                    validate={{
                      required: { value: true, errorMessage: translate('entity.validation.required') }
                    }}
                  />
                  <UncontrolledTooltip target="userNissanLabel">
                    <Translate contentKey="nissanApp.accountInfo.help.userNissan" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="passNissanLabel" for="account-info-passNissan">
                    <Translate contentKey="nissanApp.accountInfo.passNissan">Pass Nissan</Translate>
                  </Label>
                  <AvField
                    id="account-info-passNissan"
                    type="text"
                    name="passNissan"
                    validate={{
                      required: { value: true, errorMessage: translate('entity.validation.required') }
                    }}
                  />
                  <UncontrolledTooltip target="passNissanLabel">
                    <Translate contentKey="nissanApp.accountInfo.help.passNissan" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="sourceSystemLabel" for="account-info-sourceSystem">
                    <Translate contentKey="nissanApp.accountInfo.sourceSystem">Source System</Translate>
                  </Label>
                  <AvField id="account-info-sourceSystem" type="string" className="form-control" name="sourceSystem" />
                  <UncontrolledTooltip target="sourceSystemLabel">
                    <Translate contentKey="nissanApp.accountInfo.help.sourceSystem" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="groupLabel" for="account-info-group">
                    <Translate contentKey="nissanApp.accountInfo.group">Group</Translate>
                  </Label>
                  <AvField id="account-info-group" type="text" name="group" />
                  <UncontrolledTooltip target="groupLabel">
                    <Translate contentKey="nissanApp.accountInfo.help.group" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="nameLabel" for="account-info-name">
                    <Translate contentKey="nissanApp.accountInfo.name">Name</Translate>
                  </Label>
                  <AvField id="account-info-name" type="text" name="name" />
                  <UncontrolledTooltip target="nameLabel">
                    <Translate contentKey="nissanApp.accountInfo.help.name" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="webServiceVersionLabel" for="account-info-webServiceVersion">
                    <Translate contentKey="nissanApp.accountInfo.webServiceVersion">Web Service Version</Translate>
                  </Label>
                  <AvField id="account-info-webServiceVersion" type="text" name="webServiceVersion" />
                  <UncontrolledTooltip target="webServiceVersionLabel">
                    <Translate contentKey="nissanApp.accountInfo.help.webServiceVersion" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="lastSyncLabel" for="account-info-lastSync">
                    <Translate contentKey="nissanApp.accountInfo.lastSync">Last Sync</Translate>
                  </Label>
                  <AvInput
                    id="account-info-lastSync"
                    type="datetime-local"
                    className="form-control"
                    name="lastSync"
                    placeholder={'YYYY-MM-DD HH:mm'}
                    value={isNew ? null : convertDateTimeFromServer(this.props.accountInfoEntity.lastSync)}
                  />
                  <UncontrolledTooltip target="lastSyncLabel">
                    <Translate contentKey="nissanApp.accountInfo.help.lastSync" />
                  </UncontrolledTooltip>
                </AvGroup>
                <Button tag={Link} id="cancel-save" to="/entity/account-info" replace color="info">
                  <FontAwesomeIcon icon="arrow-left" />
                  &nbsp;
                  <span className="d-none d-md-inline">
                    <Translate contentKey="entity.action.back">Back</Translate>
                  </span>
                </Button>
                &nbsp;
                <Button color="primary" id="save-entity" type="submit" disabled={updating}>
                  <FontAwesomeIcon icon="save" />
                  &nbsp;
                  <Translate contentKey="entity.action.save">Save</Translate>
                </Button>
              </AvForm>
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (storeState: IRootState) => ({
  accountInfoEntity: storeState.accountInfo.entity,
  loading: storeState.accountInfo.loading,
  updating: storeState.accountInfo.updating,
  updateSuccess: storeState.accountInfo.updateSuccess
});

const mapDispatchToProps = {
  getEntity,
  updateEntity,
  createEntity,
  reset
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountInfoUpdate);
