import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, InputGroup, Col, Row, Table } from 'reactstrap';
import { AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
// tslint:disable-next-line:no-unused-variable
import { Translate, translate, ICrudSearchAction, ICrudGetAllAction, TextFormat, getSortState, IPaginationBaseState } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getSearchEntities, getEntities, reset } from './syonet-attendant-lead.reducer';
import { ISyonetAttendantLead } from 'app/shared/model/syonet-attendant-lead.model';
// tslint:disable-next-line:no-unused-variable
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { ITEMS_PER_PAGE } from 'app/shared/util/pagination.constants';

export interface ISyonetAttendantLeadProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {}

export interface ISyonetAttendantLeadState extends IPaginationBaseState {
  search: string;
}

export class SyonetAttendantLead extends React.Component<ISyonetAttendantLeadProps, ISyonetAttendantLeadState> {
  state: ISyonetAttendantLeadState = {
    search: '',
    ...getSortState(this.props.location, ITEMS_PER_PAGE)
  };

  componentDidMount() {
    this.reset();
  }

  componentDidUpdate() {
    if (this.props.updateSuccess) {
      this.reset();
    }
  }

  search = () => {
    if (this.state.search) {
      this.props.reset();
      this.setState({ activePage: 1 }, () => {
        const { activePage, itemsPerPage, sort, order, search } = this.state;
        this.props.getSearchEntities(search, activePage - 1, itemsPerPage, `${sort},${order}`);
      });
    }
  };

  clear = () => {
    this.props.reset();
    this.setState({ search: '', activePage: 1 }, () => {
      this.props.getEntities();
    });
  };

  handleSearch = event => this.setState({ search: event.target.value });

  reset = () => {
    this.props.reset();
    this.setState({ activePage: 1 }, () => {
      this.getEntities();
    });
  };

  handleLoadMore = () => {
    if (window.pageYOffset > 0) {
      this.setState({ activePage: this.state.activePage + 1 }, () => this.getEntities());
    }
  };

  sort = prop => () => {
    this.setState(
      {
        order: this.state.order === 'asc' ? 'desc' : 'asc',
        sort: prop
      },
      () => {
        this.reset();
      }
    );
  };

  getEntities = () => {
    const { activePage, itemsPerPage, sort, order, search } = this.state;
    if (search) {
      this.props.getSearchEntities(search, activePage - 1, itemsPerPage, `${sort},${order}`);
    } else {
      this.props.getEntities(activePage - 1, itemsPerPage, `${sort},${order}`);
    }
  };

  render() {
    const { syonetAttendantLeadList, match } = this.props;
    return (
      <div>
        <h2 id="syonet-attendant-lead-heading">
          <Translate contentKey="nissanApp.syonetAttendantLead.home.title">Syonet Attendant Leads</Translate>
          <Link to={`${match.url}/new`} className="btn btn-primary float-right jh-create-entity" id="jh-create-entity">
            <FontAwesomeIcon icon="plus" />
            &nbsp;
            <Translate contentKey="nissanApp.syonetAttendantLead.home.createLabel">Create new Syonet Attendant Lead</Translate>
          </Link>
        </h2>
        <Row>
          <Col sm="12">
            <AvForm onSubmit={this.search}>
              <AvGroup>
                <InputGroup>
                  <AvInput
                    type="text"
                    name="search"
                    value={this.state.search}
                    onChange={this.handleSearch}
                    placeholder={translate('nissanApp.syonetAttendantLead.home.search')}
                  />
                  <Button className="input-group-addon">
                    <FontAwesomeIcon icon="search" />
                  </Button>
                  <Button type="reset" className="input-group-addon" onClick={this.clear}>
                    <FontAwesomeIcon icon="trash" />
                  </Button>
                </InputGroup>
              </AvGroup>
            </AvForm>
          </Col>
        </Row>
        <div className="table-responsive">
          <InfiniteScroll
            pageStart={this.state.activePage}
            loadMore={this.handleLoadMore}
            hasMore={this.state.activePage - 1 < this.props.links.next}
            loader={<div className="loader">Loading ...</div>}
            threshold={0}
            initialLoad={false}
          >
            <Table responsive>
              <thead>
                <tr>
                  <th className="hand" onClick={this.sort('id')}>
                    <Translate contentKey="global.field.id">ID</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={this.sort('lastSync')}>
                    <Translate contentKey="nissanApp.syonetAttendantLead.lastSync">Last Sync</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={this.sort('crmID')}>
                    <Translate contentKey="nissanApp.syonetAttendantLead.crmID">Crm ID</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={this.sort('extracionDate')}>
                    <Translate contentKey="nissanApp.syonetAttendantLead.extracionDate">Extracion Date</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={this.sort('attendanceID')}>
                    <Translate contentKey="nissanApp.syonetAttendantLead.attendanceID">Attendance ID</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={this.sort('attendanceDate')}>
                    <Translate contentKey="nissanApp.syonetAttendantLead.attendanceDate">Attendance Date</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={this.sort('sourceAttendance')}>
                    <Translate contentKey="nissanApp.syonetAttendantLead.sourceAttendance">Source Attendance</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={this.sort('nissanLeadID')}>
                    <Translate contentKey="nissanApp.syonetAttendantLead.nissanLeadID">Nissan Lead ID</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={this.sort('salesChannel')}>
                    <Translate contentKey="nissanApp.syonetAttendantLead.salesChannel">Sales Channel</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={this.sort('saleCategory')}>
                    <Translate contentKey="nissanApp.syonetAttendantLead.saleCategory">Sale Category</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={this.sort('mediaAttraction')}>
                    <Translate contentKey="nissanApp.syonetAttendantLead.mediaAttraction">Media Attraction</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={this.sort('campaignID')}>
                    <Translate contentKey="nissanApp.syonetAttendantLead.campaignID">Campaign ID</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={this.sort('campaign')}>
                    <Translate contentKey="nissanApp.syonetAttendantLead.campaign">Campaign</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={this.sort('temperature')}>
                    <Translate contentKey="nissanApp.syonetAttendantLead.temperature">Temperature</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={this.sort('salesmanID')}>
                    <Translate contentKey="nissanApp.syonetAttendantLead.salesmanID">Salesman ID</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={this.sort('salesmanName')}>
                    <Translate contentKey="nissanApp.syonetAttendantLead.salesmanName">Salesman Name</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={this.sort('salesmanEmail')}>
                    <Translate contentKey="nissanApp.syonetAttendantLead.salesmanEmail">Salesman Email</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={this.sort('salesManagerID')}>
                    <Translate contentKey="nissanApp.syonetAttendantLead.salesManagerID">Sales Manager ID</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={this.sort('salesManagerName')}>
                    <Translate contentKey="nissanApp.syonetAttendantLead.salesManagerName">Sales Manager Name</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={this.sort('salesManagerEmail')}>
                    <Translate contentKey="nissanApp.syonetAttendantLead.salesManagerEmail">Sales Manager Email</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={this.sort('clienteType')}>
                    <Translate contentKey="nissanApp.syonetAttendantLead.clienteType">Cliente Type</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={this.sort('clientNationality')}>
                    <Translate contentKey="nissanApp.syonetAttendantLead.clientNationality">Client Nationality</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={this.sort('clienteID')}>
                    <Translate contentKey="nissanApp.syonetAttendantLead.clienteID">Cliente ID</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={this.sort('clientInternacionalID')}>
                    <Translate contentKey="nissanApp.syonetAttendantLead.clientInternacionalID">Client Internacional ID</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={this.sort('clienteFullName')}>
                    <Translate contentKey="nissanApp.syonetAttendantLead.clienteFullName">Cliente Full Name</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={this.sort('clientSocialName')}>
                    <Translate contentKey="nissanApp.syonetAttendantLead.clientSocialName">Client Social Name</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={this.sort('clientFantasyName')}>
                    <Translate contentKey="nissanApp.syonetAttendantLead.clientFantasyName">Client Fantasy Name</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={this.sort('contactName')}>
                    <Translate contentKey="nissanApp.syonetAttendantLead.contactName">Contact Name</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={this.sort('addressType')}>
                    <Translate contentKey="nissanApp.syonetAttendantLead.addressType">Address Type</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={this.sort('addressName')}>
                    <Translate contentKey="nissanApp.syonetAttendantLead.addressName">Address Name</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={this.sort('addressNumber')}>
                    <Translate contentKey="nissanApp.syonetAttendantLead.addressNumber">Address Number</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={this.sort('addressComplement')}>
                    <Translate contentKey="nissanApp.syonetAttendantLead.addressComplement">Address Complement</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={this.sort('addressNeighborhood')}>
                    <Translate contentKey="nissanApp.syonetAttendantLead.addressNeighborhood">Address Neighborhood</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={this.sort('addressCity')}>
                    <Translate contentKey="nissanApp.syonetAttendantLead.addressCity">Address City</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={this.sort('addressStateID')}>
                    <Translate contentKey="nissanApp.syonetAttendantLead.addressStateID">Address State ID</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={this.sort('addressCityCode')}>
                    <Translate contentKey="nissanApp.syonetAttendantLead.addressCityCode">Address City Code</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={this.sort('clientGender')}>
                    <Translate contentKey="nissanApp.syonetAttendantLead.clientGender">Client Gender</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={this.sort('clientBirthday')}>
                    <Translate contentKey="nissanApp.syonetAttendantLead.clientBirthday">Client Birthday</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={this.sort('clienteEmail')}>
                    <Translate contentKey="nissanApp.syonetAttendantLead.clienteEmail">Cliente Email</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={this.sort('clienteEmail2')}>
                    <Translate contentKey="nissanApp.syonetAttendantLead.clienteEmail2">Cliente Email 2</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={this.sort('residentialPhoneAreaCode')}>
                    <Translate contentKey="nissanApp.syonetAttendantLead.residentialPhoneAreaCode">Residential Phone Area Code</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={this.sort('residentialPhoneNumber')}>
                    <Translate contentKey="nissanApp.syonetAttendantLead.residentialPhoneNumber">Residential Phone Number</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={this.sort('cellPhoneAreaCode')}>
                    <Translate contentKey="nissanApp.syonetAttendantLead.cellPhoneAreaCode">Cell Phone Area Code</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={this.sort('cellPhoneNumber')}>
                    <Translate contentKey="nissanApp.syonetAttendantLead.cellPhoneNumber">Cell Phone Number</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={this.sort('commercialPhoneAreaCode')}>
                    <Translate contentKey="nissanApp.syonetAttendantLead.commercialPhoneAreaCode">Commercial Phone Area Code</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={this.sort('commercialPhoneNumber')}>
                    <Translate contentKey="nissanApp.syonetAttendantLead.commercialPhoneNumber">Commercial Phone Number</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th>
                    <Translate contentKey="nissanApp.syonetAttendantLead.syonetLead">Syonet Lead</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {syonetAttendantLeadList.map((syonetAttendantLead, i) => (
                  <tr key={`entity-${i}`}>
                    <td>
                      <Button tag={Link} to={`${match.url}/${syonetAttendantLead.id}`} color="link" size="sm">
                        {syonetAttendantLead.id}
                      </Button>
                    </td>
                    <td>
                      <TextFormat type="date" value={syonetAttendantLead.lastSync} format={APP_DATE_FORMAT} />
                    </td>
                    <td>{syonetAttendantLead.crmID}</td>
                    <td>
                      <TextFormat type="date" value={syonetAttendantLead.extracionDate} format={APP_DATE_FORMAT} />
                    </td>
                    <td>{syonetAttendantLead.attendanceID}</td>
                    <td>
                      <TextFormat type="date" value={syonetAttendantLead.attendanceDate} format={APP_DATE_FORMAT} />
                    </td>
                    <td>{syonetAttendantLead.sourceAttendance}</td>
                    <td>{syonetAttendantLead.nissanLeadID}</td>
                    <td>{syonetAttendantLead.salesChannel}</td>
                    <td>{syonetAttendantLead.saleCategory}</td>
                    <td>{syonetAttendantLead.mediaAttraction}</td>
                    <td>{syonetAttendantLead.campaignID}</td>
                    <td>{syonetAttendantLead.campaign}</td>
                    <td>{syonetAttendantLead.temperature}</td>
                    <td>{syonetAttendantLead.salesmanID}</td>
                    <td>{syonetAttendantLead.salesmanName}</td>
                    <td>{syonetAttendantLead.salesmanEmail}</td>
                    <td>{syonetAttendantLead.salesManagerID}</td>
                    <td>{syonetAttendantLead.salesManagerName}</td>
                    <td>{syonetAttendantLead.salesManagerEmail}</td>
                    <td>{syonetAttendantLead.clienteType}</td>
                    <td>{syonetAttendantLead.clientNationality}</td>
                    <td>{syonetAttendantLead.clienteID}</td>
                    <td>{syonetAttendantLead.clientInternacionalID}</td>
                    <td>{syonetAttendantLead.clienteFullName}</td>
                    <td>{syonetAttendantLead.clientSocialName}</td>
                    <td>{syonetAttendantLead.clientFantasyName}</td>
                    <td>{syonetAttendantLead.contactName}</td>
                    <td>{syonetAttendantLead.addressType}</td>
                    <td>{syonetAttendantLead.addressName}</td>
                    <td>{syonetAttendantLead.addressNumber}</td>
                    <td>{syonetAttendantLead.addressComplement}</td>
                    <td>{syonetAttendantLead.addressNeighborhood}</td>
                    <td>{syonetAttendantLead.addressCity}</td>
                    <td>{syonetAttendantLead.addressStateID}</td>
                    <td>{syonetAttendantLead.addressCityCode}</td>
                    <td>{syonetAttendantLead.clientGender}</td>
                    <td>
                      <TextFormat type="date" value={syonetAttendantLead.clientBirthday} format={APP_DATE_FORMAT} />
                    </td>
                    <td>{syonetAttendantLead.clienteEmail}</td>
                    <td>{syonetAttendantLead.clienteEmail2}</td>
                    <td>{syonetAttendantLead.residentialPhoneAreaCode}</td>
                    <td>{syonetAttendantLead.residentialPhoneNumber}</td>
                    <td>{syonetAttendantLead.cellPhoneAreaCode}</td>
                    <td>{syonetAttendantLead.cellPhoneNumber}</td>
                    <td>{syonetAttendantLead.commercialPhoneAreaCode}</td>
                    <td>{syonetAttendantLead.commercialPhoneNumber}</td>
                    <td>
                      {syonetAttendantLead.syonetLeadId ? (
                        <Link to={`syonet-lead/${syonetAttendantLead.syonetLeadId}`}>{syonetAttendantLead.syonetLeadId}</Link>
                      ) : (
                        ''
                      )}
                    </td>
                    <td className="text-right">
                      <div className="btn-group flex-btn-group-container">
                        <Button tag={Link} to={`${match.url}/${syonetAttendantLead.id}`} color="info" size="sm">
                          <FontAwesomeIcon icon="eye" />{' '}
                          <span className="d-none d-md-inline">
                            <Translate contentKey="entity.action.view">View</Translate>
                          </span>
                        </Button>
                        <Button tag={Link} to={`${match.url}/${syonetAttendantLead.id}/edit`} color="primary" size="sm">
                          <FontAwesomeIcon icon="pencil-alt" />{' '}
                          <span className="d-none d-md-inline">
                            <Translate contentKey="entity.action.edit">Edit</Translate>
                          </span>
                        </Button>
                        <Button tag={Link} to={`${match.url}/${syonetAttendantLead.id}/delete`} color="danger" size="sm">
                          <FontAwesomeIcon icon="trash" />{' '}
                          <span className="d-none d-md-inline">
                            <Translate contentKey="entity.action.delete">Delete</Translate>
                          </span>
                        </Button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </InfiniteScroll>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ syonetAttendantLead }: IRootState) => ({
  syonetAttendantLeadList: syonetAttendantLead.entities,
  totalItems: syonetAttendantLead.totalItems,
  links: syonetAttendantLead.links,
  entity: syonetAttendantLead.entity,
  updateSuccess: syonetAttendantLead.updateSuccess
});

const mapDispatchToProps = {
  getSearchEntities,
  getEntities,
  reset
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SyonetAttendantLead);
