import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import ExecutionGamma from './execution-gamma';
import ExecutionGammaDetail from './execution-gamma-detail';
import ExecutionGammaUpdate from './execution-gamma-update';
import ExecutionGammaDeleteDialog from './execution-gamma-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={ExecutionGammaUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={ExecutionGammaUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={ExecutionGammaDetail} />
      <ErrorBoundaryRoute path={match.url} component={ExecutionGamma} />
    </Switch>
    <ErrorBoundaryRoute path={`${match.url}/:id/delete`} component={ExecutionGammaDeleteDialog} />
  </>
);

export default Routes;
