import axios from 'axios';
import {
  ICrudSearchAction,
  parseHeaderForLinks,
  loadMoreDataWhenScrolled,
  ICrudGetAction,
  ICrudGetAllAction,
  ICrudPutAction,
  ICrudDeleteAction
} from 'react-jhipster';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

import { IResponseHotLead, defaultValue } from 'app/shared/model/response-hot-lead.model';

export const ACTION_TYPES = {
  SEARCH_RESPONSEHOTLEADS: 'responseHotLead/SEARCH_RESPONSEHOTLEADS',
  FETCH_RESPONSEHOTLEAD_LIST: 'responseHotLead/FETCH_RESPONSEHOTLEAD_LIST',
  FETCH_RESPONSEHOTLEAD: 'responseHotLead/FETCH_RESPONSEHOTLEAD',
  CREATE_RESPONSEHOTLEAD: 'responseHotLead/CREATE_RESPONSEHOTLEAD',
  UPDATE_RESPONSEHOTLEAD: 'responseHotLead/UPDATE_RESPONSEHOTLEAD',
  DELETE_RESPONSEHOTLEAD: 'responseHotLead/DELETE_RESPONSEHOTLEAD',
  RESET: 'responseHotLead/RESET'
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<IResponseHotLead>,
  entity: defaultValue,
  links: { next: 0 },
  updating: false,
  totalItems: 0,
  updateSuccess: false
};

export type ResponseHotLeadState = Readonly<typeof initialState>;

// Reducer

export default (state: ResponseHotLeadState = initialState, action): ResponseHotLeadState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.SEARCH_RESPONSEHOTLEADS):
    case REQUEST(ACTION_TYPES.FETCH_RESPONSEHOTLEAD_LIST):
    case REQUEST(ACTION_TYPES.FETCH_RESPONSEHOTLEAD):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true
      };
    case REQUEST(ACTION_TYPES.CREATE_RESPONSEHOTLEAD):
    case REQUEST(ACTION_TYPES.UPDATE_RESPONSEHOTLEAD):
    case REQUEST(ACTION_TYPES.DELETE_RESPONSEHOTLEAD):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true
      };
    case FAILURE(ACTION_TYPES.SEARCH_RESPONSEHOTLEADS):
    case FAILURE(ACTION_TYPES.FETCH_RESPONSEHOTLEAD_LIST):
    case FAILURE(ACTION_TYPES.FETCH_RESPONSEHOTLEAD):
    case FAILURE(ACTION_TYPES.CREATE_RESPONSEHOTLEAD):
    case FAILURE(ACTION_TYPES.UPDATE_RESPONSEHOTLEAD):
    case FAILURE(ACTION_TYPES.DELETE_RESPONSEHOTLEAD):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload
      };
    case SUCCESS(ACTION_TYPES.SEARCH_RESPONSEHOTLEADS):
    case SUCCESS(ACTION_TYPES.FETCH_RESPONSEHOTLEAD_LIST):
      const links = parseHeaderForLinks(action.payload.headers.link);
      return {
        ...state,
        links,
        loading: false,
        totalItems: action.payload.headers['x-total-count'],
        entities: loadMoreDataWhenScrolled(state.entities, action.payload.data, links)
      };
    case SUCCESS(ACTION_TYPES.FETCH_RESPONSEHOTLEAD):
      return {
        ...state,
        loading: false,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.CREATE_RESPONSEHOTLEAD):
    case SUCCESS(ACTION_TYPES.UPDATE_RESPONSEHOTLEAD):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.DELETE_RESPONSEHOTLEAD):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {}
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

const apiUrl = 'api/response-hot-leads';
const apiSearchUrl = 'api/_search/response-hot-leads';

// Actions

export const getSearchEntities: ICrudSearchAction<IResponseHotLead> = (query, page, size, sort) => ({
  type: ACTION_TYPES.SEARCH_RESPONSEHOTLEADS,
  payload: axios.get<IResponseHotLead>(`${apiSearchUrl}?query=${query}${sort ? `&page=${page}&size=${size}&sort=${sort}` : ''}`)
});

export const getEntities: ICrudGetAllAction<IResponseHotLead> = (page, size, sort) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.FETCH_RESPONSEHOTLEAD_LIST,
    payload: axios.get<IResponseHotLead>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`)
  };
};

export const getEntity: ICrudGetAction<IResponseHotLead> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_RESPONSEHOTLEAD,
    payload: axios.get<IResponseHotLead>(requestUrl)
  };
};

export const createEntity: ICrudPutAction<IResponseHotLead> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_RESPONSEHOTLEAD,
    payload: axios.post(apiUrl, cleanEntity(entity))
  });
  return result;
};

export const updateEntity: ICrudPutAction<IResponseHotLead> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_RESPONSEHOTLEAD,
    payload: axios.put(apiUrl, cleanEntity(entity))
  });
  return result;
};

export const deleteEntity: ICrudDeleteAction<IResponseHotLead> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_RESPONSEHOTLEAD,
    payload: axios.delete(requestUrl)
  });
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET
});
