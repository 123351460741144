import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import SyonetClientQualification from './syonet-client-qualification';
import SyonetClientQualificationDetail from './syonet-client-qualification-detail';
import SyonetClientQualificationUpdate from './syonet-client-qualification-update';
import SyonetClientQualificationDeleteDialog from './syonet-client-qualification-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={SyonetClientQualificationUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={SyonetClientQualificationUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={SyonetClientQualificationDetail} />
      <ErrorBoundaryRoute path={match.url} component={SyonetClientQualification} />
    </Switch>
    <ErrorBoundaryRoute path={`${match.url}/:id/delete`} component={SyonetClientQualificationDeleteDialog} />
  </>
);

export default Routes;
