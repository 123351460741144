export interface IColor {
  id?: number;
  color?: string;
  colorCode?: string;
  colorType?: string;
  suggestedPrice?: number;
  modelId?: number;
}

export const defaultValue: Readonly<IColor> = {};
