import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, UncontrolledTooltip, Row, Col } from 'reactstrap';
// tslint:disable-next-line:no-unused-variable
import { Translate, ICrudGetAction, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntity } from './syonet-test-drive.reducer';
import { ISyonetTestDrive } from 'app/shared/model/syonet-test-drive.model';
// tslint:disable-next-line:no-unused-variable
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';

export interface ISyonetTestDriveDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export class SyonetTestDriveDetail extends React.Component<ISyonetTestDriveDetailProps> {
  componentDidMount() {
    this.props.getEntity(this.props.match.params.id);
  }

  render() {
    const { syonetTestDriveEntity } = this.props;
    return (
      <Row>
        <Col md="8">
          <h2>
            <Translate contentKey="nissanApp.syonetTestDrive.detail.title">SyonetTestDrive</Translate> [<b>{syonetTestDriveEntity.id}</b>]
          </h2>
          <dl className="jh-entity-details">
            <dt>
              <span id="lastSync">
                <Translate contentKey="nissanApp.syonetTestDrive.lastSync">Last Sync</Translate>
              </span>
              <UncontrolledTooltip target="lastSync">
                <Translate contentKey="nissanApp.syonetTestDrive.help.lastSync" />
              </UncontrolledTooltip>
            </dt>
            <dd>
              <TextFormat value={syonetTestDriveEntity.lastSync} type="date" format={APP_DATE_FORMAT} />
            </dd>
            <dt>
              <span id="crmID">
                <Translate contentKey="nissanApp.syonetTestDrive.crmID">Crm ID</Translate>
              </span>
              <UncontrolledTooltip target="crmID">
                <Translate contentKey="nissanApp.syonetTestDrive.help.crmID" />
              </UncontrolledTooltip>
            </dt>
            <dd>{syonetTestDriveEntity.crmID}</dd>
            <dt>
              <span id="extracionDate">
                <Translate contentKey="nissanApp.syonetTestDrive.extracionDate">Extracion Date</Translate>
              </span>
              <UncontrolledTooltip target="extracionDate">
                <Translate contentKey="nissanApp.syonetTestDrive.help.extracionDate" />
              </UncontrolledTooltip>
            </dt>
            <dd>
              <TextFormat value={syonetTestDriveEntity.extracionDate} type="date" format={APP_DATE_FORMAT} />
            </dd>
            <dt>
              <span id="attendanceID">
                <Translate contentKey="nissanApp.syonetTestDrive.attendanceID">Attendance ID</Translate>
              </span>
              <UncontrolledTooltip target="attendanceID">
                <Translate contentKey="nissanApp.syonetTestDrive.help.attendanceID" />
              </UncontrolledTooltip>
            </dt>
            <dd>{syonetTestDriveEntity.attendanceID}</dd>
            <dt>
              <span id="nissanLeadID">
                <Translate contentKey="nissanApp.syonetTestDrive.nissanLeadID">Nissan Lead ID</Translate>
              </span>
              <UncontrolledTooltip target="nissanLeadID">
                <Translate contentKey="nissanApp.syonetTestDrive.help.nissanLeadID" />
              </UncontrolledTooltip>
            </dt>
            <dd>{syonetTestDriveEntity.nissanLeadID}</dd>
            <dt>
              <span id="testDriveDate">
                <Translate contentKey="nissanApp.syonetTestDrive.testDriveDate">Test Drive Date</Translate>
              </span>
              <UncontrolledTooltip target="testDriveDate">
                <Translate contentKey="nissanApp.syonetTestDrive.help.testDriveDate" />
              </UncontrolledTooltip>
            </dt>
            <dd>
              <TextFormat value={syonetTestDriveEntity.testDriveDate} type="date" format={APP_DATE_FORMAT} />
            </dd>
            <dt>
              <span id="note">
                <Translate contentKey="nissanApp.syonetTestDrive.note">Note</Translate>
              </span>
              <UncontrolledTooltip target="note">
                <Translate contentKey="nissanApp.syonetTestDrive.help.note" />
              </UncontrolledTooltip>
            </dt>
            <dd>{syonetTestDriveEntity.note}</dd>
            <dt>
              <span id="testDriveModelCode">
                <Translate contentKey="nissanApp.syonetTestDrive.testDriveModelCode">Test Drive Model Code</Translate>
              </span>
              <UncontrolledTooltip target="testDriveModelCode">
                <Translate contentKey="nissanApp.syonetTestDrive.help.testDriveModelCode" />
              </UncontrolledTooltip>
            </dt>
            <dd>{syonetTestDriveEntity.testDriveModelCode}</dd>
            <dt>
              <span id="testDriveEimCode">
                <Translate contentKey="nissanApp.syonetTestDrive.testDriveEimCode">Test Drive Eim Code</Translate>
              </span>
              <UncontrolledTooltip target="testDriveEimCode">
                <Translate contentKey="nissanApp.syonetTestDrive.help.testDriveEimCode" />
              </UncontrolledTooltip>
            </dt>
            <dd>{syonetTestDriveEntity.testDriveEimCode}</dd>
            <dt>
              <span id="testDriveColorCode">
                <Translate contentKey="nissanApp.syonetTestDrive.testDriveColorCode">Test Drive Color Code</Translate>
              </span>
              <UncontrolledTooltip target="testDriveColorCode">
                <Translate contentKey="nissanApp.syonetTestDrive.help.testDriveColorCode" />
              </UncontrolledTooltip>
            </dt>
            <dd>{syonetTestDriveEntity.testDriveColorCode}</dd>
            <dt>
              <span id="testDriveModelYear">
                <Translate contentKey="nissanApp.syonetTestDrive.testDriveModelYear">Test Drive Model Year</Translate>
              </span>
              <UncontrolledTooltip target="testDriveModelYear">
                <Translate contentKey="nissanApp.syonetTestDrive.help.testDriveModelYear" />
              </UncontrolledTooltip>
            </dt>
            <dd>{syonetTestDriveEntity.testDriveModelYear}</dd>
            <dt>
              <Translate contentKey="nissanApp.syonetTestDrive.syonetLead">Syonet Lead</Translate>
            </dt>
            <dd>{syonetTestDriveEntity.syonetLeadId ? syonetTestDriveEntity.syonetLeadId : ''}</dd>
          </dl>
          <Button tag={Link} to="/entity/syonet-test-drive" replace color="info">
            <FontAwesomeIcon icon="arrow-left" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.back">Back</Translate>
            </span>
          </Button>
          &nbsp;
          <Button tag={Link} to={`/entity/syonet-test-drive/${syonetTestDriveEntity.id}/edit`} replace color="primary">
            <FontAwesomeIcon icon="pencil-alt" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.edit">Edit</Translate>
            </span>
          </Button>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = ({ syonetTestDrive }: IRootState) => ({
  syonetTestDriveEntity: syonetTestDrive.entity
});

const mapDispatchToProps = { getEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SyonetTestDriveDetail);
