import React from 'react';
import MenuItem from 'app/shared/layout/menus/menu-item';
import { DropdownItem } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Translate, translate } from 'react-jhipster';
import { NavLink as Link } from 'react-router-dom';
import { NavDropdown } from './menu-components';

export const EntitiesMenu = props => (
  // tslint:disable-next-line:jsx-self-close
  <NavDropdown icon="th-list" name={translate('global.menu.entities.main')} id="entity-menu">
    <MenuItem icon="asterisk" to="/entity/attendant-lead-message">
      <Translate contentKey="global.menu.entities.attendantLeadMessage" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/entity/attendant-lead-status-code">
      <Translate contentKey="global.menu.entities.attendantLeadStatusCode" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/entity/approved-proposal-message">
      <Translate contentKey="global.menu.entities.approvedProposalMessage" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/entity/approved-proposal-status-code">
      <Translate contentKey="global.menu.entities.approvedProposalStatusCode" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/entity/approved-proposal-sync">
      <Translate contentKey="global.menu.entities.approvedProposalSync" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/entity/approved-proposal-response">
      <Translate contentKey="global.menu.entities.approvedProposalResponse" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/entity/client-qualification-message">
      <Translate contentKey="global.menu.entities.clientQualificationMessage" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/entity/client-qualification-status-code">
      <Translate contentKey="global.menu.entities.clientQualificationStatusCode" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/entity/client-qualification-sync">
      <Translate contentKey="global.menu.entities.clientQualificationSync" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/entity/client-qualification-response">
      <Translate contentKey="global.menu.entities.clientQualificationResponse" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/entity/test-drive-message">
      <Translate contentKey="global.menu.entities.testDriveMessage" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/entity/test-drive-status-code">
      <Translate contentKey="global.menu.entities.testDriveStatusCode" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/entity/test-drive-sync">
      <Translate contentKey="global.menu.entities.testDriveSync" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/entity/test-drive-response">
      <Translate contentKey="global.menu.entities.testDriveResponse" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/entity/visit-showroom-lead-message">
      <Translate contentKey="global.menu.entities.visitShowroomLeadMessage" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/entity/visit-showroom-status-code">
      <Translate contentKey="global.menu.entities.visitShowroomStatusCode" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/entity/visit-showroom-sync">
      <Translate contentKey="global.menu.entities.visitShowroomSync" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/entity/visit-showroom-response">
      <Translate contentKey="global.menu.entities.visitShowroomResponse" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/entity/negotiation-lead-message">
      <Translate contentKey="global.menu.entities.negotiationLeadMessage" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/entity/negotiation-status-code">
      <Translate contentKey="global.menu.entities.negotiationStatusCode" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/entity/negotiation-sync">
      <Translate contentKey="global.menu.entities.negotiationSync" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/entity/negotiation-response">
      <Translate contentKey="global.menu.entities.negotiationResponse" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/entity/follow-up-lead-message">
      <Translate contentKey="global.menu.entities.followUpLeadMessage" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/entity/follow-up-status-code">
      <Translate contentKey="global.menu.entities.followUpStatusCode" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/entity/follow-up-sync">
      <Translate contentKey="global.menu.entities.followUpSync" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/entity/follow-up-response">
      <Translate contentKey="global.menu.entities.followUpResponse" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/entity/refused-proposal-lead-message">
      <Translate contentKey="global.menu.entities.refusedProposalLeadMessage" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/entity/refused-proposal-status-code">
      <Translate contentKey="global.menu.entities.refusedProposalStatusCode" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/entity/refused-proposal-sync">
      <Translate contentKey="global.menu.entities.refusedProposalSync" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/entity/refused-proposal-response">
      <Translate contentKey="global.menu.entities.refusedProposalResponse" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/entity/scheduling-lead-response">
      <Translate contentKey="global.menu.entities.schedulingLeadResponse" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/entity/scheduling-lead-message">
      <Translate contentKey="global.menu.entities.schedulingLeadMessage" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/entity/scheduling-lead-status-code">
      <Translate contentKey="global.menu.entities.schedulingLeadStatusCode" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/entity/scheduling-lead-sync">
      <Translate contentKey="global.menu.entities.schedulingLeadSync" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/entity/syonet-lead">
      <Translate contentKey="global.menu.entities.syonetLead" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/entity/account-info">
      <Translate contentKey="global.menu.entities.accountInfo" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/entity/execution-hot-lead">
      <Translate contentKey="global.menu.entities.executionHotLead" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/entity/execution-gamma">
      <Translate contentKey="global.menu.entities.executionGamma" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/entity/syonet-attendant-lead">
      <Translate contentKey="global.menu.entities.syonetAttendantLead" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/entity/syonet-scheduling-lead">
      <Translate contentKey="global.menu.entities.syonetSchedulingLead" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/entity/syonet-visit-showroom">
      <Translate contentKey="global.menu.entities.syonetVisitShowroom" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/entity/syonet-client-qualification">
      <Translate contentKey="global.menu.entities.syonetClientQualification" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/entity/client-qualification-vehicle-change">
      <Translate contentKey="global.menu.entities.clientQualificationVehicleChange" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/entity/client-qualification-vehicle-interested">
      <Translate contentKey="global.menu.entities.clientQualificationVehicleInterested" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/entity/syonet-test-drive">
      <Translate contentKey="global.menu.entities.syonetTestDrive" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/entity/syonet-negotiation">
      <Translate contentKey="global.menu.entities.syonetNegotiation" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/entity/negotiation-vehicle-interested">
      <Translate contentKey="global.menu.entities.negotiationVehicleInterested" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/entity/negotiation-vehicle-on-trade">
      <Translate contentKey="global.menu.entities.negotiationVehicleOnTrade" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/entity/syonet-approved-proposal">
      <Translate contentKey="global.menu.entities.syonetApprovedProposal" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/entity/client-approved-proposal">
      <Translate contentKey="global.menu.entities.clientApprovedProposal" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/entity/vehicle-saled-approved-proposal">
      <Translate contentKey="global.menu.entities.vehicleSaledApprovedProposal" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/entity/syonet-refused-proposal">
      <Translate contentKey="global.menu.entities.syonetRefusedProposal" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/entity/syonet-follow-up">
      <Translate contentKey="global.menu.entities.syonetFollowUp" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/entity/attendant-lead-response">
      <Translate contentKey="global.menu.entities.attendantLeadResponse" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/entity/response-hot-lead">
      <Translate contentKey="global.menu.entities.responseHotLead" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/entity/vehicle-nissan-interest">
      <Translate contentKey="global.menu.entities.vehicleNissanInterest" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/entity/vehicle-exchange-nissan">
      <Translate contentKey="global.menu.entities.vehicleExchangeNissan" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/entity/lead">
      <Translate contentKey="global.menu.entities.lead" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/entity/nissan-model">
      <Translate contentKey="global.menu.entities.nissanModel" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/entity/color">
      <Translate contentKey="global.menu.entities.color" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/entity/response-gamma-message">
      <Translate contentKey="global.menu.entities.responseGammaMessage" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/entity/response-gamma-status-code">
      <Translate contentKey="global.menu.entities.responseGammaStatusCode" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/entity/response-hot-lead-message">
      <Translate contentKey="global.menu.entities.responseHotLeadMessage" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/entity/response-hot-lead-status-code">
      <Translate contentKey="global.menu.entities.responseHotLeadStatusCode" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/entity/response-gamma">
      <Translate contentKey="global.menu.entities.responseGamma" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/entity/sync-gamma">
      <Translate contentKey="global.menu.entities.syncGamma" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/entity/sync-hot-lead">
      <Translate contentKey="global.menu.entities.syncHotLead" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/entity/attendant-lead-sync">
      <Translate contentKey="global.menu.entities.attendantLeadSync" />
    </MenuItem>
    {/* jhipster-needle-add-entity-to-menu - JHipster will add entities to the menu here */}
  </NavDropdown>
);
