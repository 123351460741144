import { Moment } from 'moment';
import { IFollowUpSync } from 'app/shared/model/follow-up-sync.model';

export interface ISyonetFollowUp {
  id?: number;
  lastSync?: Moment;
  crmID?: string;
  extracionDate?: Moment;
  attendanceID?: number;
  nissanLeadID?: number;
  initDate?: Moment;
  endDate?: Moment;
  redemptionStatus?: number;
  reasonID?: number;
  rescuerID?: string;
  rescuerName?: string;
  rescuerEmail?: string;
  syonetLeadId?: number;
  syncs?: IFollowUpSync[];
}

export const defaultValue: Readonly<ISyonetFollowUp> = {};
