import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col, Label, UncontrolledTooltip } from 'reactstrap';
import { AvFeedback, AvForm, AvGroup, AvInput, AvField } from 'availity-reactstrap-validation';
// tslint:disable-next-line:no-unused-variable
import { Translate, translate, ICrudGetAction, ICrudGetAllAction, ICrudPutAction } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';

import { IResponseGamma } from 'app/shared/model/response-gamma.model';
import { getEntities as getResponseGammas } from 'app/entities/response-gamma/response-gamma.reducer';
import { getEntity, updateEntity, createEntity, reset } from './nissan-model.reducer';
import { INissanModel } from 'app/shared/model/nissan-model.model';
// tslint:disable-next-line:no-unused-variable
import { convertDateTimeFromServer, convertDateTimeToServer } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';

export interface INissanModelUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export interface INissanModelUpdateState {
  isNew: boolean;
  responseGammaId: string;
}

export class NissanModelUpdate extends React.Component<INissanModelUpdateProps, INissanModelUpdateState> {
  constructor(props) {
    super(props);
    this.state = {
      responseGammaId: '0',
      isNew: !this.props.match.params || !this.props.match.params.id
    };
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextProps.updateSuccess !== this.props.updateSuccess && nextProps.updateSuccess) {
      this.handleClose();
    }
  }

  componentDidMount() {
    if (!this.state.isNew) {
      this.props.getEntity(this.props.match.params.id);
    }

    this.props.getResponseGammas();
  }

  saveEntity = (event, errors, values) => {
    if (errors.length === 0) {
      const { nissanModelEntity } = this.props;
      const entity = {
        ...nissanModelEntity,
        ...values
      };

      if (this.state.isNew) {
        this.props.createEntity(entity);
      } else {
        this.props.updateEntity(entity);
      }
    }
  };

  handleClose = () => {
    this.props.history.push('/entity/nissan-model');
  };

  render() {
    const { nissanModelEntity, responseGammas, loading, updating } = this.props;
    const { isNew } = this.state;

    return (
      <div>
        <Row className="justify-content-center">
          <Col md="8">
            <h2 id="nissanApp.nissanModel.home.createOrEditLabel">
              <Translate contentKey="nissanApp.nissanModel.home.createOrEditLabel">Create or edit a NissanModel</Translate>
            </h2>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md="8">
            {loading ? (
              <p>Loading...</p>
            ) : (
              <AvForm model={isNew ? {} : nissanModelEntity} onSubmit={this.saveEntity}>
                {!isNew ? (
                  <AvGroup>
                    <Label for="nissan-model-id">
                      <Translate contentKey="global.field.id">ID</Translate>
                    </Label>
                    <AvInput id="nissan-model-id" type="text" className="form-control" name="id" required readOnly />
                  </AvGroup>
                ) : null}
                <AvGroup>
                  <Label id="eimLabel" for="nissan-model-eim">
                    <Translate contentKey="nissanApp.nissanModel.eim">Eim</Translate>
                  </Label>
                  <AvField
                    id="nissan-model-eim"
                    type="text"
                    name="eim"
                    validate={{
                      required: { value: true, errorMessage: translate('entity.validation.required') }
                    }}
                  />
                  <UncontrolledTooltip target="eimLabel">
                    <Translate contentKey="nissanApp.nissanModel.help.eim" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="semiClearVehicleCodeLabel" for="nissan-model-semiClearVehicleCode">
                    <Translate contentKey="nissanApp.nissanModel.semiClearVehicleCode">Semi Clear Vehicle Code</Translate>
                  </Label>
                  <AvField
                    id="nissan-model-semiClearVehicleCode"
                    type="text"
                    name="semiClearVehicleCode"
                    validate={{
                      required: { value: true, errorMessage: translate('entity.validation.required') }
                    }}
                  />
                  <UncontrolledTooltip target="semiClearVehicleCodeLabel">
                    <Translate contentKey="nissanApp.nissanModel.help.semiClearVehicleCode" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="modelCodeLabel" for="nissan-model-modelCode">
                    <Translate contentKey="nissanApp.nissanModel.modelCode">Model Code</Translate>
                  </Label>
                  <AvField
                    id="nissan-model-modelCode"
                    type="text"
                    name="modelCode"
                    validate={{
                      required: { value: true, errorMessage: translate('entity.validation.required') }
                    }}
                  />
                  <UncontrolledTooltip target="modelCodeLabel">
                    <Translate contentKey="nissanApp.nissanModel.help.modelCode" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="modelLabel" for="nissan-model-model">
                    <Translate contentKey="nissanApp.nissanModel.model">Model</Translate>
                  </Label>
                  <AvField
                    id="nissan-model-model"
                    type="text"
                    name="model"
                    validate={{
                      required: { value: true, errorMessage: translate('entity.validation.required') }
                    }}
                  />
                  <UncontrolledTooltip target="modelLabel">
                    <Translate contentKey="nissanApp.nissanModel.help.model" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="versionLabel" for="nissan-model-version">
                    <Translate contentKey="nissanApp.nissanModel.version">Version</Translate>
                  </Label>
                  <AvField
                    id="nissan-model-version"
                    type="text"
                    name="version"
                    validate={{
                      required: { value: true, errorMessage: translate('entity.validation.required') }
                    }}
                  />
                  <UncontrolledTooltip target="versionLabel">
                    <Translate contentKey="nissanApp.nissanModel.help.version" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="modelProductionYearLabel" for="nissan-model-modelProductionYear">
                    <Translate contentKey="nissanApp.nissanModel.modelProductionYear">Model Production Year</Translate>
                  </Label>
                  <AvField
                    id="nissan-model-modelProductionYear"
                    type="string"
                    className="form-control"
                    name="modelProductionYear"
                    validate={{
                      required: { value: true, errorMessage: translate('entity.validation.required') },
                      number: { value: true, errorMessage: translate('entity.validation.number') }
                    }}
                  />
                  <UncontrolledTooltip target="modelProductionYearLabel">
                    <Translate contentKey="nissanApp.nissanModel.help.modelProductionYear" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="modelYearLabel" for="nissan-model-modelYear">
                    <Translate contentKey="nissanApp.nissanModel.modelYear">Model Year</Translate>
                  </Label>
                  <AvField
                    id="nissan-model-modelYear"
                    type="string"
                    className="form-control"
                    name="modelYear"
                    validate={{
                      required: { value: true, errorMessage: translate('entity.validation.required') },
                      number: { value: true, errorMessage: translate('entity.validation.number') }
                    }}
                  />
                  <UncontrolledTooltip target="modelYearLabel">
                    <Translate contentKey="nissanApp.nissanModel.help.modelYear" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="engineCodeLabel" for="nissan-model-engineCode">
                    <Translate contentKey="nissanApp.nissanModel.engineCode">Engine Code</Translate>
                  </Label>
                  <AvField
                    id="nissan-model-engineCode"
                    type="text"
                    name="engineCode"
                    validate={{
                      required: { value: true, errorMessage: translate('entity.validation.required') }
                    }}
                  />
                  <UncontrolledTooltip target="engineCodeLabel">
                    <Translate contentKey="nissanApp.nissanModel.help.engineCode" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="engineDescriptionLabel" for="nissan-model-engineDescription">
                    <Translate contentKey="nissanApp.nissanModel.engineDescription">Engine Description</Translate>
                  </Label>
                  <AvField
                    id="nissan-model-engineDescription"
                    type="text"
                    name="engineDescription"
                    validate={{
                      required: { value: true, errorMessage: translate('entity.validation.required') }
                    }}
                  />
                  <UncontrolledTooltip target="engineDescriptionLabel">
                    <Translate contentKey="nissanApp.nissanModel.help.engineDescription" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="denatranIDLabel" for="nissan-model-denatranID">
                    <Translate contentKey="nissanApp.nissanModel.denatranID">Denatran ID</Translate>
                  </Label>
                  <AvField
                    id="nissan-model-denatranID"
                    type="string"
                    className="form-control"
                    name="denatranID"
                    validate={{
                      required: { value: true, errorMessage: translate('entity.validation.required') },
                      number: { value: true, errorMessage: translate('entity.validation.number') }
                    }}
                  />
                  <UncontrolledTooltip target="denatranIDLabel">
                    <Translate contentKey="nissanApp.nissanModel.help.denatranID" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="fuelTypeLabel" for="nissan-model-fuelType">
                    <Translate contentKey="nissanApp.nissanModel.fuelType">Fuel Type</Translate>
                  </Label>
                  <AvField
                    id="nissan-model-fuelType"
                    type="string"
                    className="form-control"
                    name="fuelType"
                    validate={{
                      required: { value: true, errorMessage: translate('entity.validation.required') },
                      number: { value: true, errorMessage: translate('entity.validation.number') }
                    }}
                  />
                  <UncontrolledTooltip target="fuelTypeLabel">
                    <Translate contentKey="nissanApp.nissanModel.help.fuelType" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label for="nissan-model-responseGamma">
                    <Translate contentKey="nissanApp.nissanModel.responseGamma">Response Gamma</Translate>
                  </Label>
                  <AvInput id="nissan-model-responseGamma" type="select" className="form-control" name="responseGammaId">
                    <option value="" key="0" />
                    {responseGammas
                      ? responseGammas.map(otherEntity => (
                          <option value={otherEntity.id} key={otherEntity.id}>
                            {otherEntity.id}
                          </option>
                        ))
                      : null}
                  </AvInput>
                </AvGroup>
                <Button tag={Link} id="cancel-save" to="/entity/nissan-model" replace color="info">
                  <FontAwesomeIcon icon="arrow-left" />
                  &nbsp;
                  <span className="d-none d-md-inline">
                    <Translate contentKey="entity.action.back">Back</Translate>
                  </span>
                </Button>
                &nbsp;
                <Button color="primary" id="save-entity" type="submit" disabled={updating}>
                  <FontAwesomeIcon icon="save" />
                  &nbsp;
                  <Translate contentKey="entity.action.save">Save</Translate>
                </Button>
              </AvForm>
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (storeState: IRootState) => ({
  responseGammas: storeState.responseGamma.entities,
  nissanModelEntity: storeState.nissanModel.entity,
  loading: storeState.nissanModel.loading,
  updating: storeState.nissanModel.updating,
  updateSuccess: storeState.nissanModel.updateSuccess
});

const mapDispatchToProps = {
  getResponseGammas,
  getEntity,
  updateEntity,
  createEntity,
  reset
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NissanModelUpdate);
