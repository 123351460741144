import { Moment } from 'moment';
import { ITestDriveResponse } from 'app/shared/model/test-drive-response.model';

export interface ITestDriveSync {
  id?: number;
  receivedIn?: Moment;
  synchronizedIn?: Moment;
  responses?: ITestDriveResponse[];
  syonetTestDriveId?: number;
}

export const defaultValue: Readonly<ITestDriveSync> = {};
