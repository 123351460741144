import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col, Label, UncontrolledTooltip } from 'reactstrap';
import { AvFeedback, AvForm, AvGroup, AvInput, AvField } from 'availity-reactstrap-validation';
// tslint:disable-next-line:no-unused-variable
import { Translate, translate, ICrudGetAction, ICrudGetAllAction, ICrudPutAction } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';

import { ISyonetNegotiation } from 'app/shared/model/syonet-negotiation.model';
import { getEntities as getSyonetNegotiations } from 'app/entities/syonet-negotiation/syonet-negotiation.reducer';
import { getEntity, updateEntity, createEntity, reset } from './negotiation-vehicle-on-trade.reducer';
import { INegotiationVehicleOnTrade } from 'app/shared/model/negotiation-vehicle-on-trade.model';
// tslint:disable-next-line:no-unused-variable
import { convertDateTimeFromServer, convertDateTimeToServer } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';

export interface INegotiationVehicleOnTradeUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export interface INegotiationVehicleOnTradeUpdateState {
  isNew: boolean;
  syonetNegotiationId: string;
}

export class NegotiationVehicleOnTradeUpdate extends React.Component<
  INegotiationVehicleOnTradeUpdateProps,
  INegotiationVehicleOnTradeUpdateState
> {
  constructor(props) {
    super(props);
    this.state = {
      syonetNegotiationId: '0',
      isNew: !this.props.match.params || !this.props.match.params.id
    };
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextProps.updateSuccess !== this.props.updateSuccess && nextProps.updateSuccess) {
      this.handleClose();
    }
  }

  componentDidMount() {
    if (!this.state.isNew) {
      this.props.getEntity(this.props.match.params.id);
    }

    this.props.getSyonetNegotiations();
  }

  saveEntity = (event, errors, values) => {
    if (errors.length === 0) {
      const { negotiationVehicleOnTradeEntity } = this.props;
      const entity = {
        ...negotiationVehicleOnTradeEntity,
        ...values
      };

      if (this.state.isNew) {
        this.props.createEntity(entity);
      } else {
        this.props.updateEntity(entity);
      }
    }
  };

  handleClose = () => {
    this.props.history.push('/entity/negotiation-vehicle-on-trade');
  };

  render() {
    const { negotiationVehicleOnTradeEntity, syonetNegotiations, loading, updating } = this.props;
    const { isNew } = this.state;

    return (
      <div>
        <Row className="justify-content-center">
          <Col md="8">
            <h2 id="nissanApp.negotiationVehicleOnTrade.home.createOrEditLabel">
              <Translate contentKey="nissanApp.negotiationVehicleOnTrade.home.createOrEditLabel">
                Create or edit a NegotiationVehicleOnTrade
              </Translate>
            </h2>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md="8">
            {loading ? (
              <p>Loading...</p>
            ) : (
              <AvForm model={isNew ? {} : negotiationVehicleOnTradeEntity} onSubmit={this.saveEntity}>
                {!isNew ? (
                  <AvGroup>
                    <Label for="negotiation-vehicle-on-trade-id">
                      <Translate contentKey="global.field.id">ID</Translate>
                    </Label>
                    <AvInput id="negotiation-vehicle-on-trade-id" type="text" className="form-control" name="id" required readOnly />
                  </AvGroup>
                ) : null}
                <AvGroup>
                  <Label id="vehicleCodeLabel" for="negotiation-vehicle-on-trade-vehicleCode">
                    <Translate contentKey="nissanApp.negotiationVehicleOnTrade.vehicleCode">Vehicle Code</Translate>
                  </Label>
                  <AvField
                    id="negotiation-vehicle-on-trade-vehicleCode"
                    type="text"
                    name="vehicleCode"
                    validate={{
                      required: { value: true, errorMessage: translate('entity.validation.required') }
                    }}
                  />
                  <UncontrolledTooltip target="vehicleCodeLabel">
                    <Translate contentKey="nissanApp.negotiationVehicleOnTrade.help.vehicleCode" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="vehicleNissanLabel" for="negotiation-vehicle-on-trade-vehicleNissan">
                    <Translate contentKey="nissanApp.negotiationVehicleOnTrade.vehicleNissan">Vehicle Nissan</Translate>
                  </Label>
                  <AvField
                    id="negotiation-vehicle-on-trade-vehicleNissan"
                    type="string"
                    className="form-control"
                    name="vehicleNissan"
                    validate={{
                      required: { value: true, errorMessage: translate('entity.validation.required') },
                      number: { value: true, errorMessage: translate('entity.validation.number') }
                    }}
                  />
                  <UncontrolledTooltip target="vehicleNissanLabel">
                    <Translate contentKey="nissanApp.negotiationVehicleOnTrade.help.vehicleNissan" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="vehicleExchangeDescriptionLabel" for="negotiation-vehicle-on-trade-vehicleExchangeDescription">
                    <Translate contentKey="nissanApp.negotiationVehicleOnTrade.vehicleExchangeDescription">
                      Vehicle Exchange Description
                    </Translate>
                  </Label>
                  <AvField id="negotiation-vehicle-on-trade-vehicleExchangeDescription" type="text" name="vehicleExchangeDescription" />
                  <UncontrolledTooltip target="vehicleExchangeDescriptionLabel">
                    <Translate contentKey="nissanApp.negotiationVehicleOnTrade.help.vehicleExchangeDescription" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="exchangeModelCodeLabel" for="negotiation-vehicle-on-trade-exchangeModelCode">
                    <Translate contentKey="nissanApp.negotiationVehicleOnTrade.exchangeModelCode">Exchange Model Code</Translate>
                  </Label>
                  <AvField id="negotiation-vehicle-on-trade-exchangeModelCode" type="text" name="exchangeModelCode" />
                  <UncontrolledTooltip target="exchangeModelCodeLabel">
                    <Translate contentKey="nissanApp.negotiationVehicleOnTrade.help.exchangeModelCode" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="interestedEimCodeLabel" for="negotiation-vehicle-on-trade-interestedEimCode">
                    <Translate contentKey="nissanApp.negotiationVehicleOnTrade.interestedEimCode">Interested Eim Code</Translate>
                  </Label>
                  <AvField id="negotiation-vehicle-on-trade-interestedEimCode" type="text" name="interestedEimCode" />
                  <UncontrolledTooltip target="interestedEimCodeLabel">
                    <Translate contentKey="nissanApp.negotiationVehicleOnTrade.help.interestedEimCode" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="negotiatedColorCodeLabel" for="negotiation-vehicle-on-trade-negotiatedColorCode">
                    <Translate contentKey="nissanApp.negotiationVehicleOnTrade.negotiatedColorCode">Negotiated Color Code</Translate>
                  </Label>
                  <AvField id="negotiation-vehicle-on-trade-negotiatedColorCode" type="text" name="negotiatedColorCode" />
                  <UncontrolledTooltip target="negotiatedColorCodeLabel">
                    <Translate contentKey="nissanApp.negotiationVehicleOnTrade.help.negotiatedColorCode" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="modelYearVehicleNegotiatedLabel" for="negotiation-vehicle-on-trade-modelYearVehicleNegotiated">
                    <Translate contentKey="nissanApp.negotiationVehicleOnTrade.modelYearVehicleNegotiated">
                      Model Year Vehicle Negotiated
                    </Translate>
                  </Label>
                  <AvField
                    id="negotiation-vehicle-on-trade-modelYearVehicleNegotiated"
                    type="string"
                    className="form-control"
                    name="modelYearVehicleNegotiated"
                  />
                  <UncontrolledTooltip target="modelYearVehicleNegotiatedLabel">
                    <Translate contentKey="nissanApp.negotiationVehicleOnTrade.help.modelYearVehicleNegotiated" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="vehicleAmountLabel" for="negotiation-vehicle-on-trade-vehicleAmount">
                    <Translate contentKey="nissanApp.negotiationVehicleOnTrade.vehicleAmount">Vehicle Amount</Translate>
                  </Label>
                  <AvField
                    id="negotiation-vehicle-on-trade-vehicleAmount"
                    type="string"
                    className="form-control"
                    name="vehicleAmount"
                    validate={{
                      required: { value: true, errorMessage: translate('entity.validation.required') },
                      number: { value: true, errorMessage: translate('entity.validation.number') }
                    }}
                  />
                  <UncontrolledTooltip target="vehicleAmountLabel">
                    <Translate contentKey="nissanApp.negotiationVehicleOnTrade.help.vehicleAmount" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label for="negotiation-vehicle-on-trade-syonetNegotiation">
                    <Translate contentKey="nissanApp.negotiationVehicleOnTrade.syonetNegotiation">Syonet Negotiation</Translate>
                  </Label>
                  <AvInput
                    id="negotiation-vehicle-on-trade-syonetNegotiation"
                    type="select"
                    className="form-control"
                    name="syonetNegotiationId"
                  >
                    <option value="" key="0" />
                    {syonetNegotiations
                      ? syonetNegotiations.map(otherEntity => (
                          <option value={otherEntity.id} key={otherEntity.id}>
                            {otherEntity.id}
                          </option>
                        ))
                      : null}
                  </AvInput>
                </AvGroup>
                <Button tag={Link} id="cancel-save" to="/entity/negotiation-vehicle-on-trade" replace color="info">
                  <FontAwesomeIcon icon="arrow-left" />
                  &nbsp;
                  <span className="d-none d-md-inline">
                    <Translate contentKey="entity.action.back">Back</Translate>
                  </span>
                </Button>
                &nbsp;
                <Button color="primary" id="save-entity" type="submit" disabled={updating}>
                  <FontAwesomeIcon icon="save" />
                  &nbsp;
                  <Translate contentKey="entity.action.save">Save</Translate>
                </Button>
              </AvForm>
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (storeState: IRootState) => ({
  syonetNegotiations: storeState.syonetNegotiation.entities,
  negotiationVehicleOnTradeEntity: storeState.negotiationVehicleOnTrade.entity,
  loading: storeState.negotiationVehicleOnTrade.loading,
  updating: storeState.negotiationVehicleOnTrade.updating,
  updateSuccess: storeState.negotiationVehicleOnTrade.updateSuccess
});

const mapDispatchToProps = {
  getSyonetNegotiations,
  getEntity,
  updateEntity,
  createEntity,
  reset
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NegotiationVehicleOnTradeUpdate);
