import { Moment } from 'moment';
import { INegotiationResponse } from 'app/shared/model/negotiation-response.model';

export interface INegotiationSync {
  id?: number;
  receivedIn?: Moment;
  synchronizedIn?: Moment;
  responses?: INegotiationResponse[];
  syonetNegotiationId?: number;
}

export const defaultValue: Readonly<INegotiationSync> = {};
