import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, UncontrolledTooltip, Row, Col } from 'reactstrap';
// tslint:disable-next-line:no-unused-variable
import { Translate, ICrudGetAction, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntity } from './syonet-negotiation.reducer';
import { ISyonetNegotiation } from 'app/shared/model/syonet-negotiation.model';
// tslint:disable-next-line:no-unused-variable
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';

export interface ISyonetNegotiationDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export class SyonetNegotiationDetail extends React.Component<ISyonetNegotiationDetailProps> {
  componentDidMount() {
    this.props.getEntity(this.props.match.params.id);
  }

  render() {
    const { syonetNegotiationEntity } = this.props;
    return (
      <Row>
        <Col md="8">
          <h2>
            <Translate contentKey="nissanApp.syonetNegotiation.detail.title">SyonetNegotiation</Translate> [
            <b>{syonetNegotiationEntity.id}</b>]
          </h2>
          <dl className="jh-entity-details">
            <dt>
              <span id="lastSync">
                <Translate contentKey="nissanApp.syonetNegotiation.lastSync">Last Sync</Translate>
              </span>
              <UncontrolledTooltip target="lastSync">
                <Translate contentKey="nissanApp.syonetNegotiation.help.lastSync" />
              </UncontrolledTooltip>
            </dt>
            <dd>
              <TextFormat value={syonetNegotiationEntity.lastSync} type="date" format={APP_DATE_FORMAT} />
            </dd>
            <dt>
              <span id="crmID">
                <Translate contentKey="nissanApp.syonetNegotiation.crmID">Crm ID</Translate>
              </span>
              <UncontrolledTooltip target="crmID">
                <Translate contentKey="nissanApp.syonetNegotiation.help.crmID" />
              </UncontrolledTooltip>
            </dt>
            <dd>{syonetNegotiationEntity.crmID}</dd>
            <dt>
              <span id="extracionDate">
                <Translate contentKey="nissanApp.syonetNegotiation.extracionDate">Extracion Date</Translate>
              </span>
              <UncontrolledTooltip target="extracionDate">
                <Translate contentKey="nissanApp.syonetNegotiation.help.extracionDate" />
              </UncontrolledTooltip>
            </dt>
            <dd>
              <TextFormat value={syonetNegotiationEntity.extracionDate} type="date" format={APP_DATE_FORMAT} />
            </dd>
            <dt>
              <span id="attendanceID">
                <Translate contentKey="nissanApp.syonetNegotiation.attendanceID">Attendance ID</Translate>
              </span>
              <UncontrolledTooltip target="attendanceID">
                <Translate contentKey="nissanApp.syonetNegotiation.help.attendanceID" />
              </UncontrolledTooltip>
            </dt>
            <dd>{syonetNegotiationEntity.attendanceID}</dd>
            <dt>
              <span id="nissanLeadID">
                <Translate contentKey="nissanApp.syonetNegotiation.nissanLeadID">Nissan Lead ID</Translate>
              </span>
              <UncontrolledTooltip target="nissanLeadID">
                <Translate contentKey="nissanApp.syonetNegotiation.help.nissanLeadID" />
              </UncontrolledTooltip>
            </dt>
            <dd>{syonetNegotiationEntity.nissanLeadID}</dd>
            <dt>
              <span id="negotiationID">
                <Translate contentKey="nissanApp.syonetNegotiation.negotiationID">Negotiation ID</Translate>
              </span>
              <UncontrolledTooltip target="negotiationID">
                <Translate contentKey="nissanApp.syonetNegotiation.help.negotiationID" />
              </UncontrolledTooltip>
            </dt>
            <dd>{syonetNegotiationEntity.negotiationID}</dd>
            <dt>
              <span id="contactDate">
                <Translate contentKey="nissanApp.syonetNegotiation.contactDate">Contact Date</Translate>
              </span>
              <UncontrolledTooltip target="contactDate">
                <Translate contentKey="nissanApp.syonetNegotiation.help.contactDate" />
              </UncontrolledTooltip>
            </dt>
            <dd>
              <TextFormat value={syonetNegotiationEntity.contactDate} type="date" format={APP_DATE_FORMAT} />
            </dd>
            <dt>
              <span id="salesChannel">
                <Translate contentKey="nissanApp.syonetNegotiation.salesChannel">Sales Channel</Translate>
              </span>
              <UncontrolledTooltip target="salesChannel">
                <Translate contentKey="nissanApp.syonetNegotiation.help.salesChannel" />
              </UncontrolledTooltip>
            </dt>
            <dd>{syonetNegotiationEntity.salesChannel}</dd>
            <dt>
              <span id="paymentCondition">
                <Translate contentKey="nissanApp.syonetNegotiation.paymentCondition">Payment Condition</Translate>
              </span>
              <UncontrolledTooltip target="paymentCondition">
                <Translate contentKey="nissanApp.syonetNegotiation.help.paymentCondition" />
              </UncontrolledTooltip>
            </dt>
            <dd>{syonetNegotiationEntity.paymentCondition}</dd>
            <dt>
              <span id="negotiationValue">
                <Translate contentKey="nissanApp.syonetNegotiation.negotiationValue">Negotiation Value</Translate>
              </span>
              <UncontrolledTooltip target="negotiationValue">
                <Translate contentKey="nissanApp.syonetNegotiation.help.negotiationValue" />
              </UncontrolledTooltip>
            </dt>
            <dd>{syonetNegotiationEntity.negotiationValue}</dd>
            <dt>
              <span id="negotiationDeadline">
                <Translate contentKey="nissanApp.syonetNegotiation.negotiationDeadline">Negotiation Deadline</Translate>
              </span>
              <UncontrolledTooltip target="negotiationDeadline">
                <Translate contentKey="nissanApp.syonetNegotiation.help.negotiationDeadline" />
              </UncontrolledTooltip>
            </dt>
            <dd>{syonetNegotiationEntity.negotiationDeadline}</dd>
            <dt>
              <span id="inputValue">
                <Translate contentKey="nissanApp.syonetNegotiation.inputValue">Input Value</Translate>
              </span>
              <UncontrolledTooltip target="inputValue">
                <Translate contentKey="nissanApp.syonetNegotiation.help.inputValue" />
              </UncontrolledTooltip>
            </dt>
            <dd>{syonetNegotiationEntity.inputValue}</dd>
            <dt>
              <span id="plotValue">
                <Translate contentKey="nissanApp.syonetNegotiation.plotValue">Plot Value</Translate>
              </span>
              <UncontrolledTooltip target="plotValue">
                <Translate contentKey="nissanApp.syonetNegotiation.help.plotValue" />
              </UncontrolledTooltip>
            </dt>
            <dd>{syonetNegotiationEntity.plotValue}</dd>
            <dt>
              <span id="changeAmount">
                <Translate contentKey="nissanApp.syonetNegotiation.changeAmount">Change Amount</Translate>
              </span>
              <UncontrolledTooltip target="changeAmount">
                <Translate contentKey="nissanApp.syonetNegotiation.help.changeAmount" />
              </UncontrolledTooltip>
            </dt>
            <dd>{syonetNegotiationEntity.changeAmount}</dd>
            <dt>
              <span id="totalValueVehiclesUsed">
                <Translate contentKey="nissanApp.syonetNegotiation.totalValueVehiclesUsed">Total Value Vehicles Used</Translate>
              </span>
            </dt>
            <dd>{syonetNegotiationEntity.totalValueVehiclesUsed}</dd>
            <dt>
              <Translate contentKey="nissanApp.syonetNegotiation.syonetLead">Syonet Lead</Translate>
            </dt>
            <dd>{syonetNegotiationEntity.syonetLeadId ? syonetNegotiationEntity.syonetLeadId : ''}</dd>
          </dl>
          <Button tag={Link} to="/entity/syonet-negotiation" replace color="info">
            <FontAwesomeIcon icon="arrow-left" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.back">Back</Translate>
            </span>
          </Button>
          &nbsp;
          <Button tag={Link} to={`/entity/syonet-negotiation/${syonetNegotiationEntity.id}/edit`} replace color="primary">
            <FontAwesomeIcon icon="pencil-alt" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.edit">Edit</Translate>
            </span>
          </Button>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = ({ syonetNegotiation }: IRootState) => ({
  syonetNegotiationEntity: syonetNegotiation.entity
});

const mapDispatchToProps = { getEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SyonetNegotiationDetail);
