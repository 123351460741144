import { Moment } from 'moment';
import { IAttendantLeadResponse } from 'app/shared/model/attendant-lead-response.model';

export interface IAttendantLeadSync {
  id?: number;
  receivedIn?: Moment;
  synchronizedIn?: Moment;
  syonetAttendantLeadId?: number;
  responses?: IAttendantLeadResponse[];
}

export const defaultValue: Readonly<IAttendantLeadSync> = {};
