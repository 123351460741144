import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, UncontrolledTooltip, Row, Col } from 'reactstrap';
// tslint:disable-next-line:no-unused-variable
import { Translate, ICrudGetAction } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntity } from './negotiation-vehicle-interested.reducer';
import { INegotiationVehicleInterested } from 'app/shared/model/negotiation-vehicle-interested.model';
// tslint:disable-next-line:no-unused-variable
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';

export interface INegotiationVehicleInterestedDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export class NegotiationVehicleInterestedDetail extends React.Component<INegotiationVehicleInterestedDetailProps> {
  componentDidMount() {
    this.props.getEntity(this.props.match.params.id);
  }

  render() {
    const { negotiationVehicleInterestedEntity } = this.props;
    return (
      <Row>
        <Col md="8">
          <h2>
            <Translate contentKey="nissanApp.negotiationVehicleInterested.detail.title">NegotiationVehicleInterested</Translate> [
            <b>{negotiationVehicleInterestedEntity.id}</b>]
          </h2>
          <dl className="jh-entity-details">
            <dt>
              <span id="vehicleCode">
                <Translate contentKey="nissanApp.negotiationVehicleInterested.vehicleCode">Vehicle Code</Translate>
              </span>
              <UncontrolledTooltip target="vehicleCode">
                <Translate contentKey="nissanApp.negotiationVehicleInterested.help.vehicleCode" />
              </UncontrolledTooltip>
            </dt>
            <dd>{negotiationVehicleInterestedEntity.vehicleCode}</dd>
            <dt>
              <span id="modelCode">
                <Translate contentKey="nissanApp.negotiationVehicleInterested.modelCode">Model Code</Translate>
              </span>
              <UncontrolledTooltip target="modelCode">
                <Translate contentKey="nissanApp.negotiationVehicleInterested.help.modelCode" />
              </UncontrolledTooltip>
            </dt>
            <dd>{negotiationVehicleInterestedEntity.modelCode}</dd>
            <dt>
              <span id="eimCode">
                <Translate contentKey="nissanApp.negotiationVehicleInterested.eimCode">Eim Code</Translate>
              </span>
              <UncontrolledTooltip target="eimCode">
                <Translate contentKey="nissanApp.negotiationVehicleInterested.help.eimCode" />
              </UncontrolledTooltip>
            </dt>
            <dd>{negotiationVehicleInterestedEntity.eimCode}</dd>
            <dt>
              <span id="colorCode">
                <Translate contentKey="nissanApp.negotiationVehicleInterested.colorCode">Color Code</Translate>
              </span>
              <UncontrolledTooltip target="colorCode">
                <Translate contentKey="nissanApp.negotiationVehicleInterested.help.colorCode" />
              </UncontrolledTooltip>
            </dt>
            <dd>{negotiationVehicleInterestedEntity.colorCode}</dd>
            <dt>
              <span id="modelYearVehicleNegotiated">
                <Translate contentKey="nissanApp.negotiationVehicleInterested.modelYearVehicleNegotiated">
                  Model Year Vehicle Negotiated
                </Translate>
              </span>
              <UncontrolledTooltip target="modelYearVehicleNegotiated">
                <Translate contentKey="nissanApp.negotiationVehicleInterested.help.modelYearVehicleNegotiated" />
              </UncontrolledTooltip>
            </dt>
            <dd>{negotiationVehicleInterestedEntity.modelYearVehicleNegotiated}</dd>
            <dt>
              <Translate contentKey="nissanApp.negotiationVehicleInterested.syonetNegotiation">Syonet Negotiation</Translate>
            </dt>
            <dd>{negotiationVehicleInterestedEntity.syonetNegotiationId ? negotiationVehicleInterestedEntity.syonetNegotiationId : ''}</dd>
          </dl>
          <Button tag={Link} to="/entity/negotiation-vehicle-interested" replace color="info">
            <FontAwesomeIcon icon="arrow-left" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.back">Back</Translate>
            </span>
          </Button>
          &nbsp;
          <Button
            tag={Link}
            to={`/entity/negotiation-vehicle-interested/${negotiationVehicleInterestedEntity.id}/edit`}
            replace
            color="primary"
          >
            <FontAwesomeIcon icon="pencil-alt" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.edit">Edit</Translate>
            </span>
          </Button>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = ({ negotiationVehicleInterested }: IRootState) => ({
  negotiationVehicleInterestedEntity: negotiationVehicleInterested.entity
});

const mapDispatchToProps = { getEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NegotiationVehicleInterestedDetail);
