export interface IClientQualificationVehicleInterested {
  id?: number;
  vehicleCode?: string;
  modelCode?: string;
  eimVehicleInterest?: string;
  colorCode?: string;
  modelYear?: number;
  syonetClientQualificationId?: number;
}

export const defaultValue: Readonly<IClientQualificationVehicleInterested> = {};
