import { Moment } from 'moment';
import { IApprovedProposalMessage } from 'app/shared/model/approved-proposal-message.model';

export interface IApprovedProposalResponse {
  id?: number;
  receivedIn?: Moment;
  synchronizedIn?: Moment;
  approvedProposalSyncId?: number;
  statusCodeId?: number;
  messages?: IApprovedProposalMessage[];
}

export const defaultValue: Readonly<IApprovedProposalResponse> = {};
