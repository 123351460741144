import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, UncontrolledTooltip, Row, Col } from 'reactstrap';
// tslint:disable-next-line:no-unused-variable
import { Translate, ICrudGetAction } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntity } from './client-qualification-message.reducer';
import { IClientQualificationMessage } from 'app/shared/model/client-qualification-message.model';
// tslint:disable-next-line:no-unused-variable
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';

export interface IClientQualificationMessageDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export class ClientQualificationMessageDetail extends React.Component<IClientQualificationMessageDetailProps> {
  componentDidMount() {
    this.props.getEntity(this.props.match.params.id);
  }

  render() {
    const { clientQualificationMessageEntity } = this.props;
    return (
      <Row>
        <Col md="8">
          <h2>
            <Translate contentKey="nissanApp.clientQualificationMessage.detail.title">ClientQualificationMessage</Translate> [
            <b>{clientQualificationMessageEntity.id}</b>]
          </h2>
          <dl className="jh-entity-details">
            <dt>
              <span id="message">
                <Translate contentKey="nissanApp.clientQualificationMessage.message">Message</Translate>
              </span>
              <UncontrolledTooltip target="message">
                <Translate contentKey="nissanApp.clientQualificationMessage.help.message" />
              </UncontrolledTooltip>
            </dt>
            <dd>{clientQualificationMessageEntity.message}</dd>
            <dt>
              <span id="messageCode">
                <Translate contentKey="nissanApp.clientQualificationMessage.messageCode">Message Code</Translate>
              </span>
              <UncontrolledTooltip target="messageCode">
                <Translate contentKey="nissanApp.clientQualificationMessage.help.messageCode" />
              </UncontrolledTooltip>
            </dt>
            <dd>{clientQualificationMessageEntity.messageCode}</dd>
            <dt>
              <span id="registryNumber">
                <Translate contentKey="nissanApp.clientQualificationMessage.registryNumber">Registry Number</Translate>
              </span>
              <UncontrolledTooltip target="registryNumber">
                <Translate contentKey="nissanApp.clientQualificationMessage.help.registryNumber" />
              </UncontrolledTooltip>
            </dt>
            <dd>{clientQualificationMessageEntity.registryNumber}</dd>
            <dt>
              <span id="returnType">
                <Translate contentKey="nissanApp.clientQualificationMessage.returnType">Return Type</Translate>
              </span>
              <UncontrolledTooltip target="returnType">
                <Translate contentKey="nissanApp.clientQualificationMessage.help.returnType" />
              </UncontrolledTooltip>
            </dt>
            <dd>{clientQualificationMessageEntity.returnType}</dd>
            <dt>
              <Translate contentKey="nissanApp.clientQualificationMessage.clientQualificationResponse">
                Client Qualification Response
              </Translate>
            </dt>
            <dd>
              {clientQualificationMessageEntity.clientQualificationResponseId
                ? clientQualificationMessageEntity.clientQualificationResponseId
                : ''}
            </dd>
          </dl>
          <Button tag={Link} to="/entity/client-qualification-message" replace color="info">
            <FontAwesomeIcon icon="arrow-left" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.back">Back</Translate>
            </span>
          </Button>
          &nbsp;
          <Button
            tag={Link}
            to={`/entity/client-qualification-message/${clientQualificationMessageEntity.id}/edit`}
            replace
            color="primary"
          >
            <FontAwesomeIcon icon="pencil-alt" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.edit">Edit</Translate>
            </span>
          </Button>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = ({ clientQualificationMessage }: IRootState) => ({
  clientQualificationMessageEntity: clientQualificationMessage.entity
});

const mapDispatchToProps = { getEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ClientQualificationMessageDetail);
