import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import NegotiationSync from './negotiation-sync';
import NegotiationSyncDetail from './negotiation-sync-detail';
import NegotiationSyncUpdate from './negotiation-sync-update';
import NegotiationSyncDeleteDialog from './negotiation-sync-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={NegotiationSyncUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={NegotiationSyncUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={NegotiationSyncDetail} />
      <ErrorBoundaryRoute path={match.url} component={NegotiationSync} />
    </Switch>
    <ErrorBoundaryRoute path={`${match.url}/:id/delete`} component={NegotiationSyncDeleteDialog} />
  </>
);

export default Routes;
