import { Moment } from 'moment';
import { IVisitShowroomSync } from 'app/shared/model/visit-showroom-sync.model';

export interface ISyonetVisitShowroom {
  id?: number;
  lastSync?: Moment;
  crmID?: string;
  extracionDate?: Moment;
  attendanceID?: number;
  nissanLeadID?: number;
  visitDate?: Moment;
  flagReturn?: boolean;
  syonetLeadId?: number;
  syncs?: IVisitShowroomSync[];
}

export const defaultValue: Readonly<ISyonetVisitShowroom> = {
  flagReturn: false
};
