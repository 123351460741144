import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import TestDriveResponse from './test-drive-response';
import TestDriveResponseDetail from './test-drive-response-detail';
import TestDriveResponseUpdate from './test-drive-response-update';
import TestDriveResponseDeleteDialog from './test-drive-response-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={TestDriveResponseUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={TestDriveResponseUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={TestDriveResponseDetail} />
      <ErrorBoundaryRoute path={match.url} component={TestDriveResponse} />
    </Switch>
    <ErrorBoundaryRoute path={`${match.url}/:id/delete`} component={TestDriveResponseDeleteDialog} />
  </>
);

export default Routes;
