import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, UncontrolledTooltip, Row, Col } from 'reactstrap';
// tslint:disable-next-line:no-unused-variable
import { Translate, ICrudGetAction } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntity } from './refused-proposal-lead-message.reducer';
import { IRefusedProposalLeadMessage } from 'app/shared/model/refused-proposal-lead-message.model';
// tslint:disable-next-line:no-unused-variable
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';

export interface IRefusedProposalLeadMessageDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export class RefusedProposalLeadMessageDetail extends React.Component<IRefusedProposalLeadMessageDetailProps> {
  componentDidMount() {
    this.props.getEntity(this.props.match.params.id);
  }

  render() {
    const { refusedProposalLeadMessageEntity } = this.props;
    return (
      <Row>
        <Col md="8">
          <h2>
            <Translate contentKey="nissanApp.refusedProposalLeadMessage.detail.title">RefusedProposalLeadMessage</Translate> [
            <b>{refusedProposalLeadMessageEntity.id}</b>]
          </h2>
          <dl className="jh-entity-details">
            <dt>
              <span id="message">
                <Translate contentKey="nissanApp.refusedProposalLeadMessage.message">Message</Translate>
              </span>
              <UncontrolledTooltip target="message">
                <Translate contentKey="nissanApp.refusedProposalLeadMessage.help.message" />
              </UncontrolledTooltip>
            </dt>
            <dd>{refusedProposalLeadMessageEntity.message}</dd>
            <dt>
              <span id="messageCode">
                <Translate contentKey="nissanApp.refusedProposalLeadMessage.messageCode">Message Code</Translate>
              </span>
              <UncontrolledTooltip target="messageCode">
                <Translate contentKey="nissanApp.refusedProposalLeadMessage.help.messageCode" />
              </UncontrolledTooltip>
            </dt>
            <dd>{refusedProposalLeadMessageEntity.messageCode}</dd>
            <dt>
              <span id="registryNumber">
                <Translate contentKey="nissanApp.refusedProposalLeadMessage.registryNumber">Registry Number</Translate>
              </span>
              <UncontrolledTooltip target="registryNumber">
                <Translate contentKey="nissanApp.refusedProposalLeadMessage.help.registryNumber" />
              </UncontrolledTooltip>
            </dt>
            <dd>{refusedProposalLeadMessageEntity.registryNumber}</dd>
            <dt>
              <span id="returnType">
                <Translate contentKey="nissanApp.refusedProposalLeadMessage.returnType">Return Type</Translate>
              </span>
              <UncontrolledTooltip target="returnType">
                <Translate contentKey="nissanApp.refusedProposalLeadMessage.help.returnType" />
              </UncontrolledTooltip>
            </dt>
            <dd>{refusedProposalLeadMessageEntity.returnType}</dd>
            <dt>
              <Translate contentKey="nissanApp.refusedProposalLeadMessage.refusedProposalResponse">Refused Proposal Response</Translate>
            </dt>
            <dd>
              {refusedProposalLeadMessageEntity.refusedProposalResponseId ? refusedProposalLeadMessageEntity.refusedProposalResponseId : ''}
            </dd>
          </dl>
          <Button tag={Link} to="/entity/refused-proposal-lead-message" replace color="info">
            <FontAwesomeIcon icon="arrow-left" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.back">Back</Translate>
            </span>
          </Button>
          &nbsp;
          <Button
            tag={Link}
            to={`/entity/refused-proposal-lead-message/${refusedProposalLeadMessageEntity.id}/edit`}
            replace
            color="primary"
          >
            <FontAwesomeIcon icon="pencil-alt" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.edit">Edit</Translate>
            </span>
          </Button>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = ({ refusedProposalLeadMessage }: IRootState) => ({
  refusedProposalLeadMessageEntity: refusedProposalLeadMessage.entity
});

const mapDispatchToProps = { getEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RefusedProposalLeadMessageDetail);
