export interface IClientQualificationVehicleChange {
  id?: number;
  vehicleCode?: string;
  vehicleNissan?: number;
  vehicleExchangeDescription?: string;
  exchangeModelCode?: string;
  interestedEimCode?: string;
  negotiatedColorCode?: string;
  modelYearVehicleNegotiated?: number;
  syonetClientQualificationId?: number;
}

export const defaultValue: Readonly<IClientQualificationVehicleChange> = {};
