import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import ClientQualificationMessage from './client-qualification-message';
import ClientQualificationMessageDetail from './client-qualification-message-detail';
import ClientQualificationMessageUpdate from './client-qualification-message-update';
import ClientQualificationMessageDeleteDialog from './client-qualification-message-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={ClientQualificationMessageUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={ClientQualificationMessageUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={ClientQualificationMessageDetail} />
      <ErrorBoundaryRoute path={match.url} component={ClientQualificationMessage} />
    </Switch>
    <ErrorBoundaryRoute path={`${match.url}/:id/delete`} component={ClientQualificationMessageDeleteDialog} />
  </>
);

export default Routes;
