import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import SyonetNegotiation from './syonet-negotiation';
import SyonetNegotiationDetail from './syonet-negotiation-detail';
import SyonetNegotiationUpdate from './syonet-negotiation-update';
import SyonetNegotiationDeleteDialog from './syonet-negotiation-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={SyonetNegotiationUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={SyonetNegotiationUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={SyonetNegotiationDetail} />
      <ErrorBoundaryRoute path={match.url} component={SyonetNegotiation} />
    </Switch>
    <ErrorBoundaryRoute path={`${match.url}/:id/delete`} component={SyonetNegotiationDeleteDialog} />
  </>
);

export default Routes;
