import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, UncontrolledTooltip, Row, Col } from 'reactstrap';
// tslint:disable-next-line:no-unused-variable
import { Translate, ICrudGetAction } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntity } from './vehicle-exchange-nissan.reducer';
import { IVehicleExchangeNissan } from 'app/shared/model/vehicle-exchange-nissan.model';
// tslint:disable-next-line:no-unused-variable
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';

export interface IVehicleExchangeNissanDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export class VehicleExchangeNissanDetail extends React.Component<IVehicleExchangeNissanDetailProps> {
  componentDidMount() {
    this.props.getEntity(this.props.match.params.id);
  }

  render() {
    const { vehicleExchangeNissanEntity } = this.props;
    return (
      <Row>
        <Col md="8">
          <h2>
            <Translate contentKey="nissanApp.vehicleExchangeNissan.detail.title">VehicleExchangeNissan</Translate> [
            <b>{vehicleExchangeNissanEntity.id}</b>]
          </h2>
          <dl className="jh-entity-details">
            <dt>
              <span id="vehicleCode">
                <Translate contentKey="nissanApp.vehicleExchangeNissan.vehicleCode">Vehicle Code</Translate>
              </span>
              <UncontrolledTooltip target="vehicleCode">
                <Translate contentKey="nissanApp.vehicleExchangeNissan.help.vehicleCode" />
              </UncontrolledTooltip>
            </dt>
            <dd>{vehicleExchangeNissanEntity.vehicleCode}</dd>
            <dt>
              <span id="vehicleNissan">
                <Translate contentKey="nissanApp.vehicleExchangeNissan.vehicleNissan">Vehicle Nissan</Translate>
              </span>
              <UncontrolledTooltip target="vehicleNissan">
                <Translate contentKey="nissanApp.vehicleExchangeNissan.help.vehicleNissan" />
              </UncontrolledTooltip>
            </dt>
            <dd>{vehicleExchangeNissanEntity.vehicleNissan}</dd>
            <dt>
              <span id="vehicleExchangeDescription">
                <Translate contentKey="nissanApp.vehicleExchangeNissan.vehicleExchangeDescription">Vehicle Exchange Description</Translate>
              </span>
              <UncontrolledTooltip target="vehicleExchangeDescription">
                <Translate contentKey="nissanApp.vehicleExchangeNissan.help.vehicleExchangeDescription" />
              </UncontrolledTooltip>
            </dt>
            <dd>{vehicleExchangeNissanEntity.vehicleExchangeDescription}</dd>
            <dt>
              <span id="exchangeModelCode">
                <Translate contentKey="nissanApp.vehicleExchangeNissan.exchangeModelCode">Exchange Model Code</Translate>
              </span>
              <UncontrolledTooltip target="exchangeModelCode">
                <Translate contentKey="nissanApp.vehicleExchangeNissan.help.exchangeModelCode" />
              </UncontrolledTooltip>
            </dt>
            <dd>{vehicleExchangeNissanEntity.exchangeModelCode}</dd>
            <dt>
              <span id="exchangeModelDescription">
                <Translate contentKey="nissanApp.vehicleExchangeNissan.exchangeModelDescription">Exchange Model Description</Translate>
              </span>
              <UncontrolledTooltip target="exchangeModelDescription">
                <Translate contentKey="nissanApp.vehicleExchangeNissan.help.exchangeModelDescription" />
              </UncontrolledTooltip>
            </dt>
            <dd>{vehicleExchangeNissanEntity.exchangeModelDescription}</dd>
            <dt>
              <span id="interestedEimCode">
                <Translate contentKey="nissanApp.vehicleExchangeNissan.interestedEimCode">Interested Eim Code</Translate>
              </span>
              <UncontrolledTooltip target="interestedEimCode">
                <Translate contentKey="nissanApp.vehicleExchangeNissan.help.interestedEimCode" />
              </UncontrolledTooltip>
            </dt>
            <dd>{vehicleExchangeNissanEntity.interestedEimCode}</dd>
            <dt>
              <span id="exchangeVersionDescription">
                <Translate contentKey="nissanApp.vehicleExchangeNissan.exchangeVersionDescription">Exchange Version Description</Translate>
              </span>
              <UncontrolledTooltip target="exchangeVersionDescription">
                <Translate contentKey="nissanApp.vehicleExchangeNissan.help.exchangeVersionDescription" />
              </UncontrolledTooltip>
            </dt>
            <dd>{vehicleExchangeNissanEntity.exchangeVersionDescription}</dd>
            <dt>
              <span id="negotiatedColorCode">
                <Translate contentKey="nissanApp.vehicleExchangeNissan.negotiatedColorCode">Negotiated Color Code</Translate>
              </span>
              <UncontrolledTooltip target="negotiatedColorCode">
                <Translate contentKey="nissanApp.vehicleExchangeNissan.help.negotiatedColorCode" />
              </UncontrolledTooltip>
            </dt>
            <dd>{vehicleExchangeNissanEntity.negotiatedColorCode}</dd>
            <dt>
              <span id="negotiatedColorDescription">
                <Translate contentKey="nissanApp.vehicleExchangeNissan.negotiatedColorDescription">Negotiated Color Description</Translate>
              </span>
              <UncontrolledTooltip target="negotiatedColorDescription">
                <Translate contentKey="nissanApp.vehicleExchangeNissan.help.negotiatedColorDescription" />
              </UncontrolledTooltip>
            </dt>
            <dd>{vehicleExchangeNissanEntity.negotiatedColorDescription}</dd>
            <dt>
              <Translate contentKey="nissanApp.vehicleExchangeNissan.lead">Lead</Translate>
            </dt>
            <dd>{vehicleExchangeNissanEntity.leadId ? vehicleExchangeNissanEntity.leadId : ''}</dd>
          </dl>
          <Button tag={Link} to="/entity/vehicle-exchange-nissan" replace color="info">
            <FontAwesomeIcon icon="arrow-left" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.back">Back</Translate>
            </span>
          </Button>
          &nbsp;
          <Button tag={Link} to={`/entity/vehicle-exchange-nissan/${vehicleExchangeNissanEntity.id}/edit`} replace color="primary">
            <FontAwesomeIcon icon="pencil-alt" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.edit">Edit</Translate>
            </span>
          </Button>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = ({ vehicleExchangeNissan }: IRootState) => ({
  vehicleExchangeNissanEntity: vehicleExchangeNissan.entity
});

const mapDispatchToProps = { getEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VehicleExchangeNissanDetail);
