import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import SyonetAttendantLead from './syonet-attendant-lead';
import SyonetAttendantLeadDetail from './syonet-attendant-lead-detail';
import SyonetAttendantLeadUpdate from './syonet-attendant-lead-update';
import SyonetAttendantLeadDeleteDialog from './syonet-attendant-lead-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={SyonetAttendantLeadUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={SyonetAttendantLeadUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={SyonetAttendantLeadDetail} />
      <ErrorBoundaryRoute path={match.url} component={SyonetAttendantLead} />
    </Switch>
    <ErrorBoundaryRoute path={`${match.url}/:id/delete`} component={SyonetAttendantLeadDeleteDialog} />
  </>
);

export default Routes;
