import { Moment } from 'moment';
import { IClientQualificationResponse } from 'app/shared/model/client-qualification-response.model';

export interface IClientQualificationSync {
  id?: number;
  receivedIn?: Moment;
  synchronizedIn?: Moment;
  responses?: IClientQualificationResponse[];
  syonetClientQualificationId?: number;
}

export const defaultValue: Readonly<IClientQualificationSync> = {};
