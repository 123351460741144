import { Moment } from 'moment';
import { ISyncHotLead } from 'app/shared/model/sync-hot-lead.model';
import { IResponseHotLead } from 'app/shared/model/response-hot-lead.model';

export interface IExecutionHotLead {
  id?: number;
  initDate?: Moment;
  endDate?: Moment;
  endTime?: Moment;
  startTime?: Moment;
  syncTime?: Moment;
  accountInfoId?: number;
  syncs?: ISyncHotLead[];
  responses?: IResponseHotLead[];
}

export const defaultValue: Readonly<IExecutionHotLead> = {};
