export interface IRefusedProposalLeadMessage {
  id?: number;
  message?: string;
  messageCode?: number;
  registryNumber?: number;
  returnType?: string;
  refusedProposalResponseId?: number;
}

export const defaultValue: Readonly<IRefusedProposalLeadMessage> = {};
