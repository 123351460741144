import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import AccountInfo from './account-info';
import AccountInfoDetail from './account-info-detail';
import AccountInfoUpdate from './account-info-update';
import AccountInfoDeleteDialog from './account-info-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={AccountInfoUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={AccountInfoUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={AccountInfoDetail} />
      <ErrorBoundaryRoute path={match.url} component={AccountInfo} />
    </Switch>
    <ErrorBoundaryRoute path={`${match.url}/:id/delete`} component={AccountInfoDeleteDialog} />
  </>
);

export default Routes;
