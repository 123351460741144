import { Moment } from 'moment';
import { ISyonetAttendantLead } from 'app/shared/model/syonet-attendant-lead.model';
import { ISyonetSchedulingLead } from 'app/shared/model/syonet-scheduling-lead.model';
import { ISyonetVisitShowroom } from 'app/shared/model/syonet-visit-showroom.model';
import { ISyonetClientQualification } from 'app/shared/model/syonet-client-qualification.model';
import { ISyonetTestDrive } from 'app/shared/model/syonet-test-drive.model';
import { ISyonetNegotiation } from 'app/shared/model/syonet-negotiation.model';
import { ISyonetApprovedProposal } from 'app/shared/model/syonet-approved-proposal.model';
import { ISyonetRefusedProposal } from 'app/shared/model/syonet-refused-proposal.model';
import { ISyonetFollowUp } from 'app/shared/model/syonet-follow-up.model';

export interface ISyonetLead {
  id?: number;
  leadID?: number;
  eventType?: string;
  nissanLeadID?: number;
  date?: Moment;
  attendants?: ISyonetAttendantLead[];
  schedulings?: ISyonetSchedulingLead[];
  visitsShowrooms?: ISyonetVisitShowroom[];
  clientQualifications?: ISyonetClientQualification[];
  testDrives?: ISyonetTestDrive[];
  negociacoes?: ISyonetNegotiation[];
  accepteds?: ISyonetApprovedProposal[];
  refuseds?: ISyonetRefusedProposal[];
  followUps?: ISyonetFollowUp[];
  accountInfoId?: number;
  ignoreEquals?: boolean;
}

export const defaultValue: Readonly<ISyonetLead> = {};
