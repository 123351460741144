import axios from 'axios';
import {
  ICrudSearchAction,
  parseHeaderForLinks,
  loadMoreDataWhenScrolled,
  ICrudGetAction,
  ICrudGetAllAction,
  ICrudPutAction,
  ICrudDeleteAction
} from 'react-jhipster';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

import { INegotiationVehicleInterested, defaultValue } from 'app/shared/model/negotiation-vehicle-interested.model';

export const ACTION_TYPES = {
  SEARCH_NEGOTIATIONVEHICLEINTERESTEDS: 'negotiationVehicleInterested/SEARCH_NEGOTIATIONVEHICLEINTERESTEDS',
  FETCH_NEGOTIATIONVEHICLEINTERESTED_LIST: 'negotiationVehicleInterested/FETCH_NEGOTIATIONVEHICLEINTERESTED_LIST',
  FETCH_NEGOTIATIONVEHICLEINTERESTED: 'negotiationVehicleInterested/FETCH_NEGOTIATIONVEHICLEINTERESTED',
  CREATE_NEGOTIATIONVEHICLEINTERESTED: 'negotiationVehicleInterested/CREATE_NEGOTIATIONVEHICLEINTERESTED',
  UPDATE_NEGOTIATIONVEHICLEINTERESTED: 'negotiationVehicleInterested/UPDATE_NEGOTIATIONVEHICLEINTERESTED',
  DELETE_NEGOTIATIONVEHICLEINTERESTED: 'negotiationVehicleInterested/DELETE_NEGOTIATIONVEHICLEINTERESTED',
  RESET: 'negotiationVehicleInterested/RESET'
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<INegotiationVehicleInterested>,
  entity: defaultValue,
  links: { next: 0 },
  updating: false,
  totalItems: 0,
  updateSuccess: false
};

export type NegotiationVehicleInterestedState = Readonly<typeof initialState>;

// Reducer

export default (state: NegotiationVehicleInterestedState = initialState, action): NegotiationVehicleInterestedState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.SEARCH_NEGOTIATIONVEHICLEINTERESTEDS):
    case REQUEST(ACTION_TYPES.FETCH_NEGOTIATIONVEHICLEINTERESTED_LIST):
    case REQUEST(ACTION_TYPES.FETCH_NEGOTIATIONVEHICLEINTERESTED):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true
      };
    case REQUEST(ACTION_TYPES.CREATE_NEGOTIATIONVEHICLEINTERESTED):
    case REQUEST(ACTION_TYPES.UPDATE_NEGOTIATIONVEHICLEINTERESTED):
    case REQUEST(ACTION_TYPES.DELETE_NEGOTIATIONVEHICLEINTERESTED):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true
      };
    case FAILURE(ACTION_TYPES.SEARCH_NEGOTIATIONVEHICLEINTERESTEDS):
    case FAILURE(ACTION_TYPES.FETCH_NEGOTIATIONVEHICLEINTERESTED_LIST):
    case FAILURE(ACTION_TYPES.FETCH_NEGOTIATIONVEHICLEINTERESTED):
    case FAILURE(ACTION_TYPES.CREATE_NEGOTIATIONVEHICLEINTERESTED):
    case FAILURE(ACTION_TYPES.UPDATE_NEGOTIATIONVEHICLEINTERESTED):
    case FAILURE(ACTION_TYPES.DELETE_NEGOTIATIONVEHICLEINTERESTED):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload
      };
    case SUCCESS(ACTION_TYPES.SEARCH_NEGOTIATIONVEHICLEINTERESTEDS):
    case SUCCESS(ACTION_TYPES.FETCH_NEGOTIATIONVEHICLEINTERESTED_LIST):
      const links = parseHeaderForLinks(action.payload.headers.link);
      return {
        ...state,
        links,
        loading: false,
        totalItems: action.payload.headers['x-total-count'],
        entities: loadMoreDataWhenScrolled(state.entities, action.payload.data, links)
      };
    case SUCCESS(ACTION_TYPES.FETCH_NEGOTIATIONVEHICLEINTERESTED):
      return {
        ...state,
        loading: false,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.CREATE_NEGOTIATIONVEHICLEINTERESTED):
    case SUCCESS(ACTION_TYPES.UPDATE_NEGOTIATIONVEHICLEINTERESTED):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.DELETE_NEGOTIATIONVEHICLEINTERESTED):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {}
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

const apiUrl = 'api/negotiation-vehicle-interesteds';
const apiSearchUrl = 'api/_search/negotiation-vehicle-interesteds';

// Actions

export const getSearchEntities: ICrudSearchAction<INegotiationVehicleInterested> = (query, page, size, sort) => ({
  type: ACTION_TYPES.SEARCH_NEGOTIATIONVEHICLEINTERESTEDS,
  payload: axios.get<INegotiationVehicleInterested>(
    `${apiSearchUrl}?query=${query}${sort ? `&page=${page}&size=${size}&sort=${sort}` : ''}`
  )
});

export const getEntities: ICrudGetAllAction<INegotiationVehicleInterested> = (page, size, sort) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.FETCH_NEGOTIATIONVEHICLEINTERESTED_LIST,
    payload: axios.get<INegotiationVehicleInterested>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`)
  };
};

export const getEntity: ICrudGetAction<INegotiationVehicleInterested> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_NEGOTIATIONVEHICLEINTERESTED,
    payload: axios.get<INegotiationVehicleInterested>(requestUrl)
  };
};

export const createEntity: ICrudPutAction<INegotiationVehicleInterested> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_NEGOTIATIONVEHICLEINTERESTED,
    payload: axios.post(apiUrl, cleanEntity(entity))
  });
  return result;
};

export const updateEntity: ICrudPutAction<INegotiationVehicleInterested> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_NEGOTIATIONVEHICLEINTERESTED,
    payload: axios.put(apiUrl, cleanEntity(entity))
  });
  return result;
};

export const deleteEntity: ICrudDeleteAction<INegotiationVehicleInterested> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_NEGOTIATIONVEHICLEINTERESTED,
    payload: axios.delete(requestUrl)
  });
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET
});
