import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import ResponseGamma from './response-gamma';
import ResponseGammaDetail from './response-gamma-detail';
import ResponseGammaUpdate from './response-gamma-update';
import ResponseGammaDeleteDialog from './response-gamma-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={ResponseGammaUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={ResponseGammaUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={ResponseGammaDetail} />
      <ErrorBoundaryRoute path={match.url} component={ResponseGamma} />
    </Switch>
    <ErrorBoundaryRoute path={`${match.url}/:id/delete`} component={ResponseGammaDeleteDialog} />
  </>
);

export default Routes;
