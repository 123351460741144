import axios from 'axios';
import {
  ICrudSearchAction,
  parseHeaderForLinks,
  loadMoreDataWhenScrolled,
  ICrudGetAction,
  ICrudGetAllAction,
  ICrudPutAction,
  ICrudDeleteAction
} from 'react-jhipster';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

import { IFollowUpResponse, defaultValue } from 'app/shared/model/follow-up-response.model';

export const ACTION_TYPES = {
  SEARCH_FOLLOWUPRESPONSES: 'followUpResponse/SEARCH_FOLLOWUPRESPONSES',
  FETCH_FOLLOWUPRESPONSE_LIST: 'followUpResponse/FETCH_FOLLOWUPRESPONSE_LIST',
  FETCH_FOLLOWUPRESPONSE: 'followUpResponse/FETCH_FOLLOWUPRESPONSE',
  CREATE_FOLLOWUPRESPONSE: 'followUpResponse/CREATE_FOLLOWUPRESPONSE',
  UPDATE_FOLLOWUPRESPONSE: 'followUpResponse/UPDATE_FOLLOWUPRESPONSE',
  DELETE_FOLLOWUPRESPONSE: 'followUpResponse/DELETE_FOLLOWUPRESPONSE',
  RESET: 'followUpResponse/RESET'
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<IFollowUpResponse>,
  entity: defaultValue,
  links: { next: 0 },
  updating: false,
  totalItems: 0,
  updateSuccess: false
};

export type FollowUpResponseState = Readonly<typeof initialState>;

// Reducer

export default (state: FollowUpResponseState = initialState, action): FollowUpResponseState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.SEARCH_FOLLOWUPRESPONSES):
    case REQUEST(ACTION_TYPES.FETCH_FOLLOWUPRESPONSE_LIST):
    case REQUEST(ACTION_TYPES.FETCH_FOLLOWUPRESPONSE):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true
      };
    case REQUEST(ACTION_TYPES.CREATE_FOLLOWUPRESPONSE):
    case REQUEST(ACTION_TYPES.UPDATE_FOLLOWUPRESPONSE):
    case REQUEST(ACTION_TYPES.DELETE_FOLLOWUPRESPONSE):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true
      };
    case FAILURE(ACTION_TYPES.SEARCH_FOLLOWUPRESPONSES):
    case FAILURE(ACTION_TYPES.FETCH_FOLLOWUPRESPONSE_LIST):
    case FAILURE(ACTION_TYPES.FETCH_FOLLOWUPRESPONSE):
    case FAILURE(ACTION_TYPES.CREATE_FOLLOWUPRESPONSE):
    case FAILURE(ACTION_TYPES.UPDATE_FOLLOWUPRESPONSE):
    case FAILURE(ACTION_TYPES.DELETE_FOLLOWUPRESPONSE):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload
      };
    case SUCCESS(ACTION_TYPES.SEARCH_FOLLOWUPRESPONSES):
    case SUCCESS(ACTION_TYPES.FETCH_FOLLOWUPRESPONSE_LIST):
      const links = parseHeaderForLinks(action.payload.headers.link);
      return {
        ...state,
        links,
        loading: false,
        totalItems: action.payload.headers['x-total-count'],
        entities: loadMoreDataWhenScrolled(state.entities, action.payload.data, links)
      };
    case SUCCESS(ACTION_TYPES.FETCH_FOLLOWUPRESPONSE):
      return {
        ...state,
        loading: false,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.CREATE_FOLLOWUPRESPONSE):
    case SUCCESS(ACTION_TYPES.UPDATE_FOLLOWUPRESPONSE):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.DELETE_FOLLOWUPRESPONSE):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {}
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

const apiUrl = 'api/follow-up-responses';
const apiSearchUrl = 'api/_search/follow-up-responses';

// Actions

export const getSearchEntities: ICrudSearchAction<IFollowUpResponse> = (query, page, size, sort) => ({
  type: ACTION_TYPES.SEARCH_FOLLOWUPRESPONSES,
  payload: axios.get<IFollowUpResponse>(`${apiSearchUrl}?query=${query}${sort ? `&page=${page}&size=${size}&sort=${sort}` : ''}`)
});

export const getEntities: ICrudGetAllAction<IFollowUpResponse> = (page, size, sort) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.FETCH_FOLLOWUPRESPONSE_LIST,
    payload: axios.get<IFollowUpResponse>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`)
  };
};

export const getEntity: ICrudGetAction<IFollowUpResponse> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_FOLLOWUPRESPONSE,
    payload: axios.get<IFollowUpResponse>(requestUrl)
  };
};

export const createEntity: ICrudPutAction<IFollowUpResponse> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_FOLLOWUPRESPONSE,
    payload: axios.post(apiUrl, cleanEntity(entity))
  });
  return result;
};

export const updateEntity: ICrudPutAction<IFollowUpResponse> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_FOLLOWUPRESPONSE,
    payload: axios.put(apiUrl, cleanEntity(entity))
  });
  return result;
};

export const deleteEntity: ICrudDeleteAction<IFollowUpResponse> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_FOLLOWUPRESPONSE,
    payload: axios.delete(requestUrl)
  });
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET
});
