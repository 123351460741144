export interface IClientQualificationMessage {
  id?: number;
  message?: string;
  messageCode?: number;
  registryNumber?: number;
  returnType?: string;
  clientQualificationResponseId?: number;
}

export const defaultValue: Readonly<IClientQualificationMessage> = {};
