import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col, Label, UncontrolledTooltip } from 'reactstrap';
import { AvFeedback, AvForm, AvGroup, AvInput, AvField } from 'availity-reactstrap-validation';
// tslint:disable-next-line:no-unused-variable
import { Translate, translate, ICrudGetAction, ICrudGetAllAction, ICrudPutAction } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';

import { ILead } from 'app/shared/model/lead.model';
import { getEntities as getLeads } from 'app/entities/lead/lead.reducer';
import { getEntity, updateEntity, createEntity, reset } from './vehicle-nissan-interest.reducer';
import { IVehicleNissanInterest } from 'app/shared/model/vehicle-nissan-interest.model';
// tslint:disable-next-line:no-unused-variable
import { convertDateTimeFromServer, convertDateTimeToServer } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';

export interface IVehicleNissanInterestUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export interface IVehicleNissanInterestUpdateState {
  isNew: boolean;
  leadId: string;
}

export class VehicleNissanInterestUpdate extends React.Component<IVehicleNissanInterestUpdateProps, IVehicleNissanInterestUpdateState> {
  constructor(props) {
    super(props);
    this.state = {
      leadId: '0',
      isNew: !this.props.match.params || !this.props.match.params.id
    };
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextProps.updateSuccess !== this.props.updateSuccess && nextProps.updateSuccess) {
      this.handleClose();
    }
  }

  componentDidMount() {
    if (!this.state.isNew) {
      this.props.getEntity(this.props.match.params.id);
    }

    this.props.getLeads();
  }

  saveEntity = (event, errors, values) => {
    if (errors.length === 0) {
      const { vehicleNissanInterestEntity } = this.props;
      const entity = {
        ...vehicleNissanInterestEntity,
        ...values
      };

      if (this.state.isNew) {
        this.props.createEntity(entity);
      } else {
        this.props.updateEntity(entity);
      }
    }
  };

  handleClose = () => {
    this.props.history.push('/entity/vehicle-nissan-interest');
  };

  render() {
    const { vehicleNissanInterestEntity, leads, loading, updating } = this.props;
    const { isNew } = this.state;

    return (
      <div>
        <Row className="justify-content-center">
          <Col md="8">
            <h2 id="nissanApp.vehicleNissanInterest.home.createOrEditLabel">
              <Translate contentKey="nissanApp.vehicleNissanInterest.home.createOrEditLabel">
                Create or edit a VehicleNissanInterest
              </Translate>
            </h2>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md="8">
            {loading ? (
              <p>Loading...</p>
            ) : (
              <AvForm model={isNew ? {} : vehicleNissanInterestEntity} onSubmit={this.saveEntity}>
                {!isNew ? (
                  <AvGroup>
                    <Label for="vehicle-nissan-interest-id">
                      <Translate contentKey="global.field.id">ID</Translate>
                    </Label>
                    <AvInput id="vehicle-nissan-interest-id" type="text" className="form-control" name="id" required readOnly />
                  </AvGroup>
                ) : null}
                <AvGroup>
                  <Label id="vehicleCodeLabel" for="vehicle-nissan-interest-vehicleCode">
                    <Translate contentKey="nissanApp.vehicleNissanInterest.vehicleCode">Vehicle Code</Translate>
                  </Label>
                  <AvField
                    id="vehicle-nissan-interest-vehicleCode"
                    type="text"
                    name="vehicleCode"
                    validate={{
                      required: { value: true, errorMessage: translate('entity.validation.required') }
                    }}
                  />
                  <UncontrolledTooltip target="vehicleCodeLabel">
                    <Translate contentKey="nissanApp.vehicleNissanInterest.help.vehicleCode" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="modelCodeLabel" for="vehicle-nissan-interest-modelCode">
                    <Translate contentKey="nissanApp.vehicleNissanInterest.modelCode">Model Code</Translate>
                  </Label>
                  <AvField id="vehicle-nissan-interest-modelCode" type="text" name="modelCode" />
                  <UncontrolledTooltip target="modelCodeLabel">
                    <Translate contentKey="nissanApp.vehicleNissanInterest.help.modelCode" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="modelLabel" for="vehicle-nissan-interest-model">
                    <Translate contentKey="nissanApp.vehicleNissanInterest.model">Model</Translate>
                  </Label>
                  <AvField id="vehicle-nissan-interest-model" type="text" name="model" />
                  <UncontrolledTooltip target="modelLabel">
                    <Translate contentKey="nissanApp.vehicleNissanInterest.help.model" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="eimVehicleInterestLabel" for="vehicle-nissan-interest-eimVehicleInterest">
                    <Translate contentKey="nissanApp.vehicleNissanInterest.eimVehicleInterest">Eim Vehicle Interest</Translate>
                  </Label>
                  <AvField id="vehicle-nissan-interest-eimVehicleInterest" type="text" name="eimVehicleInterest" />
                  <UncontrolledTooltip target="eimVehicleInterestLabel">
                    <Translate contentKey="nissanApp.vehicleNissanInterest.help.eimVehicleInterest" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="versionLabel" for="vehicle-nissan-interest-version">
                    <Translate contentKey="nissanApp.vehicleNissanInterest.version">Version</Translate>
                  </Label>
                  <AvField id="vehicle-nissan-interest-version" type="text" name="version" />
                  <UncontrolledTooltip target="versionLabel">
                    <Translate contentKey="nissanApp.vehicleNissanInterest.help.version" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="modelYearLabel" for="vehicle-nissan-interest-modelYear">
                    <Translate contentKey="nissanApp.vehicleNissanInterest.modelYear">Model Year</Translate>
                  </Label>
                  <AvField id="vehicle-nissan-interest-modelYear" type="string" className="form-control" name="modelYear" />
                  <UncontrolledTooltip target="modelYearLabel">
                    <Translate contentKey="nissanApp.vehicleNissanInterest.help.modelYear" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="colorCodeLabel" for="vehicle-nissan-interest-colorCode">
                    <Translate contentKey="nissanApp.vehicleNissanInterest.colorCode">Color Code</Translate>
                  </Label>
                  <AvField id="vehicle-nissan-interest-colorCode" type="text" name="colorCode" />
                  <UncontrolledTooltip target="colorCodeLabel">
                    <Translate contentKey="nissanApp.vehicleNissanInterest.help.colorCode" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="colorLabel" for="vehicle-nissan-interest-color">
                    <Translate contentKey="nissanApp.vehicleNissanInterest.color">Color</Translate>
                  </Label>
                  <AvField id="vehicle-nissan-interest-color" type="text" name="color" />
                  <UncontrolledTooltip target="colorLabel">
                    <Translate contentKey="nissanApp.vehicleNissanInterest.help.color" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="modelYearVehicleNegotiatedLabel" for="vehicle-nissan-interest-modelYearVehicleNegotiated">
                    <Translate contentKey="nissanApp.vehicleNissanInterest.modelYearVehicleNegotiated">
                      Model Year Vehicle Negotiated
                    </Translate>
                  </Label>
                  <AvField
                    id="vehicle-nissan-interest-modelYearVehicleNegotiated"
                    type="string"
                    className="form-control"
                    name="modelYearVehicleNegotiated"
                  />
                  <UncontrolledTooltip target="modelYearVehicleNegotiatedLabel">
                    <Translate contentKey="nissanApp.vehicleNissanInterest.help.modelYearVehicleNegotiated" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label for="vehicle-nissan-interest-lead">
                    <Translate contentKey="nissanApp.vehicleNissanInterest.lead">Lead</Translate>
                  </Label>
                  <AvInput id="vehicle-nissan-interest-lead" type="select" className="form-control" name="leadId">
                    <option value="" key="0" />
                    {leads
                      ? leads.map(otherEntity => (
                          <option value={otherEntity.id} key={otherEntity.id}>
                            {otherEntity.id}
                          </option>
                        ))
                      : null}
                  </AvInput>
                </AvGroup>
                <Button tag={Link} id="cancel-save" to="/entity/vehicle-nissan-interest" replace color="info">
                  <FontAwesomeIcon icon="arrow-left" />
                  &nbsp;
                  <span className="d-none d-md-inline">
                    <Translate contentKey="entity.action.back">Back</Translate>
                  </span>
                </Button>
                &nbsp;
                <Button color="primary" id="save-entity" type="submit" disabled={updating}>
                  <FontAwesomeIcon icon="save" />
                  &nbsp;
                  <Translate contentKey="entity.action.save">Save</Translate>
                </Button>
              </AvForm>
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (storeState: IRootState) => ({
  leads: storeState.lead.entities,
  vehicleNissanInterestEntity: storeState.vehicleNissanInterest.entity,
  loading: storeState.vehicleNissanInterest.loading,
  updating: storeState.vehicleNissanInterest.updating,
  updateSuccess: storeState.vehicleNissanInterest.updateSuccess
});

const mapDispatchToProps = {
  getLeads,
  getEntity,
  updateEntity,
  createEntity,
  reset
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VehicleNissanInterestUpdate);
