import { Moment } from 'moment';
import { IAttendantLeadSync } from 'app/shared/model/attendant-lead-sync.model';

export interface ISyonetAttendantLead {
  id?: number;
  lastSync?: Moment;
  crmID?: string;
  extracionDate?: Moment;
  attendanceID?: number;
  attendanceDate?: Moment;
  sourceAttendance?: number;
  nissanLeadID?: number;
  salesChannel?: number;
  saleCategory?: number;
  mediaAttraction?: number;
  campaignID?: number;
  campaign?: string;
  temperature?: number;
  salesmanID?: string;
  salesmanName?: string;
  salesmanEmail?: string;
  salesManagerID?: string;
  salesManagerName?: string;
  salesManagerEmail?: string;
  clienteType?: string;
  clientNationality?: string;
  clienteID?: string;
  clientInternacionalID?: string;
  clienteFullName?: string;
  clientSocialName?: string;
  clientFantasyName?: string;
  contactName?: string;
  addressType?: number;
  addressName?: string;
  addressNumber?: string;
  addressComplement?: string;
  addressNeighborhood?: string;
  addressCity?: string;
  addressStateID?: string;
  addressCityCode?: string;
  clientGender?: string;
  clientBirthday?: Moment;
  clienteEmail?: string;
  clienteEmail2?: string;
  residentialPhoneAreaCode?: number;
  residentialPhoneNumber?: number;
  cellPhoneAreaCode?: number;
  cellPhoneNumber?: number;
  commercialPhoneAreaCode?: number;
  commercialPhoneNumber?: number;
  syonetLeadId?: number;
  syncs?: IAttendantLeadSync[];
}

export const defaultValue: Readonly<ISyonetAttendantLead> = {};
