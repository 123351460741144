import axios from 'axios';
import {
  ICrudSearchAction,
  parseHeaderForLinks,
  loadMoreDataWhenScrolled,
  ICrudGetAction,
  ICrudGetAllAction,
  ICrudPutAction,
  ICrudDeleteAction
} from 'react-jhipster';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

import { IApprovedProposalStatusCode, defaultValue } from 'app/shared/model/approved-proposal-status-code.model';

export const ACTION_TYPES = {
  SEARCH_APPROVEDPROPOSALSTATUSCODES: 'approvedProposalStatusCode/SEARCH_APPROVEDPROPOSALSTATUSCODES',
  FETCH_APPROVEDPROPOSALSTATUSCODE_LIST: 'approvedProposalStatusCode/FETCH_APPROVEDPROPOSALSTATUSCODE_LIST',
  FETCH_APPROVEDPROPOSALSTATUSCODE: 'approvedProposalStatusCode/FETCH_APPROVEDPROPOSALSTATUSCODE',
  CREATE_APPROVEDPROPOSALSTATUSCODE: 'approvedProposalStatusCode/CREATE_APPROVEDPROPOSALSTATUSCODE',
  UPDATE_APPROVEDPROPOSALSTATUSCODE: 'approvedProposalStatusCode/UPDATE_APPROVEDPROPOSALSTATUSCODE',
  DELETE_APPROVEDPROPOSALSTATUSCODE: 'approvedProposalStatusCode/DELETE_APPROVEDPROPOSALSTATUSCODE',
  RESET: 'approvedProposalStatusCode/RESET'
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<IApprovedProposalStatusCode>,
  entity: defaultValue,
  links: { next: 0 },
  updating: false,
  totalItems: 0,
  updateSuccess: false
};

export type ApprovedProposalStatusCodeState = Readonly<typeof initialState>;

// Reducer

export default (state: ApprovedProposalStatusCodeState = initialState, action): ApprovedProposalStatusCodeState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.SEARCH_APPROVEDPROPOSALSTATUSCODES):
    case REQUEST(ACTION_TYPES.FETCH_APPROVEDPROPOSALSTATUSCODE_LIST):
    case REQUEST(ACTION_TYPES.FETCH_APPROVEDPROPOSALSTATUSCODE):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true
      };
    case REQUEST(ACTION_TYPES.CREATE_APPROVEDPROPOSALSTATUSCODE):
    case REQUEST(ACTION_TYPES.UPDATE_APPROVEDPROPOSALSTATUSCODE):
    case REQUEST(ACTION_TYPES.DELETE_APPROVEDPROPOSALSTATUSCODE):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true
      };
    case FAILURE(ACTION_TYPES.SEARCH_APPROVEDPROPOSALSTATUSCODES):
    case FAILURE(ACTION_TYPES.FETCH_APPROVEDPROPOSALSTATUSCODE_LIST):
    case FAILURE(ACTION_TYPES.FETCH_APPROVEDPROPOSALSTATUSCODE):
    case FAILURE(ACTION_TYPES.CREATE_APPROVEDPROPOSALSTATUSCODE):
    case FAILURE(ACTION_TYPES.UPDATE_APPROVEDPROPOSALSTATUSCODE):
    case FAILURE(ACTION_TYPES.DELETE_APPROVEDPROPOSALSTATUSCODE):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload
      };
    case SUCCESS(ACTION_TYPES.SEARCH_APPROVEDPROPOSALSTATUSCODES):
    case SUCCESS(ACTION_TYPES.FETCH_APPROVEDPROPOSALSTATUSCODE_LIST):
      const links = parseHeaderForLinks(action.payload.headers.link);
      return {
        ...state,
        links,
        loading: false,
        totalItems: action.payload.headers['x-total-count'],
        entities: loadMoreDataWhenScrolled(state.entities, action.payload.data, links)
      };
    case SUCCESS(ACTION_TYPES.FETCH_APPROVEDPROPOSALSTATUSCODE):
      return {
        ...state,
        loading: false,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.CREATE_APPROVEDPROPOSALSTATUSCODE):
    case SUCCESS(ACTION_TYPES.UPDATE_APPROVEDPROPOSALSTATUSCODE):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.DELETE_APPROVEDPROPOSALSTATUSCODE):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {}
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

const apiUrl = 'api/approved-proposal-status-codes';
const apiSearchUrl = 'api/_search/approved-proposal-status-codes';

// Actions

export const getSearchEntities: ICrudSearchAction<IApprovedProposalStatusCode> = (query, page, size, sort) => ({
  type: ACTION_TYPES.SEARCH_APPROVEDPROPOSALSTATUSCODES,
  payload: axios.get<IApprovedProposalStatusCode>(`${apiSearchUrl}?query=${query}${sort ? `&page=${page}&size=${size}&sort=${sort}` : ''}`)
});

export const getEntities: ICrudGetAllAction<IApprovedProposalStatusCode> = (page, size, sort) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.FETCH_APPROVEDPROPOSALSTATUSCODE_LIST,
    payload: axios.get<IApprovedProposalStatusCode>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`)
  };
};

export const getEntity: ICrudGetAction<IApprovedProposalStatusCode> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_APPROVEDPROPOSALSTATUSCODE,
    payload: axios.get<IApprovedProposalStatusCode>(requestUrl)
  };
};

export const createEntity: ICrudPutAction<IApprovedProposalStatusCode> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_APPROVEDPROPOSALSTATUSCODE,
    payload: axios.post(apiUrl, cleanEntity(entity))
  });
  return result;
};

export const updateEntity: ICrudPutAction<IApprovedProposalStatusCode> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_APPROVEDPROPOSALSTATUSCODE,
    payload: axios.put(apiUrl, cleanEntity(entity))
  });
  return result;
};

export const deleteEntity: ICrudDeleteAction<IApprovedProposalStatusCode> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_APPROVEDPROPOSALSTATUSCODE,
    payload: axios.delete(requestUrl)
  });
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET
});
