import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col, Label, UncontrolledTooltip } from 'reactstrap';
import { AvFeedback, AvForm, AvGroup, AvInput, AvField } from 'availity-reactstrap-validation';
// tslint:disable-next-line:no-unused-variable
import { Translate, translate, ICrudGetAction, ICrudGetAllAction, ICrudPutAction } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';

import { INissanModel } from 'app/shared/model/nissan-model.model';
import { getEntities as getNissanModels } from 'app/entities/nissan-model/nissan-model.reducer';
import { getEntity, updateEntity, createEntity, reset } from './color.reducer';
import { IColor } from 'app/shared/model/color.model';
// tslint:disable-next-line:no-unused-variable
import { convertDateTimeFromServer, convertDateTimeToServer } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';

export interface IColorUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export interface IColorUpdateState {
  isNew: boolean;
  modelId: string;
}

export class ColorUpdate extends React.Component<IColorUpdateProps, IColorUpdateState> {
  constructor(props) {
    super(props);
    this.state = {
      modelId: '0',
      isNew: !this.props.match.params || !this.props.match.params.id
    };
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextProps.updateSuccess !== this.props.updateSuccess && nextProps.updateSuccess) {
      this.handleClose();
    }
  }

  componentDidMount() {
    if (!this.state.isNew) {
      this.props.getEntity(this.props.match.params.id);
    }

    this.props.getNissanModels();
  }

  saveEntity = (event, errors, values) => {
    if (errors.length === 0) {
      const { colorEntity } = this.props;
      const entity = {
        ...colorEntity,
        ...values
      };

      if (this.state.isNew) {
        this.props.createEntity(entity);
      } else {
        this.props.updateEntity(entity);
      }
    }
  };

  handleClose = () => {
    this.props.history.push('/entity/color');
  };

  render() {
    const { colorEntity, nissanModels, loading, updating } = this.props;
    const { isNew } = this.state;

    return (
      <div>
        <Row className="justify-content-center">
          <Col md="8">
            <h2 id="nissanApp.color.home.createOrEditLabel">
              <Translate contentKey="nissanApp.color.home.createOrEditLabel">Create or edit a Color</Translate>
            </h2>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md="8">
            {loading ? (
              <p>Loading...</p>
            ) : (
              <AvForm model={isNew ? {} : colorEntity} onSubmit={this.saveEntity}>
                {!isNew ? (
                  <AvGroup>
                    <Label for="color-id">
                      <Translate contentKey="global.field.id">ID</Translate>
                    </Label>
                    <AvInput id="color-id" type="text" className="form-control" name="id" required readOnly />
                  </AvGroup>
                ) : null}
                <AvGroup>
                  <Label id="colorLabel" for="color-color">
                    <Translate contentKey="nissanApp.color.color">Color</Translate>
                  </Label>
                  <AvField
                    id="color-color"
                    type="text"
                    name="color"
                    validate={{
                      required: { value: true, errorMessage: translate('entity.validation.required') }
                    }}
                  />
                  <UncontrolledTooltip target="colorLabel">
                    <Translate contentKey="nissanApp.color.help.color" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="colorCodeLabel" for="color-colorCode">
                    <Translate contentKey="nissanApp.color.colorCode">Color Code</Translate>
                  </Label>
                  <AvField
                    id="color-colorCode"
                    type="text"
                    name="colorCode"
                    validate={{
                      required: { value: true, errorMessage: translate('entity.validation.required') }
                    }}
                  />
                  <UncontrolledTooltip target="colorCodeLabel">
                    <Translate contentKey="nissanApp.color.help.colorCode" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="colorTypeLabel" for="color-colorType">
                    <Translate contentKey="nissanApp.color.colorType">Color Type</Translate>
                  </Label>
                  <AvField
                    id="color-colorType"
                    type="text"
                    name="colorType"
                    validate={{
                      required: { value: true, errorMessage: translate('entity.validation.required') }
                    }}
                  />
                  <UncontrolledTooltip target="colorTypeLabel">
                    <Translate contentKey="nissanApp.color.help.colorType" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="suggestedPriceLabel" for="color-suggestedPrice">
                    <Translate contentKey="nissanApp.color.suggestedPrice">Suggested Price</Translate>
                  </Label>
                  <AvField
                    id="color-suggestedPrice"
                    type="string"
                    className="form-control"
                    name="suggestedPrice"
                    validate={{
                      required: { value: true, errorMessage: translate('entity.validation.required') },
                      number: { value: true, errorMessage: translate('entity.validation.number') }
                    }}
                  />
                  <UncontrolledTooltip target="suggestedPriceLabel">
                    <Translate contentKey="nissanApp.color.help.suggestedPrice" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label for="color-model">
                    <Translate contentKey="nissanApp.color.model">Model</Translate>
                  </Label>
                  <AvInput id="color-model" type="select" className="form-control" name="modelId">
                    <option value="" key="0" />
                    {nissanModels
                      ? nissanModels.map(otherEntity => (
                          <option value={otherEntity.id} key={otherEntity.id}>
                            {otherEntity.id}
                          </option>
                        ))
                      : null}
                  </AvInput>
                </AvGroup>
                <Button tag={Link} id="cancel-save" to="/entity/color" replace color="info">
                  <FontAwesomeIcon icon="arrow-left" />
                  &nbsp;
                  <span className="d-none d-md-inline">
                    <Translate contentKey="entity.action.back">Back</Translate>
                  </span>
                </Button>
                &nbsp;
                <Button color="primary" id="save-entity" type="submit" disabled={updating}>
                  <FontAwesomeIcon icon="save" />
                  &nbsp;
                  <Translate contentKey="entity.action.save">Save</Translate>
                </Button>
              </AvForm>
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (storeState: IRootState) => ({
  nissanModels: storeState.nissanModel.entities,
  colorEntity: storeState.color.entity,
  loading: storeState.color.loading,
  updating: storeState.color.updating,
  updateSuccess: storeState.color.updateSuccess
});

const mapDispatchToProps = {
  getNissanModels,
  getEntity,
  updateEntity,
  createEntity,
  reset
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ColorUpdate);
