import { Moment } from 'moment';
import { ISchedulingLeadResponse } from 'app/shared/model/scheduling-lead-response.model';

export interface ISchedulingLeadSync {
  id?: number;
  receivedIn?: Moment;
  synchronizedIn?: Moment;
  responses?: ISchedulingLeadResponse[];
  syonetSchedulingLeadId?: number;
}

export const defaultValue: Readonly<ISchedulingLeadSync> = {};
