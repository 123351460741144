import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import NegotiationVehicleOnTrade from './negotiation-vehicle-on-trade';
import NegotiationVehicleOnTradeDetail from './negotiation-vehicle-on-trade-detail';
import NegotiationVehicleOnTradeUpdate from './negotiation-vehicle-on-trade-update';
import NegotiationVehicleOnTradeDeleteDialog from './negotiation-vehicle-on-trade-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={NegotiationVehicleOnTradeUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={NegotiationVehicleOnTradeUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={NegotiationVehicleOnTradeDetail} />
      <ErrorBoundaryRoute path={match.url} component={NegotiationVehicleOnTrade} />
    </Switch>
    <ErrorBoundaryRoute path={`${match.url}/:id/delete`} component={NegotiationVehicleOnTradeDeleteDialog} />
  </>
);

export default Routes;
