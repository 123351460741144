import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, UncontrolledTooltip, Row, Col } from 'reactstrap';
// tslint:disable-next-line:no-unused-variable
import { Translate, ICrudGetAction, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntity } from './syonet-client-qualification.reducer';
import { ISyonetClientQualification } from 'app/shared/model/syonet-client-qualification.model';
// tslint:disable-next-line:no-unused-variable
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';

export interface ISyonetClientQualificationDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export class SyonetClientQualificationDetail extends React.Component<ISyonetClientQualificationDetailProps> {
  componentDidMount() {
    this.props.getEntity(this.props.match.params.id);
  }

  render() {
    const { syonetClientQualificationEntity } = this.props;
    return (
      <Row>
        <Col md="8">
          <h2>
            <Translate contentKey="nissanApp.syonetClientQualification.detail.title">SyonetClientQualification</Translate> [
            <b>{syonetClientQualificationEntity.id}</b>]
          </h2>
          <dl className="jh-entity-details">
            <dt>
              <span id="lastSync">
                <Translate contentKey="nissanApp.syonetClientQualification.lastSync">Last Sync</Translate>
              </span>
              <UncontrolledTooltip target="lastSync">
                <Translate contentKey="nissanApp.syonetClientQualification.help.lastSync" />
              </UncontrolledTooltip>
            </dt>
            <dd>
              <TextFormat value={syonetClientQualificationEntity.lastSync} type="date" format={APP_DATE_FORMAT} />
            </dd>
            <dt>
              <span id="crmID">
                <Translate contentKey="nissanApp.syonetClientQualification.crmID">Crm ID</Translate>
              </span>
              <UncontrolledTooltip target="crmID">
                <Translate contentKey="nissanApp.syonetClientQualification.help.crmID" />
              </UncontrolledTooltip>
            </dt>
            <dd>{syonetClientQualificationEntity.crmID}</dd>
            <dt>
              <span id="extracionDate">
                <Translate contentKey="nissanApp.syonetClientQualification.extracionDate">Extracion Date</Translate>
              </span>
              <UncontrolledTooltip target="extracionDate">
                <Translate contentKey="nissanApp.syonetClientQualification.help.extracionDate" />
              </UncontrolledTooltip>
            </dt>
            <dd>
              <TextFormat value={syonetClientQualificationEntity.extracionDate} type="date" format={APP_DATE_FORMAT} />
            </dd>
            <dt>
              <span id="attendanceID">
                <Translate contentKey="nissanApp.syonetClientQualification.attendanceID">Attendance ID</Translate>
              </span>
              <UncontrolledTooltip target="attendanceID">
                <Translate contentKey="nissanApp.syonetClientQualification.help.attendanceID" />
              </UncontrolledTooltip>
            </dt>
            <dd>{syonetClientQualificationEntity.attendanceID}</dd>
            <dt>
              <span id="nissanLeadID">
                <Translate contentKey="nissanApp.syonetClientQualification.nissanLeadID">Nissan Lead ID</Translate>
              </span>
              <UncontrolledTooltip target="nissanLeadID">
                <Translate contentKey="nissanApp.syonetClientQualification.help.nissanLeadID" />
              </UncontrolledTooltip>
            </dt>
            <dd>{syonetClientQualificationEntity.nissanLeadID}</dd>
            <dt>
              <span id="nissanClient">
                <Translate contentKey="nissanApp.syonetClientQualification.nissanClient">Nissan Client</Translate>
              </span>
              <UncontrolledTooltip target="nissanClient">
                <Translate contentKey="nissanApp.syonetClientQualification.help.nissanClient" />
              </UncontrolledTooltip>
            </dt>
            <dd>{syonetClientQualificationEntity.nissanClient}</dd>
            <dt>
              <span id="flagTestDrived">
                <Translate contentKey="nissanApp.syonetClientQualification.flagTestDrived">Flag Test Drived</Translate>
              </span>
              <UncontrolledTooltip target="flagTestDrived">
                <Translate contentKey="nissanApp.syonetClientQualification.help.flagTestDrived" />
              </UncontrolledTooltip>
            </dt>
            <dd>{syonetClientQualificationEntity.flagTestDrived}</dd>
            <dt>
              <span id="flagFinanc">
                <Translate contentKey="nissanApp.syonetClientQualification.flagFinanc">Flag Financ</Translate>
              </span>
              <UncontrolledTooltip target="flagFinanc">
                <Translate contentKey="nissanApp.syonetClientQualification.help.flagFinanc" />
              </UncontrolledTooltip>
            </dt>
            <dd>{syonetClientQualificationEntity.flagFinanc}</dd>
            <dt>
              <span id="financingValue">
                <Translate contentKey="nissanApp.syonetClientQualification.financingValue">Financing Value</Translate>
              </span>
              <UncontrolledTooltip target="financingValue">
                <Translate contentKey="nissanApp.syonetClientQualification.help.financingValue" />
              </UncontrolledTooltip>
            </dt>
            <dd>{syonetClientQualificationEntity.financingValue}</dd>
            <dt>
              <span id="financingPreApproved">
                <Translate contentKey="nissanApp.syonetClientQualification.financingPreApproved">Financing Pre Approved</Translate>
              </span>
              <UncontrolledTooltip target="financingPreApproved">
                <Translate contentKey="nissanApp.syonetClientQualification.help.financingPreApproved" />
              </UncontrolledTooltip>
            </dt>
            <dd>{syonetClientQualificationEntity.financingPreApproved}</dd>
            <dt>
              <span id="consortium">
                <Translate contentKey="nissanApp.syonetClientQualification.consortium">Consortium</Translate>
              </span>
              <UncontrolledTooltip target="consortium">
                <Translate contentKey="nissanApp.syonetClientQualification.help.consortium" />
              </UncontrolledTooltip>
            </dt>
            <dd>{syonetClientQualificationEntity.consortium}</dd>
            <dt>
              <span id="rangeValue">
                <Translate contentKey="nissanApp.syonetClientQualification.rangeValue">Range Value</Translate>
              </span>
              <UncontrolledTooltip target="rangeValue">
                <Translate contentKey="nissanApp.syonetClientQualification.help.rangeValue" />
              </UncontrolledTooltip>
            </dt>
            <dd>{syonetClientQualificationEntity.rangeValue}</dd>
            <dt>
              <span id="note">
                <Translate contentKey="nissanApp.syonetClientQualification.note">Note</Translate>
              </span>
              <UncontrolledTooltip target="note">
                <Translate contentKey="nissanApp.syonetClientQualification.help.note" />
              </UncontrolledTooltip>
            </dt>
            <dd>{syonetClientQualificationEntity.note}</dd>
            <dt>
              <Translate contentKey="nissanApp.syonetClientQualification.syonetLead">Syonet Lead</Translate>
            </dt>
            <dd>{syonetClientQualificationEntity.syonetLeadId ? syonetClientQualificationEntity.syonetLeadId : ''}</dd>
          </dl>
          <Button tag={Link} to="/entity/syonet-client-qualification" replace color="info">
            <FontAwesomeIcon icon="arrow-left" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.back">Back</Translate>
            </span>
          </Button>
          &nbsp;
          <Button tag={Link} to={`/entity/syonet-client-qualification/${syonetClientQualificationEntity.id}/edit`} replace color="primary">
            <FontAwesomeIcon icon="pencil-alt" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.edit">Edit</Translate>
            </span>
          </Button>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = ({ syonetClientQualification }: IRootState) => ({
  syonetClientQualificationEntity: syonetClientQualification.entity
});

const mapDispatchToProps = { getEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SyonetClientQualificationDetail);
