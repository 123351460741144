import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import SyncHotLead from './sync-hot-lead';
import SyncHotLeadDetail from './sync-hot-lead-detail';
import SyncHotLeadUpdate from './sync-hot-lead-update';
import SyncHotLeadDeleteDialog from './sync-hot-lead-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={SyncHotLeadUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={SyncHotLeadUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={SyncHotLeadDetail} />
      <ErrorBoundaryRoute path={match.url} component={SyncHotLead} />
    </Switch>
    <ErrorBoundaryRoute path={`${match.url}/:id/delete`} component={SyncHotLeadDeleteDialog} />
  </>
);

export default Routes;
