import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col, Label, UncontrolledTooltip } from 'reactstrap';
import { AvFeedback, AvForm, AvGroup, AvInput, AvField } from 'availity-reactstrap-validation';
// tslint:disable-next-line:no-unused-variable
import { Translate, translate, ICrudGetAction, ICrudGetAllAction, ICrudPutAction } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';

import { ISyonetLead } from 'app/shared/model/syonet-lead.model';
import { getEntities as getSyonetLeads } from 'app/entities/syonet-lead/syonet-lead.reducer';
import { getEntity, updateEntity, createEntity, reset } from './syonet-test-drive.reducer';
import { ISyonetTestDrive } from 'app/shared/model/syonet-test-drive.model';
// tslint:disable-next-line:no-unused-variable
import { convertDateTimeFromServer, convertDateTimeToServer } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';

export interface ISyonetTestDriveUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export interface ISyonetTestDriveUpdateState {
  isNew: boolean;
  syonetLeadId: string;
}

export class SyonetTestDriveUpdate extends React.Component<ISyonetTestDriveUpdateProps, ISyonetTestDriveUpdateState> {
  constructor(props) {
    super(props);
    this.state = {
      syonetLeadId: '0',
      isNew: !this.props.match.params || !this.props.match.params.id
    };
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextProps.updateSuccess !== this.props.updateSuccess && nextProps.updateSuccess) {
      this.handleClose();
    }
  }

  componentDidMount() {
    if (!this.state.isNew) {
      this.props.getEntity(this.props.match.params.id);
    }

    this.props.getSyonetLeads();
  }

  saveEntity = (event, errors, values) => {
    values.lastSync = convertDateTimeToServer(values.lastSync);
    values.extracionDate = convertDateTimeToServer(values.extracionDate);
    values.testDriveDate = convertDateTimeToServer(values.testDriveDate);

    if (errors.length === 0) {
      const { syonetTestDriveEntity } = this.props;
      const entity = {
        ...syonetTestDriveEntity,
        ...values
      };

      if (this.state.isNew) {
        this.props.createEntity(entity);
      } else {
        this.props.updateEntity(entity);
      }
    }
  };

  handleClose = () => {
    this.props.history.push('/entity/syonet-test-drive');
  };

  render() {
    const { syonetTestDriveEntity, syonetLeads, loading, updating } = this.props;
    const { isNew } = this.state;

    return (
      <div>
        <Row className="justify-content-center">
          <Col md="8">
            <h2 id="nissanApp.syonetTestDrive.home.createOrEditLabel">
              <Translate contentKey="nissanApp.syonetTestDrive.home.createOrEditLabel">Create or edit a SyonetTestDrive</Translate>
            </h2>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md="8">
            {loading ? (
              <p>Loading...</p>
            ) : (
              <AvForm model={isNew ? {} : syonetTestDriveEntity} onSubmit={this.saveEntity}>
                {!isNew ? (
                  <AvGroup>
                    <Label for="syonet-test-drive-id">
                      <Translate contentKey="global.field.id">ID</Translate>
                    </Label>
                    <AvInput id="syonet-test-drive-id" type="text" className="form-control" name="id" required readOnly />
                  </AvGroup>
                ) : null}
                <AvGroup>
                  <Label id="lastSyncLabel" for="syonet-test-drive-lastSync">
                    <Translate contentKey="nissanApp.syonetTestDrive.lastSync">Last Sync</Translate>
                  </Label>
                  <AvInput
                    id="syonet-test-drive-lastSync"
                    type="datetime-local"
                    className="form-control"
                    name="lastSync"
                    placeholder={'YYYY-MM-DD HH:mm'}
                    value={isNew ? null : convertDateTimeFromServer(this.props.syonetTestDriveEntity.lastSync)}
                  />
                  <UncontrolledTooltip target="lastSyncLabel">
                    <Translate contentKey="nissanApp.syonetTestDrive.help.lastSync" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="crmIDLabel" for="syonet-test-drive-crmID">
                    <Translate contentKey="nissanApp.syonetTestDrive.crmID">Crm ID</Translate>
                  </Label>
                  <AvField id="syonet-test-drive-crmID" type="text" name="crmID" />
                  <UncontrolledTooltip target="crmIDLabel">
                    <Translate contentKey="nissanApp.syonetTestDrive.help.crmID" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="extracionDateLabel" for="syonet-test-drive-extracionDate">
                    <Translate contentKey="nissanApp.syonetTestDrive.extracionDate">Extracion Date</Translate>
                  </Label>
                  <AvInput
                    id="syonet-test-drive-extracionDate"
                    type="datetime-local"
                    className="form-control"
                    name="extracionDate"
                    placeholder={'YYYY-MM-DD HH:mm'}
                    value={isNew ? null : convertDateTimeFromServer(this.props.syonetTestDriveEntity.extracionDate)}
                    validate={{
                      required: { value: true, errorMessage: translate('entity.validation.required') }
                    }}
                  />
                  <UncontrolledTooltip target="extracionDateLabel">
                    <Translate contentKey="nissanApp.syonetTestDrive.help.extracionDate" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="attendanceIDLabel" for="syonet-test-drive-attendanceID">
                    <Translate contentKey="nissanApp.syonetTestDrive.attendanceID">Attendance ID</Translate>
                  </Label>
                  <AvField
                    id="syonet-test-drive-attendanceID"
                    type="string"
                    className="form-control"
                    name="attendanceID"
                    validate={{
                      required: { value: true, errorMessage: translate('entity.validation.required') },
                      number: { value: true, errorMessage: translate('entity.validation.number') }
                    }}
                  />
                  <UncontrolledTooltip target="attendanceIDLabel">
                    <Translate contentKey="nissanApp.syonetTestDrive.help.attendanceID" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="nissanLeadIDLabel" for="syonet-test-drive-nissanLeadID">
                    <Translate contentKey="nissanApp.syonetTestDrive.nissanLeadID">Nissan Lead ID</Translate>
                  </Label>
                  <AvField id="syonet-test-drive-nissanLeadID" type="string" className="form-control" name="nissanLeadID" />
                  <UncontrolledTooltip target="nissanLeadIDLabel">
                    <Translate contentKey="nissanApp.syonetTestDrive.help.nissanLeadID" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="testDriveDateLabel" for="syonet-test-drive-testDriveDate">
                    <Translate contentKey="nissanApp.syonetTestDrive.testDriveDate">Test Drive Date</Translate>
                  </Label>
                  <AvInput
                    id="syonet-test-drive-testDriveDate"
                    type="datetime-local"
                    className="form-control"
                    name="testDriveDate"
                    placeholder={'YYYY-MM-DD HH:mm'}
                    value={isNew ? null : convertDateTimeFromServer(this.props.syonetTestDriveEntity.testDriveDate)}
                    validate={{
                      required: { value: true, errorMessage: translate('entity.validation.required') }
                    }}
                  />
                  <UncontrolledTooltip target="testDriveDateLabel">
                    <Translate contentKey="nissanApp.syonetTestDrive.help.testDriveDate" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="noteLabel" for="syonet-test-drive-note">
                    <Translate contentKey="nissanApp.syonetTestDrive.note">Note</Translate>
                  </Label>
                  <AvField id="syonet-test-drive-note" type="text" name="note" />
                  <UncontrolledTooltip target="noteLabel">
                    <Translate contentKey="nissanApp.syonetTestDrive.help.note" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="testDriveModelCodeLabel" for="syonet-test-drive-testDriveModelCode">
                    <Translate contentKey="nissanApp.syonetTestDrive.testDriveModelCode">Test Drive Model Code</Translate>
                  </Label>
                  <AvField
                    id="syonet-test-drive-testDriveModelCode"
                    type="text"
                    name="testDriveModelCode"
                    validate={{
                      required: { value: true, errorMessage: translate('entity.validation.required') }
                    }}
                  />
                  <UncontrolledTooltip target="testDriveModelCodeLabel">
                    <Translate contentKey="nissanApp.syonetTestDrive.help.testDriveModelCode" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="testDriveEimCodeLabel" for="syonet-test-drive-testDriveEimCode">
                    <Translate contentKey="nissanApp.syonetTestDrive.testDriveEimCode">Test Drive Eim Code</Translate>
                  </Label>
                  <AvField id="syonet-test-drive-testDriveEimCode" type="text" name="testDriveEimCode" />
                  <UncontrolledTooltip target="testDriveEimCodeLabel">
                    <Translate contentKey="nissanApp.syonetTestDrive.help.testDriveEimCode" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="testDriveColorCodeLabel" for="syonet-test-drive-testDriveColorCode">
                    <Translate contentKey="nissanApp.syonetTestDrive.testDriveColorCode">Test Drive Color Code</Translate>
                  </Label>
                  <AvField id="syonet-test-drive-testDriveColorCode" type="text" name="testDriveColorCode" />
                  <UncontrolledTooltip target="testDriveColorCodeLabel">
                    <Translate contentKey="nissanApp.syonetTestDrive.help.testDriveColorCode" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="testDriveModelYearLabel" for="syonet-test-drive-testDriveModelYear">
                    <Translate contentKey="nissanApp.syonetTestDrive.testDriveModelYear">Test Drive Model Year</Translate>
                  </Label>
                  <AvField id="syonet-test-drive-testDriveModelYear" type="string" className="form-control" name="testDriveModelYear" />
                  <UncontrolledTooltip target="testDriveModelYearLabel">
                    <Translate contentKey="nissanApp.syonetTestDrive.help.testDriveModelYear" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label for="syonet-test-drive-syonetLead">
                    <Translate contentKey="nissanApp.syonetTestDrive.syonetLead">Syonet Lead</Translate>
                  </Label>
                  <AvInput id="syonet-test-drive-syonetLead" type="select" className="form-control" name="syonetLeadId">
                    <option value="" key="0" />
                    {syonetLeads
                      ? syonetLeads.map(otherEntity => (
                          <option value={otherEntity.id} key={otherEntity.id}>
                            {otherEntity.id}
                          </option>
                        ))
                      : null}
                  </AvInput>
                </AvGroup>
                <Button tag={Link} id="cancel-save" to="/entity/syonet-test-drive" replace color="info">
                  <FontAwesomeIcon icon="arrow-left" />
                  &nbsp;
                  <span className="d-none d-md-inline">
                    <Translate contentKey="entity.action.back">Back</Translate>
                  </span>
                </Button>
                &nbsp;
                <Button color="primary" id="save-entity" type="submit" disabled={updating}>
                  <FontAwesomeIcon icon="save" />
                  &nbsp;
                  <Translate contentKey="entity.action.save">Save</Translate>
                </Button>
              </AvForm>
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (storeState: IRootState) => ({
  syonetLeads: storeState.syonetLead.entities,
  syonetTestDriveEntity: storeState.syonetTestDrive.entity,
  loading: storeState.syonetTestDrive.loading,
  updating: storeState.syonetTestDrive.updating,
  updateSuccess: storeState.syonetTestDrive.updateSuccess
});

const mapDispatchToProps = {
  getSyonetLeads,
  getEntity,
  updateEntity,
  createEntity,
  reset
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SyonetTestDriveUpdate);
