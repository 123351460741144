import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, UncontrolledTooltip, Row, Col } from 'reactstrap';
// tslint:disable-next-line:no-unused-variable
import { Translate, ICrudGetAction } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntity } from './client-qualification-vehicle-interested.reducer';
import { IClientQualificationVehicleInterested } from 'app/shared/model/client-qualification-vehicle-interested.model';
// tslint:disable-next-line:no-unused-variable
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';

export interface IClientQualificationVehicleInterestedDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export class ClientQualificationVehicleInterestedDetail extends React.Component<IClientQualificationVehicleInterestedDetailProps> {
  componentDidMount() {
    this.props.getEntity(this.props.match.params.id);
  }

  render() {
    const { clientQualificationVehicleInterestedEntity } = this.props;
    return (
      <Row>
        <Col md="8">
          <h2>
            <Translate contentKey="nissanApp.clientQualificationVehicleInterested.detail.title">
              ClientQualificationVehicleInterested
            </Translate>{' '}
            [<b>{clientQualificationVehicleInterestedEntity.id}</b>]
          </h2>
          <dl className="jh-entity-details">
            <dt>
              <span id="vehicleCode">
                <Translate contentKey="nissanApp.clientQualificationVehicleInterested.vehicleCode">Vehicle Code</Translate>
              </span>
              <UncontrolledTooltip target="vehicleCode">
                <Translate contentKey="nissanApp.clientQualificationVehicleInterested.help.vehicleCode" />
              </UncontrolledTooltip>
            </dt>
            <dd>{clientQualificationVehicleInterestedEntity.vehicleCode}</dd>
            <dt>
              <span id="modelCode">
                <Translate contentKey="nissanApp.clientQualificationVehicleInterested.modelCode">Model Code</Translate>
              </span>
              <UncontrolledTooltip target="modelCode">
                <Translate contentKey="nissanApp.clientQualificationVehicleInterested.help.modelCode" />
              </UncontrolledTooltip>
            </dt>
            <dd>{clientQualificationVehicleInterestedEntity.modelCode}</dd>
            <dt>
              <span id="eimVehicleInterest">
                <Translate contentKey="nissanApp.clientQualificationVehicleInterested.eimVehicleInterest">Eim Vehicle Interest</Translate>
              </span>
              <UncontrolledTooltip target="eimVehicleInterest">
                <Translate contentKey="nissanApp.clientQualificationVehicleInterested.help.eimVehicleInterest" />
              </UncontrolledTooltip>
            </dt>
            <dd>{clientQualificationVehicleInterestedEntity.eimVehicleInterest}</dd>
            <dt>
              <span id="colorCode">
                <Translate contentKey="nissanApp.clientQualificationVehicleInterested.colorCode">Color Code</Translate>
              </span>
              <UncontrolledTooltip target="colorCode">
                <Translate contentKey="nissanApp.clientQualificationVehicleInterested.help.colorCode" />
              </UncontrolledTooltip>
            </dt>
            <dd>{clientQualificationVehicleInterestedEntity.colorCode}</dd>
            <dt>
              <span id="modelYear">
                <Translate contentKey="nissanApp.clientQualificationVehicleInterested.modelYear">Model Year</Translate>
              </span>
              <UncontrolledTooltip target="modelYear">
                <Translate contentKey="nissanApp.clientQualificationVehicleInterested.help.modelYear" />
              </UncontrolledTooltip>
            </dt>
            <dd>{clientQualificationVehicleInterestedEntity.modelYear}</dd>
            <dt>
              <Translate contentKey="nissanApp.clientQualificationVehicleInterested.syonetClientQualification">
                Syonet Client Qualification
              </Translate>
            </dt>
            <dd>
              {clientQualificationVehicleInterestedEntity.syonetClientQualificationId
                ? clientQualificationVehicleInterestedEntity.syonetClientQualificationId
                : ''}
            </dd>
          </dl>
          <Button tag={Link} to="/entity/client-qualification-vehicle-interested" replace color="info">
            <FontAwesomeIcon icon="arrow-left" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.back">Back</Translate>
            </span>
          </Button>
          &nbsp;
          <Button
            tag={Link}
            to={`/entity/client-qualification-vehicle-interested/${clientQualificationVehicleInterestedEntity.id}/edit`}
            replace
            color="primary"
          >
            <FontAwesomeIcon icon="pencil-alt" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.edit">Edit</Translate>
            </span>
          </Button>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = ({ clientQualificationVehicleInterested }: IRootState) => ({
  clientQualificationVehicleInterestedEntity: clientQualificationVehicleInterested.entity
});

const mapDispatchToProps = { getEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ClientQualificationVehicleInterestedDetail);
