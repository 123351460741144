import { Moment } from 'moment';
import { ISchedulingLeadMessage } from 'app/shared/model/scheduling-lead-message.model';

export interface ISchedulingLeadResponse {
  id?: number;
  receivedIn?: Moment;
  synchronizedIn?: Moment;
  statusCodeId?: number;
  messages?: ISchedulingLeadMessage[];
  schedulingLeadSyncId?: number;
}

export const defaultValue: Readonly<ISchedulingLeadResponse> = {};
