import { Moment } from 'moment';
import { IAttendantLeadMessage } from 'app/shared/model/attendant-lead-message.model';

export interface IAttendantLeadResponse {
  id?: number;
  receivedIn?: Moment;
  synchronizedIn?: Moment;
  statusCodeId?: number;
  messages?: IAttendantLeadMessage[];
  attendantLeadSyncId?: number;
}

export const defaultValue: Readonly<IAttendantLeadResponse> = {};
