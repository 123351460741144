export interface IResponseHotLeadMessage {
  id?: number;
  message?: string;
  messageCode?: number;
  registryNumber?: number;
  returnType?: string;
  responseHotLeadId?: number;
}

export const defaultValue: Readonly<IResponseHotLeadMessage> = {};
