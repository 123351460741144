import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import SyonetRefusedProposal from './syonet-refused-proposal';
import SyonetRefusedProposalDetail from './syonet-refused-proposal-detail';
import SyonetRefusedProposalUpdate from './syonet-refused-proposal-update';
import SyonetRefusedProposalDeleteDialog from './syonet-refused-proposal-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={SyonetRefusedProposalUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={SyonetRefusedProposalUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={SyonetRefusedProposalDetail} />
      <ErrorBoundaryRoute path={match.url} component={SyonetRefusedProposal} />
    </Switch>
    <ErrorBoundaryRoute path={`${match.url}/:id/delete`} component={SyonetRefusedProposalDeleteDialog} />
  </>
);

export default Routes;
