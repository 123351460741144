import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, UncontrolledTooltip, Row, Col } from 'reactstrap';
// tslint:disable-next-line:no-unused-variable
import { Translate, ICrudGetAction, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntity } from './lead.reducer';
import { ILead } from 'app/shared/model/lead.model';
// tslint:disable-next-line:no-unused-variable
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';

export interface ILeadDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export class LeadDetail extends React.Component<ILeadDetailProps> {
  componentDidMount() {
    this.props.getEntity(this.props.match.params.id);
  }

  render() {
    const { leadEntity } = this.props;
    return (
      <Row>
        <Col md="8">
          <h2>
            <Translate contentKey="nissanApp.lead.detail.title">Lead</Translate> [<b>{leadEntity.id}</b>]
          </h2>
          <dl className="jh-entity-details">
            <dt>
              <span id="idLead">
                <Translate contentKey="nissanApp.lead.idLead">Id Lead</Translate>
              </span>
              <UncontrolledTooltip target="idLead">
                <Translate contentKey="nissanApp.lead.help.idLead" />
              </UncontrolledTooltip>
            </dt>
            <dd>{leadEntity.idLead}</dd>
            <dt>
              <span id="salesChannel">
                <Translate contentKey="nissanApp.lead.salesChannel">Sales Channel</Translate>
              </span>
              <UncontrolledTooltip target="salesChannel">
                <Translate contentKey="nissanApp.lead.help.salesChannel" />
              </UncontrolledTooltip>
            </dt>
            <dd>{leadEntity.salesChannel}</dd>
            <dt>
              <span id="saleCategory">
                <Translate contentKey="nissanApp.lead.saleCategory">Sale Category</Translate>
              </span>
              <UncontrolledTooltip target="saleCategory">
                <Translate contentKey="nissanApp.lead.help.saleCategory" />
              </UncontrolledTooltip>
            </dt>
            <dd>{leadEntity.saleCategory}</dd>
            <dt>
              <span id="mediaAttraction">
                <Translate contentKey="nissanApp.lead.mediaAttraction">Media Attraction</Translate>
              </span>
              <UncontrolledTooltip target="mediaAttraction">
                <Translate contentKey="nissanApp.lead.help.mediaAttraction" />
              </UncontrolledTooltip>
            </dt>
            <dd>{leadEntity.mediaAttraction}</dd>
            <dt>
              <span id="idCampaign">
                <Translate contentKey="nissanApp.lead.idCampaign">Id Campaign</Translate>
              </span>
              <UncontrolledTooltip target="idCampaign">
                <Translate contentKey="nissanApp.lead.help.idCampaign" />
              </UncontrolledTooltip>
            </dt>
            <dd>{leadEntity.idCampaign}</dd>
            <dt>
              <span id="campaign">
                <Translate contentKey="nissanApp.lead.campaign">Campaign</Translate>
              </span>
              <UncontrolledTooltip target="campaign">
                <Translate contentKey="nissanApp.lead.help.campaign" />
              </UncontrolledTooltip>
            </dt>
            <dd>{leadEntity.campaign}</dd>
            <dt>
              <span id="dateAvailableLead">
                <Translate contentKey="nissanApp.lead.dateAvailableLead">Date Available Lead</Translate>
              </span>
              <UncontrolledTooltip target="dateAvailableLead">
                <Translate contentKey="nissanApp.lead.help.dateAvailableLead" />
              </UncontrolledTooltip>
            </dt>
            <dd>
              <TextFormat value={leadEntity.dateAvailableLead} type="date" format={APP_DATE_FORMAT} />
            </dd>
            <dt>
              <span id="dateConsumerLead">
                <Translate contentKey="nissanApp.lead.dateConsumerLead">Date Consumer Lead</Translate>
              </span>
              <UncontrolledTooltip target="dateConsumerLead">
                <Translate contentKey="nissanApp.lead.help.dateConsumerLead" />
              </UncontrolledTooltip>
            </dt>
            <dd>
              <TextFormat value={leadEntity.dateConsumerLead} type="date" format={APP_DATE_FORMAT} />
            </dd>
            <dt>
              <span id="datePreScheduled">
                <Translate contentKey="nissanApp.lead.datePreScheduled">Date Pre Scheduled</Translate>
              </span>
              <UncontrolledTooltip target="datePreScheduled">
                <Translate contentKey="nissanApp.lead.help.datePreScheduled" />
              </UncontrolledTooltip>
            </dt>
            <dd>
              <TextFormat value={leadEntity.datePreScheduled} type="date" format={APP_DATE_FORMAT} />
            </dd>
            <dt>
              <span id="periodPreScheduled">
                <Translate contentKey="nissanApp.lead.periodPreScheduled">Period Pre Scheduled</Translate>
              </span>
              <UncontrolledTooltip target="periodPreScheduled">
                <Translate contentKey="nissanApp.lead.help.periodPreScheduled" />
              </UncontrolledTooltip>
            </dt>
            <dd>{leadEntity.periodPreScheduled}</dd>
            <dt>
              <span id="periodInterestPurchase">
                <Translate contentKey="nissanApp.lead.periodInterestPurchase">Period Interest Purchase</Translate>
              </span>
              <UncontrolledTooltip target="periodInterestPurchase">
                <Translate contentKey="nissanApp.lead.help.periodInterestPurchase" />
              </UncontrolledTooltip>
            </dt>
            <dd>{leadEntity.periodInterestPurchase}</dd>
            <dt>
              <span id="clientID">
                <Translate contentKey="nissanApp.lead.clientID">Client ID</Translate>
              </span>
              <UncontrolledTooltip target="clientID">
                <Translate contentKey="nissanApp.lead.help.clientID" />
              </UncontrolledTooltip>
            </dt>
            <dd>{leadEntity.clientID}</dd>
            <dt>
              <span id="name">
                <Translate contentKey="nissanApp.lead.name">Name</Translate>
              </span>
              <UncontrolledTooltip target="name">
                <Translate contentKey="nissanApp.lead.help.name" />
              </UncontrolledTooltip>
            </dt>
            <dd>{leadEntity.name}</dd>
            <dt>
              <span id="email">
                <Translate contentKey="nissanApp.lead.email">Email</Translate>
              </span>
              <UncontrolledTooltip target="email">
                <Translate contentKey="nissanApp.lead.help.email" />
              </UncontrolledTooltip>
            </dt>
            <dd>{leadEntity.email}</dd>
            <dt>
              <span id="areaCodeNumber">
                <Translate contentKey="nissanApp.lead.areaCodeNumber">Area Code Number</Translate>
              </span>
              <UncontrolledTooltip target="areaCodeNumber">
                <Translate contentKey="nissanApp.lead.help.areaCodeNumber" />
              </UncontrolledTooltip>
            </dt>
            <dd>{leadEntity.areaCodeNumber}</dd>
            <dt>
              <span id="phoneNumber">
                <Translate contentKey="nissanApp.lead.phoneNumber">Phone Number</Translate>
              </span>
              <UncontrolledTooltip target="phoneNumber">
                <Translate contentKey="nissanApp.lead.help.phoneNumber" />
              </UncontrolledTooltip>
            </dt>
            <dd>{leadEntity.phoneNumber}</dd>
            <dt>
              <span id="contactForm">
                <Translate contentKey="nissanApp.lead.contactForm">Contact Form</Translate>
              </span>
              <UncontrolledTooltip target="contactForm">
                <Translate contentKey="nissanApp.lead.help.contactForm" />
              </UncontrolledTooltip>
            </dt>
            <dd>{leadEntity.contactForm}</dd>
            <dt>
              <span id="zipCode">
                <Translate contentKey="nissanApp.lead.zipCode">Zip Code</Translate>
              </span>
              <UncontrolledTooltip target="zipCode">
                <Translate contentKey="nissanApp.lead.help.zipCode" />
              </UncontrolledTooltip>
            </dt>
            <dd>{leadEntity.zipCode}</dd>
            <dt>
              <span id="federalUnit">
                <Translate contentKey="nissanApp.lead.federalUnit">Federal Unit</Translate>
              </span>
              <UncontrolledTooltip target="federalUnit">
                <Translate contentKey="nissanApp.lead.help.federalUnit" />
              </UncontrolledTooltip>
            </dt>
            <dd>{leadEntity.federalUnit}</dd>
            <dt>
              <span id="city">
                <Translate contentKey="nissanApp.lead.city">City</Translate>
              </span>
              <UncontrolledTooltip target="city">
                <Translate contentKey="nissanApp.lead.help.city" />
              </UncontrolledTooltip>
            </dt>
            <dd>{leadEntity.city}</dd>
            <dt>
              <span id="personsWithSpecialNeeds">
                <Translate contentKey="nissanApp.lead.personsWithSpecialNeeds">Persons With Special Needs</Translate>
              </span>
              <UncontrolledTooltip target="personsWithSpecialNeeds">
                <Translate contentKey="nissanApp.lead.help.personsWithSpecialNeeds" />
              </UncontrolledTooltip>
            </dt>
            <dd>{leadEntity.personsWithSpecialNeeds}</dd>
            <dt>
              <span id="nissanClient">
                <Translate contentKey="nissanApp.lead.nissanClient">Nissan Client</Translate>
              </span>
              <UncontrolledTooltip target="nissanClient">
                <Translate contentKey="nissanApp.lead.help.nissanClient" />
              </UncontrolledTooltip>
            </dt>
            <dd>{leadEntity.nissanClient}</dd>
            <dt>
              <span id="flagTestDrived">
                <Translate contentKey="nissanApp.lead.flagTestDrived">Flag Test Drived</Translate>
              </span>
              <UncontrolledTooltip target="flagTestDrived">
                <Translate contentKey="nissanApp.lead.help.flagTestDrived" />
              </UncontrolledTooltip>
            </dt>
            <dd>{leadEntity.flagTestDrived}</dd>
            <dt>
              <span id="testDriveLocal">
                <Translate contentKey="nissanApp.lead.testDriveLocal">Test Drive Local</Translate>
              </span>
              <UncontrolledTooltip target="testDriveLocal">
                <Translate contentKey="nissanApp.lead.help.testDriveLocal" />
              </UncontrolledTooltip>
            </dt>
            <dd>{leadEntity.testDriveLocal}</dd>
            <dt>
              <span id="flagFinanc">
                <Translate contentKey="nissanApp.lead.flagFinanc">Flag Financ</Translate>
              </span>
              <UncontrolledTooltip target="flagFinanc">
                <Translate contentKey="nissanApp.lead.help.flagFinanc" />
              </UncontrolledTooltip>
            </dt>
            <dd>{leadEntity.flagFinanc}</dd>
            <dt>
              <span id="financingValue">
                <Translate contentKey="nissanApp.lead.financingValue">Financing Value</Translate>
              </span>
              <UncontrolledTooltip target="financingValue">
                <Translate contentKey="nissanApp.lead.help.financingValue" />
              </UncontrolledTooltip>
            </dt>
            <dd>{leadEntity.financingValue}</dd>
            <dt>
              <span id="financingPreApproved">
                <Translate contentKey="nissanApp.lead.financingPreApproved">Financing Pre Approved</Translate>
              </span>
              <UncontrolledTooltip target="financingPreApproved">
                <Translate contentKey="nissanApp.lead.help.financingPreApproved" />
              </UncontrolledTooltip>
            </dt>
            <dd>{leadEntity.financingPreApproved}</dd>
            <dt>
              <span id="consortium">
                <Translate contentKey="nissanApp.lead.consortium">Consortium</Translate>
              </span>
              <UncontrolledTooltip target="consortium">
                <Translate contentKey="nissanApp.lead.help.consortium" />
              </UncontrolledTooltip>
            </dt>
            <dd>{leadEntity.consortium}</dd>
            <dt>
              <span id="rangeValue">
                <Translate contentKey="nissanApp.lead.rangeValue">Range Value</Translate>
              </span>
              <UncontrolledTooltip target="rangeValue">
                <Translate contentKey="nissanApp.lead.help.rangeValue" />
              </UncontrolledTooltip>
            </dt>
            <dd>{leadEntity.rangeValue}</dd>
            <dt>
              <span id="note">
                <Translate contentKey="nissanApp.lead.note">Note</Translate>
              </span>
              <UncontrolledTooltip target="note">
                <Translate contentKey="nissanApp.lead.help.note" />
              </UncontrolledTooltip>
            </dt>
            <dd>{leadEntity.note}</dd>
            <dt>
              <Translate contentKey="nissanApp.lead.responseHotLead">Response Hot Lead</Translate>
            </dt>
            <dd>{leadEntity.responseHotLeadId ? leadEntity.responseHotLeadId : ''}</dd>
          </dl>
          <Button tag={Link} to="/entity/lead" replace color="info">
            <FontAwesomeIcon icon="arrow-left" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.back">Back</Translate>
            </span>
          </Button>
          &nbsp;
          <Button tag={Link} to={`/entity/lead/${leadEntity.id}/edit`} replace color="primary">
            <FontAwesomeIcon icon="pencil-alt" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.edit">Edit</Translate>
            </span>
          </Button>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = ({ lead }: IRootState) => ({
  leadEntity: lead.entity
});

const mapDispatchToProps = { getEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LeadDetail);
