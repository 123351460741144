import { Moment } from 'moment';
import { IApprovedProposalSync } from 'app/shared/model/approved-proposal-sync.model';
import { IVehicleSaledApprovedProposal } from 'app/shared/model/vehicle-saled-approved-proposal.model';

export interface ISyonetApprovedProposal {
  id?: number;
  lastSync?: Moment;
  crmID?: string;
  extracionDate?: Moment;
  attendanceID?: number;
  nissanLeadID?: number;
  salesID?: number;
  scheduledDate?: Moment;
  rangeValue?: number;
  syonetLeadId?: number;
  clientId?: number;
  syncs?: IApprovedProposalSync[];
  vehicleSaleds?: IVehicleSaledApprovedProposal[];
}

export const defaultValue: Readonly<ISyonetApprovedProposal> = {};
