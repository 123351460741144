import { Moment } from 'moment';
import { IFollowUpLeadMessage } from 'app/shared/model/follow-up-lead-message.model';

export interface IFollowUpResponse {
  id?: number;
  receivedIn?: Moment;
  synchronizedIn?: Moment;
  followUpSyncId?: number;
  statusCodeId?: number;
  messages?: IFollowUpLeadMessage[];
}

export const defaultValue: Readonly<IFollowUpResponse> = {};
