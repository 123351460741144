import { Moment } from 'moment';
import { IVisitShowroomLeadMessage } from 'app/shared/model/visit-showroom-lead-message.model';

export interface IVisitShowroomResponse {
  id?: number;
  receivedIn?: Moment;
  synchronizedIn?: Moment;
  visitShowroomSyncId?: number;
  statusCodeId?: number;
  messages?: IVisitShowroomLeadMessage[];
}

export const defaultValue: Readonly<IVisitShowroomResponse> = {};
