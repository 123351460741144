export interface IVehicleExchangeNissan {
  id?: number;
  vehicleCode?: string;
  vehicleNissan?: number;
  vehicleExchangeDescription?: string;
  exchangeModelCode?: string;
  exchangeModelDescription?: string;
  interestedEimCode?: string;
  exchangeVersionDescription?: string;
  negotiatedColorCode?: string;
  negotiatedColorDescription?: string;
  leadId?: number;
}

export const defaultValue: Readonly<IVehicleExchangeNissan> = {};
