export interface INegotiationVehicleInterested {
  id?: number;
  vehicleCode?: string;
  modelCode?: string;
  eimCode?: string;
  colorCode?: string;
  modelYearVehicleNegotiated?: number;
  syonetNegotiationId?: number;
}

export const defaultValue: Readonly<INegotiationVehicleInterested> = {};
