import { Moment } from 'moment';
import { IVisitShowroomResponse } from 'app/shared/model/visit-showroom-response.model';

export interface IVisitShowroomSync {
  id?: number;
  receivedIn?: Moment;
  synchronizedIn?: Moment;
  responses?: IVisitShowroomResponse[];
  syonetVisitShowroomId?: number;
}

export const defaultValue: Readonly<IVisitShowroomSync> = {};
