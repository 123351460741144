import { Moment } from 'moment';
import { IClientQualificationSync } from 'app/shared/model/client-qualification-sync.model';
import { IClientQualificationVehicleChange } from 'app/shared/model/client-qualification-vehicle-change.model';
import { IClientQualificationVehicleInterested } from 'app/shared/model/client-qualification-vehicle-interested.model';

export interface ISyonetClientQualification {
  id?: number;
  lastSync?: Moment;
  crmID?: string;
  extracionDate?: Moment;
  attendanceID?: number;
  nissanLeadID?: number;
  nissanClient?: number;
  flagTestDrived?: number;
  flagFinanc?: number;
  financingValue?: number;
  financingPreApproved?: number;
  consortium?: number;
  rangeValue?: number;
  note?: string;
  syonetLeadId?: number;
  syncs?: IClientQualificationSync[];
  vehicleChanges?: IClientQualificationVehicleChange[];
  vehicleInteresteds?: IClientQualificationVehicleInterested[];
}

export const defaultValue: Readonly<ISyonetClientQualification> = {};
