import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, UncontrolledTooltip, Row, Col } from 'reactstrap';
// tslint:disable-next-line:no-unused-variable
import { Translate, ICrudGetAction, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntity } from './execution-hot-lead.reducer';
import { IExecutionHotLead } from 'app/shared/model/execution-hot-lead.model';
// tslint:disable-next-line:no-unused-variable
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';

export interface IExecutionHotLeadDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export class ExecutionHotLeadDetail extends React.Component<IExecutionHotLeadDetailProps> {
  componentDidMount() {
    this.props.getEntity(this.props.match.params.id);
  }

  render() {
    const { executionHotLeadEntity } = this.props;
    return (
      <Row>
        <Col md="8">
          <h2>
            <Translate contentKey="nissanApp.executionHotLead.detail.title">ExecutionHotLead</Translate> [<b>{executionHotLeadEntity.id}</b>
            ]
          </h2>
          <dl className="jh-entity-details">
            <dt>
              <span id="initDate">
                <Translate contentKey="nissanApp.executionHotLead.initDate">Init Date</Translate>
              </span>
              <UncontrolledTooltip target="initDate">
                <Translate contentKey="nissanApp.executionHotLead.help.initDate" />
              </UncontrolledTooltip>
            </dt>
            <dd>
              <TextFormat value={executionHotLeadEntity.initDate} type="date" format={APP_DATE_FORMAT} />
            </dd>
            <dt>
              <span id="endDate">
                <Translate contentKey="nissanApp.executionHotLead.endDate">End Date</Translate>
              </span>
              <UncontrolledTooltip target="endDate">
                <Translate contentKey="nissanApp.executionHotLead.help.endDate" />
              </UncontrolledTooltip>
            </dt>
            <dd>
              <TextFormat value={executionHotLeadEntity.endDate} type="date" format={APP_DATE_FORMAT} />
            </dd>
            <dt>
              <span id="endTime">
                <Translate contentKey="nissanApp.executionHotLead.endTime">End Time</Translate>
              </span>
              <UncontrolledTooltip target="endTime">
                <Translate contentKey="nissanApp.executionHotLead.help.endTime" />
              </UncontrolledTooltip>
            </dt>
            <dd>
              <TextFormat value={executionHotLeadEntity.endTime} type="date" format={APP_DATE_FORMAT} />
            </dd>
            <dt>
              <span id="startTime">
                <Translate contentKey="nissanApp.executionHotLead.startTime">Start Time</Translate>
              </span>
              <UncontrolledTooltip target="startTime">
                <Translate contentKey="nissanApp.executionHotLead.help.startTime" />
              </UncontrolledTooltip>
            </dt>
            <dd>
              <TextFormat value={executionHotLeadEntity.startTime} type="date" format={APP_DATE_FORMAT} />
            </dd>
            <dt>
              <span id="syncTime">
                <Translate contentKey="nissanApp.executionHotLead.syncTime">Sync Time</Translate>
              </span>
              <UncontrolledTooltip target="syncTime">
                <Translate contentKey="nissanApp.executionHotLead.help.syncTime" />
              </UncontrolledTooltip>
            </dt>
            <dd>
              <TextFormat value={executionHotLeadEntity.syncTime} type="date" format={APP_DATE_FORMAT} />
            </dd>
            <dt>
              <Translate contentKey="nissanApp.executionHotLead.accountInfo">Account Info</Translate>
            </dt>
            <dd>{executionHotLeadEntity.accountInfoId ? executionHotLeadEntity.accountInfoId : ''}</dd>
          </dl>
          <Button tag={Link} to="/entity/execution-hot-lead" replace color="info">
            <FontAwesomeIcon icon="arrow-left" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.back">Back</Translate>
            </span>
          </Button>
          &nbsp;
          <Button tag={Link} to={`/entity/execution-hot-lead/${executionHotLeadEntity.id}/edit`} replace color="primary">
            <FontAwesomeIcon icon="pencil-alt" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.edit">Edit</Translate>
            </span>
          </Button>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = ({ executionHotLead }: IRootState) => ({
  executionHotLeadEntity: executionHotLead.entity
});

const mapDispatchToProps = { getEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ExecutionHotLeadDetail);
