import axios from 'axios';
import {
  ICrudSearchAction,
  parseHeaderForLinks,
  loadMoreDataWhenScrolled,
  ICrudGetAction,
  ICrudGetAllAction,
  ICrudPutAction,
  ICrudDeleteAction
} from 'react-jhipster';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

import { INegotiationResponse, defaultValue } from 'app/shared/model/negotiation-response.model';

export const ACTION_TYPES = {
  SEARCH_NEGOTIATIONRESPONSES: 'negotiationResponse/SEARCH_NEGOTIATIONRESPONSES',
  FETCH_NEGOTIATIONRESPONSE_LIST: 'negotiationResponse/FETCH_NEGOTIATIONRESPONSE_LIST',
  FETCH_NEGOTIATIONRESPONSE: 'negotiationResponse/FETCH_NEGOTIATIONRESPONSE',
  CREATE_NEGOTIATIONRESPONSE: 'negotiationResponse/CREATE_NEGOTIATIONRESPONSE',
  UPDATE_NEGOTIATIONRESPONSE: 'negotiationResponse/UPDATE_NEGOTIATIONRESPONSE',
  DELETE_NEGOTIATIONRESPONSE: 'negotiationResponse/DELETE_NEGOTIATIONRESPONSE',
  RESET: 'negotiationResponse/RESET'
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<INegotiationResponse>,
  entity: defaultValue,
  links: { next: 0 },
  updating: false,
  totalItems: 0,
  updateSuccess: false
};

export type NegotiationResponseState = Readonly<typeof initialState>;

// Reducer

export default (state: NegotiationResponseState = initialState, action): NegotiationResponseState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.SEARCH_NEGOTIATIONRESPONSES):
    case REQUEST(ACTION_TYPES.FETCH_NEGOTIATIONRESPONSE_LIST):
    case REQUEST(ACTION_TYPES.FETCH_NEGOTIATIONRESPONSE):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true
      };
    case REQUEST(ACTION_TYPES.CREATE_NEGOTIATIONRESPONSE):
    case REQUEST(ACTION_TYPES.UPDATE_NEGOTIATIONRESPONSE):
    case REQUEST(ACTION_TYPES.DELETE_NEGOTIATIONRESPONSE):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true
      };
    case FAILURE(ACTION_TYPES.SEARCH_NEGOTIATIONRESPONSES):
    case FAILURE(ACTION_TYPES.FETCH_NEGOTIATIONRESPONSE_LIST):
    case FAILURE(ACTION_TYPES.FETCH_NEGOTIATIONRESPONSE):
    case FAILURE(ACTION_TYPES.CREATE_NEGOTIATIONRESPONSE):
    case FAILURE(ACTION_TYPES.UPDATE_NEGOTIATIONRESPONSE):
    case FAILURE(ACTION_TYPES.DELETE_NEGOTIATIONRESPONSE):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload
      };
    case SUCCESS(ACTION_TYPES.SEARCH_NEGOTIATIONRESPONSES):
    case SUCCESS(ACTION_TYPES.FETCH_NEGOTIATIONRESPONSE_LIST):
      const links = parseHeaderForLinks(action.payload.headers.link);
      return {
        ...state,
        links,
        loading: false,
        totalItems: action.payload.headers['x-total-count'],
        entities: loadMoreDataWhenScrolled(state.entities, action.payload.data, links)
      };
    case SUCCESS(ACTION_TYPES.FETCH_NEGOTIATIONRESPONSE):
      return {
        ...state,
        loading: false,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.CREATE_NEGOTIATIONRESPONSE):
    case SUCCESS(ACTION_TYPES.UPDATE_NEGOTIATIONRESPONSE):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.DELETE_NEGOTIATIONRESPONSE):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {}
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

const apiUrl = 'api/negotiation-responses';
const apiSearchUrl = 'api/_search/negotiation-responses';

// Actions

export const getSearchEntities: ICrudSearchAction<INegotiationResponse> = (query, page, size, sort) => ({
  type: ACTION_TYPES.SEARCH_NEGOTIATIONRESPONSES,
  payload: axios.get<INegotiationResponse>(`${apiSearchUrl}?query=${query}${sort ? `&page=${page}&size=${size}&sort=${sort}` : ''}`)
});

export const getEntities: ICrudGetAllAction<INegotiationResponse> = (page, size, sort) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.FETCH_NEGOTIATIONRESPONSE_LIST,
    payload: axios.get<INegotiationResponse>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`)
  };
};

export const getEntity: ICrudGetAction<INegotiationResponse> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_NEGOTIATIONRESPONSE,
    payload: axios.get<INegotiationResponse>(requestUrl)
  };
};

export const createEntity: ICrudPutAction<INegotiationResponse> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_NEGOTIATIONRESPONSE,
    payload: axios.post(apiUrl, cleanEntity(entity))
  });
  return result;
};

export const updateEntity: ICrudPutAction<INegotiationResponse> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_NEGOTIATIONRESPONSE,
    payload: axios.put(apiUrl, cleanEntity(entity))
  });
  return result;
};

export const deleteEntity: ICrudDeleteAction<INegotiationResponse> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_NEGOTIATIONRESPONSE,
    payload: axios.delete(requestUrl)
  });
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET
});
