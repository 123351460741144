import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import AttendantLeadStatusCode from './attendant-lead-status-code';
import AttendantLeadStatusCodeDetail from './attendant-lead-status-code-detail';
import AttendantLeadStatusCodeUpdate from './attendant-lead-status-code-update';
import AttendantLeadStatusCodeDeleteDialog from './attendant-lead-status-code-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={AttendantLeadStatusCodeUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={AttendantLeadStatusCodeUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={AttendantLeadStatusCodeDetail} />
      <ErrorBoundaryRoute path={match.url} component={AttendantLeadStatusCode} />
    </Switch>
    <ErrorBoundaryRoute path={`${match.url}/:id/delete`} component={AttendantLeadStatusCodeDeleteDialog} />
  </>
);

export default Routes;
