import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col, Label, UncontrolledTooltip } from 'reactstrap';
import { AvFeedback, AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
// tslint:disable-next-line:no-unused-variable
import { Translate, translate, ICrudGetAction, ICrudGetAllAction, ICrudPutAction } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';

import { IAttendantLeadStatusCode } from 'app/shared/model/attendant-lead-status-code.model';
import { getEntities as getAttendantLeadStatusCodes } from 'app/entities/attendant-lead-status-code/attendant-lead-status-code.reducer';
import { IAttendantLeadSync } from 'app/shared/model/attendant-lead-sync.model';
import { getEntities as getAttendantLeadSyncs } from 'app/entities/attendant-lead-sync/attendant-lead-sync.reducer';
import { getEntity, updateEntity, createEntity, reset } from './attendant-lead-response.reducer';
import { IAttendantLeadResponse } from 'app/shared/model/attendant-lead-response.model';
// tslint:disable-next-line:no-unused-variable
import { convertDateTimeFromServer, convertDateTimeToServer } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';

export interface IAttendantLeadResponseUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export interface IAttendantLeadResponseUpdateState {
  isNew: boolean;
  statusCodeId: string;
  attendantLeadSyncId: string;
}

export class AttendantLeadResponseUpdate extends React.Component<IAttendantLeadResponseUpdateProps, IAttendantLeadResponseUpdateState> {
  constructor(props) {
    super(props);
    this.state = {
      statusCodeId: '0',
      attendantLeadSyncId: '0',
      isNew: !this.props.match.params || !this.props.match.params.id
    };
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextProps.updateSuccess !== this.props.updateSuccess && nextProps.updateSuccess) {
      this.handleClose();
    }
  }

  componentDidMount() {
    if (!this.state.isNew) {
      this.props.getEntity(this.props.match.params.id);
    }

    this.props.getAttendantLeadStatusCodes();
    this.props.getAttendantLeadSyncs();
  }

  saveEntity = (event, errors, values) => {
    values.receivedIn = convertDateTimeToServer(values.receivedIn);
    values.synchronizedIn = convertDateTimeToServer(values.synchronizedIn);

    if (errors.length === 0) {
      const { attendantLeadResponseEntity } = this.props;
      const entity = {
        ...attendantLeadResponseEntity,
        ...values
      };

      if (this.state.isNew) {
        this.props.createEntity(entity);
      } else {
        this.props.updateEntity(entity);
      }
    }
  };

  handleClose = () => {
    this.props.history.push('/entity/attendant-lead-response');
  };

  render() {
    const { attendantLeadResponseEntity, attendantLeadStatusCodes, attendantLeadSyncs, loading, updating } = this.props;
    const { isNew } = this.state;

    return (
      <div>
        <Row className="justify-content-center">
          <Col md="8">
            <h2 id="nissanApp.attendantLeadResponse.home.createOrEditLabel">
              <Translate contentKey="nissanApp.attendantLeadResponse.home.createOrEditLabel">
                Create or edit a AttendantLeadResponse
              </Translate>
            </h2>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md="8">
            {loading ? (
              <p>Loading...</p>
            ) : (
              <AvForm model={isNew ? {} : attendantLeadResponseEntity} onSubmit={this.saveEntity}>
                {!isNew ? (
                  <AvGroup>
                    <Label for="attendant-lead-response-id">
                      <Translate contentKey="global.field.id">ID</Translate>
                    </Label>
                    <AvInput id="attendant-lead-response-id" type="text" className="form-control" name="id" required readOnly />
                  </AvGroup>
                ) : null}
                <AvGroup>
                  <Label id="receivedInLabel" for="attendant-lead-response-receivedIn">
                    <Translate contentKey="nissanApp.attendantLeadResponse.receivedIn">Received In</Translate>
                  </Label>
                  <AvInput
                    id="attendant-lead-response-receivedIn"
                    type="datetime-local"
                    className="form-control"
                    name="receivedIn"
                    placeholder={'YYYY-MM-DD HH:mm'}
                    value={isNew ? null : convertDateTimeFromServer(this.props.attendantLeadResponseEntity.receivedIn)}
                  />
                  <UncontrolledTooltip target="receivedInLabel">
                    <Translate contentKey="nissanApp.attendantLeadResponse.help.receivedIn" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="synchronizedInLabel" for="attendant-lead-response-synchronizedIn">
                    <Translate contentKey="nissanApp.attendantLeadResponse.synchronizedIn">Synchronized In</Translate>
                  </Label>
                  <AvInput
                    id="attendant-lead-response-synchronizedIn"
                    type="datetime-local"
                    className="form-control"
                    name="synchronizedIn"
                    placeholder={'YYYY-MM-DD HH:mm'}
                    value={isNew ? null : convertDateTimeFromServer(this.props.attendantLeadResponseEntity.synchronizedIn)}
                  />
                  <UncontrolledTooltip target="synchronizedInLabel">
                    <Translate contentKey="nissanApp.attendantLeadResponse.help.synchronizedIn" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label for="attendant-lead-response-statusCode">
                    <Translate contentKey="nissanApp.attendantLeadResponse.statusCode">Status Code</Translate>
                  </Label>
                  <AvInput id="attendant-lead-response-statusCode" type="select" className="form-control" name="statusCodeId">
                    <option value="" key="0" />
                    {attendantLeadStatusCodes
                      ? attendantLeadStatusCodes.map(otherEntity => (
                          <option value={otherEntity.id} key={otherEntity.id}>
                            {otherEntity.id}
                          </option>
                        ))
                      : null}
                  </AvInput>
                </AvGroup>
                <AvGroup>
                  <Label for="attendant-lead-response-attendantLeadSync">
                    <Translate contentKey="nissanApp.attendantLeadResponse.attendantLeadSync">Attendant Lead Sync</Translate>
                  </Label>
                  <AvInput id="attendant-lead-response-attendantLeadSync" type="select" className="form-control" name="attendantLeadSyncId">
                    <option value="" key="0" />
                    {attendantLeadSyncs
                      ? attendantLeadSyncs.map(otherEntity => (
                          <option value={otherEntity.id} key={otherEntity.id}>
                            {otherEntity.id}
                          </option>
                        ))
                      : null}
                  </AvInput>
                </AvGroup>
                <Button tag={Link} id="cancel-save" to="/entity/attendant-lead-response" replace color="info">
                  <FontAwesomeIcon icon="arrow-left" />
                  &nbsp;
                  <span className="d-none d-md-inline">
                    <Translate contentKey="entity.action.back">Back</Translate>
                  </span>
                </Button>
                &nbsp;
                <Button color="primary" id="save-entity" type="submit" disabled={updating}>
                  <FontAwesomeIcon icon="save" />
                  &nbsp;
                  <Translate contentKey="entity.action.save">Save</Translate>
                </Button>
              </AvForm>
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (storeState: IRootState) => ({
  attendantLeadStatusCodes: storeState.attendantLeadStatusCode.entities,
  attendantLeadSyncs: storeState.attendantLeadSync.entities,
  attendantLeadResponseEntity: storeState.attendantLeadResponse.entity,
  loading: storeState.attendantLeadResponse.loading,
  updating: storeState.attendantLeadResponse.updating,
  updateSuccess: storeState.attendantLeadResponse.updateSuccess
});

const mapDispatchToProps = {
  getAttendantLeadStatusCodes,
  getAttendantLeadSyncs,
  getEntity,
  updateEntity,
  createEntity,
  reset
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AttendantLeadResponseUpdate);
