import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import ClientApprovedProposal from './client-approved-proposal';
import ClientApprovedProposalDetail from './client-approved-proposal-detail';
import ClientApprovedProposalUpdate from './client-approved-proposal-update';
import ClientApprovedProposalDeleteDialog from './client-approved-proposal-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={ClientApprovedProposalUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={ClientApprovedProposalUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={ClientApprovedProposalDetail} />
      <ErrorBoundaryRoute path={match.url} component={ClientApprovedProposal} />
    </Switch>
    <ErrorBoundaryRoute path={`${match.url}/:id/delete`} component={ClientApprovedProposalDeleteDialog} />
  </>
);

export default Routes;
