export interface IFollowUpLeadMessage {
  id?: number;
  message?: string;
  messageCode?: number;
  registryNumber?: number;
  returnType?: string;
  followUpResponseId?: number;
}

export const defaultValue: Readonly<IFollowUpLeadMessage> = {};
