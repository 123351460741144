import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import TestDriveSync from './test-drive-sync';
import TestDriveSyncDetail from './test-drive-sync-detail';
import TestDriveSyncUpdate from './test-drive-sync-update';
import TestDriveSyncDeleteDialog from './test-drive-sync-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={TestDriveSyncUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={TestDriveSyncUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={TestDriveSyncDetail} />
      <ErrorBoundaryRoute path={match.url} component={TestDriveSync} />
    </Switch>
    <ErrorBoundaryRoute path={`${match.url}/:id/delete`} component={TestDriveSyncDeleteDialog} />
  </>
);

export default Routes;
