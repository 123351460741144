import axios from 'axios';
import {
  ICrudSearchAction,
  parseHeaderForLinks,
  loadMoreDataWhenScrolled,
  ICrudGetAction,
  ICrudGetAllAction,
  ICrudPutAction,
  ICrudDeleteAction
} from 'react-jhipster';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

import { ISyonetSchedulingLead, defaultValue } from 'app/shared/model/syonet-scheduling-lead.model';

export const ACTION_TYPES = {
  SEARCH_SYONETSCHEDULINGLEADS: 'syonetSchedulingLead/SEARCH_SYONETSCHEDULINGLEADS',
  FETCH_SYONETSCHEDULINGLEAD_LIST: 'syonetSchedulingLead/FETCH_SYONETSCHEDULINGLEAD_LIST',
  FETCH_SYONETSCHEDULINGLEAD: 'syonetSchedulingLead/FETCH_SYONETSCHEDULINGLEAD',
  CREATE_SYONETSCHEDULINGLEAD: 'syonetSchedulingLead/CREATE_SYONETSCHEDULINGLEAD',
  UPDATE_SYONETSCHEDULINGLEAD: 'syonetSchedulingLead/UPDATE_SYONETSCHEDULINGLEAD',
  DELETE_SYONETSCHEDULINGLEAD: 'syonetSchedulingLead/DELETE_SYONETSCHEDULINGLEAD',
  RESET: 'syonetSchedulingLead/RESET'
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<ISyonetSchedulingLead>,
  entity: defaultValue,
  links: { next: 0 },
  updating: false,
  totalItems: 0,
  updateSuccess: false
};

export type SyonetSchedulingLeadState = Readonly<typeof initialState>;

// Reducer

export default (state: SyonetSchedulingLeadState = initialState, action): SyonetSchedulingLeadState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.SEARCH_SYONETSCHEDULINGLEADS):
    case REQUEST(ACTION_TYPES.FETCH_SYONETSCHEDULINGLEAD_LIST):
    case REQUEST(ACTION_TYPES.FETCH_SYONETSCHEDULINGLEAD):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true
      };
    case REQUEST(ACTION_TYPES.CREATE_SYONETSCHEDULINGLEAD):
    case REQUEST(ACTION_TYPES.UPDATE_SYONETSCHEDULINGLEAD):
    case REQUEST(ACTION_TYPES.DELETE_SYONETSCHEDULINGLEAD):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true
      };
    case FAILURE(ACTION_TYPES.SEARCH_SYONETSCHEDULINGLEADS):
    case FAILURE(ACTION_TYPES.FETCH_SYONETSCHEDULINGLEAD_LIST):
    case FAILURE(ACTION_TYPES.FETCH_SYONETSCHEDULINGLEAD):
    case FAILURE(ACTION_TYPES.CREATE_SYONETSCHEDULINGLEAD):
    case FAILURE(ACTION_TYPES.UPDATE_SYONETSCHEDULINGLEAD):
    case FAILURE(ACTION_TYPES.DELETE_SYONETSCHEDULINGLEAD):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload
      };
    case SUCCESS(ACTION_TYPES.SEARCH_SYONETSCHEDULINGLEADS):
    case SUCCESS(ACTION_TYPES.FETCH_SYONETSCHEDULINGLEAD_LIST):
      const links = parseHeaderForLinks(action.payload.headers.link);
      return {
        ...state,
        links,
        loading: false,
        totalItems: action.payload.headers['x-total-count'],
        entities: loadMoreDataWhenScrolled(state.entities, action.payload.data, links)
      };
    case SUCCESS(ACTION_TYPES.FETCH_SYONETSCHEDULINGLEAD):
      return {
        ...state,
        loading: false,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.CREATE_SYONETSCHEDULINGLEAD):
    case SUCCESS(ACTION_TYPES.UPDATE_SYONETSCHEDULINGLEAD):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.DELETE_SYONETSCHEDULINGLEAD):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {}
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

const apiUrl = 'api/syonet-scheduling-leads';
const apiSearchUrl = 'api/_search/syonet-scheduling-leads';

// Actions

export const getSearchEntities: ICrudSearchAction<ISyonetSchedulingLead> = (query, page, size, sort) => ({
  type: ACTION_TYPES.SEARCH_SYONETSCHEDULINGLEADS,
  payload: axios.get<ISyonetSchedulingLead>(`${apiSearchUrl}?query=${query}${sort ? `&page=${page}&size=${size}&sort=${sort}` : ''}`)
});

export const getEntities: ICrudGetAllAction<ISyonetSchedulingLead> = (page, size, sort) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.FETCH_SYONETSCHEDULINGLEAD_LIST,
    payload: axios.get<ISyonetSchedulingLead>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`)
  };
};

export const getEntity: ICrudGetAction<ISyonetSchedulingLead> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_SYONETSCHEDULINGLEAD,
    payload: axios.get<ISyonetSchedulingLead>(requestUrl)
  };
};

export const createEntity: ICrudPutAction<ISyonetSchedulingLead> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_SYONETSCHEDULINGLEAD,
    payload: axios.post(apiUrl, cleanEntity(entity))
  });
  return result;
};

export const updateEntity: ICrudPutAction<ISyonetSchedulingLead> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_SYONETSCHEDULINGLEAD,
    payload: axios.put(apiUrl, cleanEntity(entity))
  });
  return result;
};

export const deleteEntity: ICrudDeleteAction<ISyonetSchedulingLead> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_SYONETSCHEDULINGLEAD,
    payload: axios.delete(requestUrl)
  });
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET
});
