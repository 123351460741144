export interface IApprovedProposalMessage {
  id?: number;
  message?: string;
  messageCode?: number;
  registryNumber?: number;
  returnType?: string;
  approvedProposalResponseId?: number;
}

export const defaultValue: Readonly<IApprovedProposalMessage> = {};
