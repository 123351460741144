import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col, Label, UncontrolledTooltip } from 'reactstrap';
import { AvFeedback, AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
// tslint:disable-next-line:no-unused-variable
import { Translate, translate, ICrudGetAction, ICrudGetAllAction, ICrudPutAction } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';

import { IApprovedProposalSync } from 'app/shared/model/approved-proposal-sync.model';
import { getEntities as getApprovedProposalSyncs } from 'app/entities/approved-proposal-sync/approved-proposal-sync.reducer';
import { IApprovedProposalStatusCode } from 'app/shared/model/approved-proposal-status-code.model';
import { getEntities as getApprovedProposalStatusCodes } from 'app/entities/approved-proposal-status-code/approved-proposal-status-code.reducer';
import { getEntity, updateEntity, createEntity, reset } from './approved-proposal-response.reducer';
import { IApprovedProposalResponse } from 'app/shared/model/approved-proposal-response.model';
// tslint:disable-next-line:no-unused-variable
import { convertDateTimeFromServer, convertDateTimeToServer } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';

export interface IApprovedProposalResponseUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export interface IApprovedProposalResponseUpdateState {
  isNew: boolean;
  approvedProposalSyncId: string;
  statusCodeId: string;
}

export class ApprovedProposalResponseUpdate extends React.Component<
  IApprovedProposalResponseUpdateProps,
  IApprovedProposalResponseUpdateState
> {
  constructor(props) {
    super(props);
    this.state = {
      approvedProposalSyncId: '0',
      statusCodeId: '0',
      isNew: !this.props.match.params || !this.props.match.params.id
    };
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextProps.updateSuccess !== this.props.updateSuccess && nextProps.updateSuccess) {
      this.handleClose();
    }
  }

  componentDidMount() {
    if (!this.state.isNew) {
      this.props.getEntity(this.props.match.params.id);
    }

    this.props.getApprovedProposalSyncs();
    this.props.getApprovedProposalStatusCodes();
  }

  saveEntity = (event, errors, values) => {
    values.receivedIn = convertDateTimeToServer(values.receivedIn);
    values.synchronizedIn = convertDateTimeToServer(values.synchronizedIn);

    if (errors.length === 0) {
      const { approvedProposalResponseEntity } = this.props;
      const entity = {
        ...approvedProposalResponseEntity,
        ...values
      };

      if (this.state.isNew) {
        this.props.createEntity(entity);
      } else {
        this.props.updateEntity(entity);
      }
    }
  };

  handleClose = () => {
    this.props.history.push('/entity/approved-proposal-response');
  };

  render() {
    const { approvedProposalResponseEntity, approvedProposalSyncs, approvedProposalStatusCodes, loading, updating } = this.props;
    const { isNew } = this.state;

    return (
      <div>
        <Row className="justify-content-center">
          <Col md="8">
            <h2 id="nissanApp.approvedProposalResponse.home.createOrEditLabel">
              <Translate contentKey="nissanApp.approvedProposalResponse.home.createOrEditLabel">
                Create or edit a ApprovedProposalResponse
              </Translate>
            </h2>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md="8">
            {loading ? (
              <p>Loading...</p>
            ) : (
              <AvForm model={isNew ? {} : approvedProposalResponseEntity} onSubmit={this.saveEntity}>
                {!isNew ? (
                  <AvGroup>
                    <Label for="approved-proposal-response-id">
                      <Translate contentKey="global.field.id">ID</Translate>
                    </Label>
                    <AvInput id="approved-proposal-response-id" type="text" className="form-control" name="id" required readOnly />
                  </AvGroup>
                ) : null}
                <AvGroup>
                  <Label id="receivedInLabel" for="approved-proposal-response-receivedIn">
                    <Translate contentKey="nissanApp.approvedProposalResponse.receivedIn">Received In</Translate>
                  </Label>
                  <AvInput
                    id="approved-proposal-response-receivedIn"
                    type="datetime-local"
                    className="form-control"
                    name="receivedIn"
                    placeholder={'YYYY-MM-DD HH:mm'}
                    value={isNew ? null : convertDateTimeFromServer(this.props.approvedProposalResponseEntity.receivedIn)}
                  />
                  <UncontrolledTooltip target="receivedInLabel">
                    <Translate contentKey="nissanApp.approvedProposalResponse.help.receivedIn" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="synchronizedInLabel" for="approved-proposal-response-synchronizedIn">
                    <Translate contentKey="nissanApp.approvedProposalResponse.synchronizedIn">Synchronized In</Translate>
                  </Label>
                  <AvInput
                    id="approved-proposal-response-synchronizedIn"
                    type="datetime-local"
                    className="form-control"
                    name="synchronizedIn"
                    placeholder={'YYYY-MM-DD HH:mm'}
                    value={isNew ? null : convertDateTimeFromServer(this.props.approvedProposalResponseEntity.synchronizedIn)}
                  />
                  <UncontrolledTooltip target="synchronizedInLabel">
                    <Translate contentKey="nissanApp.approvedProposalResponse.help.synchronizedIn" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label for="approved-proposal-response-approvedProposalSync">
                    <Translate contentKey="nissanApp.approvedProposalResponse.approvedProposalSync">Approved Proposal Sync</Translate>
                  </Label>
                  <AvInput
                    id="approved-proposal-response-approvedProposalSync"
                    type="select"
                    className="form-control"
                    name="approvedProposalSyncId"
                  >
                    <option value="" key="0" />
                    {approvedProposalSyncs
                      ? approvedProposalSyncs.map(otherEntity => (
                          <option value={otherEntity.id} key={otherEntity.id}>
                            {otherEntity.id}
                          </option>
                        ))
                      : null}
                  </AvInput>
                </AvGroup>
                <AvGroup>
                  <Label for="approved-proposal-response-statusCode">
                    <Translate contentKey="nissanApp.approvedProposalResponse.statusCode">Status Code</Translate>
                  </Label>
                  <AvInput id="approved-proposal-response-statusCode" type="select" className="form-control" name="statusCodeId">
                    <option value="" key="0" />
                    {approvedProposalStatusCodes
                      ? approvedProposalStatusCodes.map(otherEntity => (
                          <option value={otherEntity.id} key={otherEntity.id}>
                            {otherEntity.id}
                          </option>
                        ))
                      : null}
                  </AvInput>
                </AvGroup>
                <Button tag={Link} id="cancel-save" to="/entity/approved-proposal-response" replace color="info">
                  <FontAwesomeIcon icon="arrow-left" />
                  &nbsp;
                  <span className="d-none d-md-inline">
                    <Translate contentKey="entity.action.back">Back</Translate>
                  </span>
                </Button>
                &nbsp;
                <Button color="primary" id="save-entity" type="submit" disabled={updating}>
                  <FontAwesomeIcon icon="save" />
                  &nbsp;
                  <Translate contentKey="entity.action.save">Save</Translate>
                </Button>
              </AvForm>
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (storeState: IRootState) => ({
  approvedProposalSyncs: storeState.approvedProposalSync.entities,
  approvedProposalStatusCodes: storeState.approvedProposalStatusCode.entities,
  approvedProposalResponseEntity: storeState.approvedProposalResponse.entity,
  loading: storeState.approvedProposalResponse.loading,
  updating: storeState.approvedProposalResponse.updating,
  updateSuccess: storeState.approvedProposalResponse.updateSuccess
});

const mapDispatchToProps = {
  getApprovedProposalSyncs,
  getApprovedProposalStatusCodes,
  getEntity,
  updateEntity,
  createEntity,
  reset
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ApprovedProposalResponseUpdate);
