import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import SchedulingLeadStatusCode from './scheduling-lead-status-code';
import SchedulingLeadStatusCodeDetail from './scheduling-lead-status-code-detail';
import SchedulingLeadStatusCodeUpdate from './scheduling-lead-status-code-update';
import SchedulingLeadStatusCodeDeleteDialog from './scheduling-lead-status-code-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={SchedulingLeadStatusCodeUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={SchedulingLeadStatusCodeUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={SchedulingLeadStatusCodeDetail} />
      <ErrorBoundaryRoute path={match.url} component={SchedulingLeadStatusCode} />
    </Switch>
    <ErrorBoundaryRoute path={`${match.url}/:id/delete`} component={SchedulingLeadStatusCodeDeleteDialog} />
  </>
);

export default Routes;
