export interface ITestDriveMessage {
  id?: number;
  message?: string;
  messageCode?: number;
  registryNumber?: number;
  returnType?: string;
  testDriveResponseId?: number;
}

export const defaultValue: Readonly<ITestDriveMessage> = {};
