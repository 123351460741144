import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, UncontrolledTooltip, Row, Col } from 'reactstrap';
// tslint:disable-next-line:no-unused-variable
import { Translate, ICrudGetAction, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntity } from './syonet-scheduling-lead.reducer';
import { ISyonetSchedulingLead } from 'app/shared/model/syonet-scheduling-lead.model';
// tslint:disable-next-line:no-unused-variable
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';

export interface ISyonetSchedulingLeadDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export class SyonetSchedulingLeadDetail extends React.Component<ISyonetSchedulingLeadDetailProps> {
  componentDidMount() {
    this.props.getEntity(this.props.match.params.id);
  }

  render() {
    const { syonetSchedulingLeadEntity } = this.props;
    return (
      <Row>
        <Col md="8">
          <h2>
            <Translate contentKey="nissanApp.syonetSchedulingLead.detail.title">SyonetSchedulingLead</Translate> [
            <b>{syonetSchedulingLeadEntity.id}</b>]
          </h2>
          <dl className="jh-entity-details">
            <dt>
              <span id="lastSync">
                <Translate contentKey="nissanApp.syonetSchedulingLead.lastSync">Last Sync</Translate>
              </span>
              <UncontrolledTooltip target="lastSync">
                <Translate contentKey="nissanApp.syonetSchedulingLead.help.lastSync" />
              </UncontrolledTooltip>
            </dt>
            <dd>
              <TextFormat value={syonetSchedulingLeadEntity.lastSync} type="date" format={APP_DATE_FORMAT} />
            </dd>
            <dt>
              <span id="crmID">
                <Translate contentKey="nissanApp.syonetSchedulingLead.crmID">Crm ID</Translate>
              </span>
              <UncontrolledTooltip target="crmID">
                <Translate contentKey="nissanApp.syonetSchedulingLead.help.crmID" />
              </UncontrolledTooltip>
            </dt>
            <dd>{syonetSchedulingLeadEntity.crmID}</dd>
            <dt>
              <span id="extracionDate">
                <Translate contentKey="nissanApp.syonetSchedulingLead.extracionDate">Extracion Date</Translate>
              </span>
              <UncontrolledTooltip target="extracionDate">
                <Translate contentKey="nissanApp.syonetSchedulingLead.help.extracionDate" />
              </UncontrolledTooltip>
            </dt>
            <dd>
              <TextFormat value={syonetSchedulingLeadEntity.extracionDate} type="date" format={APP_DATE_FORMAT} />
            </dd>
            <dt>
              <span id="attendanceID">
                <Translate contentKey="nissanApp.syonetSchedulingLead.attendanceID">Attendance ID</Translate>
              </span>
              <UncontrolledTooltip target="attendanceID">
                <Translate contentKey="nissanApp.syonetSchedulingLead.help.attendanceID" />
              </UncontrolledTooltip>
            </dt>
            <dd>{syonetSchedulingLeadEntity.attendanceID}</dd>
            <dt>
              <span id="nissanLeadID">
                <Translate contentKey="nissanApp.syonetSchedulingLead.nissanLeadID">Nissan Lead ID</Translate>
              </span>
              <UncontrolledTooltip target="nissanLeadID">
                <Translate contentKey="nissanApp.syonetSchedulingLead.help.nissanLeadID" />
              </UncontrolledTooltip>
            </dt>
            <dd>{syonetSchedulingLeadEntity.nissanLeadID}</dd>
            <dt>
              <span id="contactDate">
                <Translate contentKey="nissanApp.syonetSchedulingLead.contactDate">Contact Date</Translate>
              </span>
              <UncontrolledTooltip target="contactDate">
                <Translate contentKey="nissanApp.syonetSchedulingLead.help.contactDate" />
              </UncontrolledTooltip>
            </dt>
            <dd>
              <TextFormat value={syonetSchedulingLeadEntity.contactDate} type="date" format={APP_DATE_FORMAT} />
            </dd>
            <dt>
              <span id="comment">
                <Translate contentKey="nissanApp.syonetSchedulingLead.comment">Comment</Translate>
              </span>
              <UncontrolledTooltip target="comment">
                <Translate contentKey="nissanApp.syonetSchedulingLead.help.comment" />
              </UncontrolledTooltip>
            </dt>
            <dd>{syonetSchedulingLeadEntity.comment}</dd>
            <dt>
              <span id="scheduledDate">
                <Translate contentKey="nissanApp.syonetSchedulingLead.scheduledDate">Scheduled Date</Translate>
              </span>
              <UncontrolledTooltip target="scheduledDate">
                <Translate contentKey="nissanApp.syonetSchedulingLead.help.scheduledDate" />
              </UncontrolledTooltip>
            </dt>
            <dd>
              <TextFormat value={syonetSchedulingLeadEntity.scheduledDate} type="date" format={APP_DATE_FORMAT} />
            </dd>
            <dt>
              <span id="flagTestDrive">
                <Translate contentKey="nissanApp.syonetSchedulingLead.flagTestDrive">Flag Test Drive</Translate>
              </span>
              <UncontrolledTooltip target="flagTestDrive">
                <Translate contentKey="nissanApp.syonetSchedulingLead.help.flagTestDrive" />
              </UncontrolledTooltip>
            </dt>
            <dd>{syonetSchedulingLeadEntity.flagTestDrive ? 'true' : 'false'}</dd>
            <dt>
              <Translate contentKey="nissanApp.syonetSchedulingLead.syonetLead">Syonet Lead</Translate>
            </dt>
            <dd>{syonetSchedulingLeadEntity.syonetLeadId ? syonetSchedulingLeadEntity.syonetLeadId : ''}</dd>
          </dl>
          <Button tag={Link} to="/entity/syonet-scheduling-lead" replace color="info">
            <FontAwesomeIcon icon="arrow-left" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.back">Back</Translate>
            </span>
          </Button>
          &nbsp;
          <Button tag={Link} to={`/entity/syonet-scheduling-lead/${syonetSchedulingLeadEntity.id}/edit`} replace color="primary">
            <FontAwesomeIcon icon="pencil-alt" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.edit">Edit</Translate>
            </span>
          </Button>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = ({ syonetSchedulingLead }: IRootState) => ({
  syonetSchedulingLeadEntity: syonetSchedulingLead.entity
});

const mapDispatchToProps = { getEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SyonetSchedulingLeadDetail);
