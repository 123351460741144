import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, UncontrolledTooltip, Row, Col } from 'reactstrap';
// tslint:disable-next-line:no-unused-variable
import { Translate, ICrudGetAction } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntity } from './client-qualification-vehicle-change.reducer';
import { IClientQualificationVehicleChange } from 'app/shared/model/client-qualification-vehicle-change.model';
// tslint:disable-next-line:no-unused-variable
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';

export interface IClientQualificationVehicleChangeDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export class ClientQualificationVehicleChangeDetail extends React.Component<IClientQualificationVehicleChangeDetailProps> {
  componentDidMount() {
    this.props.getEntity(this.props.match.params.id);
  }

  render() {
    const { clientQualificationVehicleChangeEntity } = this.props;
    return (
      <Row>
        <Col md="8">
          <h2>
            <Translate contentKey="nissanApp.clientQualificationVehicleChange.detail.title">ClientQualificationVehicleChange</Translate> [
            <b>{clientQualificationVehicleChangeEntity.id}</b>]
          </h2>
          <dl className="jh-entity-details">
            <dt>
              <span id="vehicleCode">
                <Translate contentKey="nissanApp.clientQualificationVehicleChange.vehicleCode">Vehicle Code</Translate>
              </span>
              <UncontrolledTooltip target="vehicleCode">
                <Translate contentKey="nissanApp.clientQualificationVehicleChange.help.vehicleCode" />
              </UncontrolledTooltip>
            </dt>
            <dd>{clientQualificationVehicleChangeEntity.vehicleCode}</dd>
            <dt>
              <span id="vehicleNissan">
                <Translate contentKey="nissanApp.clientQualificationVehicleChange.vehicleNissan">Vehicle Nissan</Translate>
              </span>
              <UncontrolledTooltip target="vehicleNissan">
                <Translate contentKey="nissanApp.clientQualificationVehicleChange.help.vehicleNissan" />
              </UncontrolledTooltip>
            </dt>
            <dd>{clientQualificationVehicleChangeEntity.vehicleNissan}</dd>
            <dt>
              <span id="vehicleExchangeDescription">
                <Translate contentKey="nissanApp.clientQualificationVehicleChange.vehicleExchangeDescription">
                  Vehicle Exchange Description
                </Translate>
              </span>
              <UncontrolledTooltip target="vehicleExchangeDescription">
                <Translate contentKey="nissanApp.clientQualificationVehicleChange.help.vehicleExchangeDescription" />
              </UncontrolledTooltip>
            </dt>
            <dd>{clientQualificationVehicleChangeEntity.vehicleExchangeDescription}</dd>
            <dt>
              <span id="exchangeModelCode">
                <Translate contentKey="nissanApp.clientQualificationVehicleChange.exchangeModelCode">Exchange Model Code</Translate>
              </span>
              <UncontrolledTooltip target="exchangeModelCode">
                <Translate contentKey="nissanApp.clientQualificationVehicleChange.help.exchangeModelCode" />
              </UncontrolledTooltip>
            </dt>
            <dd>{clientQualificationVehicleChangeEntity.exchangeModelCode}</dd>
            <dt>
              <span id="interestedEimCode">
                <Translate contentKey="nissanApp.clientQualificationVehicleChange.interestedEimCode">Interested Eim Code</Translate>
              </span>
              <UncontrolledTooltip target="interestedEimCode">
                <Translate contentKey="nissanApp.clientQualificationVehicleChange.help.interestedEimCode" />
              </UncontrolledTooltip>
            </dt>
            <dd>{clientQualificationVehicleChangeEntity.interestedEimCode}</dd>
            <dt>
              <span id="negotiatedColorCode">
                <Translate contentKey="nissanApp.clientQualificationVehicleChange.negotiatedColorCode">Negotiated Color Code</Translate>
              </span>
              <UncontrolledTooltip target="negotiatedColorCode">
                <Translate contentKey="nissanApp.clientQualificationVehicleChange.help.negotiatedColorCode" />
              </UncontrolledTooltip>
            </dt>
            <dd>{clientQualificationVehicleChangeEntity.negotiatedColorCode}</dd>
            <dt>
              <span id="modelYearVehicleNegotiated">
                <Translate contentKey="nissanApp.clientQualificationVehicleChange.modelYearVehicleNegotiated">
                  Model Year Vehicle Negotiated
                </Translate>
              </span>
              <UncontrolledTooltip target="modelYearVehicleNegotiated">
                <Translate contentKey="nissanApp.clientQualificationVehicleChange.help.modelYearVehicleNegotiated" />
              </UncontrolledTooltip>
            </dt>
            <dd>{clientQualificationVehicleChangeEntity.modelYearVehicleNegotiated}</dd>
            <dt>
              <Translate contentKey="nissanApp.clientQualificationVehicleChange.syonetClientQualification">
                Syonet Client Qualification
              </Translate>
            </dt>
            <dd>
              {clientQualificationVehicleChangeEntity.syonetClientQualificationId
                ? clientQualificationVehicleChangeEntity.syonetClientQualificationId
                : ''}
            </dd>
          </dl>
          <Button tag={Link} to="/entity/client-qualification-vehicle-change" replace color="info">
            <FontAwesomeIcon icon="arrow-left" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.back">Back</Translate>
            </span>
          </Button>
          &nbsp;
          <Button
            tag={Link}
            to={`/entity/client-qualification-vehicle-change/${clientQualificationVehicleChangeEntity.id}/edit`}
            replace
            color="primary"
          >
            <FontAwesomeIcon icon="pencil-alt" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.edit">Edit</Translate>
            </span>
          </Button>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = ({ clientQualificationVehicleChange }: IRootState) => ({
  clientQualificationVehicleChangeEntity: clientQualificationVehicleChange.entity
});

const mapDispatchToProps = { getEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ClientQualificationVehicleChangeDetail);
