import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col, Label, UncontrolledTooltip } from 'reactstrap';
import { AvFeedback, AvForm, AvGroup, AvInput, AvField } from 'availity-reactstrap-validation';
// tslint:disable-next-line:no-unused-variable
import { Translate, translate, ICrudGetAction, ICrudGetAllAction, ICrudPutAction } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';

import { ISyonetLead } from 'app/shared/model/syonet-lead.model';
import { getEntities as getSyonetLeads } from 'app/entities/syonet-lead/syonet-lead.reducer';
import { getEntity, updateEntity, createEntity, reset } from './syonet-scheduling-lead.reducer';
import { ISyonetSchedulingLead } from 'app/shared/model/syonet-scheduling-lead.model';
// tslint:disable-next-line:no-unused-variable
import { convertDateTimeFromServer, convertDateTimeToServer } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';

export interface ISyonetSchedulingLeadUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export interface ISyonetSchedulingLeadUpdateState {
  isNew: boolean;
  syonetLeadId: string;
}

export class SyonetSchedulingLeadUpdate extends React.Component<ISyonetSchedulingLeadUpdateProps, ISyonetSchedulingLeadUpdateState> {
  constructor(props) {
    super(props);
    this.state = {
      syonetLeadId: '0',
      isNew: !this.props.match.params || !this.props.match.params.id
    };
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextProps.updateSuccess !== this.props.updateSuccess && nextProps.updateSuccess) {
      this.handleClose();
    }
  }

  componentDidMount() {
    if (!this.state.isNew) {
      this.props.getEntity(this.props.match.params.id);
    }

    this.props.getSyonetLeads();
  }

  saveEntity = (event, errors, values) => {
    values.lastSync = convertDateTimeToServer(values.lastSync);
    values.extracionDate = convertDateTimeToServer(values.extracionDate);
    values.contactDate = convertDateTimeToServer(values.contactDate);
    values.scheduledDate = convertDateTimeToServer(values.scheduledDate);

    if (errors.length === 0) {
      const { syonetSchedulingLeadEntity } = this.props;
      const entity = {
        ...syonetSchedulingLeadEntity,
        ...values
      };

      if (this.state.isNew) {
        this.props.createEntity(entity);
      } else {
        this.props.updateEntity(entity);
      }
    }
  };

  handleClose = () => {
    this.props.history.push('/entity/syonet-scheduling-lead');
  };

  render() {
    const { syonetSchedulingLeadEntity, syonetLeads, loading, updating } = this.props;
    const { isNew } = this.state;

    return (
      <div>
        <Row className="justify-content-center">
          <Col md="8">
            <h2 id="nissanApp.syonetSchedulingLead.home.createOrEditLabel">
              <Translate contentKey="nissanApp.syonetSchedulingLead.home.createOrEditLabel">
                Create or edit a SyonetSchedulingLead
              </Translate>
            </h2>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md="8">
            {loading ? (
              <p>Loading...</p>
            ) : (
              <AvForm model={isNew ? {} : syonetSchedulingLeadEntity} onSubmit={this.saveEntity}>
                {!isNew ? (
                  <AvGroup>
                    <Label for="syonet-scheduling-lead-id">
                      <Translate contentKey="global.field.id">ID</Translate>
                    </Label>
                    <AvInput id="syonet-scheduling-lead-id" type="text" className="form-control" name="id" required readOnly />
                  </AvGroup>
                ) : null}
                <AvGroup>
                  <Label id="lastSyncLabel" for="syonet-scheduling-lead-lastSync">
                    <Translate contentKey="nissanApp.syonetSchedulingLead.lastSync">Last Sync</Translate>
                  </Label>
                  <AvInput
                    id="syonet-scheduling-lead-lastSync"
                    type="datetime-local"
                    className="form-control"
                    name="lastSync"
                    placeholder={'YYYY-MM-DD HH:mm'}
                    value={isNew ? null : convertDateTimeFromServer(this.props.syonetSchedulingLeadEntity.lastSync)}
                  />
                  <UncontrolledTooltip target="lastSyncLabel">
                    <Translate contentKey="nissanApp.syonetSchedulingLead.help.lastSync" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="crmIDLabel" for="syonet-scheduling-lead-crmID">
                    <Translate contentKey="nissanApp.syonetSchedulingLead.crmID">Crm ID</Translate>
                  </Label>
                  <AvField id="syonet-scheduling-lead-crmID" type="text" name="crmID" />
                  <UncontrolledTooltip target="crmIDLabel">
                    <Translate contentKey="nissanApp.syonetSchedulingLead.help.crmID" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="extracionDateLabel" for="syonet-scheduling-lead-extracionDate">
                    <Translate contentKey="nissanApp.syonetSchedulingLead.extracionDate">Extracion Date</Translate>
                  </Label>
                  <AvInput
                    id="syonet-scheduling-lead-extracionDate"
                    type="datetime-local"
                    className="form-control"
                    name="extracionDate"
                    placeholder={'YYYY-MM-DD HH:mm'}
                    value={isNew ? null : convertDateTimeFromServer(this.props.syonetSchedulingLeadEntity.extracionDate)}
                    validate={{
                      required: { value: true, errorMessage: translate('entity.validation.required') }
                    }}
                  />
                  <UncontrolledTooltip target="extracionDateLabel">
                    <Translate contentKey="nissanApp.syonetSchedulingLead.help.extracionDate" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="attendanceIDLabel" for="syonet-scheduling-lead-attendanceID">
                    <Translate contentKey="nissanApp.syonetSchedulingLead.attendanceID">Attendance ID</Translate>
                  </Label>
                  <AvField
                    id="syonet-scheduling-lead-attendanceID"
                    type="string"
                    className="form-control"
                    name="attendanceID"
                    validate={{
                      required: { value: true, errorMessage: translate('entity.validation.required') },
                      number: { value: true, errorMessage: translate('entity.validation.number') }
                    }}
                  />
                  <UncontrolledTooltip target="attendanceIDLabel">
                    <Translate contentKey="nissanApp.syonetSchedulingLead.help.attendanceID" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="nissanLeadIDLabel" for="syonet-scheduling-lead-nissanLeadID">
                    <Translate contentKey="nissanApp.syonetSchedulingLead.nissanLeadID">Nissan Lead ID</Translate>
                  </Label>
                  <AvField id="syonet-scheduling-lead-nissanLeadID" type="string" className="form-control" name="nissanLeadID" />
                  <UncontrolledTooltip target="nissanLeadIDLabel">
                    <Translate contentKey="nissanApp.syonetSchedulingLead.help.nissanLeadID" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="contactDateLabel" for="syonet-scheduling-lead-contactDate">
                    <Translate contentKey="nissanApp.syonetSchedulingLead.contactDate">Contact Date</Translate>
                  </Label>
                  <AvInput
                    id="syonet-scheduling-lead-contactDate"
                    type="datetime-local"
                    className="form-control"
                    name="contactDate"
                    placeholder={'YYYY-MM-DD HH:mm'}
                    value={isNew ? null : convertDateTimeFromServer(this.props.syonetSchedulingLeadEntity.contactDate)}
                    validate={{
                      required: { value: true, errorMessage: translate('entity.validation.required') }
                    }}
                  />
                  <UncontrolledTooltip target="contactDateLabel">
                    <Translate contentKey="nissanApp.syonetSchedulingLead.help.contactDate" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="commentLabel" for="syonet-scheduling-lead-comment">
                    <Translate contentKey="nissanApp.syonetSchedulingLead.comment">Comment</Translate>
                  </Label>
                  <AvField id="syonet-scheduling-lead-comment" type="text" name="comment" />
                  <UncontrolledTooltip target="commentLabel">
                    <Translate contentKey="nissanApp.syonetSchedulingLead.help.comment" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="scheduledDateLabel" for="syonet-scheduling-lead-scheduledDate">
                    <Translate contentKey="nissanApp.syonetSchedulingLead.scheduledDate">Scheduled Date</Translate>
                  </Label>
                  <AvInput
                    id="syonet-scheduling-lead-scheduledDate"
                    type="datetime-local"
                    className="form-control"
                    name="scheduledDate"
                    placeholder={'YYYY-MM-DD HH:mm'}
                    value={isNew ? null : convertDateTimeFromServer(this.props.syonetSchedulingLeadEntity.scheduledDate)}
                  />
                  <UncontrolledTooltip target="scheduledDateLabel">
                    <Translate contentKey="nissanApp.syonetSchedulingLead.help.scheduledDate" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="flagTestDriveLabel" check>
                    <AvInput id="syonet-scheduling-lead-flagTestDrive" type="checkbox" className="form-control" name="flagTestDrive" />
                    <Translate contentKey="nissanApp.syonetSchedulingLead.flagTestDrive">Flag Test Drive</Translate>
                  </Label>
                  <UncontrolledTooltip target="flagTestDriveLabel">
                    <Translate contentKey="nissanApp.syonetSchedulingLead.help.flagTestDrive" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label for="syonet-scheduling-lead-syonetLead">
                    <Translate contentKey="nissanApp.syonetSchedulingLead.syonetLead">Syonet Lead</Translate>
                  </Label>
                  <AvInput id="syonet-scheduling-lead-syonetLead" type="select" className="form-control" name="syonetLeadId">
                    <option value="" key="0" />
                    {syonetLeads
                      ? syonetLeads.map(otherEntity => (
                          <option value={otherEntity.id} key={otherEntity.id}>
                            {otherEntity.id}
                          </option>
                        ))
                      : null}
                  </AvInput>
                </AvGroup>
                <Button tag={Link} id="cancel-save" to="/entity/syonet-scheduling-lead" replace color="info">
                  <FontAwesomeIcon icon="arrow-left" />
                  &nbsp;
                  <span className="d-none d-md-inline">
                    <Translate contentKey="entity.action.back">Back</Translate>
                  </span>
                </Button>
                &nbsp;
                <Button color="primary" id="save-entity" type="submit" disabled={updating}>
                  <FontAwesomeIcon icon="save" />
                  &nbsp;
                  <Translate contentKey="entity.action.save">Save</Translate>
                </Button>
              </AvForm>
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (storeState: IRootState) => ({
  syonetLeads: storeState.syonetLead.entities,
  syonetSchedulingLeadEntity: storeState.syonetSchedulingLead.entity,
  loading: storeState.syonetSchedulingLead.loading,
  updating: storeState.syonetSchedulingLead.updating,
  updateSuccess: storeState.syonetSchedulingLead.updateSuccess
});

const mapDispatchToProps = {
  getSyonetLeads,
  getEntity,
  updateEntity,
  createEntity,
  reset
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SyonetSchedulingLeadUpdate);
