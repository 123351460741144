import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, InputGroup, Col, Row, Table } from 'reactstrap';
import { AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
// tslint:disable-next-line:no-unused-variable
import { Translate, translate, ICrudSearchAction, ICrudGetAllAction, TextFormat, getSortState, IPaginationBaseState } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getSearchEntities, getEntities, reset } from './lead.reducer';
import { ILead } from 'app/shared/model/lead.model';
// tslint:disable-next-line:no-unused-variable
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { ITEMS_PER_PAGE } from 'app/shared/util/pagination.constants';

export interface ILeadProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {}

export interface ILeadState extends IPaginationBaseState {
  search: string;
}

export class Lead extends React.Component<ILeadProps, ILeadState> {
  state: ILeadState = {
    search: '',
    ...getSortState(this.props.location, ITEMS_PER_PAGE)
  };

  componentDidMount() {
    this.reset();
  }

  componentDidUpdate() {
    if (this.props.updateSuccess) {
      this.reset();
    }
  }

  search = () => {
    if (this.state.search) {
      this.props.reset();
      this.setState({ activePage: 1 }, () => {
        const { activePage, itemsPerPage, sort, order, search } = this.state;
        this.props.getSearchEntities(search, activePage - 1, itemsPerPage, `${sort},${order}`);
      });
    }
  };

  clear = () => {
    this.props.reset();
    this.setState({ search: '', activePage: 1 }, () => {
      this.props.getEntities();
    });
  };

  handleSearch = event => this.setState({ search: event.target.value });

  reset = () => {
    this.props.reset();
    this.setState({ activePage: 1 }, () => {
      this.getEntities();
    });
  };

  handleLoadMore = () => {
    if (window.pageYOffset > 0) {
      this.setState({ activePage: this.state.activePage + 1 }, () => this.getEntities());
    }
  };

  sort = prop => () => {
    this.setState(
      {
        order: this.state.order === 'asc' ? 'desc' : 'asc',
        sort: prop
      },
      () => {
        this.reset();
      }
    );
  };

  getEntities = () => {
    const { activePage, itemsPerPage, sort, order, search } = this.state;
    if (search) {
      this.props.getSearchEntities(search, activePage - 1, itemsPerPage, `${sort},${order}`);
    } else {
      this.props.getEntities(activePage - 1, itemsPerPage, `${sort},${order}`);
    }
  };

  render() {
    const { leadList, match } = this.props;
    return (
      <div>
        <h2 id="lead-heading">
          <Translate contentKey="nissanApp.lead.home.title">Leads</Translate>
          <Link to={`${match.url}/new`} className="btn btn-primary float-right jh-create-entity" id="jh-create-entity">
            <FontAwesomeIcon icon="plus" />
            &nbsp;
            <Translate contentKey="nissanApp.lead.home.createLabel">Create new Lead</Translate>
          </Link>
        </h2>
        <Row>
          <Col sm="12">
            <AvForm onSubmit={this.search}>
              <AvGroup>
                <InputGroup>
                  <AvInput
                    type="text"
                    name="search"
                    value={this.state.search}
                    onChange={this.handleSearch}
                    placeholder={translate('nissanApp.lead.home.search')}
                  />
                  <Button className="input-group-addon">
                    <FontAwesomeIcon icon="search" />
                  </Button>
                  <Button type="reset" className="input-group-addon" onClick={this.clear}>
                    <FontAwesomeIcon icon="trash" />
                  </Button>
                </InputGroup>
              </AvGroup>
            </AvForm>
          </Col>
        </Row>
        <div className="table-responsive">
          <InfiniteScroll
            pageStart={this.state.activePage}
            loadMore={this.handleLoadMore}
            hasMore={this.state.activePage - 1 < this.props.links.next}
            loader={<div className="loader">Loading ...</div>}
            threshold={0}
            initialLoad={false}
          >
            <Table responsive>
              <thead>
                <tr>
                  <th className="hand" onClick={this.sort('id')}>
                    <Translate contentKey="global.field.id">ID</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={this.sort('idLead')}>
                    <Translate contentKey="nissanApp.lead.idLead">Id Lead</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={this.sort('salesChannel')}>
                    <Translate contentKey="nissanApp.lead.salesChannel">Sales Channel</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={this.sort('saleCategory')}>
                    <Translate contentKey="nissanApp.lead.saleCategory">Sale Category</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={this.sort('mediaAttraction')}>
                    <Translate contentKey="nissanApp.lead.mediaAttraction">Media Attraction</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={this.sort('idCampaign')}>
                    <Translate contentKey="nissanApp.lead.idCampaign">Id Campaign</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={this.sort('campaign')}>
                    <Translate contentKey="nissanApp.lead.campaign">Campaign</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={this.sort('dateAvailableLead')}>
                    <Translate contentKey="nissanApp.lead.dateAvailableLead">Date Available Lead</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={this.sort('dateConsumerLead')}>
                    <Translate contentKey="nissanApp.lead.dateConsumerLead">Date Consumer Lead</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={this.sort('datePreScheduled')}>
                    <Translate contentKey="nissanApp.lead.datePreScheduled">Date Pre Scheduled</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={this.sort('periodPreScheduled')}>
                    <Translate contentKey="nissanApp.lead.periodPreScheduled">Period Pre Scheduled</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={this.sort('periodInterestPurchase')}>
                    <Translate contentKey="nissanApp.lead.periodInterestPurchase">Period Interest Purchase</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={this.sort('clientID')}>
                    <Translate contentKey="nissanApp.lead.clientID">Client ID</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={this.sort('name')}>
                    <Translate contentKey="nissanApp.lead.name">Name</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={this.sort('email')}>
                    <Translate contentKey="nissanApp.lead.email">Email</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={this.sort('areaCodeNumber')}>
                    <Translate contentKey="nissanApp.lead.areaCodeNumber">Area Code Number</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={this.sort('phoneNumber')}>
                    <Translate contentKey="nissanApp.lead.phoneNumber">Phone Number</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={this.sort('contactForm')}>
                    <Translate contentKey="nissanApp.lead.contactForm">Contact Form</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={this.sort('zipCode')}>
                    <Translate contentKey="nissanApp.lead.zipCode">Zip Code</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={this.sort('federalUnit')}>
                    <Translate contentKey="nissanApp.lead.federalUnit">Federal Unit</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={this.sort('city')}>
                    <Translate contentKey="nissanApp.lead.city">City</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={this.sort('personsWithSpecialNeeds')}>
                    <Translate contentKey="nissanApp.lead.personsWithSpecialNeeds">Persons With Special Needs</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={this.sort('nissanClient')}>
                    <Translate contentKey="nissanApp.lead.nissanClient">Nissan Client</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={this.sort('flagTestDrived')}>
                    <Translate contentKey="nissanApp.lead.flagTestDrived">Flag Test Drived</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={this.sort('testDriveLocal')}>
                    <Translate contentKey="nissanApp.lead.testDriveLocal">Test Drive Local</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={this.sort('flagFinanc')}>
                    <Translate contentKey="nissanApp.lead.flagFinanc">Flag Financ</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={this.sort('financingValue')}>
                    <Translate contentKey="nissanApp.lead.financingValue">Financing Value</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={this.sort('financingPreApproved')}>
                    <Translate contentKey="nissanApp.lead.financingPreApproved">Financing Pre Approved</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={this.sort('consortium')}>
                    <Translate contentKey="nissanApp.lead.consortium">Consortium</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={this.sort('rangeValue')}>
                    <Translate contentKey="nissanApp.lead.rangeValue">Range Value</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={this.sort('note')}>
                    <Translate contentKey="nissanApp.lead.note">Note</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th>
                    <Translate contentKey="nissanApp.lead.responseHotLead">Response Hot Lead</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {leadList.map((lead, i) => (
                  <tr key={`entity-${i}`}>
                    <td>
                      <Button tag={Link} to={`${match.url}/${lead.id}`} color="link" size="sm">
                        {lead.id}
                      </Button>
                    </td>
                    <td>{lead.idLead}</td>
                    <td>{lead.salesChannel}</td>
                    <td>{lead.saleCategory}</td>
                    <td>{lead.mediaAttraction}</td>
                    <td>{lead.idCampaign}</td>
                    <td>{lead.campaign}</td>
                    <td>
                      <TextFormat type="date" value={lead.dateAvailableLead} format={APP_DATE_FORMAT} />
                    </td>
                    <td>
                      <TextFormat type="date" value={lead.dateConsumerLead} format={APP_DATE_FORMAT} />
                    </td>
                    <td>
                      <TextFormat type="date" value={lead.datePreScheduled} format={APP_DATE_FORMAT} />
                    </td>
                    <td>{lead.periodPreScheduled}</td>
                    <td>{lead.periodInterestPurchase}</td>
                    <td>{lead.clientID}</td>
                    <td>{lead.name}</td>
                    <td>{lead.email}</td>
                    <td>{lead.areaCodeNumber}</td>
                    <td>{lead.phoneNumber}</td>
                    <td>{lead.contactForm}</td>
                    <td>{lead.zipCode}</td>
                    <td>{lead.federalUnit}</td>
                    <td>{lead.city}</td>
                    <td>{lead.personsWithSpecialNeeds}</td>
                    <td>{lead.nissanClient}</td>
                    <td>{lead.flagTestDrived}</td>
                    <td>{lead.testDriveLocal}</td>
                    <td>{lead.flagFinanc}</td>
                    <td>{lead.financingValue}</td>
                    <td>{lead.financingPreApproved}</td>
                    <td>{lead.consortium}</td>
                    <td>{lead.rangeValue}</td>
                    <td>{lead.note}</td>
                    <td>
                      {lead.responseHotLeadId ? (
                        <Link to={`response-hot-lead/${lead.responseHotLeadId}`}>{lead.responseHotLeadId}</Link>
                      ) : (
                        ''
                      )}
                    </td>
                    <td className="text-right">
                      <div className="btn-group flex-btn-group-container">
                        <Button tag={Link} to={`${match.url}/${lead.id}`} color="info" size="sm">
                          <FontAwesomeIcon icon="eye" />{' '}
                          <span className="d-none d-md-inline">
                            <Translate contentKey="entity.action.view">View</Translate>
                          </span>
                        </Button>
                        <Button tag={Link} to={`${match.url}/${lead.id}/edit`} color="primary" size="sm">
                          <FontAwesomeIcon icon="pencil-alt" />{' '}
                          <span className="d-none d-md-inline">
                            <Translate contentKey="entity.action.edit">Edit</Translate>
                          </span>
                        </Button>
                        <Button tag={Link} to={`${match.url}/${lead.id}/delete`} color="danger" size="sm">
                          <FontAwesomeIcon icon="trash" />{' '}
                          <span className="d-none d-md-inline">
                            <Translate contentKey="entity.action.delete">Delete</Translate>
                          </span>
                        </Button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </InfiniteScroll>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ lead }: IRootState) => ({
  leadList: lead.entities,
  totalItems: lead.totalItems,
  links: lead.links,
  entity: lead.entity,
  updateSuccess: lead.updateSuccess
});

const mapDispatchToProps = {
  getSearchEntities,
  getEntities,
  reset
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Lead);
