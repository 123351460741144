import { Moment } from 'moment';
import { IRefusedProposalSync } from 'app/shared/model/refused-proposal-sync.model';

export interface ISyonetRefusedProposal {
  id?: number;
  lastSync?: Moment;
  crmID?: string;
  extracionDate?: Moment;
  attendanceID?: number;
  nissanLeadID?: number;
  date?: Moment;
  reasonID?: number;
  syonetLeadId?: number;
  syncs?: IRefusedProposalSync[];
}

export const defaultValue: Readonly<ISyonetRefusedProposal> = {};
