import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, UncontrolledTooltip, Row, Col } from 'reactstrap';
// tslint:disable-next-line:no-unused-variable
import { Translate, ICrudGetAction } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntity } from './color.reducer';
import { IColor } from 'app/shared/model/color.model';
// tslint:disable-next-line:no-unused-variable
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';

export interface IColorDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export class ColorDetail extends React.Component<IColorDetailProps> {
  componentDidMount() {
    this.props.getEntity(this.props.match.params.id);
  }

  render() {
    const { colorEntity } = this.props;
    return (
      <Row>
        <Col md="8">
          <h2>
            <Translate contentKey="nissanApp.color.detail.title">Color</Translate> [<b>{colorEntity.id}</b>]
          </h2>
          <dl className="jh-entity-details">
            <dt>
              <span id="color">
                <Translate contentKey="nissanApp.color.color">Color</Translate>
              </span>
              <UncontrolledTooltip target="color">
                <Translate contentKey="nissanApp.color.help.color" />
              </UncontrolledTooltip>
            </dt>
            <dd>{colorEntity.color}</dd>
            <dt>
              <span id="colorCode">
                <Translate contentKey="nissanApp.color.colorCode">Color Code</Translate>
              </span>
              <UncontrolledTooltip target="colorCode">
                <Translate contentKey="nissanApp.color.help.colorCode" />
              </UncontrolledTooltip>
            </dt>
            <dd>{colorEntity.colorCode}</dd>
            <dt>
              <span id="colorType">
                <Translate contentKey="nissanApp.color.colorType">Color Type</Translate>
              </span>
              <UncontrolledTooltip target="colorType">
                <Translate contentKey="nissanApp.color.help.colorType" />
              </UncontrolledTooltip>
            </dt>
            <dd>{colorEntity.colorType}</dd>
            <dt>
              <span id="suggestedPrice">
                <Translate contentKey="nissanApp.color.suggestedPrice">Suggested Price</Translate>
              </span>
              <UncontrolledTooltip target="suggestedPrice">
                <Translate contentKey="nissanApp.color.help.suggestedPrice" />
              </UncontrolledTooltip>
            </dt>
            <dd>{colorEntity.suggestedPrice}</dd>
            <dt>
              <Translate contentKey="nissanApp.color.model">Model</Translate>
            </dt>
            <dd>{colorEntity.modelId ? colorEntity.modelId : ''}</dd>
          </dl>
          <Button tag={Link} to="/entity/color" replace color="info">
            <FontAwesomeIcon icon="arrow-left" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.back">Back</Translate>
            </span>
          </Button>
          &nbsp;
          <Button tag={Link} to={`/entity/color/${colorEntity.id}/edit`} replace color="primary">
            <FontAwesomeIcon icon="pencil-alt" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.edit">Edit</Translate>
            </span>
          </Button>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = ({ color }: IRootState) => ({
  colorEntity: color.entity
});

const mapDispatchToProps = { getEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ColorDetail);
