import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import NegotiationResponse from './negotiation-response';
import NegotiationResponseDetail from './negotiation-response-detail';
import NegotiationResponseUpdate from './negotiation-response-update';
import NegotiationResponseDeleteDialog from './negotiation-response-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={NegotiationResponseUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={NegotiationResponseUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={NegotiationResponseDetail} />
      <ErrorBoundaryRoute path={match.url} component={NegotiationResponse} />
    </Switch>
    <ErrorBoundaryRoute path={`${match.url}/:id/delete`} component={NegotiationResponseDeleteDialog} />
  </>
);

export default Routes;
