import { Moment } from 'moment';
import { IApprovedProposalResponse } from 'app/shared/model/approved-proposal-response.model';

export interface IApprovedProposalSync {
  id?: number;
  receivedIn?: Moment;
  synchronizedIn?: Moment;
  responses?: IApprovedProposalResponse[];
  syonetApprovedProposalId?: number;
}

export const defaultValue: Readonly<IApprovedProposalSync> = {};
