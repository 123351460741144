import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import FollowUpLeadMessage from './follow-up-lead-message';
import FollowUpLeadMessageDetail from './follow-up-lead-message-detail';
import FollowUpLeadMessageUpdate from './follow-up-lead-message-update';
import FollowUpLeadMessageDeleteDialog from './follow-up-lead-message-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={FollowUpLeadMessageUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={FollowUpLeadMessageUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={FollowUpLeadMessageDetail} />
      <ErrorBoundaryRoute path={match.url} component={FollowUpLeadMessage} />
    </Switch>
    <ErrorBoundaryRoute path={`${match.url}/:id/delete`} component={FollowUpLeadMessageDeleteDialog} />
  </>
);

export default Routes;
