import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import VehicleSaledApprovedProposal from './vehicle-saled-approved-proposal';
import VehicleSaledApprovedProposalDetail from './vehicle-saled-approved-proposal-detail';
import VehicleSaledApprovedProposalUpdate from './vehicle-saled-approved-proposal-update';
import VehicleSaledApprovedProposalDeleteDialog from './vehicle-saled-approved-proposal-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={VehicleSaledApprovedProposalUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={VehicleSaledApprovedProposalUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={VehicleSaledApprovedProposalDetail} />
      <ErrorBoundaryRoute path={match.url} component={VehicleSaledApprovedProposal} />
    </Switch>
    <ErrorBoundaryRoute path={`${match.url}/:id/delete`} component={VehicleSaledApprovedProposalDeleteDialog} />
  </>
);

export default Routes;
