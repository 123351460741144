import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, UncontrolledTooltip, Row, Col } from 'reactstrap';
// tslint:disable-next-line:no-unused-variable
import { Translate, ICrudGetAction, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntity } from './refused-proposal-response.reducer';
import { IRefusedProposalResponse } from 'app/shared/model/refused-proposal-response.model';
// tslint:disable-next-line:no-unused-variable
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';

export interface IRefusedProposalResponseDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export class RefusedProposalResponseDetail extends React.Component<IRefusedProposalResponseDetailProps> {
  componentDidMount() {
    this.props.getEntity(this.props.match.params.id);
  }

  render() {
    const { refusedProposalResponseEntity } = this.props;
    return (
      <Row>
        <Col md="8">
          <h2>
            <Translate contentKey="nissanApp.refusedProposalResponse.detail.title">RefusedProposalResponse</Translate> [
            <b>{refusedProposalResponseEntity.id}</b>]
          </h2>
          <dl className="jh-entity-details">
            <dt>
              <span id="receivedIn">
                <Translate contentKey="nissanApp.refusedProposalResponse.receivedIn">Received In</Translate>
              </span>
              <UncontrolledTooltip target="receivedIn">
                <Translate contentKey="nissanApp.refusedProposalResponse.help.receivedIn" />
              </UncontrolledTooltip>
            </dt>
            <dd>
              <TextFormat value={refusedProposalResponseEntity.receivedIn} type="date" format={APP_DATE_FORMAT} />
            </dd>
            <dt>
              <span id="synchronizedIn">
                <Translate contentKey="nissanApp.refusedProposalResponse.synchronizedIn">Synchronized In</Translate>
              </span>
              <UncontrolledTooltip target="synchronizedIn">
                <Translate contentKey="nissanApp.refusedProposalResponse.help.synchronizedIn" />
              </UncontrolledTooltip>
            </dt>
            <dd>
              <TextFormat value={refusedProposalResponseEntity.synchronizedIn} type="date" format={APP_DATE_FORMAT} />
            </dd>
            <dt>
              <Translate contentKey="nissanApp.refusedProposalResponse.refusedProposalSync">Refused Proposal Sync</Translate>
            </dt>
            <dd>{refusedProposalResponseEntity.refusedProposalSyncId ? refusedProposalResponseEntity.refusedProposalSyncId : ''}</dd>
            <dt>
              <Translate contentKey="nissanApp.refusedProposalResponse.statusCode">Status Code</Translate>
            </dt>
            <dd>{refusedProposalResponseEntity.statusCodeId ? refusedProposalResponseEntity.statusCodeId : ''}</dd>
          </dl>
          <Button tag={Link} to="/entity/refused-proposal-response" replace color="info">
            <FontAwesomeIcon icon="arrow-left" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.back">Back</Translate>
            </span>
          </Button>
          &nbsp;
          <Button tag={Link} to={`/entity/refused-proposal-response/${refusedProposalResponseEntity.id}/edit`} replace color="primary">
            <FontAwesomeIcon icon="pencil-alt" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.edit">Edit</Translate>
            </span>
          </Button>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = ({ refusedProposalResponse }: IRootState) => ({
  refusedProposalResponseEntity: refusedProposalResponse.entity
});

const mapDispatchToProps = { getEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RefusedProposalResponseDetail);
