export interface IVehicleSaledApprovedProposal {
  id?: number;
  vehicleCode?: string;
  vim?: string;
  modelCode?: string;
  eimCode?: string;
  colorCode?: string;
  modelYear?: number;
  taxInvoice?: string;
  syonetApprovedProposalId?: number;
}

export const defaultValue: Readonly<IVehicleSaledApprovedProposal> = {};
