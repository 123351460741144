import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col, Label, UncontrolledTooltip } from 'reactstrap';
import { AvFeedback, AvForm, AvGroup, AvInput, AvField } from 'availity-reactstrap-validation';
// tslint:disable-next-line:no-unused-variable
import { Translate, translate, ICrudGetAction, ICrudGetAllAction, ICrudPutAction } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';

import { ISyonetLead } from 'app/shared/model/syonet-lead.model';
import { getEntities as getSyonetLeads } from 'app/entities/syonet-lead/syonet-lead.reducer';
import { getEntity, updateEntity, createEntity, reset } from './syonet-client-qualification.reducer';
import { ISyonetClientQualification } from 'app/shared/model/syonet-client-qualification.model';
// tslint:disable-next-line:no-unused-variable
import { convertDateTimeFromServer, convertDateTimeToServer } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';

export interface ISyonetClientQualificationUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export interface ISyonetClientQualificationUpdateState {
  isNew: boolean;
  syonetLeadId: string;
}

export class SyonetClientQualificationUpdate extends React.Component<
  ISyonetClientQualificationUpdateProps,
  ISyonetClientQualificationUpdateState
> {
  constructor(props) {
    super(props);
    this.state = {
      syonetLeadId: '0',
      isNew: !this.props.match.params || !this.props.match.params.id
    };
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextProps.updateSuccess !== this.props.updateSuccess && nextProps.updateSuccess) {
      this.handleClose();
    }
  }

  componentDidMount() {
    if (!this.state.isNew) {
      this.props.getEntity(this.props.match.params.id);
    }

    this.props.getSyonetLeads();
  }

  saveEntity = (event, errors, values) => {
    values.lastSync = convertDateTimeToServer(values.lastSync);
    values.extracionDate = convertDateTimeToServer(values.extracionDate);

    if (errors.length === 0) {
      const { syonetClientQualificationEntity } = this.props;
      const entity = {
        ...syonetClientQualificationEntity,
        ...values
      };

      if (this.state.isNew) {
        this.props.createEntity(entity);
      } else {
        this.props.updateEntity(entity);
      }
    }
  };

  handleClose = () => {
    this.props.history.push('/entity/syonet-client-qualification');
  };

  render() {
    const { syonetClientQualificationEntity, syonetLeads, loading, updating } = this.props;
    const { isNew } = this.state;

    return (
      <div>
        <Row className="justify-content-center">
          <Col md="8">
            <h2 id="nissanApp.syonetClientQualification.home.createOrEditLabel">
              <Translate contentKey="nissanApp.syonetClientQualification.home.createOrEditLabel">
                Create or edit a SyonetClientQualification
              </Translate>
            </h2>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md="8">
            {loading ? (
              <p>Loading...</p>
            ) : (
              <AvForm model={isNew ? {} : syonetClientQualificationEntity} onSubmit={this.saveEntity}>
                {!isNew ? (
                  <AvGroup>
                    <Label for="syonet-client-qualification-id">
                      <Translate contentKey="global.field.id">ID</Translate>
                    </Label>
                    <AvInput id="syonet-client-qualification-id" type="text" className="form-control" name="id" required readOnly />
                  </AvGroup>
                ) : null}
                <AvGroup>
                  <Label id="lastSyncLabel" for="syonet-client-qualification-lastSync">
                    <Translate contentKey="nissanApp.syonetClientQualification.lastSync">Last Sync</Translate>
                  </Label>
                  <AvInput
                    id="syonet-client-qualification-lastSync"
                    type="datetime-local"
                    className="form-control"
                    name="lastSync"
                    placeholder={'YYYY-MM-DD HH:mm'}
                    value={isNew ? null : convertDateTimeFromServer(this.props.syonetClientQualificationEntity.lastSync)}
                  />
                  <UncontrolledTooltip target="lastSyncLabel">
                    <Translate contentKey="nissanApp.syonetClientQualification.help.lastSync" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="crmIDLabel" for="syonet-client-qualification-crmID">
                    <Translate contentKey="nissanApp.syonetClientQualification.crmID">Crm ID</Translate>
                  </Label>
                  <AvField id="syonet-client-qualification-crmID" type="text" name="crmID" />
                  <UncontrolledTooltip target="crmIDLabel">
                    <Translate contentKey="nissanApp.syonetClientQualification.help.crmID" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="extracionDateLabel" for="syonet-client-qualification-extracionDate">
                    <Translate contentKey="nissanApp.syonetClientQualification.extracionDate">Extracion Date</Translate>
                  </Label>
                  <AvInput
                    id="syonet-client-qualification-extracionDate"
                    type="datetime-local"
                    className="form-control"
                    name="extracionDate"
                    placeholder={'YYYY-MM-DD HH:mm'}
                    value={isNew ? null : convertDateTimeFromServer(this.props.syonetClientQualificationEntity.extracionDate)}
                    validate={{
                      required: { value: true, errorMessage: translate('entity.validation.required') }
                    }}
                  />
                  <UncontrolledTooltip target="extracionDateLabel">
                    <Translate contentKey="nissanApp.syonetClientQualification.help.extracionDate" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="attendanceIDLabel" for="syonet-client-qualification-attendanceID">
                    <Translate contentKey="nissanApp.syonetClientQualification.attendanceID">Attendance ID</Translate>
                  </Label>
                  <AvField
                    id="syonet-client-qualification-attendanceID"
                    type="string"
                    className="form-control"
                    name="attendanceID"
                    validate={{
                      required: { value: true, errorMessage: translate('entity.validation.required') },
                      number: { value: true, errorMessage: translate('entity.validation.number') }
                    }}
                  />
                  <UncontrolledTooltip target="attendanceIDLabel">
                    <Translate contentKey="nissanApp.syonetClientQualification.help.attendanceID" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="nissanLeadIDLabel" for="syonet-client-qualification-nissanLeadID">
                    <Translate contentKey="nissanApp.syonetClientQualification.nissanLeadID">Nissan Lead ID</Translate>
                  </Label>
                  <AvField id="syonet-client-qualification-nissanLeadID" type="string" className="form-control" name="nissanLeadID" />
                  <UncontrolledTooltip target="nissanLeadIDLabel">
                    <Translate contentKey="nissanApp.syonetClientQualification.help.nissanLeadID" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="nissanClientLabel" for="syonet-client-qualification-nissanClient">
                    <Translate contentKey="nissanApp.syonetClientQualification.nissanClient">Nissan Client</Translate>
                  </Label>
                  <AvField
                    id="syonet-client-qualification-nissanClient"
                    type="string"
                    className="form-control"
                    name="nissanClient"
                    validate={{
                      required: { value: true, errorMessage: translate('entity.validation.required') },
                      number: { value: true, errorMessage: translate('entity.validation.number') }
                    }}
                  />
                  <UncontrolledTooltip target="nissanClientLabel">
                    <Translate contentKey="nissanApp.syonetClientQualification.help.nissanClient" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="flagTestDrivedLabel" for="syonet-client-qualification-flagTestDrived">
                    <Translate contentKey="nissanApp.syonetClientQualification.flagTestDrived">Flag Test Drived</Translate>
                  </Label>
                  <AvField
                    id="syonet-client-qualification-flagTestDrived"
                    type="string"
                    className="form-control"
                    name="flagTestDrived"
                    validate={{
                      required: { value: true, errorMessage: translate('entity.validation.required') },
                      number: { value: true, errorMessage: translate('entity.validation.number') }
                    }}
                  />
                  <UncontrolledTooltip target="flagTestDrivedLabel">
                    <Translate contentKey="nissanApp.syonetClientQualification.help.flagTestDrived" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="flagFinancLabel" for="syonet-client-qualification-flagFinanc">
                    <Translate contentKey="nissanApp.syonetClientQualification.flagFinanc">Flag Financ</Translate>
                  </Label>
                  <AvField
                    id="syonet-client-qualification-flagFinanc"
                    type="string"
                    className="form-control"
                    name="flagFinanc"
                    validate={{
                      required: { value: true, errorMessage: translate('entity.validation.required') },
                      number: { value: true, errorMessage: translate('entity.validation.number') }
                    }}
                  />
                  <UncontrolledTooltip target="flagFinancLabel">
                    <Translate contentKey="nissanApp.syonetClientQualification.help.flagFinanc" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="financingValueLabel" for="syonet-client-qualification-financingValue">
                    <Translate contentKey="nissanApp.syonetClientQualification.financingValue">Financing Value</Translate>
                  </Label>
                  <AvField id="syonet-client-qualification-financingValue" type="string" className="form-control" name="financingValue" />
                  <UncontrolledTooltip target="financingValueLabel">
                    <Translate contentKey="nissanApp.syonetClientQualification.help.financingValue" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="financingPreApprovedLabel" for="syonet-client-qualification-financingPreApproved">
                    <Translate contentKey="nissanApp.syonetClientQualification.financingPreApproved">Financing Pre Approved</Translate>
                  </Label>
                  <AvField
                    id="syonet-client-qualification-financingPreApproved"
                    type="string"
                    className="form-control"
                    name="financingPreApproved"
                    validate={{
                      required: { value: true, errorMessage: translate('entity.validation.required') },
                      number: { value: true, errorMessage: translate('entity.validation.number') }
                    }}
                  />
                  <UncontrolledTooltip target="financingPreApprovedLabel">
                    <Translate contentKey="nissanApp.syonetClientQualification.help.financingPreApproved" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="consortiumLabel" for="syonet-client-qualification-consortium">
                    <Translate contentKey="nissanApp.syonetClientQualification.consortium">Consortium</Translate>
                  </Label>
                  <AvField
                    id="syonet-client-qualification-consortium"
                    type="string"
                    className="form-control"
                    name="consortium"
                    validate={{
                      required: { value: true, errorMessage: translate('entity.validation.required') },
                      number: { value: true, errorMessage: translate('entity.validation.number') }
                    }}
                  />
                  <UncontrolledTooltip target="consortiumLabel">
                    <Translate contentKey="nissanApp.syonetClientQualification.help.consortium" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="rangeValueLabel" for="syonet-client-qualification-rangeValue">
                    <Translate contentKey="nissanApp.syonetClientQualification.rangeValue">Range Value</Translate>
                  </Label>
                  <AvField id="syonet-client-qualification-rangeValue" type="string" className="form-control" name="rangeValue" />
                  <UncontrolledTooltip target="rangeValueLabel">
                    <Translate contentKey="nissanApp.syonetClientQualification.help.rangeValue" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="noteLabel" for="syonet-client-qualification-note">
                    <Translate contentKey="nissanApp.syonetClientQualification.note">Note</Translate>
                  </Label>
                  <AvField id="syonet-client-qualification-note" type="text" name="note" />
                  <UncontrolledTooltip target="noteLabel">
                    <Translate contentKey="nissanApp.syonetClientQualification.help.note" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label for="syonet-client-qualification-syonetLead">
                    <Translate contentKey="nissanApp.syonetClientQualification.syonetLead">Syonet Lead</Translate>
                  </Label>
                  <AvInput id="syonet-client-qualification-syonetLead" type="select" className="form-control" name="syonetLeadId">
                    <option value="" key="0" />
                    {syonetLeads
                      ? syonetLeads.map(otherEntity => (
                          <option value={otherEntity.id} key={otherEntity.id}>
                            {otherEntity.id}
                          </option>
                        ))
                      : null}
                  </AvInput>
                </AvGroup>
                <Button tag={Link} id="cancel-save" to="/entity/syonet-client-qualification" replace color="info">
                  <FontAwesomeIcon icon="arrow-left" />
                  &nbsp;
                  <span className="d-none d-md-inline">
                    <Translate contentKey="entity.action.back">Back</Translate>
                  </span>
                </Button>
                &nbsp;
                <Button color="primary" id="save-entity" type="submit" disabled={updating}>
                  <FontAwesomeIcon icon="save" />
                  &nbsp;
                  <Translate contentKey="entity.action.save">Save</Translate>
                </Button>
              </AvForm>
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (storeState: IRootState) => ({
  syonetLeads: storeState.syonetLead.entities,
  syonetClientQualificationEntity: storeState.syonetClientQualification.entity,
  loading: storeState.syonetClientQualification.loading,
  updating: storeState.syonetClientQualification.updating,
  updateSuccess: storeState.syonetClientQualification.updateSuccess
});

const mapDispatchToProps = {
  getSyonetLeads,
  getEntity,
  updateEntity,
  createEntity,
  reset
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SyonetClientQualificationUpdate);
