import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col, Label, UncontrolledTooltip } from 'reactstrap';
import { AvFeedback, AvForm, AvGroup, AvInput, AvField } from 'availity-reactstrap-validation';
// tslint:disable-next-line:no-unused-variable
import { Translate, translate, ICrudGetAction, ICrudGetAllAction, ICrudPutAction } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';

import { IResponseHotLead } from 'app/shared/model/response-hot-lead.model';
import { getEntities as getResponseHotLeads } from 'app/entities/response-hot-lead/response-hot-lead.reducer';
import { getEntity, updateEntity, createEntity, reset } from './lead.reducer';
import { ILead } from 'app/shared/model/lead.model';
// tslint:disable-next-line:no-unused-variable
import { convertDateTimeFromServer, convertDateTimeToServer } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';

export interface ILeadUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export interface ILeadUpdateState {
  isNew: boolean;
  responseHotLeadId: string;
}

export class LeadUpdate extends React.Component<ILeadUpdateProps, ILeadUpdateState> {
  constructor(props) {
    super(props);
    this.state = {
      responseHotLeadId: '0',
      isNew: !this.props.match.params || !this.props.match.params.id
    };
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextProps.updateSuccess !== this.props.updateSuccess && nextProps.updateSuccess) {
      this.handleClose();
    }
  }

  componentDidMount() {
    if (!this.state.isNew) {
      this.props.getEntity(this.props.match.params.id);
    }

    this.props.getResponseHotLeads();
  }

  saveEntity = (event, errors, values) => {
    values.dateAvailableLead = convertDateTimeToServer(values.dateAvailableLead);
    values.dateConsumerLead = convertDateTimeToServer(values.dateConsumerLead);
    values.datePreScheduled = convertDateTimeToServer(values.datePreScheduled);

    if (errors.length === 0) {
      const { leadEntity } = this.props;
      const entity = {
        ...leadEntity,
        ...values
      };

      if (this.state.isNew) {
        this.props.createEntity(entity);
      } else {
        this.props.updateEntity(entity);
      }
    }
  };

  handleClose = () => {
    this.props.history.push('/entity/lead');
  };

  render() {
    const { leadEntity, responseHotLeads, loading, updating } = this.props;
    const { isNew } = this.state;

    return (
      <div>
        <Row className="justify-content-center">
          <Col md="8">
            <h2 id="nissanApp.lead.home.createOrEditLabel">
              <Translate contentKey="nissanApp.lead.home.createOrEditLabel">Create or edit a Lead</Translate>
            </h2>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md="8">
            {loading ? (
              <p>Loading...</p>
            ) : (
              <AvForm model={isNew ? {} : leadEntity} onSubmit={this.saveEntity}>
                {!isNew ? (
                  <AvGroup>
                    <Label for="lead-id">
                      <Translate contentKey="global.field.id">ID</Translate>
                    </Label>
                    <AvInput id="lead-id" type="text" className="form-control" name="id" required readOnly />
                  </AvGroup>
                ) : null}
                <AvGroup>
                  <Label id="idLeadLabel" for="lead-idLead">
                    <Translate contentKey="nissanApp.lead.idLead">Id Lead</Translate>
                  </Label>
                  <AvField id="lead-idLead" type="string" className="form-control" name="idLead" />
                  <UncontrolledTooltip target="idLeadLabel">
                    <Translate contentKey="nissanApp.lead.help.idLead" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="salesChannelLabel" for="lead-salesChannel">
                    <Translate contentKey="nissanApp.lead.salesChannel">Sales Channel</Translate>
                  </Label>
                  <AvField id="lead-salesChannel" type="string" className="form-control" name="salesChannel" />
                  <UncontrolledTooltip target="salesChannelLabel">
                    <Translate contentKey="nissanApp.lead.help.salesChannel" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="saleCategoryLabel" for="lead-saleCategory">
                    <Translate contentKey="nissanApp.lead.saleCategory">Sale Category</Translate>
                  </Label>
                  <AvField id="lead-saleCategory" type="string" className="form-control" name="saleCategory" />
                  <UncontrolledTooltip target="saleCategoryLabel">
                    <Translate contentKey="nissanApp.lead.help.saleCategory" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="mediaAttractionLabel" for="lead-mediaAttraction">
                    <Translate contentKey="nissanApp.lead.mediaAttraction">Media Attraction</Translate>
                  </Label>
                  <AvField id="lead-mediaAttraction" type="string" className="form-control" name="mediaAttraction" />
                  <UncontrolledTooltip target="mediaAttractionLabel">
                    <Translate contentKey="nissanApp.lead.help.mediaAttraction" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="idCampaignLabel" for="lead-idCampaign">
                    <Translate contentKey="nissanApp.lead.idCampaign">Id Campaign</Translate>
                  </Label>
                  <AvField id="lead-idCampaign" type="string" className="form-control" name="idCampaign" />
                  <UncontrolledTooltip target="idCampaignLabel">
                    <Translate contentKey="nissanApp.lead.help.idCampaign" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="campaignLabel" for="lead-campaign">
                    <Translate contentKey="nissanApp.lead.campaign">Campaign</Translate>
                  </Label>
                  <AvField id="lead-campaign" type="text" name="campaign" />
                  <UncontrolledTooltip target="campaignLabel">
                    <Translate contentKey="nissanApp.lead.help.campaign" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="dateAvailableLeadLabel" for="lead-dateAvailableLead">
                    <Translate contentKey="nissanApp.lead.dateAvailableLead">Date Available Lead</Translate>
                  </Label>
                  <AvInput
                    id="lead-dateAvailableLead"
                    type="datetime-local"
                    className="form-control"
                    name="dateAvailableLead"
                    placeholder={'YYYY-MM-DD HH:mm'}
                    value={isNew ? null : convertDateTimeFromServer(this.props.leadEntity.dateAvailableLead)}
                  />
                  <UncontrolledTooltip target="dateAvailableLeadLabel">
                    <Translate contentKey="nissanApp.lead.help.dateAvailableLead" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="dateConsumerLeadLabel" for="lead-dateConsumerLead">
                    <Translate contentKey="nissanApp.lead.dateConsumerLead">Date Consumer Lead</Translate>
                  </Label>
                  <AvInput
                    id="lead-dateConsumerLead"
                    type="datetime-local"
                    className="form-control"
                    name="dateConsumerLead"
                    placeholder={'YYYY-MM-DD HH:mm'}
                    value={isNew ? null : convertDateTimeFromServer(this.props.leadEntity.dateConsumerLead)}
                  />
                  <UncontrolledTooltip target="dateConsumerLeadLabel">
                    <Translate contentKey="nissanApp.lead.help.dateConsumerLead" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="datePreScheduledLabel" for="lead-datePreScheduled">
                    <Translate contentKey="nissanApp.lead.datePreScheduled">Date Pre Scheduled</Translate>
                  </Label>
                  <AvInput
                    id="lead-datePreScheduled"
                    type="datetime-local"
                    className="form-control"
                    name="datePreScheduled"
                    placeholder={'YYYY-MM-DD HH:mm'}
                    value={isNew ? null : convertDateTimeFromServer(this.props.leadEntity.datePreScheduled)}
                  />
                  <UncontrolledTooltip target="datePreScheduledLabel">
                    <Translate contentKey="nissanApp.lead.help.datePreScheduled" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="periodPreScheduledLabel" for="lead-periodPreScheduled">
                    <Translate contentKey="nissanApp.lead.periodPreScheduled">Period Pre Scheduled</Translate>
                  </Label>
                  <AvField id="lead-periodPreScheduled" type="string" className="form-control" name="periodPreScheduled" />
                  <UncontrolledTooltip target="periodPreScheduledLabel">
                    <Translate contentKey="nissanApp.lead.help.periodPreScheduled" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="periodInterestPurchaseLabel" for="lead-periodInterestPurchase">
                    <Translate contentKey="nissanApp.lead.periodInterestPurchase">Period Interest Purchase</Translate>
                  </Label>
                  <AvField id="lead-periodInterestPurchase" type="string" className="form-control" name="periodInterestPurchase" />
                  <UncontrolledTooltip target="periodInterestPurchaseLabel">
                    <Translate contentKey="nissanApp.lead.help.periodInterestPurchase" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="clientIDLabel" for="lead-clientID">
                    <Translate contentKey="nissanApp.lead.clientID">Client ID</Translate>
                  </Label>
                  <AvField id="lead-clientID" type="text" name="clientID" />
                  <UncontrolledTooltip target="clientIDLabel">
                    <Translate contentKey="nissanApp.lead.help.clientID" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="nameLabel" for="lead-name">
                    <Translate contentKey="nissanApp.lead.name">Name</Translate>
                  </Label>
                  <AvField id="lead-name" type="text" name="name" />
                  <UncontrolledTooltip target="nameLabel">
                    <Translate contentKey="nissanApp.lead.help.name" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="emailLabel" for="lead-email">
                    <Translate contentKey="nissanApp.lead.email">Email</Translate>
                  </Label>
                  <AvField id="lead-email" type="text" name="email" />
                  <UncontrolledTooltip target="emailLabel">
                    <Translate contentKey="nissanApp.lead.help.email" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="areaCodeNumberLabel" for="lead-areaCodeNumber">
                    <Translate contentKey="nissanApp.lead.areaCodeNumber">Area Code Number</Translate>
                  </Label>
                  <AvField id="lead-areaCodeNumber" type="string" className="form-control" name="areaCodeNumber" />
                  <UncontrolledTooltip target="areaCodeNumberLabel">
                    <Translate contentKey="nissanApp.lead.help.areaCodeNumber" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="phoneNumberLabel" for="lead-phoneNumber">
                    <Translate contentKey="nissanApp.lead.phoneNumber">Phone Number</Translate>
                  </Label>
                  <AvField id="lead-phoneNumber" type="string" className="form-control" name="phoneNumber" />
                  <UncontrolledTooltip target="phoneNumberLabel">
                    <Translate contentKey="nissanApp.lead.help.phoneNumber" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="contactFormLabel" for="lead-contactForm">
                    <Translate contentKey="nissanApp.lead.contactForm">Contact Form</Translate>
                  </Label>
                  <AvField id="lead-contactForm" type="text" name="contactForm" />
                  <UncontrolledTooltip target="contactFormLabel">
                    <Translate contentKey="nissanApp.lead.help.contactForm" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="zipCodeLabel" for="lead-zipCode">
                    <Translate contentKey="nissanApp.lead.zipCode">Zip Code</Translate>
                  </Label>
                  <AvField id="lead-zipCode" type="text" name="zipCode" />
                  <UncontrolledTooltip target="zipCodeLabel">
                    <Translate contentKey="nissanApp.lead.help.zipCode" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="federalUnitLabel" for="lead-federalUnit">
                    <Translate contentKey="nissanApp.lead.federalUnit">Federal Unit</Translate>
                  </Label>
                  <AvField id="lead-federalUnit" type="text" name="federalUnit" />
                  <UncontrolledTooltip target="federalUnitLabel">
                    <Translate contentKey="nissanApp.lead.help.federalUnit" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="cityLabel" for="lead-city">
                    <Translate contentKey="nissanApp.lead.city">City</Translate>
                  </Label>
                  <AvField id="lead-city" type="text" name="city" />
                  <UncontrolledTooltip target="cityLabel">
                    <Translate contentKey="nissanApp.lead.help.city" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="personsWithSpecialNeedsLabel" for="lead-personsWithSpecialNeeds">
                    <Translate contentKey="nissanApp.lead.personsWithSpecialNeeds">Persons With Special Needs</Translate>
                  </Label>
                  <AvField id="lead-personsWithSpecialNeeds" type="string" className="form-control" name="personsWithSpecialNeeds" />
                  <UncontrolledTooltip target="personsWithSpecialNeedsLabel">
                    <Translate contentKey="nissanApp.lead.help.personsWithSpecialNeeds" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="nissanClientLabel" for="lead-nissanClient">
                    <Translate contentKey="nissanApp.lead.nissanClient">Nissan Client</Translate>
                  </Label>
                  <AvField id="lead-nissanClient" type="string" className="form-control" name="nissanClient" />
                  <UncontrolledTooltip target="nissanClientLabel">
                    <Translate contentKey="nissanApp.lead.help.nissanClient" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="flagTestDrivedLabel" for="lead-flagTestDrived">
                    <Translate contentKey="nissanApp.lead.flagTestDrived">Flag Test Drived</Translate>
                  </Label>
                  <AvField id="lead-flagTestDrived" type="string" className="form-control" name="flagTestDrived" />
                  <UncontrolledTooltip target="flagTestDrivedLabel">
                    <Translate contentKey="nissanApp.lead.help.flagTestDrived" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="testDriveLocalLabel" for="lead-testDriveLocal">
                    <Translate contentKey="nissanApp.lead.testDriveLocal">Test Drive Local</Translate>
                  </Label>
                  <AvField id="lead-testDriveLocal" type="text" name="testDriveLocal" />
                  <UncontrolledTooltip target="testDriveLocalLabel">
                    <Translate contentKey="nissanApp.lead.help.testDriveLocal" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="flagFinancLabel" for="lead-flagFinanc">
                    <Translate contentKey="nissanApp.lead.flagFinanc">Flag Financ</Translate>
                  </Label>
                  <AvField id="lead-flagFinanc" type="string" className="form-control" name="flagFinanc" />
                  <UncontrolledTooltip target="flagFinancLabel">
                    <Translate contentKey="nissanApp.lead.help.flagFinanc" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="financingValueLabel" for="lead-financingValue">
                    <Translate contentKey="nissanApp.lead.financingValue">Financing Value</Translate>
                  </Label>
                  <AvField id="lead-financingValue" type="string" className="form-control" name="financingValue" />
                  <UncontrolledTooltip target="financingValueLabel">
                    <Translate contentKey="nissanApp.lead.help.financingValue" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="financingPreApprovedLabel" for="lead-financingPreApproved">
                    <Translate contentKey="nissanApp.lead.financingPreApproved">Financing Pre Approved</Translate>
                  </Label>
                  <AvField
                    id="lead-financingPreApproved"
                    type="string"
                    className="form-control"
                    name="financingPreApproved"
                    validate={{
                      required: { value: true, errorMessage: translate('entity.validation.required') },
                      number: { value: true, errorMessage: translate('entity.validation.number') }
                    }}
                  />
                  <UncontrolledTooltip target="financingPreApprovedLabel">
                    <Translate contentKey="nissanApp.lead.help.financingPreApproved" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="consortiumLabel" for="lead-consortium">
                    <Translate contentKey="nissanApp.lead.consortium">Consortium</Translate>
                  </Label>
                  <AvField
                    id="lead-consortium"
                    type="string"
                    className="form-control"
                    name="consortium"
                    validate={{
                      required: { value: true, errorMessage: translate('entity.validation.required') },
                      number: { value: true, errorMessage: translate('entity.validation.number') }
                    }}
                  />
                  <UncontrolledTooltip target="consortiumLabel">
                    <Translate contentKey="nissanApp.lead.help.consortium" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="rangeValueLabel" for="lead-rangeValue">
                    <Translate contentKey="nissanApp.lead.rangeValue">Range Value</Translate>
                  </Label>
                  <AvField id="lead-rangeValue" type="string" className="form-control" name="rangeValue" />
                  <UncontrolledTooltip target="rangeValueLabel">
                    <Translate contentKey="nissanApp.lead.help.rangeValue" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="noteLabel" for="lead-note">
                    <Translate contentKey="nissanApp.lead.note">Note</Translate>
                  </Label>
                  <AvField id="lead-note" type="text" name="note" />
                  <UncontrolledTooltip target="noteLabel">
                    <Translate contentKey="nissanApp.lead.help.note" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label for="lead-responseHotLead">
                    <Translate contentKey="nissanApp.lead.responseHotLead">Response Hot Lead</Translate>
                  </Label>
                  <AvInput id="lead-responseHotLead" type="select" className="form-control" name="responseHotLeadId">
                    <option value="" key="0" />
                    {responseHotLeads
                      ? responseHotLeads.map(otherEntity => (
                          <option value={otherEntity.id} key={otherEntity.id}>
                            {otherEntity.id}
                          </option>
                        ))
                      : null}
                  </AvInput>
                </AvGroup>
                <Button tag={Link} id="cancel-save" to="/entity/lead" replace color="info">
                  <FontAwesomeIcon icon="arrow-left" />
                  &nbsp;
                  <span className="d-none d-md-inline">
                    <Translate contentKey="entity.action.back">Back</Translate>
                  </span>
                </Button>
                &nbsp;
                <Button color="primary" id="save-entity" type="submit" disabled={updating}>
                  <FontAwesomeIcon icon="save" />
                  &nbsp;
                  <Translate contentKey="entity.action.save">Save</Translate>
                </Button>
              </AvForm>
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (storeState: IRootState) => ({
  responseHotLeads: storeState.responseHotLead.entities,
  leadEntity: storeState.lead.entity,
  loading: storeState.lead.loading,
  updating: storeState.lead.updating,
  updateSuccess: storeState.lead.updateSuccess
});

const mapDispatchToProps = {
  getResponseHotLeads,
  getEntity,
  updateEntity,
  createEntity,
  reset
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LeadUpdate);
