export interface IVisitShowroomLeadMessage {
  id?: number;
  message?: string;
  messageCode?: number;
  registryNumber?: number;
  returnType?: string;
  visitShowroomResponseId?: number;
}

export const defaultValue: Readonly<IVisitShowroomLeadMessage> = {};
