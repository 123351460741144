import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import FollowUpResponse from './follow-up-response';
import FollowUpResponseDetail from './follow-up-response-detail';
import FollowUpResponseUpdate from './follow-up-response-update';
import FollowUpResponseDeleteDialog from './follow-up-response-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={FollowUpResponseUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={FollowUpResponseUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={FollowUpResponseDetail} />
      <ErrorBoundaryRoute path={match.url} component={FollowUpResponse} />
    </Switch>
    <ErrorBoundaryRoute path={`${match.url}/:id/delete`} component={FollowUpResponseDeleteDialog} />
  </>
);

export default Routes;
