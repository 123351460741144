export interface IResponseGammaMessage {
  id?: number;
  message?: string;
  messageCode?: number;
  registryNumber?: number;
  returnType?: string;
  responseGammaId?: number;
}

export const defaultValue: Readonly<IResponseGammaMessage> = {};
