import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import RefusedProposalSync from './refused-proposal-sync';
import RefusedProposalSyncDetail from './refused-proposal-sync-detail';
import RefusedProposalSyncUpdate from './refused-proposal-sync-update';
import RefusedProposalSyncDeleteDialog from './refused-proposal-sync-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={RefusedProposalSyncUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={RefusedProposalSyncUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={RefusedProposalSyncDetail} />
      <ErrorBoundaryRoute path={match.url} component={RefusedProposalSync} />
    </Switch>
    <ErrorBoundaryRoute path={`${match.url}/:id/delete`} component={RefusedProposalSyncDeleteDialog} />
  </>
);

export default Routes;
