import axios from 'axios';
import {
  ICrudSearchAction,
  parseHeaderForLinks,
  loadMoreDataWhenScrolled,
  ICrudGetAction,
  ICrudGetAllAction,
  ICrudPutAction,
  ICrudDeleteAction,
  IPayloadResult,
  IPayload
} from 'react-jhipster';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

import { IAccountInfo, defaultValue } from 'app/shared/model/account-info.model';

export const ACTION_TYPES = {
  SEARCH_ACCOUNTINFOS: 'accountInfo/SEARCH_ACCOUNTINFOS',
  FETCH_ACCOUNTINFO_LIST: 'accountInfo/FETCH_ACCOUNTINFO_LIST',
  FETCH_ACCOUNTINFO: 'accountInfo/FETCH_ACCOUNTINFO',
  CREATE_ACCOUNTINFO: 'accountInfo/CREATE_ACCOUNTINFO',
  UPDATE_ACCOUNTINFO: 'accountInfo/UPDATE_ACCOUNTINFO',
  DELETE_ACCOUNTINFO: 'accountInfo/DELETE_ACCOUNTINFO',
  REPROCESS: 'accountInfo/REPROCESS',
  RESET: 'accountInfo/RESET'
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<IAccountInfo>,
  entity: defaultValue,
  links: { next: 0 },
  updating: false,
  totalItems: 0,
  updateSuccess: false
};

export declare type IReprocessAction<T> = (id?: string | number) => IPayload<T> | IPayloadResult<T>;

export type AccountInfoState = Readonly<typeof initialState>;

// Reducer

export default (state: AccountInfoState = initialState, action): AccountInfoState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.SEARCH_ACCOUNTINFOS):
    case REQUEST(ACTION_TYPES.FETCH_ACCOUNTINFO_LIST):
    case REQUEST(ACTION_TYPES.FETCH_ACCOUNTINFO):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true
      };
    case REQUEST(ACTION_TYPES.CREATE_ACCOUNTINFO):
    case REQUEST(ACTION_TYPES.UPDATE_ACCOUNTINFO):
    case REQUEST(ACTION_TYPES.DELETE_ACCOUNTINFO):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true
      };
    case REQUEST(ACTION_TYPES.REPROCESS):
    case FAILURE(ACTION_TYPES.SEARCH_ACCOUNTINFOS):
    case FAILURE(ACTION_TYPES.FETCH_ACCOUNTINFO_LIST):
    case FAILURE(ACTION_TYPES.FETCH_ACCOUNTINFO):
    case FAILURE(ACTION_TYPES.CREATE_ACCOUNTINFO):
    case FAILURE(ACTION_TYPES.UPDATE_ACCOUNTINFO):
    case FAILURE(ACTION_TYPES.DELETE_ACCOUNTINFO):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload
      };
    case FAILURE(ACTION_TYPES.REPROCESS):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload
      };
    case SUCCESS(ACTION_TYPES.SEARCH_ACCOUNTINFOS):
    case SUCCESS(ACTION_TYPES.FETCH_ACCOUNTINFO_LIST):
      const links = parseHeaderForLinks(action.payload.headers.link);
      return {
        ...state,
        links,
        loading: false,
        totalItems: action.payload.headers['x-total-count'],
        entities: loadMoreDataWhenScrolled(state.entities, action.payload.data, links)
      };
    case SUCCESS(ACTION_TYPES.FETCH_ACCOUNTINFO):
      return {
        ...state,
        loading: false,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.CREATE_ACCOUNTINFO):
    case SUCCESS(ACTION_TYPES.UPDATE_ACCOUNTINFO):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.REPROCESS):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.DELETE_ACCOUNTINFO):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {}
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

const apiUrl = 'api/account-infos';
const apiSearchUrl = 'api/_search/account-infos';

// Actions

export const getSearchEntities: ICrudSearchAction<IAccountInfo> = (query, page, size, sort) => ({
  type: ACTION_TYPES.SEARCH_ACCOUNTINFOS,
  payload: axios.get<IAccountInfo>(`${apiSearchUrl}?query=${query}${sort ? `&page=${page}&size=${size}&sort=${sort}` : ''}`)
});

export const getEntities: ICrudGetAllAction<IAccountInfo> = (page, size, sort) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.FETCH_ACCOUNTINFO_LIST,
    payload: axios.get<IAccountInfo>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`)
  };
};

export const getEntity: ICrudGetAction<IAccountInfo> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_ACCOUNTINFO,
    payload: axios.get<IAccountInfo>(requestUrl)
  };
};

export const createEntity: ICrudPutAction<IAccountInfo> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_ACCOUNTINFO,
    payload: axios.post(apiUrl, cleanEntity(entity))
  });
  return result;
};

export const updateEntity: ICrudPutAction<IAccountInfo> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_ACCOUNTINFO,
    payload: axios.put(apiUrl, cleanEntity(entity))
  });
  return result;
};

export const deleteEntity: ICrudDeleteAction<IAccountInfo> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_ACCOUNTINFO,
    payload: axios.delete(requestUrl)
  });
  return result;
};

export const reprocess: IReprocessAction<IAccountInfo> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}/reprocess`;
  const result = await dispatch({
    type: ACTION_TYPES.REPROCESS,
    payload: axios.post(requestUrl)
  });
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET
});
