import { combineReducers } from 'redux';
import { loadingBarReducer as loadingBar } from 'react-redux-loading-bar';

import locale, { LocaleState } from './locale';
import authentication, { AuthenticationState } from './authentication';
import applicationProfile, { ApplicationProfileState } from './application-profile';

import administration, { AdministrationState } from 'app/modules/administration/administration.reducer';
import userManagement, { UserManagementState } from 'app/modules/administration/user-management/user-management.reducer';
import register, { RegisterState } from 'app/modules/account/register/register.reducer';
import activate, { ActivateState } from 'app/modules/account/activate/activate.reducer';
import password, { PasswordState } from 'app/modules/account/password/password.reducer';
import settings, { SettingsState } from 'app/modules/account/settings/settings.reducer';
import passwordReset, { PasswordResetState } from 'app/modules/account/password-reset/password-reset.reducer';
// prettier-ignore
import attendantLeadMessage, {
  AttendantLeadMessageState
} from 'app/entities/attendant-lead-message/attendant-lead-message.reducer';
// prettier-ignore
import attendantLeadStatusCode, {
  AttendantLeadStatusCodeState
} from 'app/entities/attendant-lead-status-code/attendant-lead-status-code.reducer';
// prettier-ignore
import approvedProposalMessage, {
  ApprovedProposalMessageState
} from 'app/entities/approved-proposal-message/approved-proposal-message.reducer';
// prettier-ignore
import approvedProposalStatusCode, {
  ApprovedProposalStatusCodeState
} from 'app/entities/approved-proposal-status-code/approved-proposal-status-code.reducer';
// prettier-ignore
import approvedProposalSync, {
  ApprovedProposalSyncState
} from 'app/entities/approved-proposal-sync/approved-proposal-sync.reducer';
// prettier-ignore
import approvedProposalResponse, {
  ApprovedProposalResponseState
} from 'app/entities/approved-proposal-response/approved-proposal-response.reducer';
// prettier-ignore
import clientQualificationMessage, {
  ClientQualificationMessageState
} from 'app/entities/client-qualification-message/client-qualification-message.reducer';
// prettier-ignore
import clientQualificationStatusCode, {
  ClientQualificationStatusCodeState
} from 'app/entities/client-qualification-status-code/client-qualification-status-code.reducer';
// prettier-ignore
import clientQualificationSync, {
  ClientQualificationSyncState
} from 'app/entities/client-qualification-sync/client-qualification-sync.reducer';
// prettier-ignore
import clientQualificationResponse, {
  ClientQualificationResponseState
} from 'app/entities/client-qualification-response/client-qualification-response.reducer';
// prettier-ignore
import testDriveMessage, {
  TestDriveMessageState
} from 'app/entities/test-drive-message/test-drive-message.reducer';
// prettier-ignore
import testDriveStatusCode, {
  TestDriveStatusCodeState
} from 'app/entities/test-drive-status-code/test-drive-status-code.reducer';
// prettier-ignore
import testDriveSync, {
  TestDriveSyncState
} from 'app/entities/test-drive-sync/test-drive-sync.reducer';
// prettier-ignore
import testDriveResponse, {
  TestDriveResponseState
} from 'app/entities/test-drive-response/test-drive-response.reducer';
// prettier-ignore
import visitShowroomLeadMessage, {
  VisitShowroomLeadMessageState
} from 'app/entities/visit-showroom-lead-message/visit-showroom-lead-message.reducer';
// prettier-ignore
import visitShowroomStatusCode, {
  VisitShowroomStatusCodeState
} from 'app/entities/visit-showroom-status-code/visit-showroom-status-code.reducer';
// prettier-ignore
import visitShowroomSync, {
  VisitShowroomSyncState
} from 'app/entities/visit-showroom-sync/visit-showroom-sync.reducer';
// prettier-ignore
import visitShowroomResponse, {
  VisitShowroomResponseState
} from 'app/entities/visit-showroom-response/visit-showroom-response.reducer';
// prettier-ignore
import negotiationLeadMessage, {
  NegotiationLeadMessageState
} from 'app/entities/negotiation-lead-message/negotiation-lead-message.reducer';
// prettier-ignore
import negotiationStatusCode, {
  NegotiationStatusCodeState
} from 'app/entities/negotiation-status-code/negotiation-status-code.reducer';
// prettier-ignore
import negotiationSync, {
  NegotiationSyncState
} from 'app/entities/negotiation-sync/negotiation-sync.reducer';
// prettier-ignore
import negotiationResponse, {
  NegotiationResponseState
} from 'app/entities/negotiation-response/negotiation-response.reducer';
// prettier-ignore
import followUpLeadMessage, {
  FollowUpLeadMessageState
} from 'app/entities/follow-up-lead-message/follow-up-lead-message.reducer';
// prettier-ignore
import followUpStatusCode, {
  FollowUpStatusCodeState
} from 'app/entities/follow-up-status-code/follow-up-status-code.reducer';
// prettier-ignore
import followUpSync, {
  FollowUpSyncState
} from 'app/entities/follow-up-sync/follow-up-sync.reducer';
// prettier-ignore
import followUpResponse, {
  FollowUpResponseState
} from 'app/entities/follow-up-response/follow-up-response.reducer';
// prettier-ignore
import refusedProposalLeadMessage, {
  RefusedProposalLeadMessageState
} from 'app/entities/refused-proposal-lead-message/refused-proposal-lead-message.reducer';
// prettier-ignore
import refusedProposalStatusCode, {
  RefusedProposalStatusCodeState
} from 'app/entities/refused-proposal-status-code/refused-proposal-status-code.reducer';
// prettier-ignore
import refusedProposalSync, {
  RefusedProposalSyncState
} from 'app/entities/refused-proposal-sync/refused-proposal-sync.reducer';
// prettier-ignore
import refusedProposalResponse, {
  RefusedProposalResponseState
} from 'app/entities/refused-proposal-response/refused-proposal-response.reducer';
// prettier-ignore
import schedulingLeadResponse, {
  SchedulingLeadResponseState
} from 'app/entities/scheduling-lead-response/scheduling-lead-response.reducer';
// prettier-ignore
import schedulingLeadMessage, {
  SchedulingLeadMessageState
} from 'app/entities/scheduling-lead-message/scheduling-lead-message.reducer';
// prettier-ignore
import schedulingLeadStatusCode, {
  SchedulingLeadStatusCodeState
} from 'app/entities/scheduling-lead-status-code/scheduling-lead-status-code.reducer';
// prettier-ignore
import schedulingLeadSync, {
  SchedulingLeadSyncState
} from 'app/entities/scheduling-lead-sync/scheduling-lead-sync.reducer';
// prettier-ignore
import syonetLead, {
  SyonetLeadState
} from 'app/entities/syonet-lead/syonet-lead.reducer';
// prettier-ignore
import accountInfo, {
  AccountInfoState
} from 'app/entities/account-info/account-info.reducer';
// prettier-ignore
import executionHotLead, {
  ExecutionHotLeadState
} from 'app/entities/execution-hot-lead/execution-hot-lead.reducer';
// prettier-ignore
import executionGamma, {
  ExecutionGammaState
} from 'app/entities/execution-gamma/execution-gamma.reducer';
// prettier-ignore
import syonetAttendantLead, {
  SyonetAttendantLeadState
} from 'app/entities/syonet-attendant-lead/syonet-attendant-lead.reducer';
// prettier-ignore
import syonetSchedulingLead, {
  SyonetSchedulingLeadState
} from 'app/entities/syonet-scheduling-lead/syonet-scheduling-lead.reducer';
// prettier-ignore
import syonetVisitShowroom, {
  SyonetVisitShowroomState
} from 'app/entities/syonet-visit-showroom/syonet-visit-showroom.reducer';
// prettier-ignore
import syonetClientQualification, {
  SyonetClientQualificationState
} from 'app/entities/syonet-client-qualification/syonet-client-qualification.reducer';
// prettier-ignore
import clientQualificationVehicleChange, {
  ClientQualificationVehicleChangeState
} from 'app/entities/client-qualification-vehicle-change/client-qualification-vehicle-change.reducer';
// prettier-ignore
import clientQualificationVehicleInterested, {
  ClientQualificationVehicleInterestedState
} from 'app/entities/client-qualification-vehicle-interested/client-qualification-vehicle-interested.reducer';
// prettier-ignore
import syonetTestDrive, {
  SyonetTestDriveState
} from 'app/entities/syonet-test-drive/syonet-test-drive.reducer';
// prettier-ignore
import syonetNegotiation, {
  SyonetNegotiationState
} from 'app/entities/syonet-negotiation/syonet-negotiation.reducer';
// prettier-ignore
import negotiationVehicleInterested, {
  NegotiationVehicleInterestedState
} from 'app/entities/negotiation-vehicle-interested/negotiation-vehicle-interested.reducer';
// prettier-ignore
import negotiationVehicleOnTrade, {
  NegotiationVehicleOnTradeState
} from 'app/entities/negotiation-vehicle-on-trade/negotiation-vehicle-on-trade.reducer';
// prettier-ignore
import syonetApprovedProposal, {
  SyonetApprovedProposalState
} from 'app/entities/syonet-approved-proposal/syonet-approved-proposal.reducer';
// prettier-ignore
import clientApprovedProposal, {
  ClientApprovedProposalState
} from 'app/entities/client-approved-proposal/client-approved-proposal.reducer';
// prettier-ignore
import vehicleSaledApprovedProposal, {
  VehicleSaledApprovedProposalState
} from 'app/entities/vehicle-saled-approved-proposal/vehicle-saled-approved-proposal.reducer';
// prettier-ignore
import syonetRefusedProposal, {
  SyonetRefusedProposalState
} from 'app/entities/syonet-refused-proposal/syonet-refused-proposal.reducer';
// prettier-ignore
import syonetFollowUp, {
  SyonetFollowUpState
} from 'app/entities/syonet-follow-up/syonet-follow-up.reducer';
// prettier-ignore
import attendantLeadResponse, {
  AttendantLeadResponseState
} from 'app/entities/attendant-lead-response/attendant-lead-response.reducer';
// prettier-ignore
import responseHotLead, {
  ResponseHotLeadState
} from 'app/entities/response-hot-lead/response-hot-lead.reducer';
// prettier-ignore
import vehicleNissanInterest, {
  VehicleNissanInterestState
} from 'app/entities/vehicle-nissan-interest/vehicle-nissan-interest.reducer';
// prettier-ignore
import vehicleExchangeNissan, {
  VehicleExchangeNissanState
} from 'app/entities/vehicle-exchange-nissan/vehicle-exchange-nissan.reducer';
// prettier-ignore
import lead, {
  LeadState
} from 'app/entities/lead/lead.reducer';
// prettier-ignore
import nissanModel, {
  NissanModelState
} from 'app/entities/nissan-model/nissan-model.reducer';
// prettier-ignore
import color, {
  ColorState
} from 'app/entities/color/color.reducer';
// prettier-ignore
import responseGammaMessage, {
  ResponseGammaMessageState
} from 'app/entities/response-gamma-message/response-gamma-message.reducer';
// prettier-ignore
import responseGammaStatusCode, {
  ResponseGammaStatusCodeState
} from 'app/entities/response-gamma-status-code/response-gamma-status-code.reducer';
// prettier-ignore
import responseHotLeadMessage, {
  ResponseHotLeadMessageState
} from 'app/entities/response-hot-lead-message/response-hot-lead-message.reducer';
// prettier-ignore
import responseHotLeadStatusCode, {
  ResponseHotLeadStatusCodeState
} from 'app/entities/response-hot-lead-status-code/response-hot-lead-status-code.reducer';
// prettier-ignore
import responseGamma, {
  ResponseGammaState
} from 'app/entities/response-gamma/response-gamma.reducer';
// prettier-ignore
import syncGamma, {
  SyncGammaState
} from 'app/entities/sync-gamma/sync-gamma.reducer';
// prettier-ignore
import syncHotLead, {
  SyncHotLeadState
} from 'app/entities/sync-hot-lead/sync-hot-lead.reducer';
// prettier-ignore
import attendantLeadSync, {
  AttendantLeadSyncState
} from 'app/entities/attendant-lead-sync/attendant-lead-sync.reducer';
/* jhipster-needle-add-reducer-import - JHipster will add reducer here */

export interface IRootState {
  readonly authentication: AuthenticationState;
  readonly locale: LocaleState;
  readonly applicationProfile: ApplicationProfileState;
  readonly administration: AdministrationState;
  readonly userManagement: UserManagementState;
  readonly register: RegisterState;
  readonly activate: ActivateState;
  readonly passwordReset: PasswordResetState;
  readonly password: PasswordState;
  readonly settings: SettingsState;
  readonly attendantLeadMessage: AttendantLeadMessageState;
  readonly attendantLeadStatusCode: AttendantLeadStatusCodeState;
  readonly approvedProposalMessage: ApprovedProposalMessageState;
  readonly approvedProposalStatusCode: ApprovedProposalStatusCodeState;
  readonly approvedProposalSync: ApprovedProposalSyncState;
  readonly approvedProposalResponse: ApprovedProposalResponseState;
  readonly clientQualificationMessage: ClientQualificationMessageState;
  readonly clientQualificationStatusCode: ClientQualificationStatusCodeState;
  readonly clientQualificationSync: ClientQualificationSyncState;
  readonly clientQualificationResponse: ClientQualificationResponseState;
  readonly testDriveMessage: TestDriveMessageState;
  readonly testDriveStatusCode: TestDriveStatusCodeState;
  readonly testDriveSync: TestDriveSyncState;
  readonly testDriveResponse: TestDriveResponseState;
  readonly visitShowroomLeadMessage: VisitShowroomLeadMessageState;
  readonly visitShowroomStatusCode: VisitShowroomStatusCodeState;
  readonly visitShowroomSync: VisitShowroomSyncState;
  readonly visitShowroomResponse: VisitShowroomResponseState;
  readonly negotiationLeadMessage: NegotiationLeadMessageState;
  readonly negotiationStatusCode: NegotiationStatusCodeState;
  readonly negotiationSync: NegotiationSyncState;
  readonly negotiationResponse: NegotiationResponseState;
  readonly followUpLeadMessage: FollowUpLeadMessageState;
  readonly followUpStatusCode: FollowUpStatusCodeState;
  readonly followUpSync: FollowUpSyncState;
  readonly followUpResponse: FollowUpResponseState;
  readonly refusedProposalLeadMessage: RefusedProposalLeadMessageState;
  readonly refusedProposalStatusCode: RefusedProposalStatusCodeState;
  readonly refusedProposalSync: RefusedProposalSyncState;
  readonly refusedProposalResponse: RefusedProposalResponseState;
  readonly schedulingLeadResponse: SchedulingLeadResponseState;
  readonly schedulingLeadMessage: SchedulingLeadMessageState;
  readonly schedulingLeadStatusCode: SchedulingLeadStatusCodeState;
  readonly schedulingLeadSync: SchedulingLeadSyncState;
  readonly syonetLead: SyonetLeadState;
  readonly accountInfo: AccountInfoState;
  readonly executionHotLead: ExecutionHotLeadState;
  readonly executionGamma: ExecutionGammaState;
  readonly syonetAttendantLead: SyonetAttendantLeadState;
  readonly syonetSchedulingLead: SyonetSchedulingLeadState;
  readonly syonetVisitShowroom: SyonetVisitShowroomState;
  readonly syonetClientQualification: SyonetClientQualificationState;
  readonly clientQualificationVehicleChange: ClientQualificationVehicleChangeState;
  readonly clientQualificationVehicleInterested: ClientQualificationVehicleInterestedState;
  readonly syonetTestDrive: SyonetTestDriveState;
  readonly syonetNegotiation: SyonetNegotiationState;
  readonly negotiationVehicleInterested: NegotiationVehicleInterestedState;
  readonly negotiationVehicleOnTrade: NegotiationVehicleOnTradeState;
  readonly syonetApprovedProposal: SyonetApprovedProposalState;
  readonly clientApprovedProposal: ClientApprovedProposalState;
  readonly vehicleSaledApprovedProposal: VehicleSaledApprovedProposalState;
  readonly syonetRefusedProposal: SyonetRefusedProposalState;
  readonly syonetFollowUp: SyonetFollowUpState;
  readonly attendantLeadResponse: AttendantLeadResponseState;
  readonly responseHotLead: ResponseHotLeadState;
  readonly vehicleNissanInterest: VehicleNissanInterestState;
  readonly vehicleExchangeNissan: VehicleExchangeNissanState;
  readonly lead: LeadState;
  readonly nissanModel: NissanModelState;
  readonly color: ColorState;
  readonly responseGammaMessage: ResponseGammaMessageState;
  readonly responseGammaStatusCode: ResponseGammaStatusCodeState;
  readonly responseHotLeadMessage: ResponseHotLeadMessageState;
  readonly responseHotLeadStatusCode: ResponseHotLeadStatusCodeState;
  readonly responseGamma: ResponseGammaState;
  readonly syncGamma: SyncGammaState;
  readonly syncHotLead: SyncHotLeadState;
  readonly attendantLeadSync: AttendantLeadSyncState;
  /* jhipster-needle-add-reducer-type - JHipster will add reducer type here */
  readonly loadingBar: any;
}

const rootReducer = combineReducers<IRootState>({
  authentication,
  locale,
  applicationProfile,
  administration,
  userManagement,
  register,
  activate,
  passwordReset,
  password,
  settings,
  attendantLeadMessage,
  attendantLeadStatusCode,
  approvedProposalMessage,
  approvedProposalStatusCode,
  approvedProposalSync,
  approvedProposalResponse,
  clientQualificationMessage,
  clientQualificationStatusCode,
  clientQualificationSync,
  clientQualificationResponse,
  testDriveMessage,
  testDriveStatusCode,
  testDriveSync,
  testDriveResponse,
  visitShowroomLeadMessage,
  visitShowroomStatusCode,
  visitShowroomSync,
  visitShowroomResponse,
  negotiationLeadMessage,
  negotiationStatusCode,
  negotiationSync,
  negotiationResponse,
  followUpLeadMessage,
  followUpStatusCode,
  followUpSync,
  followUpResponse,
  refusedProposalLeadMessage,
  refusedProposalStatusCode,
  refusedProposalSync,
  refusedProposalResponse,
  schedulingLeadResponse,
  schedulingLeadMessage,
  schedulingLeadStatusCode,
  schedulingLeadSync,
  syonetLead,
  accountInfo,
  executionHotLead,
  executionGamma,
  syonetAttendantLead,
  syonetSchedulingLead,
  syonetVisitShowroom,
  syonetClientQualification,
  clientQualificationVehicleChange,
  clientQualificationVehicleInterested,
  syonetTestDrive,
  syonetNegotiation,
  negotiationVehicleInterested,
  negotiationVehicleOnTrade,
  syonetApprovedProposal,
  clientApprovedProposal,
  vehicleSaledApprovedProposal,
  syonetRefusedProposal,
  syonetFollowUp,
  attendantLeadResponse,
  responseHotLead,
  vehicleNissanInterest,
  vehicleExchangeNissan,
  lead,
  nissanModel,
  color,
  responseGammaMessage,
  responseGammaStatusCode,
  responseHotLeadMessage,
  responseHotLeadStatusCode,
  responseGamma,
  syncGamma,
  syncHotLead,
  attendantLeadSync,
  /* jhipster-needle-add-reducer-combine - JHipster will add reducer here */
  loadingBar
});

export default rootReducer;
