import { Moment } from 'moment';
import { IVehicleExchangeNissan } from 'app/shared/model/vehicle-exchange-nissan.model';
import { IVehicleNissanInterest } from 'app/shared/model/vehicle-nissan-interest.model';

export interface ILead {
  id?: number;
  idLead?: number;
  salesChannel?: number;
  saleCategory?: number;
  mediaAttraction?: number;
  idCampaign?: number;
  campaign?: string;
  dateAvailableLead?: Moment;
  dateConsumerLead?: Moment;
  datePreScheduled?: Moment;
  periodPreScheduled?: number;
  periodInterestPurchase?: number;
  clientID?: string;
  name?: string;
  email?: string;
  areaCodeNumber?: number;
  phoneNumber?: number;
  contactForm?: string;
  zipCode?: string;
  federalUnit?: string;
  city?: string;
  personsWithSpecialNeeds?: number;
  nissanClient?: number;
  flagTestDrived?: number;
  testDriveLocal?: string;
  flagFinanc?: number;
  financingValue?: number;
  financingPreApproved?: number;
  consortium?: number;
  rangeValue?: number;
  note?: string;
  responseHotLeadId?: number;
  vehiclesExchangeds?: IVehicleExchangeNissan[];
  vehiclesInterests?: IVehicleNissanInterest[];
}

export const defaultValue: Readonly<ILead> = {};
