import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, UncontrolledTooltip, Row, Col } from 'reactstrap';
// tslint:disable-next-line:no-unused-variable
import { Translate, ICrudGetAction } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntity } from './visit-showroom-lead-message.reducer';
import { IVisitShowroomLeadMessage } from 'app/shared/model/visit-showroom-lead-message.model';
// tslint:disable-next-line:no-unused-variable
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';

export interface IVisitShowroomLeadMessageDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export class VisitShowroomLeadMessageDetail extends React.Component<IVisitShowroomLeadMessageDetailProps> {
  componentDidMount() {
    this.props.getEntity(this.props.match.params.id);
  }

  render() {
    const { visitShowroomLeadMessageEntity } = this.props;
    return (
      <Row>
        <Col md="8">
          <h2>
            <Translate contentKey="nissanApp.visitShowroomLeadMessage.detail.title">VisitShowroomLeadMessage</Translate> [
            <b>{visitShowroomLeadMessageEntity.id}</b>]
          </h2>
          <dl className="jh-entity-details">
            <dt>
              <span id="message">
                <Translate contentKey="nissanApp.visitShowroomLeadMessage.message">Message</Translate>
              </span>
              <UncontrolledTooltip target="message">
                <Translate contentKey="nissanApp.visitShowroomLeadMessage.help.message" />
              </UncontrolledTooltip>
            </dt>
            <dd>{visitShowroomLeadMessageEntity.message}</dd>
            <dt>
              <span id="messageCode">
                <Translate contentKey="nissanApp.visitShowroomLeadMessage.messageCode">Message Code</Translate>
              </span>
              <UncontrolledTooltip target="messageCode">
                <Translate contentKey="nissanApp.visitShowroomLeadMessage.help.messageCode" />
              </UncontrolledTooltip>
            </dt>
            <dd>{visitShowroomLeadMessageEntity.messageCode}</dd>
            <dt>
              <span id="registryNumber">
                <Translate contentKey="nissanApp.visitShowroomLeadMessage.registryNumber">Registry Number</Translate>
              </span>
              <UncontrolledTooltip target="registryNumber">
                <Translate contentKey="nissanApp.visitShowroomLeadMessage.help.registryNumber" />
              </UncontrolledTooltip>
            </dt>
            <dd>{visitShowroomLeadMessageEntity.registryNumber}</dd>
            <dt>
              <span id="returnType">
                <Translate contentKey="nissanApp.visitShowroomLeadMessage.returnType">Return Type</Translate>
              </span>
              <UncontrolledTooltip target="returnType">
                <Translate contentKey="nissanApp.visitShowroomLeadMessage.help.returnType" />
              </UncontrolledTooltip>
            </dt>
            <dd>{visitShowroomLeadMessageEntity.returnType}</dd>
            <dt>
              <Translate contentKey="nissanApp.visitShowroomLeadMessage.visitShowroomResponse">Visit Showroom Response</Translate>
            </dt>
            <dd>{visitShowroomLeadMessageEntity.visitShowroomResponseId ? visitShowroomLeadMessageEntity.visitShowroomResponseId : ''}</dd>
          </dl>
          <Button tag={Link} to="/entity/visit-showroom-lead-message" replace color="info">
            <FontAwesomeIcon icon="arrow-left" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.back">Back</Translate>
            </span>
          </Button>
          &nbsp;
          <Button tag={Link} to={`/entity/visit-showroom-lead-message/${visitShowroomLeadMessageEntity.id}/edit`} replace color="primary">
            <FontAwesomeIcon icon="pencil-alt" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.edit">Edit</Translate>
            </span>
          </Button>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = ({ visitShowroomLeadMessage }: IRootState) => ({
  visitShowroomLeadMessageEntity: visitShowroomLeadMessage.entity
});

const mapDispatchToProps = { getEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VisitShowroomLeadMessageDetail);
