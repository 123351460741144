import { Moment } from 'moment';
import { ITestDriveSync } from 'app/shared/model/test-drive-sync.model';

export interface ISyonetTestDrive {
  id?: number;
  lastSync?: Moment;
  crmID?: string;
  extracionDate?: Moment;
  attendanceID?: number;
  nissanLeadID?: number;
  testDriveDate?: Moment;
  note?: string;
  testDriveModelCode?: string;
  testDriveEimCode?: string;
  testDriveColorCode?: string;
  testDriveModelYear?: number;
  syonetLeadId?: number;
  syncs?: ITestDriveSync[];
}

export const defaultValue: Readonly<ISyonetTestDrive> = {};
