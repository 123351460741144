import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col, Label, UncontrolledTooltip } from 'reactstrap';
import { AvFeedback, AvForm, AvGroup, AvInput, AvField } from 'availity-reactstrap-validation';
// tslint:disable-next-line:no-unused-variable
import { Translate, translate, ICrudGetAction, ICrudGetAllAction, ICrudPutAction } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';

import { ISyonetLead } from 'app/shared/model/syonet-lead.model';
import { getEntities as getSyonetLeads } from 'app/entities/syonet-lead/syonet-lead.reducer';
import { getEntity, updateEntity, createEntity, reset } from './syonet-attendant-lead.reducer';
import { ISyonetAttendantLead } from 'app/shared/model/syonet-attendant-lead.model';
// tslint:disable-next-line:no-unused-variable
import { convertDateTimeFromServer, convertDateTimeToServer } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';

export interface ISyonetAttendantLeadUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export interface ISyonetAttendantLeadUpdateState {
  isNew: boolean;
  syonetLeadId: string;
}

export class SyonetAttendantLeadUpdate extends React.Component<ISyonetAttendantLeadUpdateProps, ISyonetAttendantLeadUpdateState> {
  constructor(props) {
    super(props);
    this.state = {
      syonetLeadId: '0',
      isNew: !this.props.match.params || !this.props.match.params.id
    };
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextProps.updateSuccess !== this.props.updateSuccess && nextProps.updateSuccess) {
      this.handleClose();
    }
  }

  componentDidMount() {
    if (!this.state.isNew) {
      this.props.getEntity(this.props.match.params.id);
    }

    this.props.getSyonetLeads();
  }

  saveEntity = (event, errors, values) => {
    values.lastSync = convertDateTimeToServer(values.lastSync);
    values.extracionDate = convertDateTimeToServer(values.extracionDate);
    values.attendanceDate = convertDateTimeToServer(values.attendanceDate);
    values.clientBirthday = convertDateTimeToServer(values.clientBirthday);

    if (errors.length === 0) {
      const { syonetAttendantLeadEntity } = this.props;
      const entity = {
        ...syonetAttendantLeadEntity,
        ...values
      };

      if (this.state.isNew) {
        this.props.createEntity(entity);
      } else {
        this.props.updateEntity(entity);
      }
    }
  };

  handleClose = () => {
    this.props.history.push('/entity/syonet-attendant-lead');
  };

  render() {
    const { syonetAttendantLeadEntity, syonetLeads, loading, updating } = this.props;
    const { isNew } = this.state;

    return (
      <div>
        <Row className="justify-content-center">
          <Col md="8">
            <h2 id="nissanApp.syonetAttendantLead.home.createOrEditLabel">
              <Translate contentKey="nissanApp.syonetAttendantLead.home.createOrEditLabel">Create or edit a SyonetAttendantLead</Translate>
            </h2>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md="8">
            {loading ? (
              <p>Loading...</p>
            ) : (
              <AvForm model={isNew ? {} : syonetAttendantLeadEntity} onSubmit={this.saveEntity}>
                {!isNew ? (
                  <AvGroup>
                    <Label for="syonet-attendant-lead-id">
                      <Translate contentKey="global.field.id">ID</Translate>
                    </Label>
                    <AvInput id="syonet-attendant-lead-id" type="text" className="form-control" name="id" required readOnly />
                  </AvGroup>
                ) : null}
                <AvGroup>
                  <Label id="lastSyncLabel" for="syonet-attendant-lead-lastSync">
                    <Translate contentKey="nissanApp.syonetAttendantLead.lastSync">Last Sync</Translate>
                  </Label>
                  <AvInput
                    id="syonet-attendant-lead-lastSync"
                    type="datetime-local"
                    className="form-control"
                    name="lastSync"
                    placeholder={'YYYY-MM-DD HH:mm'}
                    value={isNew ? null : convertDateTimeFromServer(this.props.syonetAttendantLeadEntity.lastSync)}
                  />
                  <UncontrolledTooltip target="lastSyncLabel">
                    <Translate contentKey="nissanApp.syonetAttendantLead.help.lastSync" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="crmIDLabel" for="syonet-attendant-lead-crmID">
                    <Translate contentKey="nissanApp.syonetAttendantLead.crmID">Crm ID</Translate>
                  </Label>
                  <AvField id="syonet-attendant-lead-crmID" type="text" name="crmID" />
                  <UncontrolledTooltip target="crmIDLabel">
                    <Translate contentKey="nissanApp.syonetAttendantLead.help.crmID" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="extracionDateLabel" for="syonet-attendant-lead-extracionDate">
                    <Translate contentKey="nissanApp.syonetAttendantLead.extracionDate">Extracion Date</Translate>
                  </Label>
                  <AvInput
                    id="syonet-attendant-lead-extracionDate"
                    type="datetime-local"
                    className="form-control"
                    name="extracionDate"
                    placeholder={'YYYY-MM-DD HH:mm'}
                    value={isNew ? null : convertDateTimeFromServer(this.props.syonetAttendantLeadEntity.extracionDate)}
                    validate={{
                      required: { value: true, errorMessage: translate('entity.validation.required') }
                    }}
                  />
                  <UncontrolledTooltip target="extracionDateLabel">
                    <Translate contentKey="nissanApp.syonetAttendantLead.help.extracionDate" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="attendanceIDLabel" for="syonet-attendant-lead-attendanceID">
                    <Translate contentKey="nissanApp.syonetAttendantLead.attendanceID">Attendance ID</Translate>
                  </Label>
                  <AvField
                    id="syonet-attendant-lead-attendanceID"
                    type="string"
                    className="form-control"
                    name="attendanceID"
                    validate={{
                      required: { value: true, errorMessage: translate('entity.validation.required') },
                      number: { value: true, errorMessage: translate('entity.validation.number') }
                    }}
                  />
                  <UncontrolledTooltip target="attendanceIDLabel">
                    <Translate contentKey="nissanApp.syonetAttendantLead.help.attendanceID" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="attendanceDateLabel" for="syonet-attendant-lead-attendanceDate">
                    <Translate contentKey="nissanApp.syonetAttendantLead.attendanceDate">Attendance Date</Translate>
                  </Label>
                  <AvInput
                    id="syonet-attendant-lead-attendanceDate"
                    type="datetime-local"
                    className="form-control"
                    name="attendanceDate"
                    placeholder={'YYYY-MM-DD HH:mm'}
                    value={isNew ? null : convertDateTimeFromServer(this.props.syonetAttendantLeadEntity.attendanceDate)}
                    validate={{
                      required: { value: true, errorMessage: translate('entity.validation.required') }
                    }}
                  />
                  <UncontrolledTooltip target="attendanceDateLabel">
                    <Translate contentKey="nissanApp.syonetAttendantLead.help.attendanceDate" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="sourceAttendanceLabel" for="syonet-attendant-lead-sourceAttendance">
                    <Translate contentKey="nissanApp.syonetAttendantLead.sourceAttendance">Source Attendance</Translate>
                  </Label>
                  <AvField
                    id="syonet-attendant-lead-sourceAttendance"
                    type="string"
                    className="form-control"
                    name="sourceAttendance"
                    validate={{
                      required: { value: true, errorMessage: translate('entity.validation.required') },
                      number: { value: true, errorMessage: translate('entity.validation.number') }
                    }}
                  />
                  <UncontrolledTooltip target="sourceAttendanceLabel">
                    <Translate contentKey="nissanApp.syonetAttendantLead.help.sourceAttendance" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="nissanLeadIDLabel" for="syonet-attendant-lead-nissanLeadID">
                    <Translate contentKey="nissanApp.syonetAttendantLead.nissanLeadID">Nissan Lead ID</Translate>
                  </Label>
                  <AvField id="syonet-attendant-lead-nissanLeadID" type="string" className="form-control" name="nissanLeadID" />
                  <UncontrolledTooltip target="nissanLeadIDLabel">
                    <Translate contentKey="nissanApp.syonetAttendantLead.help.nissanLeadID" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="salesChannelLabel" for="syonet-attendant-lead-salesChannel">
                    <Translate contentKey="nissanApp.syonetAttendantLead.salesChannel">Sales Channel</Translate>
                  </Label>
                  <AvField id="syonet-attendant-lead-salesChannel" type="string" className="form-control" name="salesChannel" />
                  <UncontrolledTooltip target="salesChannelLabel">
                    <Translate contentKey="nissanApp.syonetAttendantLead.help.salesChannel" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="saleCategoryLabel" for="syonet-attendant-lead-saleCategory">
                    <Translate contentKey="nissanApp.syonetAttendantLead.saleCategory">Sale Category</Translate>
                  </Label>
                  <AvField id="syonet-attendant-lead-saleCategory" type="string" className="form-control" name="saleCategory" />
                  <UncontrolledTooltip target="saleCategoryLabel">
                    <Translate contentKey="nissanApp.syonetAttendantLead.help.saleCategory" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="mediaAttractionLabel" for="syonet-attendant-lead-mediaAttraction">
                    <Translate contentKey="nissanApp.syonetAttendantLead.mediaAttraction">Media Attraction</Translate>
                  </Label>
                  <AvField id="syonet-attendant-lead-mediaAttraction" type="string" className="form-control" name="mediaAttraction" />
                  <UncontrolledTooltip target="mediaAttractionLabel">
                    <Translate contentKey="nissanApp.syonetAttendantLead.help.mediaAttraction" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="campaignIDLabel" for="syonet-attendant-lead-campaignID">
                    <Translate contentKey="nissanApp.syonetAttendantLead.campaignID">Campaign ID</Translate>
                  </Label>
                  <AvField id="syonet-attendant-lead-campaignID" type="string" className="form-control" name="campaignID" />
                  <UncontrolledTooltip target="campaignIDLabel">
                    <Translate contentKey="nissanApp.syonetAttendantLead.help.campaignID" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="campaignLabel" for="syonet-attendant-lead-campaign">
                    <Translate contentKey="nissanApp.syonetAttendantLead.campaign">Campaign</Translate>
                  </Label>
                  <AvField id="syonet-attendant-lead-campaign" type="text" name="campaign" />
                  <UncontrolledTooltip target="campaignLabel">
                    <Translate contentKey="nissanApp.syonetAttendantLead.help.campaign" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="temperatureLabel" for="syonet-attendant-lead-temperature">
                    <Translate contentKey="nissanApp.syonetAttendantLead.temperature">Temperature</Translate>
                  </Label>
                  <AvField id="syonet-attendant-lead-temperature" type="string" className="form-control" name="temperature" />
                  <UncontrolledTooltip target="temperatureLabel">
                    <Translate contentKey="nissanApp.syonetAttendantLead.help.temperature" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="salesmanIDLabel" for="syonet-attendant-lead-salesmanID">
                    <Translate contentKey="nissanApp.syonetAttendantLead.salesmanID">Salesman ID</Translate>
                  </Label>
                  <AvField id="syonet-attendant-lead-salesmanID" type="text" name="salesmanID" />
                  <UncontrolledTooltip target="salesmanIDLabel">
                    <Translate contentKey="nissanApp.syonetAttendantLead.help.salesmanID" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="salesmanNameLabel" for="syonet-attendant-lead-salesmanName">
                    <Translate contentKey="nissanApp.syonetAttendantLead.salesmanName">Salesman Name</Translate>
                  </Label>
                  <AvField id="syonet-attendant-lead-salesmanName" type="text" name="salesmanName" />
                  <UncontrolledTooltip target="salesmanNameLabel">
                    <Translate contentKey="nissanApp.syonetAttendantLead.help.salesmanName" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="salesmanEmailLabel" for="syonet-attendant-lead-salesmanEmail">
                    <Translate contentKey="nissanApp.syonetAttendantLead.salesmanEmail">Salesman Email</Translate>
                  </Label>
                  <AvField id="syonet-attendant-lead-salesmanEmail" type="text" name="salesmanEmail" />
                  <UncontrolledTooltip target="salesmanEmailLabel">
                    <Translate contentKey="nissanApp.syonetAttendantLead.help.salesmanEmail" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="salesManagerIDLabel" for="syonet-attendant-lead-salesManagerID">
                    <Translate contentKey="nissanApp.syonetAttendantLead.salesManagerID">Sales Manager ID</Translate>
                  </Label>
                  <AvField id="syonet-attendant-lead-salesManagerID" type="text" name="salesManagerID" />
                  <UncontrolledTooltip target="salesManagerIDLabel">
                    <Translate contentKey="nissanApp.syonetAttendantLead.help.salesManagerID" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="salesManagerNameLabel" for="syonet-attendant-lead-salesManagerName">
                    <Translate contentKey="nissanApp.syonetAttendantLead.salesManagerName">Sales Manager Name</Translate>
                  </Label>
                  <AvField id="syonet-attendant-lead-salesManagerName" type="text" name="salesManagerName" />
                  <UncontrolledTooltip target="salesManagerNameLabel">
                    <Translate contentKey="nissanApp.syonetAttendantLead.help.salesManagerName" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="salesManagerEmailLabel" for="syonet-attendant-lead-salesManagerEmail">
                    <Translate contentKey="nissanApp.syonetAttendantLead.salesManagerEmail">Sales Manager Email</Translate>
                  </Label>
                  <AvField id="syonet-attendant-lead-salesManagerEmail" type="text" name="salesManagerEmail" />
                  <UncontrolledTooltip target="salesManagerEmailLabel">
                    <Translate contentKey="nissanApp.syonetAttendantLead.help.salesManagerEmail" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="clienteTypeLabel" for="syonet-attendant-lead-clienteType">
                    <Translate contentKey="nissanApp.syonetAttendantLead.clienteType">Cliente Type</Translate>
                  </Label>
                  <AvField
                    id="syonet-attendant-lead-clienteType"
                    type="text"
                    name="clienteType"
                    validate={{
                      required: { value: true, errorMessage: translate('entity.validation.required') }
                    }}
                  />
                  <UncontrolledTooltip target="clienteTypeLabel">
                    <Translate contentKey="nissanApp.syonetAttendantLead.help.clienteType" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="clientNationalityLabel" for="syonet-attendant-lead-clientNationality">
                    <Translate contentKey="nissanApp.syonetAttendantLead.clientNationality">Client Nationality</Translate>
                  </Label>
                  <AvField id="syonet-attendant-lead-clientNationality" type="text" name="clientNationality" />
                  <UncontrolledTooltip target="clientNationalityLabel">
                    <Translate contentKey="nissanApp.syonetAttendantLead.help.clientNationality" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="clienteIDLabel" for="syonet-attendant-lead-clienteID">
                    <Translate contentKey="nissanApp.syonetAttendantLead.clienteID">Cliente ID</Translate>
                  </Label>
                  <AvField id="syonet-attendant-lead-clienteID" type="text" name="clienteID" />
                  <UncontrolledTooltip target="clienteIDLabel">
                    <Translate contentKey="nissanApp.syonetAttendantLead.help.clienteID" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="clientInternacionalIDLabel" for="syonet-attendant-lead-clientInternacionalID">
                    <Translate contentKey="nissanApp.syonetAttendantLead.clientInternacionalID">Client Internacional ID</Translate>
                  </Label>
                  <AvField id="syonet-attendant-lead-clientInternacionalID" type="text" name="clientInternacionalID" />
                  <UncontrolledTooltip target="clientInternacionalIDLabel">
                    <Translate contentKey="nissanApp.syonetAttendantLead.help.clientInternacionalID" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="clienteFullNameLabel" for="syonet-attendant-lead-clienteFullName">
                    <Translate contentKey="nissanApp.syonetAttendantLead.clienteFullName">Cliente Full Name</Translate>
                  </Label>
                  <AvField id="syonet-attendant-lead-clienteFullName" type="text" name="clienteFullName" />
                  <UncontrolledTooltip target="clienteFullNameLabel">
                    <Translate contentKey="nissanApp.syonetAttendantLead.help.clienteFullName" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="clientSocialNameLabel" for="syonet-attendant-lead-clientSocialName">
                    <Translate contentKey="nissanApp.syonetAttendantLead.clientSocialName">Client Social Name</Translate>
                  </Label>
                  <AvField id="syonet-attendant-lead-clientSocialName" type="text" name="clientSocialName" />
                  <UncontrolledTooltip target="clientSocialNameLabel">
                    <Translate contentKey="nissanApp.syonetAttendantLead.help.clientSocialName" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="clientFantasyNameLabel" for="syonet-attendant-lead-clientFantasyName">
                    <Translate contentKey="nissanApp.syonetAttendantLead.clientFantasyName">Client Fantasy Name</Translate>
                  </Label>
                  <AvField id="syonet-attendant-lead-clientFantasyName" type="text" name="clientFantasyName" />
                  <UncontrolledTooltip target="clientFantasyNameLabel">
                    <Translate contentKey="nissanApp.syonetAttendantLead.help.clientFantasyName" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="contactNameLabel" for="syonet-attendant-lead-contactName">
                    <Translate contentKey="nissanApp.syonetAttendantLead.contactName">Contact Name</Translate>
                  </Label>
                  <AvField id="syonet-attendant-lead-contactName" type="text" name="contactName" />
                  <UncontrolledTooltip target="contactNameLabel">
                    <Translate contentKey="nissanApp.syonetAttendantLead.help.contactName" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="addressTypeLabel" for="syonet-attendant-lead-addressType">
                    <Translate contentKey="nissanApp.syonetAttendantLead.addressType">Address Type</Translate>
                  </Label>
                  <AvField id="syonet-attendant-lead-addressType" type="string" className="form-control" name="addressType" />
                  <UncontrolledTooltip target="addressTypeLabel">
                    <Translate contentKey="nissanApp.syonetAttendantLead.help.addressType" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="addressNameLabel" for="syonet-attendant-lead-addressName">
                    <Translate contentKey="nissanApp.syonetAttendantLead.addressName">Address Name</Translate>
                  </Label>
                  <AvField id="syonet-attendant-lead-addressName" type="text" name="addressName" />
                  <UncontrolledTooltip target="addressNameLabel">
                    <Translate contentKey="nissanApp.syonetAttendantLead.help.addressName" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="addressNumberLabel" for="syonet-attendant-lead-addressNumber">
                    <Translate contentKey="nissanApp.syonetAttendantLead.addressNumber">Address Number</Translate>
                  </Label>
                  <AvField id="syonet-attendant-lead-addressNumber" type="text" name="addressNumber" />
                  <UncontrolledTooltip target="addressNumberLabel">
                    <Translate contentKey="nissanApp.syonetAttendantLead.help.addressNumber" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="addressComplementLabel" for="syonet-attendant-lead-addressComplement">
                    <Translate contentKey="nissanApp.syonetAttendantLead.addressComplement">Address Complement</Translate>
                  </Label>
                  <AvField id="syonet-attendant-lead-addressComplement" type="text" name="addressComplement" />
                  <UncontrolledTooltip target="addressComplementLabel">
                    <Translate contentKey="nissanApp.syonetAttendantLead.help.addressComplement" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="addressNeighborhoodLabel" for="syonet-attendant-lead-addressNeighborhood">
                    <Translate contentKey="nissanApp.syonetAttendantLead.addressNeighborhood">Address Neighborhood</Translate>
                  </Label>
                  <AvField id="syonet-attendant-lead-addressNeighborhood" type="text" name="addressNeighborhood" />
                  <UncontrolledTooltip target="addressNeighborhoodLabel">
                    <Translate contentKey="nissanApp.syonetAttendantLead.help.addressNeighborhood" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="addressCityLabel" for="syonet-attendant-lead-addressCity">
                    <Translate contentKey="nissanApp.syonetAttendantLead.addressCity">Address City</Translate>
                  </Label>
                  <AvField id="syonet-attendant-lead-addressCity" type="text" name="addressCity" />
                  <UncontrolledTooltip target="addressCityLabel">
                    <Translate contentKey="nissanApp.syonetAttendantLead.help.addressCity" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="addressStateIDLabel" for="syonet-attendant-lead-addressStateID">
                    <Translate contentKey="nissanApp.syonetAttendantLead.addressStateID">Address State ID</Translate>
                  </Label>
                  <AvField id="syonet-attendant-lead-addressStateID" type="text" name="addressStateID" />
                  <UncontrolledTooltip target="addressStateIDLabel">
                    <Translate contentKey="nissanApp.syonetAttendantLead.help.addressStateID" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="addressCityCodeLabel" for="syonet-attendant-lead-addressCityCode">
                    <Translate contentKey="nissanApp.syonetAttendantLead.addressCityCode">Address City Code</Translate>
                  </Label>
                  <AvField id="syonet-attendant-lead-addressCityCode" type="text" name="addressCityCode" />
                  <UncontrolledTooltip target="addressCityCodeLabel">
                    <Translate contentKey="nissanApp.syonetAttendantLead.help.addressCityCode" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="clientGenderLabel" for="syonet-attendant-lead-clientGender">
                    <Translate contentKey="nissanApp.syonetAttendantLead.clientGender">Client Gender</Translate>
                  </Label>
                  <AvField id="syonet-attendant-lead-clientGender" type="text" name="clientGender" />
                  <UncontrolledTooltip target="clientGenderLabel">
                    <Translate contentKey="nissanApp.syonetAttendantLead.help.clientGender" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="clientBirthdayLabel" for="syonet-attendant-lead-clientBirthday">
                    <Translate contentKey="nissanApp.syonetAttendantLead.clientBirthday">Client Birthday</Translate>
                  </Label>
                  <AvInput
                    id="syonet-attendant-lead-clientBirthday"
                    type="datetime-local"
                    className="form-control"
                    name="clientBirthday"
                    placeholder={'YYYY-MM-DD HH:mm'}
                    value={isNew ? null : convertDateTimeFromServer(this.props.syonetAttendantLeadEntity.clientBirthday)}
                  />
                  <UncontrolledTooltip target="clientBirthdayLabel">
                    <Translate contentKey="nissanApp.syonetAttendantLead.help.clientBirthday" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="clienteEmailLabel" for="syonet-attendant-lead-clienteEmail">
                    <Translate contentKey="nissanApp.syonetAttendantLead.clienteEmail">Cliente Email</Translate>
                  </Label>
                  <AvField id="syonet-attendant-lead-clienteEmail" type="text" name="clienteEmail" />
                  <UncontrolledTooltip target="clienteEmailLabel">
                    <Translate contentKey="nissanApp.syonetAttendantLead.help.clienteEmail" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="clienteEmail2Label" for="syonet-attendant-lead-clienteEmail2">
                    <Translate contentKey="nissanApp.syonetAttendantLead.clienteEmail2">Cliente Email 2</Translate>
                  </Label>
                  <AvField id="syonet-attendant-lead-clienteEmail2" type="text" name="clienteEmail2" />
                  <UncontrolledTooltip target="clienteEmail2Label">
                    <Translate contentKey="nissanApp.syonetAttendantLead.help.clienteEmail2" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="residentialPhoneAreaCodeLabel" for="syonet-attendant-lead-residentialPhoneAreaCode">
                    <Translate contentKey="nissanApp.syonetAttendantLead.residentialPhoneAreaCode">Residential Phone Area Code</Translate>
                  </Label>
                  <AvField
                    id="syonet-attendant-lead-residentialPhoneAreaCode"
                    type="string"
                    className="form-control"
                    name="residentialPhoneAreaCode"
                  />
                  <UncontrolledTooltip target="residentialPhoneAreaCodeLabel">
                    <Translate contentKey="nissanApp.syonetAttendantLead.help.residentialPhoneAreaCode" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="residentialPhoneNumberLabel" for="syonet-attendant-lead-residentialPhoneNumber">
                    <Translate contentKey="nissanApp.syonetAttendantLead.residentialPhoneNumber">Residential Phone Number</Translate>
                  </Label>
                  <AvField
                    id="syonet-attendant-lead-residentialPhoneNumber"
                    type="string"
                    className="form-control"
                    name="residentialPhoneNumber"
                  />
                  <UncontrolledTooltip target="residentialPhoneNumberLabel">
                    <Translate contentKey="nissanApp.syonetAttendantLead.help.residentialPhoneNumber" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="cellPhoneAreaCodeLabel" for="syonet-attendant-lead-cellPhoneAreaCode">
                    <Translate contentKey="nissanApp.syonetAttendantLead.cellPhoneAreaCode">Cell Phone Area Code</Translate>
                  </Label>
                  <AvField id="syonet-attendant-lead-cellPhoneAreaCode" type="string" className="form-control" name="cellPhoneAreaCode" />
                  <UncontrolledTooltip target="cellPhoneAreaCodeLabel">
                    <Translate contentKey="nissanApp.syonetAttendantLead.help.cellPhoneAreaCode" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="cellPhoneNumberLabel" for="syonet-attendant-lead-cellPhoneNumber">
                    <Translate contentKey="nissanApp.syonetAttendantLead.cellPhoneNumber">Cell Phone Number</Translate>
                  </Label>
                  <AvField id="syonet-attendant-lead-cellPhoneNumber" type="string" className="form-control" name="cellPhoneNumber" />
                  <UncontrolledTooltip target="cellPhoneNumberLabel">
                    <Translate contentKey="nissanApp.syonetAttendantLead.help.cellPhoneNumber" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="commercialPhoneAreaCodeLabel" for="syonet-attendant-lead-commercialPhoneAreaCode">
                    <Translate contentKey="nissanApp.syonetAttendantLead.commercialPhoneAreaCode">Commercial Phone Area Code</Translate>
                  </Label>
                  <AvField
                    id="syonet-attendant-lead-commercialPhoneAreaCode"
                    type="string"
                    className="form-control"
                    name="commercialPhoneAreaCode"
                  />
                  <UncontrolledTooltip target="commercialPhoneAreaCodeLabel">
                    <Translate contentKey="nissanApp.syonetAttendantLead.help.commercialPhoneAreaCode" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label id="commercialPhoneNumberLabel" for="syonet-attendant-lead-commercialPhoneNumber">
                    <Translate contentKey="nissanApp.syonetAttendantLead.commercialPhoneNumber">Commercial Phone Number</Translate>
                  </Label>
                  <AvField
                    id="syonet-attendant-lead-commercialPhoneNumber"
                    type="string"
                    className="form-control"
                    name="commercialPhoneNumber"
                  />
                  <UncontrolledTooltip target="commercialPhoneNumberLabel">
                    <Translate contentKey="nissanApp.syonetAttendantLead.help.commercialPhoneNumber" />
                  </UncontrolledTooltip>
                </AvGroup>
                <AvGroup>
                  <Label for="syonet-attendant-lead-syonetLead">
                    <Translate contentKey="nissanApp.syonetAttendantLead.syonetLead">Syonet Lead</Translate>
                  </Label>
                  <AvInput id="syonet-attendant-lead-syonetLead" type="select" className="form-control" name="syonetLeadId">
                    <option value="" key="0" />
                    {syonetLeads
                      ? syonetLeads.map(otherEntity => (
                          <option value={otherEntity.id} key={otherEntity.id}>
                            {otherEntity.id}
                          </option>
                        ))
                      : null}
                  </AvInput>
                </AvGroup>
                <Button tag={Link} id="cancel-save" to="/entity/syonet-attendant-lead" replace color="info">
                  <FontAwesomeIcon icon="arrow-left" />
                  &nbsp;
                  <span className="d-none d-md-inline">
                    <Translate contentKey="entity.action.back">Back</Translate>
                  </span>
                </Button>
                &nbsp;
                <Button color="primary" id="save-entity" type="submit" disabled={updating}>
                  <FontAwesomeIcon icon="save" />
                  &nbsp;
                  <Translate contentKey="entity.action.save">Save</Translate>
                </Button>
              </AvForm>
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (storeState: IRootState) => ({
  syonetLeads: storeState.syonetLead.entities,
  syonetAttendantLeadEntity: storeState.syonetAttendantLead.entity,
  loading: storeState.syonetAttendantLead.loading,
  updating: storeState.syonetAttendantLead.updating,
  updateSuccess: storeState.syonetAttendantLead.updateSuccess
});

const mapDispatchToProps = {
  getSyonetLeads,
  getEntity,
  updateEntity,
  createEntity,
  reset
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SyonetAttendantLeadUpdate);
