import { IColor } from 'app/shared/model/color.model';

export interface INissanModel {
  id?: number;
  eim?: string;
  semiClearVehicleCode?: string;
  modelCode?: string;
  model?: string;
  version?: string;
  modelProductionYear?: number;
  modelYear?: number;
  engineCode?: string;
  engineDescription?: string;
  denatranID?: number;
  fuelType?: number;
  colors?: IColor[];
  responseGammaId?: number;
}

export const defaultValue: Readonly<INissanModel> = {};
