import { Moment } from 'moment';
import { INissanModel } from 'app/shared/model/nissan-model.model';
import { IResponseGammaMessage } from 'app/shared/model/response-gamma-message.model';

export interface IResponseGamma {
  id?: number;
  receivedIn?: Moment;
  synchronizedIn?: Moment;
  executionGammaId?: number;
  statusCodeId?: number;
  models?: INissanModel[];
  messages?: IResponseGammaMessage[];
}

export const defaultValue: Readonly<IResponseGamma> = {};
