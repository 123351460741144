import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, UncontrolledTooltip, Row, Col } from 'reactstrap';
// tslint:disable-next-line:no-unused-variable
import { Translate, ICrudGetAction, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntity } from './client-qualification-response.reducer';
import { IClientQualificationResponse } from 'app/shared/model/client-qualification-response.model';
// tslint:disable-next-line:no-unused-variable
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';

export interface IClientQualificationResponseDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export class ClientQualificationResponseDetail extends React.Component<IClientQualificationResponseDetailProps> {
  componentDidMount() {
    this.props.getEntity(this.props.match.params.id);
  }

  render() {
    const { clientQualificationResponseEntity } = this.props;
    return (
      <Row>
        <Col md="8">
          <h2>
            <Translate contentKey="nissanApp.clientQualificationResponse.detail.title">ClientQualificationResponse</Translate> [
            <b>{clientQualificationResponseEntity.id}</b>]
          </h2>
          <dl className="jh-entity-details">
            <dt>
              <span id="receivedIn">
                <Translate contentKey="nissanApp.clientQualificationResponse.receivedIn">Received In</Translate>
              </span>
              <UncontrolledTooltip target="receivedIn">
                <Translate contentKey="nissanApp.clientQualificationResponse.help.receivedIn" />
              </UncontrolledTooltip>
            </dt>
            <dd>
              <TextFormat value={clientQualificationResponseEntity.receivedIn} type="date" format={APP_DATE_FORMAT} />
            </dd>
            <dt>
              <span id="synchronizedIn">
                <Translate contentKey="nissanApp.clientQualificationResponse.synchronizedIn">Synchronized In</Translate>
              </span>
              <UncontrolledTooltip target="synchronizedIn">
                <Translate contentKey="nissanApp.clientQualificationResponse.help.synchronizedIn" />
              </UncontrolledTooltip>
            </dt>
            <dd>
              <TextFormat value={clientQualificationResponseEntity.synchronizedIn} type="date" format={APP_DATE_FORMAT} />
            </dd>
            <dt>
              <Translate contentKey="nissanApp.clientQualificationResponse.clientQualificationSync">Client Qualification Sync</Translate>
            </dt>
            <dd>
              {clientQualificationResponseEntity.clientQualificationSyncId
                ? clientQualificationResponseEntity.clientQualificationSyncId
                : ''}
            </dd>
            <dt>
              <Translate contentKey="nissanApp.clientQualificationResponse.statusCode">Status Code</Translate>
            </dt>
            <dd>{clientQualificationResponseEntity.statusCodeId ? clientQualificationResponseEntity.statusCodeId : ''}</dd>
          </dl>
          <Button tag={Link} to="/entity/client-qualification-response" replace color="info">
            <FontAwesomeIcon icon="arrow-left" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.back">Back</Translate>
            </span>
          </Button>
          &nbsp;
          <Button
            tag={Link}
            to={`/entity/client-qualification-response/${clientQualificationResponseEntity.id}/edit`}
            replace
            color="primary"
          >
            <FontAwesomeIcon icon="pencil-alt" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.edit">Edit</Translate>
            </span>
          </Button>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = ({ clientQualificationResponse }: IRootState) => ({
  clientQualificationResponseEntity: clientQualificationResponse.entity
});

const mapDispatchToProps = { getEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ClientQualificationResponseDetail);
