import axios from 'axios';
import {
  ICrudSearchAction,
  parseHeaderForLinks,
  loadMoreDataWhenScrolled,
  ICrudGetAction,
  ICrudGetAllAction,
  ICrudPutAction,
  ICrudDeleteAction
} from 'react-jhipster';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

import { ISchedulingLeadMessage, defaultValue } from 'app/shared/model/scheduling-lead-message.model';

export const ACTION_TYPES = {
  SEARCH_SCHEDULINGLEADMESSAGES: 'schedulingLeadMessage/SEARCH_SCHEDULINGLEADMESSAGES',
  FETCH_SCHEDULINGLEADMESSAGE_LIST: 'schedulingLeadMessage/FETCH_SCHEDULINGLEADMESSAGE_LIST',
  FETCH_SCHEDULINGLEADMESSAGE: 'schedulingLeadMessage/FETCH_SCHEDULINGLEADMESSAGE',
  CREATE_SCHEDULINGLEADMESSAGE: 'schedulingLeadMessage/CREATE_SCHEDULINGLEADMESSAGE',
  UPDATE_SCHEDULINGLEADMESSAGE: 'schedulingLeadMessage/UPDATE_SCHEDULINGLEADMESSAGE',
  DELETE_SCHEDULINGLEADMESSAGE: 'schedulingLeadMessage/DELETE_SCHEDULINGLEADMESSAGE',
  RESET: 'schedulingLeadMessage/RESET'
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<ISchedulingLeadMessage>,
  entity: defaultValue,
  links: { next: 0 },
  updating: false,
  totalItems: 0,
  updateSuccess: false
};

export type SchedulingLeadMessageState = Readonly<typeof initialState>;

// Reducer

export default (state: SchedulingLeadMessageState = initialState, action): SchedulingLeadMessageState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.SEARCH_SCHEDULINGLEADMESSAGES):
    case REQUEST(ACTION_TYPES.FETCH_SCHEDULINGLEADMESSAGE_LIST):
    case REQUEST(ACTION_TYPES.FETCH_SCHEDULINGLEADMESSAGE):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true
      };
    case REQUEST(ACTION_TYPES.CREATE_SCHEDULINGLEADMESSAGE):
    case REQUEST(ACTION_TYPES.UPDATE_SCHEDULINGLEADMESSAGE):
    case REQUEST(ACTION_TYPES.DELETE_SCHEDULINGLEADMESSAGE):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true
      };
    case FAILURE(ACTION_TYPES.SEARCH_SCHEDULINGLEADMESSAGES):
    case FAILURE(ACTION_TYPES.FETCH_SCHEDULINGLEADMESSAGE_LIST):
    case FAILURE(ACTION_TYPES.FETCH_SCHEDULINGLEADMESSAGE):
    case FAILURE(ACTION_TYPES.CREATE_SCHEDULINGLEADMESSAGE):
    case FAILURE(ACTION_TYPES.UPDATE_SCHEDULINGLEADMESSAGE):
    case FAILURE(ACTION_TYPES.DELETE_SCHEDULINGLEADMESSAGE):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload
      };
    case SUCCESS(ACTION_TYPES.SEARCH_SCHEDULINGLEADMESSAGES):
    case SUCCESS(ACTION_TYPES.FETCH_SCHEDULINGLEADMESSAGE_LIST):
      const links = parseHeaderForLinks(action.payload.headers.link);
      return {
        ...state,
        links,
        loading: false,
        totalItems: action.payload.headers['x-total-count'],
        entities: loadMoreDataWhenScrolled(state.entities, action.payload.data, links)
      };
    case SUCCESS(ACTION_TYPES.FETCH_SCHEDULINGLEADMESSAGE):
      return {
        ...state,
        loading: false,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.CREATE_SCHEDULINGLEADMESSAGE):
    case SUCCESS(ACTION_TYPES.UPDATE_SCHEDULINGLEADMESSAGE):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.DELETE_SCHEDULINGLEADMESSAGE):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {}
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

const apiUrl = 'api/scheduling-lead-messages';
const apiSearchUrl = 'api/_search/scheduling-lead-messages';

// Actions

export const getSearchEntities: ICrudSearchAction<ISchedulingLeadMessage> = (query, page, size, sort) => ({
  type: ACTION_TYPES.SEARCH_SCHEDULINGLEADMESSAGES,
  payload: axios.get<ISchedulingLeadMessage>(`${apiSearchUrl}?query=${query}${sort ? `&page=${page}&size=${size}&sort=${sort}` : ''}`)
});

export const getEntities: ICrudGetAllAction<ISchedulingLeadMessage> = (page, size, sort) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.FETCH_SCHEDULINGLEADMESSAGE_LIST,
    payload: axios.get<ISchedulingLeadMessage>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`)
  };
};

export const getEntity: ICrudGetAction<ISchedulingLeadMessage> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_SCHEDULINGLEADMESSAGE,
    payload: axios.get<ISchedulingLeadMessage>(requestUrl)
  };
};

export const createEntity: ICrudPutAction<ISchedulingLeadMessage> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_SCHEDULINGLEADMESSAGE,
    payload: axios.post(apiUrl, cleanEntity(entity))
  });
  return result;
};

export const updateEntity: ICrudPutAction<ISchedulingLeadMessage> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_SCHEDULINGLEADMESSAGE,
    payload: axios.put(apiUrl, cleanEntity(entity))
  });
  return result;
};

export const deleteEntity: ICrudDeleteAction<ISchedulingLeadMessage> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_SCHEDULINGLEADMESSAGE,
    payload: axios.delete(requestUrl)
  });
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET
});
