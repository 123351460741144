import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import SchedulingLeadResponse from './scheduling-lead-response';
import SchedulingLeadResponseDetail from './scheduling-lead-response-detail';
import SchedulingLeadResponseUpdate from './scheduling-lead-response-update';
import SchedulingLeadResponseDeleteDialog from './scheduling-lead-response-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={SchedulingLeadResponseUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={SchedulingLeadResponseUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={SchedulingLeadResponseDetail} />
      <ErrorBoundaryRoute path={match.url} component={SchedulingLeadResponse} />
    </Switch>
    <ErrorBoundaryRoute path={`${match.url}/:id/delete`} component={SchedulingLeadResponseDeleteDialog} />
  </>
);

export default Routes;
