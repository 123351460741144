export interface INegotiationVehicleOnTrade {
  id?: number;
  vehicleCode?: string;
  vehicleNissan?: number;
  vehicleExchangeDescription?: string;
  exchangeModelCode?: string;
  interestedEimCode?: string;
  negotiatedColorCode?: string;
  modelYearVehicleNegotiated?: number;
  vehicleAmount?: number;
  syonetNegotiationId?: number;
}

export const defaultValue: Readonly<INegotiationVehicleOnTrade> = {};
